var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-row flex-warp flex-space-between" },
    _vm._l(_vm.list, function(item, index) {
      return _c(
        "div",
        {
          key: "knowledge_goods_" + index,
          staticClass: "flex-row",
          staticStyle: { width: "calc(50% - 10px)" }
        },
        [
          index < _vm.size
            ? _c(
                "div",
                [
                  _c("resource-item", {
                    attrs: {
                      type: "smallPicture",
                      card: _vm.card,
                      resource: item,
                      "cur-icon-index": _vm.recommendRule
                        ? index + 2
                        : index + 1,
                      "is-rank-list": _vm.type === "ranking_list"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "hidden-line",
                      staticStyle: { "margin-top": "10px" },
                      style: { width: _vm.card ? "144px" : "165px" }
                    },
                    [
                      _c("span", { staticClass: "live-title" }, [
                        _vm._v(_vm._s(item.title))
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _vm.type === "knowledge_goods"
                    ? _c(
                        "div",
                        {
                          staticClass: "flex-row flex-space-between",
                          staticStyle: { margin: "5px 0 15px 0", width: "100%" }
                        },
                        [
                          _c("resource-type", {
                            attrs: { "resource-type": item.resourceType }
                          }),
                          _vm._v(" "),
                          item.price && item.price !== 0 && !item.vipOnly
                            ? _c("div", { staticClass: "price" }, [
                                item.discount > 0 && item.discount < 1
                                  ? _c("span", { staticClass: "price" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.priceShowByCent(
                                            item.discount > 0 &&
                                              item.discount < 1
                                              ? item.price * item.discount
                                              : item.price
                                          )
                                        )
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    class: [
                                      item.discount > 0 && item.discount < 1
                                        ? "discount-original-price"
                                        : "price"
                                    ]
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.priceShowByCent(item.price))
                                    )
                                  ]
                                )
                              ])
                            : !item.vipOnly
                            ? _c("span", { staticClass: "price" }, [
                                _vm._v("免费")
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.type === "ranking_list"
                    ? _c("ranking-info", {
                        staticStyle: { "margin-top": "5px" },
                        attrs: {
                          content: _vm.content,
                          value: item,
                          "price-options": Object.assign({}, _vm.options, {
                            priceColor: "#F85862"
                          })
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }