var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "draggable",
    _vm._b(
      {
        staticStyle: { width: "100%" },
        attrs: { "ghost-class": "ghost" },
        on: { end: _vm.handleMoveEnd, add: _vm.handleWidgetAdd },
        model: {
          value: _vm.data.list,
          callback: function($$v) {
            _vm.$set(_vm.data, "list", $$v)
          },
          expression: "data.list"
        }
      },
      "draggable",
      { group: "components", animation: 200 },
      false
    ),
    [
      _c(
        "transition-group",
        {
          staticClass: "widget-form-list",
          attrs: { name: "fade", tag: "div" }
        },
        [
          _vm._l(_vm.data.list, function(element, index) {
            return [
              _c(
                "div",
                { key: index, staticClass: "relative-list" },
                [
                  element && element.key
                    ? _c("widget-form-item", {
                        key: element.key,
                        staticClass: "center-scrollbar-list",
                        attrs: {
                          "is-cloud-retail-partner": _vm.isCloudRetailPartner,
                          "html-data": _vm.htmlData,
                          element: element,
                          "element-key": element.key,
                          "element-index": index
                        },
                        on: {
                          changeTabs: _vm.changeTabs,
                          handleSelectWidget: _vm.handleSelectWidget
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activeIndex === index
                    ? _c("div", { staticClass: "active-border" })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activeIndex === index
                    ? _c("draggable-options", {
                        attrs: { index: index, "element-data": element },
                        on: {
                          handleActiveIndex: _vm.handleActiveIndex,
                          handleSelectWidget: _vm.handleSelectWidget
                        },
                        model: {
                          value: _vm.data.list,
                          callback: function($$v) {
                            _vm.$set(_vm.data, "list", $$v)
                          },
                          expression: "data.list"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }