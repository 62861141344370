var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "material-dialogue" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择图片",
            width: "1000px",
            visible: _vm.visible,
            "before-close": _vm.cancelDialogue,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            },
            open: _vm.initData
          }
        },
        [
          _c("div", { staticClass: "flex-row" }, [
            _c(
              "div",
              { staticClass: "catalogue" },
              [
                _c("catalogue", {
                  attrs: {
                    open: _vm.open,
                    "material-classification-open-id":
                      _vm.materialClassificationOpenId,
                    "is-personal": _vm.isPersonal,
                    refresh: _vm.refresh
                  },
                  on: {
                    "update:refresh": function($event) {
                      _vm.refresh = $event
                    },
                    handleClick: _vm.handleClick
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "material-content" },
              [
                _c("material-content", {
                  attrs: {
                    "open-id": _vm.openId,
                    "is-personal": _vm.personal,
                    "close-dialog": _vm.visible,
                    "material-open-id": _vm.materialOpenId
                  },
                  on: {
                    selectImgResult: _vm.selectImgResult,
                    refreshCatalogue: _vm.refreshCatalogue
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.cancelDialogue } }, [
                _vm._v("取消")
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitMaterial }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }