var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-item",
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.handleNotTypeSelectWidget(_vm.element)
        }
      }
    },
    [
      _vm.element.type === "types_navigate"
        ? _c(
            "div",
            {
              style: {
                "padding-top": _vm.element.options.spacingTop ? "15px" : 0,
                "padding-bottom": _vm.element.options.spacingBottom
                  ? "15px"
                  : 0,
                color: _vm.element.options.color.font
              }
            },
            [
              _c("div", { class: [_vm.getCard(_vm.element)] }, [
                _c(
                  "div",
                  {
                    staticClass: "types-navigate",
                    class: [
                      _vm.element.options.appearance === "card"
                        ? "card-inner"
                        : ""
                    ],
                    style: { background: _vm.element.options.color.background }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "type-list",
                        staticStyle: { padding: "0 15px" }
                      },
                      _vm._l(_vm.element.content, function(item, index) {
                        return _c(
                          "div",
                          {
                            key: item.id,
                            class: ["tab-item"],
                            style: {
                              width: _vm.getTypeClass(_vm.element),
                              "font-size": "14px",
                              "margin-right":
                                _vm.getTypeClass(_vm.element) === "auto"
                                  ? "20px"
                                  : 0,
                              height: "25px"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                style: {
                                  color:
                                    _vm.activeName === index
                                      ? _vm.element.options.color.activityFont
                                      : _vm.element.options.color.font,
                                  "padding-bottom": "5px",
                                  "border-bottom":
                                    _vm.activeName === index
                                      ? "2px solid " +
                                        _vm.element.options.color.underline
                                      : "2px solid transparent"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.changeTypeNav(index)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(item.title) +
                                    "\n              "
                                )
                              ]
                            )
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.element.content, function(itemData, indexDrag) {
                      return _c(
                        "div",
                        { key: indexDrag },
                        [
                          indexDrag === _vm.activeName
                            ? _c(
                                "draggable",
                                _vm._b(
                                  {
                                    attrs: { "ghost-class": "ghost" },
                                    on: {
                                      end: _vm.handleMoveEnd,
                                      add: _vm.handleTypeAdd
                                    },
                                    model: {
                                      value:
                                        _vm.element.content[_vm.activeName]
                                          .parts,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.element.content[_vm.activeName],
                                          "parts",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "element.content[activeName].parts"
                                    }
                                  },
                                  "draggable",
                                  { group: "components", animation: 200 },
                                  false
                                ),
                                [
                                  _c(
                                    "transition-group",
                                    {
                                      staticClass: "type-draggable",
                                      attrs: { name: "fade", tag: "div" }
                                    },
                                    [
                                      _vm._l(
                                        _vm.element.content[_vm.activeName]
                                          .parts,
                                        function(elementItem, index) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                key: index,
                                                staticClass:
                                                  "types-relative-list",
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.handleTypeChoose(
                                                      elementItem.model,
                                                      index
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("micro-page-item", {
                                                  attrs: {
                                                    "html-data": _vm.htmlData,
                                                    "is-cloud-retail-partner":
                                                      _vm.isCloudRetailPartner,
                                                    element: elementItem,
                                                    "element-key":
                                                      elementItem.key,
                                                    "is-type-navigate":
                                                      _vm.element.options
                                                        .appearance === "card"
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _vm.activeTypeIndex === index &&
                                                _vm.activeTypeModel ===
                                                  elementItem.model
                                                  ? _c("div", {
                                                      staticClass:
                                                        "active-border"
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.activeTypeIndex === index &&
                                                _vm.activeTypeModel ===
                                                  elementItem.model
                                                  ? _c("draggable-options", {
                                                      attrs: {
                                                        index: index,
                                                        "element-data":
                                                          _vm.element.content[
                                                            _vm.activeName
                                                          ].parts[
                                                            _vm.activeTypeIndex
                                                          ]
                                                      },
                                                      on: {
                                                        handleActiveIndex:
                                                          _vm.handleActiveIndex,
                                                        handleSelectWidget:
                                                          _vm.handleSelectType
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.element.content[
                                                            _vm.activeName
                                                          ].parts,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.element.content[
                                                              _vm.activeName
                                                            ],
                                                            "parts",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "element.content[activeName].parts"
                                                      }
                                                    })
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              ])
            ]
          )
        : _c("micro-page-item", {
            attrs: {
              "html-data": _vm.htmlData,
              "is-cloud-retail-partner": _vm.isCloudRetailPartner,
              element: _vm.element,
              "element-key": _vm.elementKey
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }