import { render, staticRenderFns } from "./DefaultSyncInfo.vue?vue&type=template&id=23e77a08&"
import script from "./DefaultSyncInfo.vue?vue&type=script&lang=js&"
export * from "./DefaultSyncInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/front-end_micro-page_oo0i/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('23e77a08')) {
      api.createRecord('23e77a08', component.options)
    } else {
      api.reload('23e77a08', component.options)
    }
    module.hot.accept("./DefaultSyncInfo.vue?vue&type=template&id=23e77a08&", function () {
      api.rerender('23e77a08', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/SelectDialogue/TableContent/ProductContent/Selector/SyncInfo/DefaultSyncInfo.vue"
export default component.exports