"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.function.name");

var _readOverview = _interopRequireDefault(require("@/assets/wordTrems/read-overview.png"));

var _newWriteOverview = _interopRequireDefault(require("@/assets/wordTrems/new-write-overview.png"));

var _ReadItem = _interopRequireDefault(require("./ReadItem"));

var _common = require("../../../utils/common");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'WordItem',
  components: {
    ReadItem: _ReadItem.default
  },
  props: {
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    isRead: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      readView: _readOverview.default,
      writeView: _newWriteOverview.default,
      isMore: undefined
    };
  },
  computed: {
    overviewHeight: function overviewHeight() {
      if (this.isRead) {
        return '';
      }

      return this.options.appearance !== 'card' ? {
        height: '63px'
      } : {
        height: '57px'
      };
    }
  },
  methods: {
    newImgSrc: function newImgSrc(val) {
      if (!val.name) {
        return undefined;
      }

      var data = escape(val.name).replace(/\%u/g, '');

      if (!data) {
        return undefined;
      }

      return "".concat(process.env.VUE_APP_IMG_URL_PREFIX, "production/assets/characterKaiTi/").concat(data, ".svg");
    },
    isHidden: function isHidden(index) {
      if (!this.options.isCharacterHidden) {
        return true;
      }

      if (this.isMore === 'up') {
        return true;
      }

      if (this.options.hiddenNum) {
        return parseInt(this.options.hiddenNum, 0) >= index + 1;
      }

      return true;
    },
    handleMore: function handleMore(val) {
      this.isMore = val === 'up' ? 'down' : 'up';
    },
    getPinYin: function getPinYin(val) {
      if (val.pinyin) {
        return val.pinyin;
      }

      return val.pinyinList[0].pinyin;
    }
  }
};
exports.default = _default2;