var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ranking-info" }, [
    _c(
      "div",
      { staticClass: "ranking-price-div" },
      [
        _vm.value.resourceType === 3 && _vm.value.albumContentNums
          ? _c("div", { staticClass: "common-pages" }, [
              _vm._v(_vm._s(_vm.value.albumContentNums) + "页")
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.value.resourceType === 4 && _vm.value.albumContentNums
          ? _c("div", { staticClass: "common-pages" }, [
              _vm._v(_vm._s(_vm.value.albumContentNums) + "讲")
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.value.resourceType === 8
          ? _c("div", { staticClass: "common-pages" }, [
              _vm._v(_vm._s(_vm.value.fileContentNums) + "个文件")
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("audio-book-price", {
          attrs: { value: _vm.value, options: _vm.priceOptions }
        })
      ],
      1
    ),
    _vm._v(" "),
    _vm.showDownload
      ? _c("div", { staticClass: "page-view" }, [
          _vm.value.viewNums && _vm.value.viewNums !== 0
            ? _c("img", {
                staticClass: "page-view-icon",
                attrs: { src: require("@/assets/microPage/page-view-icon.png") }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.value.viewNums && _vm.value.viewNums !== 0
            ? _c("span", [
                _vm._v(
                  _vm._s(_vm._f("formatNumberWithUnit")(_vm.value.viewNums))
                )
              ])
            : _vm._e()
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.showViewNums
      ? _c("div", { staticClass: "page-view" }, [
          _vm.value.downloadNums && _vm.value.downloadNums !== 0
            ? _c("img", {
                staticClass: "page-view-icon",
                attrs: { src: require("@/assets/microPage/download.png") }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.value.downloadNums && _vm.value.downloadNums !== 0
            ? _c("span", [
                _vm._v(
                  _vm._s(_vm._f("formatNumberWithUnit")(_vm.value.downloadNums))
                )
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }