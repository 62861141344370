"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _search = _interopRequireDefault(require("@/components/SearchUser/search.vue"));

var _index = _interopRequireDefault(require("./SelectResult/index"));

var _BookContent = _interopRequireDefault(require("./TableContent/BookContent"));

var _CouponContent = _interopRequireDefault(require("./TableContent/CouponContent"));

var _FamousTeacher = _interopRequireDefault(require("./TableContent/FamousTeacher"));

var _LiveContent = _interopRequireDefault(require("./TableContent/LiveContent"));

var _main = _interopRequireDefault(require("../../organizationLibs/powerCache/v1.0/main"));

var _microPage = require("../../utils/microPage");

var _TestPaper = _interopRequireDefault(require("./TableContent/TestPaper"));

var _TestQuestions = _interopRequireDefault(require("./TableContent/TestQuestions"));

var _ProductContent = _interopRequireDefault(require("./TableContent/ProductContent"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'SelectDialogue',
  components: {
    ProductContent: _ProductContent.default,
    TestQuestions: _TestQuestions.default,
    TestPaper: _TestPaper.default,
    LiveContent: _LiveContent.default,
    FamousTeacher: _FamousTeacher.default,
    CouponContent: _CouponContent.default,
    SelectResult: _index.default,
    BookContent: _BookContent.default,
    SearchUser: _search.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: undefined
    },
    valueResult: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    modelKey: {
      type: String,
      default: ''
    },
    // 用于多个书架缓存时区分
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      title: '选择直播课程',
      resultList: [],
      checkResultList: [],
      tableCheckList: [],
      storeName: undefined,
      beforeResultList: [],
      partnerOpenId: this.$store.state.microPage.partnerOpenId
    };
  },
  watch: {
    type: {
      deep: true,
      immediate: true,
      handler: function handler(val) {
        if (val) {
          this.getList();
        }
      }
    },
    // 监听 点击恢复 列表选中数据为空
    valueResult: {
      deep: true,
      immediate: true,
      handler: function handler(val) {
        this.tableCheckList = val;
        this.resultList = val;
      }
    }
  },
  methods: {
    receivePartnerId: function receivePartnerId(val) {
      this.partnerOpenId = val;

      if (this.$refs.bookContent) {
        this.$refs.bookContent.handlePartner(val);
      }
    },
    handlePartner: function handlePartner(val) {
      this.partnerOpenId = val;
    },
    // 获取列表选中数据
    handleChecked: function handleChecked(val) {
      this.resultList = val;
    },
    // 区分
    getList: function getList() {
      var _refreshSelectStore = (0, _microPage.refreshSelectStore)(this.type),
          storeName = _refreshSelectStore.storeName,
          title = _refreshSelectStore.title;

      this.storeName = storeName;
      this.title = title;
    },
    cancelDialogue: function cancelDialogue() {
      this.$emit('update:visible', false);
    },
    submitDialogue: function submitDialogue() {
      if (this.checkResultList.length <= 0) {
        this.$message.warning({
          message: "\u8BF7".concat(this.title)
        });
        return;
      }

      this.beforeResultList = this.checkResultList;
      this.$emit('submitDialogue', this.checkResultList); // 存放缓存

      if (this.type === 'book_shelf' || this.type === 'coupon' || this.type === 'test_paper' || this.type === 'test_questions' || this.type === 'cloud_retail_goods') {
        (0, _microPage.setTypesNavigateStore)(this.checkResultList, this.storeName, this.modelKey);
        return;
      }

      _main.default.put(this.storeName, this.checkResultList);
    },
    handleResult: function handleResult(val) {
      this.checkResultList = val;
    },
    handleDeleteResult: function handleDeleteResult(val) {
      this.tableCheckList = val;
    }
  }
};
exports.default = _default2;