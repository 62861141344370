"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCookie = getCookie;
exports.setCookie = setCookie;
exports.removeCookie = removeCookie;

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var _main = _interopRequireDefault(require("../../typeIs/main"));

var _config = require("../config");

/**
 * 获取 cookie
 *
 * @param {string} key
 * @returns {string|undefined}
 */
function getCookie(key) {
  try {
    // 如果 key 不是有效的 string，则返回 undefined
    if (!_main.default.isString(key) || _main.default.isEmpty(key)) {
      return undefined;
    }

    return _jsCookie.default.get(key, {
      domain: (0, _config.getConfig)().domain,
      path: (0, _config.getConfig)().path
    });
  } catch (e) {
    console.error(e);
    return undefined;
  }
}
/**
 * 设置 cookie
 *
 * @param {string} key
 * @param {string} value
 * @param {number} expires
 * @returns {string}
 */


function setCookie(key, value) {
  var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
      expires = _ref.expires;

  try {
    // 如果 key 不是有效的 string，则不进行保存操作
    if (!_main.default.isString(key) || _main.default.isEmpty(key)) {
      return value;
    } // 把 expires 转化为 date 对象


    var expiresDate;

    if (expires) {
      expiresDate = new Date();
      expiresDate.setTime(expiresDate.getTime() + expires * 1000);
    } // 设置 cookie


    _jsCookie.default.set(key, value, {
      domain: (0, _config.getConfig)().domain,
      path: (0, _config.getConfig)().path,
      expires: expiresDate
    });

    return value;
  } catch (e) {
    console.error(e);
    return value;
  }
}
/**
 * 删除 cookie
 *
 * @param {string} key
 */


function removeCookie(key) {
  try {
    // 如果 key 不是有效的 string，则不进行删除操作
    if (!_main.default.isString(key) || _main.default.isEmpty(key)) {
      return;
    }

    _jsCookie.default.remove(key, {
      domain: (0, _config.getConfig)().domain,
      path: (0, _config.getConfig)().path
    });
  } catch (e) {
    console.error(e);
  }
}