var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-group" },
    [
      _c(
        "el-select",
        {
          staticClass: "resource-type-select",
          attrs: { placeholder: "商品类型", clearable: "" },
          on: { change: _vm.searchChanged },
          model: {
            value: _vm.valueProxy.resourceType,
            callback: function($$v) {
              _vm.$set(_vm.valueProxy, "resourceType", $$v)
            },
            expression: "valueProxy.resourceType"
          }
        },
        _vm._l(_vm.resourceTypeOptions, function(item) {
          return _c("el-option", {
            key: item.value,
            attrs: { label: item.label, value: item.value }
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("el-input", {
        staticClass: "header-search",
        attrs: {
          "prefix-icon": "el-icon-search",
          placeholder: _vm.placeholder,
          clearable: ""
        },
        nativeOn: {
          keyup: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.searchChanged($event)
          }
        },
        model: {
          value: _vm.valueProxy.keyword,
          callback: function($$v) {
            _vm.$set(_vm.valueProxy, "keyword", $$v)
          },
          expression: "valueProxy.keyword"
        }
      }),
      _vm._v(" "),
      _c(
        "search-button",
        { on: { "search-changed": _vm.searchChanged } },
        [
          _c("filter-options", {
            model: {
              value: _vm.valueProxy,
              callback: function($$v) {
                _vm.valueProxy = $$v
              },
              expression: "valueProxy"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.showRemember
        ? _c(
            "div",
            { staticClass: "remember" },
            [
              _c("el-checkbox", {
                staticStyle: { "margin-right": "5px" },
                on: { change: _vm.handleRemember },
                model: {
                  value: _vm.remember,
                  callback: function($$v) {
                    _vm.remember = $$v
                  },
                  expression: "remember"
                }
              }),
              _vm._v("记住所选\n  ")
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }