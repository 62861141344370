var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "micro-rich" }, [
    _vm.content.isShowTitle
      ? _c("span", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.content.title))
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.richText
      ? _c("div", {
          staticClass: "rich-content",
          domProps: { innerHTML: _vm._s(_vm.rich) }
        })
      : _c("div", { staticClass: "no-content" }, [_vm._v("富文本内容")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }