var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form-item",
        { attrs: { label: "是否套装" } },
        [
          _c(
            "el-radio-group",
            {
              staticClass: "single-line",
              model: {
                value: _vm.valueProxy.isSuit,
                callback: function($$v) {
                  _vm.$set(_vm.valueProxy, "isSuit", $$v)
                },
                expression: "valueProxy.isSuit"
              }
            },
            _vm._l(_vm.options, function(i) {
              return _c(
                "el-radio-button",
                { key: i.key, attrs: { label: i.key } },
                [_vm._v(_vm._s(i.value))]
              )
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "教辅单品" } },
        [
          _c(
            "el-radio-group",
            {
              staticClass: "single-line",
              model: {
                value: _vm.valueProxy.single,
                callback: function($$v) {
                  _vm.$set(_vm.valueProxy, "single", $$v)
                },
                expression: "valueProxy.single"
              }
            },
            _vm._l(_vm.options, function(i) {
              return _c(
                "el-radio-button",
                { key: i.key, attrs: { label: i.key } },
                [_vm._v(_vm._s(i.value))]
              )
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "有无规格" } },
        [
          _c(
            "el-radio-group",
            {
              staticClass: "single-line",
              model: {
                value: _vm.valueProxy.specificJson,
                callback: function($$v) {
                  _vm.$set(_vm.valueProxy, "specificJson", $$v)
                },
                expression: "valueProxy.specificJson"
              }
            },
            _vm._l(_vm.options, function(i) {
              return _c(
                "el-radio-button",
                { key: i.key, attrs: { label: i.key } },
                [_vm._v(_vm._s(i.value))]
              )
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "主图视频" } },
        [
          _c(
            "el-radio-group",
            {
              staticClass: "single-line",
              model: {
                value: _vm.valueProxy.showVideo,
                callback: function($$v) {
                  _vm.$set(_vm.valueProxy, "showVideo", $$v)
                },
                expression: "valueProxy.showVideo"
              }
            },
            _vm._l(_vm.options, function(i) {
              return _c(
                "el-radio-button",
                { key: i.key, attrs: { label: i.key } },
                [_vm._v(_vm._s(i.value))]
              )
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "是否限购" } },
        [
          _c(
            "el-radio-group",
            {
              staticClass: "single-line",
              model: {
                value: _vm.valueProxy.quota,
                callback: function($$v) {
                  _vm.$set(_vm.valueProxy, "quota", $$v)
                },
                expression: "valueProxy.quota"
              }
            },
            _vm._l(_vm.quota, function(i) {
              return _c(
                "el-radio-button",
                { key: i.key, attrs: { label: i.key } },
                [_vm._v(_vm._s(i.value))]
              )
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "教师可购" } },
        [
          _c(
            "el-radio-group",
            {
              staticClass: "single-line",
              model: {
                value: _vm.valueProxy.teacherBuy,
                callback: function($$v) {
                  _vm.$set(_vm.valueProxy, "teacherBuy", $$v)
                },
                expression: "valueProxy.teacherBuy"
              }
            },
            _vm._l(_vm.teacherBuy, function(i) {
              return _c(
                "el-radio-button",
                { key: i.key, attrs: { label: i.key } },
                [_vm._v(_vm._s(i.value))]
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }