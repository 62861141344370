var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "official-account" },
    [
      _vm.type === "official_account" && _vm.valueProxy.type === 2
        ? _c(
            "div",
            { staticClass: "official-btn margin-bottom-fourteen" },
            [
              _c(
                "el-button",
                {
                  staticClass: "btn",
                  attrs: { icon: "el-icon-plus" },
                  on: {
                    click: function($event) {
                      _vm.visible = true
                    }
                  }
                },
                [_vm._v("选择公众号")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("div", { staticClass: "book-title margin-bottom-fourteen" }, [
            _vm._v(
              _vm._s(_vm.type === "official_account" ? "公众号" : "小程序") +
                "头像"
            )
          ]),
          _vm._v(" "),
          _vm.valueProxy.type === 1
            ? _c("select-material-btn", {
                attrs: {
                  type: _vm.type,
                  "icon-text": "上传头像",
                  path: _vm.valueProxy.headImg,
                  "img-width-height": { width: "100px", height: "100px" }
                },
                on: { submitMaterial: _vm.submitHeadMaterial }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.valueProxy.type === 2 && _vm.valueProxy.headImg
            ? _c("img", {
                staticClass: "official-cover",
                attrs: { src: _vm.valueProxy.headImg }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.valueProxy.type === 2 && !_vm.valueProxy.headImg
            ? _c("div", { staticClass: "official-cover common-no-cover" }, [
                _c("i", { staticClass: "el-icon-picture-outline icon" }),
                _vm._v(" "),
                _c("span", [_vm._v("logo")])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "book-title margin-fourteen" }, [
            _vm._v(
              _vm._s(_vm.type === "official_account" ? "公众号" : "小程序") +
                "二维码"
            )
          ]),
          _vm._v(" "),
          _vm.valueProxy.type === 1
            ? _c("select-material-btn", {
                attrs: {
                  "icon-name": "iconfont icon-black_antOutline-qrcode",
                  type: _vm.type,
                  "icon-text": "上传二维码",
                  path: _vm.valueProxy.qrcodeUrl,
                  "img-width-height": { width: "100px", height: "100px" }
                },
                on: { submitMaterial: _vm.submitMaterial }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.valueProxy.type === 2 &&
          _vm.valueProxy.qrcodeUrl &&
          _vm.type !== "official_attention"
            ? _c("el-image", {
                staticClass: "official-cover",
                attrs: {
                  fit: "cover",
                  src: _vm.getImgUrl(_vm.valueProxy.qrcodeUrl)
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.valueProxy.type === 2 && !_vm.valueProxy.qrcodeUrl
            ? _c("div", { staticClass: "official-cover common-no-cover" }, [
                _c("i", {
                  staticClass: "iconfont icon-black_antOutline-qrcode icon"
                }),
                _vm._v(" "),
                _c("span", [_vm._v("二维码")])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "book-title margin-fourteen" }, [
            _vm._v(
              _vm._s(_vm.type === "official_account" ? "公众号" : "小程序") +
                "名称"
            )
          ]),
          _vm._v(" "),
          _c("el-input", {
            attrs: {
              disabled: _vm.valueProxy.type === 2,
              placeholder:
                _vm.valueProxy.type === "official_account"
                  ? "公众号名称"
                  : "小程序名称"
            },
            model: {
              value: _vm.valueProxy.nickName,
              callback: function($$v) {
                _vm.$set(_vm.valueProxy, "nickName", $$v)
              },
              expression: "valueProxy.nickName"
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "book-title margin-fourteen" }, [
            _vm._v("功能介绍")
          ]),
          _vm._v(" "),
          _c("el-input", {
            attrs: {
              placeholder:
                _vm.valueProxy.type === "official_account"
                  ? "公众号功能介绍"
                  : "小程序功能介绍"
            },
            model: {
              value: _vm.valueProxy.detail,
              callback: function($$v) {
                _vm.$set(_vm.valueProxy, "detail", $$v)
              },
              expression: "valueProxy.detail"
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "book-title margin-fourteen" }, [
            _vm._v("提示文字")
          ]),
          _vm._v(" "),
          _c("el-input", {
            attrs: { placeholder: "长按保存二维码" },
            model: {
              value: _vm.valueProxy.tips,
              callback: function($$v) {
                _vm.$set(_vm.valueProxy, "tips", $$v)
              },
              expression: "valueProxy.tips"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("select-official", {
        attrs: { visible: _vm.visible, value: this.valueProxy },
        on: {
          "update:visible": function($event) {
            _vm.visible = $event
          },
          submit: _vm.submit
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }