"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

var _vueConciseSlider = require("vue-concise-slider");

var _common = require("../../../utils/common");

var _price = require("../../../utils/price");

var _price2 = require("../../../utils/CloudProgarm/price");

var _GoodItemInfo = _interopRequireDefault(require("./GoodItemInfo"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 引入slider组件
var _default2 = {
  name: 'CloudRetailGoods',
  components: {
    GoodItemInfo: _GoodItemInfo.default,
    slider: _vueConciseSlider.slider,
    slideritem: _vueConciseSlider.slideritem
  },
  props: {
    options: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    size: {
      type: [String, Number],
      default: function _default() {
        return undefined;
      }
    },
    showOriginal: {
      type: Boolean,
      default: true
    },
    // 显示原价
    showReality: {
      type: Boolean,
      default: true
    },
    // 显示实际价格
    isTypeCard: {
      type: Boolean,
      default: false
    } // 分类组件是否是card

  },
  data: function data() {
    return {
      priceShowByCent: _price.priceShowByCent,
      priceShow: _price2.priceShow,
      getPriceFn: _price2.getPriceFn,
      sliderinit: {
        pagination: false,
        currentPage: 0
      }
    };
  },
  computed: {
    threeProductWidth: function threeProductWidth() {
      if (this.isTypeCard) {
        return this.options.appearance === 'card' ? '88px' : '98px';
      }

      return this.options.appearance === 'card' ? '98px' : '108px';
    },
    smallProductWidth: function smallProductWidth() {
      if (this.isTypeCard) {
        return this.options.appearance === 'card' ? '132px' : '150px';
      }

      return this.options.appearance === 'card' ? '150px' : '165px';
    },
    cardProductWidth: function cardProductWidth() {
      if (this.isTypeCard) {
        return this.options.appearance === 'card' ? '112px' : '130px';
      }

      return this.options.appearance === 'card' ? '130px' : '145px';
    },
    leftList: function leftList() {
      return this.handleWaterFall(true);
    },
    rightList: function rightList() {
      return this.handleWaterFall(false);
    }
  },
  methods: {
    handleWaterFall: function handleWaterFall(isLeft) {
      var _this = this;

      var right = [];
      var left = [];
      this.list.forEach(function (item, index) {
        if (index > Number(_this.size) - 1) {
          return;
        }

        if ((index + 1) % 2 === 0) {
          right.push(item);
        } else {
          left.push(item);
        }
      });

      if (isLeft) {
        return left;
      }

      return right;
    },
    getShowOriPrice: function getShowOriPrice(val) {
      if (val.resourceType == 22 || val.resourceType == 21) {
        return false;
      }

      if (val.isSingle || !val.isSingle && !val.isHasSpecific || !val.isSingle && val.specificType == 0) {
        var displayPrice = this.priceShow(val.displayPrice);
        return displayPrice !== this.priceShow(val.orginalPrice, true);
      }

      return false;
    },
    getOrginalPrice: function getOrginalPrice(val) {
      if (!val) {
        return '';
      }

      if (val.resourceType == 22 || val.resourceType == 21) {
        return val.orginalPrice > 0 ? this.priceShow(val.orginalPrice) : "".concat(this.priceShow(val.totalPrice), "\u8D77");
      }

      if (val.isSingle || !val.isSingle && !val.isHasSpecific || !val.isSingle && val.specificType == 0) {
        return this.priceShow(val.orginalPrice);
      }

      return (0, _price2.getPriceFn)(val);
    },
    getPriceFnData: function getPriceFnData(val) {
      if (!val) {
        return '';
      }

      return (0, _price2.getPriceFn)(val);
    },
    getIsInventory: function getIsInventory(value) {
      if (value.resourceType == 21 || value.resourceType == 22) {
        return false;
      }

      if (value.isSingle) {
        return value.inventory <= 0;
      } // 非单品 无规格


      if (!value.isHasSpecific) {
        return value.inventory <= 0;
      }

      return false;
    },
    // 处理图片
    getImgUrl: function getImgUrl(cover) {
      var style = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'style/width-limit-32';
      return (0, _common.imageUrlFun)(cover, style);
    }
  }
};
exports.default = _default2;