"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getWordList = getWordList;
exports.checkIsThereWord = checkIsThereWord;
exports.updateWord = updateWord;
exports.deleteWord = deleteWord;
exports.getChineseChars = getChineseChars;

var _main = _interopRequireDefault(require("@/utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/library/admin/chineseChars/"));
/* 获取字列表 */

function getWordList(params) {
  return request.get('findPage', {
    params: params
  });
}
/* 查询字是否存在 */


function checkIsThereWord(chars) {
  return request.get('checkChars', {
    params: {
      chars: chars
    }
  });
}
/* 修改字 */


function updateWord(params, charOpenId) {
  return request.put("updateChars/".concat(charOpenId), params);
}
/* 删除字 */


function deleteWord(charOpenId) {
  return request.delete("deleteChars/".concat(charOpenId));
}
/* 根据多个openIds 获取字 */


function getChineseChars(data) {
  return request.post('getChineseChars', data);
}