"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bookList = bookList;
exports.bookListByOpenIds = bookListByOpenIds;
exports.getAllBookAreaList = getAllBookAreaList;
exports.getAllBookSeries = getAllBookSeries;

var _main = _interopRequireDefault(require("../utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/value-added/admin"));
var dictionaryRequest = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/common/dictionary/admin/"));
/**
 * 查询图书列表【商户端】
 * @param {Object} params
 * @returns {Promise<*>}
 */

function bookList(params) {
  return request.get('/book/', {
    params: params
  });
}
/**
 * 根据openIds
 * @param {Object} params
 * @returns {Promise<*>}
 */


function bookListByOpenIds(params) {
  return request.get('/book/listByOpenIds', {
    params: params
  });
}
/**
 * 查询所有图书地区(不分页)
 * @Params {Object}
 * @returns {Promise<*>}
 */


function getAllBookAreaList(params) {
  return dictionaryRequest.get('/bookArea/all', {
    params: params
  });
}
/**
 * 查询全部图书系列
 * @Params {Object}
 * @returns {Promise<*>}
 */


function getAllBookSeries(params) {
  return request.get('/bookSeries/list', {
    params: params
  });
}