"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _ConditionalFiltering = _interopRequireDefault(require("./ConditionalFiltering"));

var _StatusFiltering = _interopRequireDefault(require("./StatusFiltering"));

var _TimeAndSorting = _interopRequireDefault(require("./TimeAndSorting"));

//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'CPMGeFilterOptionsIndex',
  components: {
    ConditionalFiltering: _ConditionalFiltering.default,
    StatusFiltering: _StatusFiltering.default,
    TimeAndSorting: _TimeAndSorting.default
  },
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  }
};
exports.default = _default2;