"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

var _editIndex = _interopRequireDefault(require("../views/editIndex.vue"));

var _microPage = _interopRequireDefault(require("../views/microPage.vue"));

var _welcome = _interopRequireDefault(require("../views/welcome"));

_vue.default.use(_vueRouter.default);

var routes = [{
  path: '/',
  name: 'editIndex',
  component: _editIndex.default
}, {
  path: '/microPage',
  name: 'MicroPage',
  component: _microPage.default
}, {
  path: '/welcome',
  name: 'welcome',
  component: _welcome.default
}];
var router = new _vueRouter.default({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
});
var _default = router;
exports.default = _default;