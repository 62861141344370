var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "coupon-content" },
    [
      _c("search", { on: { search: _vm.search } }),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          ref: "multipleList",
          staticStyle: { width: "100%" },
          attrs: { data: _vm.list, height: "400px" },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { prop: "title" } }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { prop: "discountTypeName" } }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { prop: "offerContent" } })
        ],
        1
      ),
      _vm._v(" "),
      _c("table-footer", {
        attrs: {
          "footer-check-all": _vm.checkAll,
          query: _vm.query,
          "total-elements": _vm.totalElements
        },
        on: {
          pagination: _vm.getCouponList,
          handleListCheckAll: _vm.handleListCheckAll
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }