import { render, staticRenderFns } from "./DefaultTagSelect.vue?vue&type=template&id=4d61eed2&scoped=true&"
import script from "./DefaultTagSelect.vue?vue&type=script&lang=js&"
export * from "./DefaultTagSelect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d61eed2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/front-end_micro-page_oo0i/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4d61eed2')) {
      api.createRecord('4d61eed2', component.options)
    } else {
      api.reload('4d61eed2', component.options)
    }
    module.hot.accept("./DefaultTagSelect.vue?vue&type=template&id=4d61eed2&scoped=true&", function () {
      api.rerender('4d61eed2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/SelectDialogue/TableContent/ProductContent/Selector/TagSelect/DefaultTagSelect.vue"
export default component.exports