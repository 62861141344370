var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vip-icon-position", style: _vm.iconStyle }, [
    _vm.userIsVip && _vm.value.vipOnly
      ? _c("img", {
          staticClass: "vipIcon",
          attrs: { src: require("@/assets/microPage/vipOnly.png") }
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.userIsVip &&
    _vm.value.vipDiscount === 0 &&
    _vm.value.price &&
    _vm.value.price !== 0 &&
    !_vm.value.vipOnly
      ? _c("img", {
          staticClass: "vipIcon",
          attrs: { src: require("@/assets/microPage/vipFree.png") }
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.userIsVip &&
    _vm.value.vipDiscount > 0 &&
    _vm.value.vipDiscount < 1 &&
    _vm.value.price !== 0 &&
    !_vm.value.vipOnly
      ? _c("img", {
          staticClass: "vipIcon",
          attrs: {
            src: require("@/assets/microPage/vipPreferential.png"),
            mode: "aspectFit"
          }
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }