var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isOldSearch
        ? _c("column-dialog-filter-panel", {
            attrs: {
              "type-name": _vm.typeName,
              "show-vid-search": _vm.showVidSearch,
              "is-show-area": true,
              "show-json-data": _vm.showJsonData
            },
            model: {
              value: _vm.valueProxy,
              callback: function($$v) {
                _vm.valueProxy = $$v
              },
              expression: "valueProxy"
            }
          })
        : _c("new-filter-panel", {
            attrs: {
              "type-name": _vm.typeName,
              "show-vid-search": _vm.showVidSearch,
              "is-show-area": true,
              "show-json-data": _vm.showJsonData
            },
            model: {
              value: _vm.valueProxy,
              callback: function($$v) {
                _vm.valueProxy = $$v
              },
              expression: "valueProxy"
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }