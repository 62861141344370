var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "live-item" }, [
    _vm.isLine
      ? _c(
          "div",
          { staticClass: "flex-row live-margin-top" },
          [
            _vm.myCourseItem.cover
              ? _c("el-image", {
                  staticClass: "small-cover",
                  attrs: {
                    fit: "cover",
                    src: _vm.getImgUrl(
                      _vm.myCourseItem.cover,
                      "style/width-limit-256"
                    )
                  }
                })
              : _c("img", {
                  staticClass: "small-cover",
                  attrs: { src: require("@/assets/cover/cover-live.png") }
                }),
            _vm._v(" "),
            _c("span", { staticClass: "live-name" }, [
              _vm._v(_vm._s(_vm.myCourseItem.title))
            ])
          ],
          1
        )
      : _c(
          "div",
          [
            _vm.myCourseItem.cover
              ? _c("el-image", {
                  staticClass: "big-cover",
                  attrs: {
                    fit: "cover",
                    src: _vm.getImgUrl(
                      _vm.myCourseItem.cover,
                      "x-oss-process=style/thumbnail"
                    )
                  }
                })
              : _c("img", {
                  staticClass: "big-cover",
                  attrs: { src: require("@/assets/cover/cover-live.png") }
                }),
            _vm._v(" "),
            _c("span", { staticClass: "live-name live-picture-title" }, [
              _vm._v(_vm._s(_vm.myCourseItem.title))
            ])
          ],
          1
        ),
    _vm._v(" "),
    _c("span", { staticClass: "hidden-one-line" }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "flex-row align-center flex-space-between",
        class: [_vm.isLine ? "bottom-time-status" : "live-picture-status"]
      },
      [
        _c("span", { staticStyle: { "font-size": "14px", color: "#999999" } }, [
          _vm._v(
            _vm._s(
              _vm._f("parseTime")(_vm.myCourseItem.startTime, "{m}-{d} {h}:{i}")
            )
          )
        ]),
        _vm._v(" "),
        _vm.stateItem === 2
          ? _c(
              "el-tag",
              {
                staticClass: "flex-row align-center live-living",
                attrs: { type: "danger", effect: "dark" }
              },
              [
                _c("el-image", {
                  staticClass: "live-icon",
                  attrs: {
                    src: _vm.ossUrlFun(
                      "static/live/iconBtn2.png",
                      "style/width-limit-32"
                    )
                  }
                }),
                _vm._v("\n      直播中\n    ")
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.stateItem === 3
          ? _c(
              "el-tag",
              {
                staticClass: "flex-row align-center live-playback",
                attrs: { type: "success" }
              },
              [
                _c("el-image", {
                  staticClass: "live-icon",
                  attrs: {
                    src: _vm.ossUrlFun(
                      "static/live/iconBtn3.png",
                      "style/width-limit-32"
                    )
                  }
                }),
                _vm._v("\n      回放\n    ")
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.stateItem === 4
          ? _c("el-tag", { attrs: { type: "info" } }, [_vm._v("已结束")])
          : _vm._e(),
        _vm._v(" "),
        _vm.stateItem === 7
          ? _c(
              "el-tag",
              {
                staticClass: "flex-row align-center live-preview",
                attrs: { type: "danger", effect: "dark" }
              },
              [
                _c("el-image", {
                  staticClass: "live-icon",
                  attrs: {
                    src: _vm.ossUrlFun(
                      "static/live/iconBtn2.png",
                      "style/width-limit-32"
                    )
                  }
                }),
                _vm._v("\n      进入直播间\n    ")
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.stateItem === 1
          ? _c(
              "el-tag",
              {
                staticClass: "flex-row align-center live-preview",
                attrs: { effect: "dark" }
              },
              [
                _c("el-image", {
                  staticClass: "live-icon",
                  attrs: {
                    src: _vm.ossUrlFun(
                      "static/live/iconBtn1.png",
                      "style/width-limit-32"
                    )
                  }
                }),
                _vm._v("\n      预约\n    ")
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }