"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _verificationHost = _interopRequireDefault(require("./guard/verificationHost"));

function guards(router) {
  router.beforeEach(_verificationHost.default);
}

var _default = guards;
exports.default = _default;