"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _axios = _interopRequireDefault(require("axios"));

var _config = _interopRequireDefault(require("./config"));

var _onRequest = require("./onRequest");

var _onResponse = require("./onResponse");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// 创建axios实例
var service = null;
/**
 * 创建一个 axios request
 *
 * @param {string} baseUrl
 * @param {object} config
 * @returns {AxiosInstance}
 */

function createRequest() {
  var baseUrl = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : process.env.BASE_API;
  var config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  service = _axios.default.create(_objectSpread(_objectSpread({}, _config.default), {}, {
    baseURL: baseUrl
  }, config)); // request拦截器

  service.interceptors.request.use(_onRequest.onRequestFulfilled, _onRequest.onRequestRejected); // response 拦截器

  service.interceptors.response.use(_onResponse.onResponseFulfilled, _onResponse.onResponseRejected);
  return service;
}

var _default = createRequest;
exports.default = _default;