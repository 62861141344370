var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dan-xuan" },
    _vm._l(_vm.optionText[0], function(item, index) {
      return _c(
        "div",
        { key: index, staticClass: "flex-row align-center common-select" },
        [
          _c(
            "div",
            {
              class: [
                item.selectIndex === index ? "select-style" : "common-options"
              ],
              style: {
                "border-radius":
                  _vm.examQuestionTypeCode === 1002 ? "5px" : "50%"
              }
            },
            [_vm._v(_vm._s(index.toUpperCase()))]
          ),
          _vm._v(" "),
          _c("span", { domProps: { innerHTML: _vm._s(item) } })
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }