"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.otherComponents = exports.marketingComponents = exports.classComponents = exports.basicComponents = void 0;
var basicComponents = [{
  type: 'input',
  options: {
    appearance: '白色',
    required: false,
    dataType: 'string',
    pattern: '',
    placeholder: '搜索课程名称',
    disabled: false,
    color: {
      background: '#ffffff',
      searchBackground: '#EFEFEF',
      font: '#000000',
      icon: '#000000'
    },
    style: 'default'
  }
}, {
  type: 'image_navigate',
  options: {
    appearance: 'open',
    shape: 'round',
    // round：圆; square：方
    spacingTop: '0px',
    //  up：上间距 down：下间距
    spacingBottom: '0px',
    //  up：上间距 down：下间距
    color: {
      background: '#ffffff',
      font: '#000000'
    }
  },
  content: {
    // 内容设置
    size: 3,
    parts: [{
      title: '导航1',
      image: '',
      id: 1
    }, {
      title: '导航2',
      image: '',
      id: 2
    }, {
      title: '导航3',
      image: '',
      id: 3
    }]
  }
}, {
  type: 'picture_navigate',
  options: {
    appearance: 'open',
    style: 'outsideBottom',
    // outsideBottom 图片下方 innerBottom 图片内下方 innerCenter 图片内居中
    spacingTop: '0px',
    //  up：上间距 down：下间距
    spacingBottom: '0px',
    //  up：上间距 down：下间距
    color: {
      background: '#ffffff',
      font: '#000000'
    }
  },
  content: {
    // 内容设置
    size: 3,
    // 单行个数
    parts: [{
      title: '导航1',
      image: '',
      id: 1
    }, {
      title: '导航2',
      image: '',
      id: 2
    }, {
      title: '导航3',
      image: '',
      id: 3
    }]
  }
}, {
  type: 'carousel',
  options: {
    style: 'carousel',
    // carousel：轮播; slide：左右滑动
    appearance: 'open',
    imageHeight: 150,
    // 图片高度
    spacingTop: '0px',
    //  up：上间距 down：下间距
    spacingBottom: '0px',
    //  up：上间距 down：下间距
    immerse: false,
    // 是否为沉浸式
    color: {
      background: '#ffffff',
      font: '#000000'
    }
  },
  content: {
    size: 2,
    parts: [{
      image: '',
      id: 1
    }, {
      image: '',
      id: 2
    }]
  }
}, {
  type: 'image_advert',
  options: {
    appearance: 'open',
    // open：展开; card：卡片
    imageHeight: 200,
    // 高度
    innerMargin: 'fill',
    // fill margin
    spacingTop: '0px',
    //  up：上间距 down：下间距
    spacingBottom: '0px',
    //  up：上间距 down：下间距
    color: {
      background: '#ffffff',
      font: '#000000'
    }
  },
  content: {
    size: 1,
    // -1 轮播  1-3正常
    parts: [{
      image: '',
      id: 1
    }]
  }
}, {
  type: 'rich_text',
  options: {
    appearance: 'open',
    // open：展开; card：卡片
    innerMargin: 'fill',
    // fill margin
    spacingTop: '0px',
    //  up：上间距 down：下间距
    spacingBottom: '0px',
    //  up：上间距 down：下间距
    color: {
      background: '#ffffff',
      font: '#000000'
    }
  },
  content: {
    title: '标题名称',
    // 标题
    text: '',
    isShowTitle: true
  }
}, {
  type: 'micro_title',
  options: {
    appearance: 'open',
    // open：展开; card：卡片
    spacingTop: '0px',
    //  up：上间距 down：下间距
    spacingBottom: '0px',
    //  up：上间距 down：下间距
    color: {
      background: '#ffffff',
      mainFont: '#000000',
      subFont: '#999999'
    },
    backgroundImage: 'assets/taozhi/app/static/micro_title_bg.png',
    showBgImg: false
  },
  content: {
    align: 'center',
    mainTitle: '主标题标题名称',
    subTitle: '副标题名称',
    mainSize: 14,
    subSize: 12,
    mainWeight: 400,
    subWeight: 400
  }
}, {
  type: 'types_navigate',
  options: {
    // 基础设置
    appearance: 'open',
    // open：展开; card：卡片
    style: 'line',
    // smallPicture：小图显示 line:图文列表
    spacingTop: '0px',
    //  up：上间距 down：下间距
    spacingBottom: '0px',
    //  up：上间距 down：下间距
    color: {
      activityFont: '#409EFF',
      underline: '#409EFF',
      background: '#ffffff',
      font: '#000000'
    }
  },
  content: [{
    title: '分类1',
    id: 1,
    parts: []
  }, {
    title: '分类2',
    id: 2,
    parts: []
  }]
}];
exports.basicComponents = basicComponents;
var classComponents = [{
  type: 'book_shelf',
  options: {
    appearance: 'open',
    // open：展开; card：卡片
    style: 'line',
    // picture：书籍展示1 pictureTwo: 书籍展示2 line:图文列表
    spacingTop: '0px',
    //  up：上间距 down：下间距
    spacingBottom: '0px',
    //  up：上间距 down：下间距
    color: {
      background: '#ffffff',
      font: '#000000'
    },
    resourceColor: '#999999',
    priceColor: '#999999',
    showPrice: false,
    showBgImg: false,
    titleColor: '#101010',
    namePosition: '0',
    // '0' 图片下方 '1' 图片内下方
    rowNum: 2,
    showResourceTitle: true,
    showAllTitle: false
  },
  title: {
    style: 'default',
    // default：默认 custom：自定义 none：无
    title: undefined,
    more: undefined,
    isShowMore: true
  },
  content: {
    size: '3',
    parts: []
  }
}, {
  type: 'live',
  options: {
    appearance: 'open',
    // open：展开; card：卡片
    style: 'line',
    // picture：大图显示 line:图文列表
    spacingTop: '0px',
    //  up：上间距 down：下间距
    spacingBottom: '0px',
    //  up：上间距 down：下间距
    color: {
      background: '#ffffff',
      font: '#000000'
    }
  },
  title: {
    style: 'default',
    // default：默认 custom：自定义 none：无
    title: undefined,
    more: undefined,
    isShowMore: true
  },
  content: {
    size: '3',
    parts: []
  }
}, {
  type: 'knowledge_goods',
  options: {
    appearance: 'open',
    // open：展开; card：卡片
    style: 'line',
    // smallPicture：小图显示 line:图文列表 slide：左右滚动
    spacingTop: '0px',
    //  up：上间距 down：下间距
    spacingBottom: '0px',
    //  up：上间距 down：下间距
    color: {
      background: '#ffffff',
      font: '#000000'
    }
  },
  title: {
    style: 'default',
    // default：默认 custom：自定义 none：无
    title: undefined,
    more: undefined,
    isShowMore: true
  },
  content: {
    rule: 'custom',
    // default：默认 custom：自定义
    size: '2',
    // 数量
    recommendRule: false,
    // 推荐
    selectRecommend: false,
    // 是选择的 还是默认的
    recommend: undefined,
    // {title: '知识商品名称知识商品名称知识商知识商品名称知', resourceType: 1, price: 0,}
    parts: [],
    // 作品选择
    partsOptions: {} // 条件选择

  }
}, {
  type: 'famous_teachers',
  options: {
    appearance: 'card',
    // open：展开; card：卡片
    spacingTop: '0px',
    //  up：上间距 down：下间距
    spacingBottom: '0px',
    //  up：上间距 down：下间距
    color: {
      background: '#ffffff',
      font: '#000000'
    }
  },
  title: {
    style: 'default',
    // default：默认 custom：自定义 none：无
    title: undefined,
    more: undefined,
    isShowMore: true
  },
  content: {
    size: '3',
    parts: []
  }
}, {
  type: 'test_paper',
  options: {
    appearance: 'card',
    // open：展开; card：卡片
    style: 'line',
    // smallPicture：小图显示 line:图文列表 slide：左右滚动
    spacingTop: '0px',
    //  up：上间距 down：下间距
    spacingBottom: '0px',
    //  up：上间距 down：下间距
    color: {
      background: '#ffffff',
      font: '#000000'
    }
  },
  title: {
    style: 'default',
    // default：默认 custom：自定义 none：无
    title: undefined,
    more: undefined,
    isShowMore: true
  },
  content: {
    size: '2',
    // 数量
    recommendRule: false,
    // 推荐
    selectRecommend: false,
    // 是选择的 还是默认的
    recommend: undefined,
    //
    parts: [],
    // 作品选择
    showBakImg: false,
    // 是否展示推荐背景图
    recommendBakImg: 'material/micro-test-paper.png' // 推荐背景图

  }
}, {
  type: 'test_questions',
  options: {
    appearance: 'card',
    // open：展开; card：卡片
    style: 'line',
    // smallPicture：小图显示 line:图文列表 slide：左右滚动
    spacingTop: '0px',
    //  up：上间距 down：下间距
    spacingBottom: '0px',
    //  up：上间距 down：下间距
    color: {
      background: '#ffffff',
      font: '#000000'
    }
  },
  title: {
    style: 'default',
    // default：默认 custom：自定义 none：无
    title: undefined,
    more: undefined,
    isShowMore: true
  },
  content: {
    isAnswerState: true,
    size: '2',
    // 数量
    parts: [] // 作品选择

  }
}, {
  type: 'micro_audio',
  options: {
    appearance: 'card',
    // open：展开; card：卡片
    style: 'line',
    // line:音频列表 column：纵向列表
    spacingTop: '0px',
    //  up：上间距 down：下间距
    spacingBottom: '0px',
    //  up：上间距 down：下间距
    color: {
      background: '#ffffff',
      font: '#000000'
    },
    autoPlay: false,
    downLoad: false // 允许下载

  },
  title: {
    style: 'default',
    // default：默认 custom：自定义 none：无
    title: undefined,
    more: undefined,
    isShowMore: true
  },
  content: {
    size: '3',
    parts: []
  }
}, {
  type: 'micro_video',
  options: {
    appearance: 'card',
    // open：展开; card：卡片
    style: 'line',
    // line:音频列表 column：纵向列表
    spacingTop: '0px',
    //  up：上间距 down：下间距
    spacingBottom: '0px',
    //  up：上间距 down：下间距
    innerMargin: 'margin',
    // fill margin
    color: {
      background: '#ffffff',
      font: '#000000'
    },
    autoPlay: false,
    isScreenFull: false,
    // 允许全屏
    screenFull: undefined,
    // 全屏 默认 undefined 横屏 -90 竖屏 0
    screenWidth: 16,
    screenHeight: 9,
    showTitle: true // 显示作品标题,

  },
  title: {
    style: 'default',
    // default：默认 custom：自定义 none：无
    title: undefined,
    more: undefined,
    isShowMore: true
  },
  content: {
    size: '3',
    parts: []
  }
}, {
  type: 'micro_word',
  // 字
  options: {
    appearance: 'card',
    // open：展开; card：卡片
    style: 'read',
    // read 会认字 write：会写字
    spacingTop: '0px',
    //  up：上间距 down：下间距
    spacingBottom: '0px',
    //  up：上间距 down：下间距
    color: {
      background: '#ffffff',
      font: '#000000'
    },
    overview: true,
    // 概览
    isCharacterHidden: false,
    // 超出隐藏
    hiddenNum: 4
  },
  title: {
    style: 'default',
    // default：默认 custom：自定义 none：无
    title: undefined,
    more: undefined,
    isShowMore: false
  },
  content: {
    parts: []
  }
}, {
  type: 'micro_polyphonic',
  // 多音字
  options: {
    appearance: 'card',
    // open：展开; card：卡片
    style: 'line',
    // line 列表  nav：导航切换
    spacingTop: '0px',
    //  up：上间距 down：下间距
    spacingBottom: '0px',
    //  up：上间距 down：下间距
    color: {
      background: '#ffffff',
      font: '#000000'
    },
    isCharacterHidden: false,
    // 超出隐藏
    hiddenNum: 4
  },
  title: {
    style: 'default',
    // default：默认 custom：自定义 none：无
    title: undefined,
    more: undefined,
    isShowMore: false
  },
  content: {
    parts: []
  }
}, {
  type: 'micro_terms',
  // 词语
  options: {
    appearance: 'card',
    // open：展开; card：卡片
    spacingTop: '0px',
    //  up：上间距 down：下间距
    spacingBottom: '0px',
    //  up：上间距 down：下间距
    color: {
      background: '#ffffff',
      font: '#000000'
    },
    isCharacterHidden: false,
    // 超出隐藏
    hiddenNum: 4
  },
  title: {
    style: 'default',
    // default：默认 custom：自定义 none：无
    title: undefined,
    more: undefined,
    isShowMore: false
  },
  content: {
    parts: []
  }
}];
exports.classComponents = classComponents;
var marketingComponents = [{
  type: 'coupon',
  options: {
    // 基础设置
    appearance: 'open',
    // open：展开; card：卡片
    spacingTop: '0px',
    //  up：上间距 down：下间距
    spacingBottom: '0px',
    //  up：上间距 down：下间距
    style: 'line',
    // line：列表 slide：侧滑
    color: {
      background: '#ffffff',
      font: '#000000'
    }
  },
  title: {
    // 标题设置
    style: 'default',
    // default：默认 custom：自定义 none：无
    title: '优惠券',
    more: '查看更多',
    isShowMore: true
  },
  content: {
    // 内容设置
    size: '2',
    parts: []
  }
}, {
  type: 'partner_info',
  options: {
    // 基础设置
    style: 'tile',
    // default：默认样式; center：居中样式：tile：平铺样式
    spacingTop: '0px',
    //  up：上间距 down：下间距
    spacingBottom: '0px',
    //  up：上间距 down：下间距
    color: {
      partnerName: '#101010',
      statistics: '#999999',
      background: '#ffffff',
      font: undefined
    },
    backgroundImage: 'material/default-bac@2x.png',
    immerse: false // 是否为沉浸式

  },
  followBtn: {
    // 标题设置
    style: 'default',
    // default：默认 custom：自定义 none：无
    font: '#ffffff',
    background: '#409EFF'
  },
  content: {
    // 内容设置
    partnerOpenId: undefined,
    partnerLogo: '',
    partnerName: '',
    partnerType: '',
    parts: [4]
  }
}, {
  type: 'bookstore',
  options: {
    // 基础设置
    appearance: 'open',
    // open：展开; card：卡片
    spacingTop: '0px',
    //  up：上间距 down：下间距
    spacingBottom: '0px',
    //  up：上间距 down：下间距
    color: {
      background: '#ffffff',
      font: '#000000'
    }
  },
  title: {
    // 标题设置
    style: 'default',
    // default：默认 custom：自定义 none：无
    title: '附近书店',
    more: '',
    isShowMore: false,
    cover: undefined
  }
}];
exports.marketingComponents = marketingComponents;
var otherComponents = [{
  type: 'official_account',
  options: {
    // 基础设置
    appearance: 'open',
    // open：展开; card：卡片
    spacingTop: 0,
    //  up：上间距 down：下间距
    spacingBottom: 0,
    //  up：上间距 down：下间距
    color: {
      background: '#ffffff',
      font: '#000000'
    }
  },
  content: {
    // 内容设置
    type: 1,
    headImg: undefined,
    qrcodeUrl: undefined,
    nickName: '公众号名称',
    detail: '公众号功能介绍',
    tips: '长按保存二维码'
  }
}, {
  type: 'applets',
  options: {
    // 基础设置
    appearance: 'default',
    // default：默认样式; center：居中样式：tile：平铺样式
    spacingTop: '0px',
    //  up：上间距 down：下间距
    spacingBottom: '0px',
    //  up：上间距 down：下间距
    color: {
      background: '#ffffff',
      font: '#000000'
    }
  },
  content: {
    // 内容设置
    type: 1,
    headImg: undefined,
    qrcodeUrl: undefined,
    nickName: '小程序名称',
    detail: '小程序描述描述',
    tips: '长按保存二维码'
  }
}];
exports.otherComponents = otherComponents;