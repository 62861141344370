var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "word" },
    [
      _vm.options.overview && _vm.list.length > 0
        ? _c(
            "div",
            { staticClass: "word-overview" },
            _vm._l(_vm.list, function(item) {
              return _c(
                "div",
                {
                  key: item.openId,
                  class: [
                    "item-bg",
                    _vm.options.appearance !== "card"
                      ? "no-card-item-bg"
                      : "card-item-bg"
                  ],
                  style: _vm.overviewHeight
                },
                [
                  _c("el-image", {
                    staticClass: "item-img",
                    attrs: { src: _vm.isRead ? _vm.readView : _vm.writeView }
                  }),
                  _vm._v(" "),
                  _vm.isRead
                    ? _c(
                        "span",
                        {
                          class: [
                            "pinyin",
                            _vm.options.appearance !== "card"
                              ? ""
                              : "card-pinyin"
                          ],
                          style: { color: _vm.isRead ? "#00A2FF" : "#2A536B" }
                        },
                        [_vm._v(_vm._s(_vm.getPinYin(item)))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.newImgSrc(item)
                    ? _c(
                        "div",
                        {
                          class: [
                            "item-name",
                            _vm.options.appearance !== "card"
                              ? ""
                              : "card-item-name"
                          ]
                        },
                        [
                          _c("el-image", {
                            staticClass: "bishun-cover",
                            attrs: { src: _vm.newImgSrc(item) }
                          })
                        ],
                        1
                      )
                    : _c(
                        "span",
                        {
                          class: [
                            "item-name",
                            _vm.options.appearance !== "card"
                              ? ""
                              : "card-item-name"
                          ]
                        },
                        [_vm._v(_vm._s(item.name))]
                      )
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.list, function(item, index) {
        return _c(
          "div",
          { key: item.openId },
          [_c("read-item", { attrs: { value: item, options: _vm.options } })],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }