var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "select-resource-table" }, [
    _c(
      "div",
      { staticClass: "content-table" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            ref: "singleTable",
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.resourceList,
              height: "100%",
              "tooltip-effect": "dark",
              "highlight-current-row": ""
            },
            on: { "current-change": _vm.handleCurrentChange }
          },
          [
            _c("el-table-column", {
              attrs: { width: "30" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: scope.row.resourceOpenId },
                          model: {
                            value: _vm.value.resourceOpenId,
                            callback: function($$v) {
                              _vm.$set(_vm.value, "resourceOpenId", $$v)
                            },
                            expression: "value.resourceOpenId"
                          }
                        },
                        [
                          _c("div", { staticClass: "radio-content-hidden" }, [
                            _vm._v(_vm._s(scope.row.resourceOpenId))
                          ])
                        ]
                      )
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "作品" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("resource-simple", {
                        attrs: { resourceObj: scope.row, isEdit: false }
                      })
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "data",
                width: "220",
                align: "center",
                label: "发布时间"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm.showVal(scope.row.publishedAt)))
                      ])
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }