var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cloud-page-class" },
    [
      _c(
        "el-form",
        {
          ref: "appletForm",
          attrs: { model: _vm.valueProxy, rules: _vm.rules }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "选择分类", "label-width": "80px" } },
            [
              _c("el-cascader", {
                ref: "cloudCategory",
                staticClass: "category-box",
                attrs: {
                  options: _vm.options,
                  clearable: "",
                  props: _vm.props
                },
                model: {
                  value: _vm.valueProxy.groupIds,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "groupIds", $$v)
                  },
                  expression: "valueProxy.groupIds"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "选择年级", "label-width": "80px" } },
            [
              _c("select-param-sync-info", {
                staticClass: "sync-select",
                attrs: {
                  size: "small",
                  multiple: true,
                  filterable: true,
                  placeholder: "年级",
                  "query-option": "FindGradeList"
                },
                model: {
                  value: _vm.valueProxy.gradeCode,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "gradeCode", $$v)
                  },
                  expression: "valueProxy.gradeCode"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "选必修", "label-width": "80px" } },
            [
              _c("select-param-sync-info", {
                staticClass: "sync-select",
                attrs: {
                  size: "small",
                  multiple: true,
                  filterable: true,
                  placeholder: "选必修",
                  "query-option": "FindElective"
                },
                model: {
                  value: _vm.valueProxy.electiveCode,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "electiveCode", $$v)
                  },
                  expression: "valueProxy.electiveCode"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "阶段", "label-width": "80px" } },
            [
              _c("select-param-sync-info", {
                staticClass: "sync-select",
                attrs: {
                  size: "small",
                  multiple: true,
                  filterable: true,
                  placeholder: "阶段",
                  "query-option": "FindJDList"
                },
                model: {
                  value: _vm.valueProxy.volumeCode,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "volumeCode", $$v)
                  },
                  expression: "valueProxy.volumeCode"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "学科", "label-width": "80px" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "category-box",
                  attrs: {
                    multiple: true,
                    placeholder: "学科",
                    filterable: true,
                    clearable: ""
                  },
                  on: { "visible-change": _vm.visibleChange },
                  model: {
                    value: _vm.valueProxy.subjectCode,
                    callback: function($$v) {
                      _vm.$set(_vm.valueProxy, "subjectCode", $$v)
                    },
                    expression: "valueProxy.subjectCode"
                  }
                },
                _vm._l(_vm.selectOptions, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.subjectName, value: item.subjectCode }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "地区", "label-width": "80px" } },
            [
              _c("select-param-sync-info", {
                staticClass: "sync-select",
                attrs: {
                  multiple: true,
                  filterable: true,
                  "query-option": "FindArea",
                  placeholder: "地区"
                },
                model: {
                  value: _vm.valueProxy.areaCode,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "areaCode", $$v)
                  },
                  expression: "valueProxy.areaCode"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }