"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.queryWordList = queryWordList;
exports.getWordNatureList = getWordNatureList;
exports.checkWord = checkWord;
exports.deleteWord = deleteWord;
exports.updateWord = updateWord;
exports.getMerchantWordGroup = getMerchantWordGroup;

var _main = _interopRequireDefault(require("@/utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/library/admin/"));
/* 获取单词列表 */

function queryWordList(params) {
  return request.get('word/findPage', {
    params: params
  });
}
/* 获取单词库词性列表 */


function getWordNatureList() {
  return request.get('wordNature/wordNatureList');
}
/* 删除单词 */


function deleteWord(wordOpenId) {
  return request.delete("word/deleteWord/".concat(wordOpenId));
}
/* 更新单词 */


function updateWord(data, params) {
  return request.put("word/updateWord/".concat(data.wordOpenId), data, {
    params: params
  });
}
/* 校验单词是否存在 */


function checkWord(name) {
  return request.get('word/findOne', {
    params: {
      name: name
    }
  });
}
/* 查询商户单词分组 */


function getMerchantWordGroup(params) {
  return request.get('wordGroup/list', {
    params: params
  });
}