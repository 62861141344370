var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cloud-retail-partner" },
    [
      _vm.partnerInfo.partnerLogo
        ? _c("el-image", {
            staticClass: "partner-logo",
            style: {
              "border-radius":
                _vm.element.options.shape === "round" ? "50%" : "4px"
            },
            attrs: {
              fit: "cover",
              src: _vm.getImgUrl(
                _vm.partnerInfo.partnerLogo,
                "style/width-limit-80"
              )
            }
          })
        : _c(
            "div",
            {
              staticClass: "common-flex-center partner-logo",
              style: {
                "border-radius":
                  _vm.element.options.shape === "round" ? "50%" : "4px"
              }
            },
            [
              _c("img", {
                staticStyle: { width: "40px", height: "13px" },
                attrs: { src: require("@/assets/images/taozhiLogo.png") }
              })
            ]
          ),
      _vm._v(" "),
      _c("div", { staticClass: "info", style: { width: _vm.infoWidth } }, [
        _c(
          "span",
          {
            staticClass: "partner-name",
            style: { color: _vm.element.options.color.partnerName }
          },
          [_vm._v(_vm._s(_vm.storeName))]
        ),
        _vm._v(" "),
        _vm.storePhone && _vm.element.options.showPhoneTxt
          ? _c(
              "span",
              {
                style: {
                  color: _vm.element.options.color.phone,
                  "font-size": "12px"
                }
              },
              [_vm._v(_vm._s(_vm.storePhone))]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.storeAddress && _vm.element.options.showAddressTxt
          ? _c(
              "span",
              {
                staticClass: "address",
                style: { color: _vm.element.options.color.address }
              },
              [_vm._v(_vm._s(_vm.storeAddress))]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "operator flex-row" },
        [
          _vm.element.options.showPhoneIcon
            ? _c("el-image", {
                staticClass: "operator-phone",
                attrs: {
                  fit: "cover",
                  src: _vm.getImgUrl(
                    "assets/taozhi/shopMiniProgram/home/home-phone.png",
                    "style/width-limit-64"
                  )
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.element.options.showAddressIcon
            ? _c("el-image", {
                staticClass: "operator-phone",
                attrs: {
                  fit: "cover",
                  src: _vm.getImgUrl(
                    "assets/taozhi/shopMiniProgram/home/home-address.png",
                    "style/width-limit-64"
                  )
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }