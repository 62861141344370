var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      ref: "table",
      staticStyle: { width: "100%", "padding-bottom": "6px" },
      attrs: {
        data: _vm.data,
        height: _vm.height,
        "highlight-current-row": true,
        "cell-class-name": _vm.typeCellClassName
      },
      on: {
        "selection-change": _vm.handleSelectionChange,
        "current-change": _vm.handleCurrentChange,
        "row-click": _vm.selectCall
      }
    },
    [
      _vm.isMultiple
        ? _c("el-table-column", {
            attrs: {
              selectable: _vm.selectable,
              fixed: "",
              type: "selection",
              width: "50"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._t("prefix"),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { width: "300", fixed: "", label: "商品" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [_c("commodity-cell", { attrs: { commodity: scope.row } })]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { width: "220", label: "openid", prop: "productOpenId" }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { width: "80", label: "商品类型" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c("div", [
                  scope.row.resourceType == 21
                    ? _c("span", { staticStyle: { color: "#67C23A" } }, [
                        _vm._v("数字商品")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  scope.row.resourceType == 22
                    ? _c("span", { staticStyle: { color: "#E6A23C" } }, [
                        _vm._v("组合商品")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  scope.row.resourceType == 20
                    ? _c("span", { staticStyle: { color: "#409EFF" } }, [
                        _vm._v("实物商品")
                      ])
                    : _vm._e()
                ])
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { width: "120", label: "商品编码", prop: "productCode" }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: {
          width: "150",
          label: "主图视频",
          prop: "showVideo",
          formatter: _vm.hasFormatter
        }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { width: "150", label: "条码", prop: "barCode" }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { width: "150", label: "类目", prop: "regimentationDisplay" }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { width: "150", label: "品牌", prop: "brandText" }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { width: "150", label: "图书系列", prop: "seriesText" }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { width: "150", label: "学段", prop: "sectionText" }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { width: "150", label: "学科", prop: "subjectText" }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { width: "150", label: "版本", prop: "editionText" }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { width: "150", label: "年级", prop: "gradeText" }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { width: "150", label: "阶段", prop: "volumeText" }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { width: "150", label: "选必修", prop: "electiveText" }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { width: "150", label: "版次", prop: "periodText" }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { width: "150", label: "地区", prop: "areaText" }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { width: "150", label: "标签", prop: "tags" }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: {
          width: "150",
          label: "是否套装",
          prop: "isSuit",
          formatter: _vm.booleanFormatter
        }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: {
          width: "150",
          label: "规格",
          prop: "specificJson",
          formatter: _vm.hasFormatter
        }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { width: "150", label: "库存", prop: "inventory" }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: {
          width: "150",
          label: "限购",
          prop: "quota",
          formatter: _vm.quotaFormatter
        }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: {
          width: "150",
          label: "仅认证教师可购",
          prop: "isTeacherBuy",
          formatter: _vm.booleanFormatter
        }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: {
          width: "150",
          label: "原价",
          prop: "orginalPrice",
          formatter: _vm.priceFormatter
        }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: {
          width: "150",
          label: "最低售价",
          prop: "lowPrice",
          formatter: _vm.discountFormatter
        }
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: {
          width: "150",
          label: "售价",
          prop: "price",
          formatter: _vm.discountFormatter
        }
      }),
      _vm._v(" "),
      _vm._t("additional"),
      _vm._v(" "),
      _vm._t("action")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }