"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatBuyQuantity = formatBuyQuantity;
exports.formatCent = formatCent;
exports.formatYuanToCent = formatYuanToCent;
exports.centToYuanDisplay = centToYuanDisplay;
exports.yuanDisplay = yuanDisplay;
exports.productListPriceDisplay = productListPriceDisplay;
exports.productPriceDisplay = productPriceDisplay;
exports.centToYuan = centToYuan;
exports.priceShow = priceShow;
exports.getPriceFn = getPriceFn;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es7.symbol.async-iterator");

require("core-js/modules/es6.symbol");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.array.from");

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.regexp.to-string");

var _typeof2 = _interopRequireDefault(require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime-corejs2/helpers/typeof"));

require("core-js/modules/es7.string.pad-start");

require("core-js/modules/es6.math.trunc");

require("core-js/modules/es6.number.is-finite");

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.number.is-nan");

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

/**
 * 格式化购买数量，最低购买 1 个
 */
function formatBuyQuantity(value) {
  if (typeof value !== 'number') {
    return 1;
  }

  if (Number.isNaN(value)) {
    return 1;
  }

  if (!Number.isFinite(value)) {
    return 1;
  }

  value = Math.trunc(value);

  if (value < 1) {
    return 1;
  }

  return value;
}
/**
 * 格式化价格，以分为单位，最低 0 分
 */


function formatCent(value) {
  if (typeof value !== 'number') {
    return 0;
  }

  if (Number.isNaN(value)) {
    return 0;
  }

  if (!Number.isFinite(value)) {
    return 0;
  }

  value = Math.trunc(value);

  if (value < 0) {
    return 0;
  }

  return value;
}
/**
 * 格式化价格，将元格式化为分
 */


function formatYuanToCent(value) {
  if (typeof value !== 'number') {
    return 0;
  }

  if (Number.isNaN(value)) {
    return 0;
  }

  if (!Number.isFinite(value)) {
    return 0;
  }

  return formatCent(value * 100);
}
/**
 * 把分转换为以元为单位的展示值
 */


function centToYuanDisplay(value) {
  value = formatCent(value);
  var integerSection = Math.trunc(value / 100);
  var decimalFractionSection = value % 100;
  decimalFractionSection = "".concat(decimalFractionSection).padStart(2, '0');
  return "".concat(integerSection, ".").concat(decimalFractionSection);
}
/**
 * 把元转换为展示值
 */


function yuanDisplay(value) {
  return centToYuanDisplay(formatYuanToCent(value));
}
/**
 * 计算商品列表的总价
 */


function productListPriceDisplay(value) {
  if ((0, _typeof2.default)(value) !== 'object' || value === null) {
    return '0.00';
  }

  if (!Array.isArray(value)) {
    return '0.00';
  }

  var res = 0;

  var _iterator = _createForOfIteratorHelper(value),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var i = _step.value;

      if ((0, _typeof2.default)(i) !== 'object' || i === null) {
        continue;
      }

      res += formatYuanToCent(i.price) * formatBuyQuantity(i.buyQuantity);
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return centToYuanDisplay(res);
}
/**
 * 计算单个商品的总价
 */


function productPriceDisplay(value) {
  return productListPriceDisplay([value]);
}
/**
 * 从分转换为元
 * 由于需要末尾补零，所以结果必须为 string
 *
 * @param {*} value 要转换的分
 * @returns {string} 转换后的元
 */


function centToYuan(value) {
  return centToYuanDisplay(value);
}

function priceShow(price) {
  if (typeof price !== 'number') {
    price = parseFloat(price);
  }

  price = centToYuan(price);
  return "\xA5".concat(price);
}

function getPriceFn(val) {
  if (val.resourceType == 21 || val.resourceType == 22) {
    return val.resourceType == 21 ? priceShow(val.totalPrice) : "".concat(priceShow(val.totalPrice), "\u8D77");
  } // 单品


  if (val.isSingle) {
    return priceShow(val.displayPrice);
  } // 非单品 无规格


  if (!val.isHasSpecific) {
    return priceShow(val.displayPrice);
  }

  if (val.minPrice >= 0) {
    return priceShow(val.minPrice);
  }

  return priceShow(val.price);
}