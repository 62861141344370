var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "micro-form-item" }, [
    _vm.element.type === "input" && _vm.element.options.style === "cloudRetail"
      ? _c(
          "div",
          {
            staticClass: "cloud-search-input flex-row align-center",
            style: { background: _vm.element.options.color.searchBackground }
          },
          [
            _c("i", {
              staticClass: "el-icon-search",
              staticStyle: { "margin-right": "5px", "font-size": "16px" },
              style: { color: _vm.element.options.color.icon }
            }),
            _vm._v(" "),
            _c(
              "span",
              {
                staticStyle: { "font-size": "12px" },
                style: { color: _vm.element.options.color.font }
              },
              [_vm._v(_vm._s(_vm.element.options.placeholder))]
            )
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.element.type === "input" && _vm.element.options.style !== "cloudRetail"
      ? _c(
          "div",
          {
            staticClass: "phone-search-input flex-column flex-justify-center",
            style: {
              background: _vm.element.options.color.background,
              "padding-top": _vm.getMarginTop,
              "padding-bottom": _vm.getMarginBottom
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "search-input",
                style: {
                  background: _vm.element.options.color.searchBackground
                }
              },
              [
                _c("i", {
                  staticClass: "el-icon-search",
                  staticStyle: { "margin-right": "5px" },
                  style: { color: _vm.element.options.color.icon }
                }),
                _vm._v(" "),
                _c(
                  "span",
                  { style: { color: _vm.element.options.color.font } },
                  [_vm._v(_vm._s(_vm.element.options.placeholder))]
                )
              ]
            )
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.element.type === "image_navigate" ||
    _vm.element.type === "app_image_navigate"
      ? _c(
          "div",
          {
            style: {
              color: _vm.element.options.color.font,
              "padding-top": _vm.getMarginTop,
              "padding-bottom": _vm.getMarginBottom
            }
          },
          [
            _c("div", { class: [_vm.getCard(_vm.element)] }, [
              _c(
                "div",
                {
                  staticClass: "flex-row",
                  class: [
                    _vm.element.options.appearance === "card"
                      ? "card-inner"
                      : "",
                    "image-navigate"
                  ],
                  style: { background: _vm.element.options.color.background }
                },
                _vm._l(_vm.element.content.parts, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: item.id.toString(),
                      staticClass:
                        "flex-column align-center image-navigate-item",
                      style: {
                        width: "calc(100% / " + _vm.element.content.size + ")"
                      }
                    },
                    [
                      item.image
                        ? _c("el-image", {
                            staticStyle: {
                              "margin-bottom": "6px",
                              width: "45px",
                              height: "45px",
                              "text-align": "center"
                            },
                            style: {
                              "border-radius":
                                _vm.element.options.shape === "round"
                                  ? "50%"
                                  : "4px"
                            },
                            attrs: {
                              fit: "cover",
                              src: _vm.getImgUrl(
                                item.image,
                                "style/width-limit-80"
                              )
                            }
                          })
                        : _c(
                            "div",
                            {
                              staticClass: "common-flex-center",
                              staticStyle: {
                                "margin-bottom": "6px",
                                width: "45px",
                                height: "45px",
                                "background-color": "#EFEFEF"
                              },
                              style: {
                                "border-radius":
                                  _vm.element.options.shape === "round"
                                    ? "50%"
                                    : "4px"
                              }
                            },
                            [
                              _vm.isCloudRetailPartner
                                ? _c("img", {
                                    staticStyle: {
                                      width: "22px",
                                      height: "25px"
                                    },
                                    attrs: {
                                      src: require("../../assets/cloudRetail/default-cover.png")
                                    }
                                  })
                                : _c("img", {
                                    staticStyle: {
                                      width: "40px",
                                      height: "13px"
                                    },
                                    attrs: {
                                      src: require("../../assets/images/taozhiLogo.png")
                                    }
                                  })
                            ]
                          ),
                      _vm._v(" "),
                      _c("div", { staticClass: "title hidden-line" }, [
                        _vm._v(_vm._s(item.title))
                      ])
                    ],
                    1
                  )
                }),
                0
              )
            ])
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.element.type === "picture_navigate"
      ? _c(
          "div",
          {
            style: {
              color: _vm.element.options.color.font,
              "padding-top": _vm.getMarginTop,
              "padding-bottom": _vm.getMarginBottom
            }
          },
          [
            _c("div", { class: [_vm.getCard(_vm.element)] }, [
              _c(
                "div",
                {
                  staticClass: "picture-navigate flex-row flex-warp",
                  class: [
                    _vm.element.options.appearance === "card"
                      ? "card-inner"
                      : ""
                  ],
                  style: {
                    background: _vm.element.options.color.background,
                    padding: "15px 15px 0 15px"
                  }
                },
                _vm._l(_vm.element.content.parts, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      class: [
                        _vm.getPictureItem(_vm.element.content.size),
                        "picture-navigate-op"
                      ]
                    },
                    [
                      _c("picture-navigate", {
                        attrs: {
                          value: item,
                          options: _vm.element.options,
                          size: _vm.element.content.size,
                          "is-cloud-retail-partner": _vm.isCloudRetailPartner
                        }
                      })
                    ],
                    1
                  )
                }),
                0
              )
            ])
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.element.type === "carousel" || _vm.element.type === "app_carousel"
      ? _c(
          "div",
          {
            style: {
              "padding-top": _vm.getMarginTop,
              "padding-bottom": _vm.getMarginBottom,
              color: _vm.element.options.color.font
            }
          },
          [
            _c("div", { class: [_vm.getCard(_vm.element)] }, [
              _c(
                "div",
                {
                  class: [
                    _vm.element.options.appearance === "card"
                      ? "card-inner"
                      : ""
                  ],
                  style: {
                    background: _vm.element.options.color.background,
                    width: "100%"
                  }
                },
                [
                  _vm.element.options.style === "carousel"
                    ? _c(
                        "el-carousel",
                        {
                          attrs: {
                            autoplay: "",
                            trigger: "click",
                            height: _vm.element.options.imageHeight + "px",
                            arrow: "never"
                          }
                        },
                        _vm._l(_vm.element.content.parts, function(item) {
                          return _c(
                            "el-carousel-item",
                            { key: "carousel_" + item.id },
                            [
                              item.image
                                ? _c(
                                    "el-image",
                                    {
                                      style: {
                                        height:
                                          _vm.element.options.imageHeight +
                                          "px",
                                        width: "100%"
                                      },
                                      attrs: {
                                        fit: "cover",
                                        src: _vm.getImgUrl(
                                          item.image,
                                          "style/width-limit-512"
                                        )
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "image-slot",
                                          attrs: { slot: "placeholder" },
                                          slot: "placeholder"
                                        },
                                        [
                                          _vm._v("\n                加载中"),
                                          _c("span", { staticClass: "dot" }, [
                                            _vm._v("...")
                                          ])
                                        ]
                                      )
                                    ]
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "common-flex-center error-background",
                                      style: {
                                        height:
                                          _vm.element.options.imageHeight +
                                          "px",
                                        width: "100%"
                                      }
                                    },
                                    [
                                      _vm.isCloudRetailPartner
                                        ? _c("img", {
                                            staticClass: "common-no-default",
                                            attrs: {
                                              src: require("../../assets/cloudRetail/default-cover.png")
                                            }
                                          })
                                        : _c("img", {
                                            staticClass: "common-no-default",
                                            attrs: {
                                              src: require("../../assets/images/taozhiLogo.png")
                                            }
                                          })
                                    ]
                                  )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.element.options.style === "slide"
                    ? _c(
                        "slider",
                        {
                          ref: "slider",
                          staticClass: "carousel-slider",
                          attrs: { options: _vm.sliderinit }
                        },
                        _vm._l(_vm.element.content.parts, function(
                          item,
                          index
                        ) {
                          return _c(
                            "slideritem",
                            {
                              key: index,
                              style: {
                                width: "335px",
                                "margin-right": "10px",
                                height: _vm.element.options.imageHeight + "px",
                                "border-radius": "4px"
                              }
                            },
                            [
                              item.image
                                ? _c(
                                    "el-image",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%"
                                      },
                                      attrs: {
                                        fit: "cover",
                                        src: _vm.getImgUrl(
                                          item.image,
                                          "style/width-limit-512"
                                        )
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "image-slot",
                                          attrs: { slot: "placeholder" },
                                          slot: "placeholder"
                                        },
                                        [
                                          _vm._v("加载中"),
                                          _c("span", { staticClass: "dot" }, [
                                            _vm._v("...")
                                          ])
                                        ]
                                      )
                                    ]
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "common-flex-center error-background",
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%"
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "common-no-default",
                                        attrs: {
                                          src: _vm.isCloudRetailPartner
                                            ? "../../assets/cloudRetail/default-cover.png"
                                            : "../../assets/images/taozhiLogo.png"
                                        }
                                      })
                                    ]
                                  )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ])
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.element.type === "image_advert"
      ? _c(
          "div",
          {
            style: {
              "padding-top": _vm.getMarginTop,
              "padding-bottom": _vm.getMarginBottom,
              color: _vm.element.options.color.font
            }
          },
          [
            _c(
              "div",
              { staticClass: "flex-row", class: [_vm.getCard(_vm.element)] },
              [
                _c(
                  "div",
                  {
                    class: [
                      _vm.element.options.appearance === "card"
                        ? "card-inner"
                        : ""
                    ],
                    style: {
                      background: _vm.element.options.color.background,
                      width: "100%"
                    }
                  },
                  [
                    _vm.element.content.size === -1
                      ? _c(
                          "div",
                          {
                            style: {
                              padding:
                                _vm.element.options.innerMargin === "margin"
                                  ? "15px"
                                  : "0px"
                            }
                          },
                          [
                            _c(
                              "el-carousel",
                              {
                                attrs: {
                                  trigger: "click",
                                  height: _vm.element.options.imageHeight + "px"
                                }
                              },
                              _vm._l(_vm.element.content.parts, function(
                                item,
                                index
                              ) {
                                return _c(
                                  "el-carousel-item",
                                  { key: item.id.toString() },
                                  [
                                    item.image
                                      ? _c(
                                          "el-image",
                                          {
                                            staticClass:
                                              "common-image-auto-size",
                                            staticStyle: {
                                              "border-radius": "5px"
                                            },
                                            attrs: {
                                              fit: "cover",
                                              src: _vm.getImgUrl(
                                                item.image,
                                                "style/width-limit-512"
                                              )
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "image-slot",
                                                attrs: { slot: "placeholder" },
                                                slot: "placeholder"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  加载中"
                                                ),
                                                _c(
                                                  "span",
                                                  { staticClass: "dot" },
                                                  [_vm._v("...")]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass:
                                              "common-flex-center error-background",
                                            staticStyle: {
                                              "border-radius": "5px"
                                            },
                                            style: {
                                              height:
                                                _vm.element.options
                                                  .imageHeight + "px",
                                              width: "100%"
                                            }
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "common-no-default",
                                              attrs: {
                                                src: _vm.isCloudRetailPartner
                                                  ? "../../assets/images/taozhiLogo.png"
                                                  : "../../assets/images/taozhiLogo.png"
                                              }
                                            })
                                          ]
                                        )
                                  ],
                                  1
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _c("div", [
                          _vm.element.content.size === 1
                            ? _c(
                                "div",
                                {
                                  staticClass: "flex-row flex-space-between",
                                  style: {
                                    margin:
                                      _vm.element.options.innerMargin ===
                                      "margin"
                                        ? "15px"
                                        : "0px",
                                    height:
                                      _vm.element.options.imageHeight + "px"
                                  }
                                },
                                [
                                  _vm.element.content.parts.length >= 1 &&
                                  _vm.element.content.parts[0].image
                                    ? _c(
                                        "el-image",
                                        {
                                          staticClass: "common-image-auto-size",
                                          attrs: {
                                            fit: "cover",
                                            src: _vm.getImgUrl(
                                              _vm.element.content.parts[0]
                                                .image,
                                              "style/width-limit-512"
                                            )
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "image-slot",
                                              attrs: { slot: "placeholder" },
                                              slot: "placeholder"
                                            },
                                            [
                                              _vm._v(
                                                "\n                加载中"
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "dot" },
                                                [_vm._v("...")]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "common-flex-center error-background",
                                          style: {
                                            height:
                                              _vm.element.options.imageHeight +
                                              "px",
                                            width: "100%"
                                          }
                                        },
                                        [
                                          _vm.isCloudRetailPartner
                                            ? _c("img", {
                                                staticClass:
                                                  "cloud-common-no-default",
                                                attrs: {
                                                  src: require("../../assets/cloudRetail/default-cover.png")
                                                }
                                              })
                                            : _c("img", {
                                                staticClass:
                                                  "common-no-default",
                                                attrs: {
                                                  src: require("../../assets/images/taozhiLogo.png")
                                                }
                                              })
                                        ]
                                      )
                                ],
                                1
                              )
                            : _c(
                                "div",
                                {
                                  staticClass: "flex-row flex-space-between",
                                  style: {
                                    "padding-right":
                                      _vm.element.options.innerMargin ===
                                      "margin"
                                        ? "15px"
                                        : 0
                                  }
                                },
                                _vm._l(_vm.element.content.parts, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: item.id.toString(),
                                      style: {
                                        width:
                                          _vm.element.content.size === 3
                                            ? "33%"
                                            : "50%",
                                        height:
                                          _vm.element.options.imageHeight +
                                          "px",
                                        margin:
                                          _vm.element.options.innerMargin ===
                                          "margin"
                                            ? "15px 0 15px 15px"
                                            : "0"
                                      }
                                    },
                                    [
                                      item.image
                                        ? _c(
                                            "el-image",
                                            {
                                              staticClass:
                                                "common-image-auto-size",
                                              attrs: {
                                                fit: "cover",
                                                src: _vm.getImgUrl(
                                                  item.image,
                                                  "style/width-limit-512"
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "image-slot",
                                                  attrs: {
                                                    slot: "placeholder"
                                                  },
                                                  slot: "placeholder"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  加载中"
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "dot" },
                                                    [_vm._v("...")]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            {
                                              staticClass:
                                                "common-flex-center error-background",
                                              style: {
                                                height:
                                                  _vm.element.options
                                                    .imageHeight + "px"
                                              }
                                            },
                                            [
                                              _vm.isCloudRetailPartner
                                                ? _c("img", {
                                                    staticClass:
                                                      "cloud-common-no-default",
                                                    attrs: {
                                                      src: require("../../assets/cloudRetail/default-cover.png")
                                                    }
                                                  })
                                                : _c("img", {
                                                    staticClass:
                                                      "common-no-default",
                                                    attrs: {
                                                      src: require("../../assets/images/taozhiLogo.png")
                                                    }
                                                  })
                                            ]
                                          )
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                        ])
                  ]
                )
              ]
            )
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.element.type === "rich_text"
      ? _c(
          "div",
          {
            style: [
              {
                "padding-top": _vm.getMarginTop,
                "padding-bottom": _vm.getMarginBottom
              }
            ]
          },
          [
            _c("div", { class: [_vm.getCard(_vm.element)] }, [
              _c(
                "div",
                {
                  class: [
                    _vm.element.options.appearance === "card"
                      ? "card-inner"
                      : ""
                  ],
                  style: {
                    "background-color": _vm.element.options.color.background,
                    padding:
                      _vm.element.options.innerMargin === "fill" ? 0 : "15px"
                  }
                },
                [
                  _c("rich-text", {
                    attrs: {
                      "rich-text": _vm.element.content.text,
                      content: _vm.element.content
                    }
                  })
                ],
                1
              )
            ])
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.element.type === "micro_title"
      ? _c(
          "div",
          {
            style: [
              {
                "padding-top": _vm.getMarginTop,
                "padding-bottom": _vm.getMarginBottom
              }
            ]
          },
          [
            _c("div", { class: [_vm.getCard(_vm.element)] }, [
              _c(
                "div",
                {
                  class: [
                    _vm.element.options.appearance === "card"
                      ? "card-inner"
                      : "",
                    "micro-title-component"
                  ],
                  style: {
                    "background-color": _vm.element.options.color.background,
                    "text-align": _vm.element.content.align,
                    "background-image": _vm.microTitleBg
                  }
                },
                [
                  _c(
                    "div",
                    {
                      style: {
                        color: _vm.element.options.color.mainFont,
                        "font-size": _vm.element.content.mainSize + "px",
                        "font-weight": _vm.element.content.mainWeight
                      }
                    },
                    [_vm._v(_vm._s(_vm.element.content.mainTitle))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      style: {
                        "padding-top":
                          _vm.element.content.subTitle &&
                          _vm.element.content.mainTitle
                            ? "10px"
                            : undefined,
                        color: _vm.element.options.color.subFont,
                        "font-size": _vm.element.content.subSize + "px",
                        "font-weight": _vm.element.content.subWeight
                      }
                    },
                    [_vm._v(_vm._s(_vm.element.content.subTitle))]
                  )
                ]
              )
            ])
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.element.type === "book_shelf" || _vm.element.type === "app_audio_book"
      ? _c(
          "div",
          {
            style: {
              color: _vm.element.options.color.font,
              "padding-top": _vm.getMarginTop,
              "padding-bottom": _vm.getMarginBottom
            }
          },
          [
            _c("div", { class: [_vm.getCard(_vm.element)] }, [
              _c(
                "div",
                {
                  class: [
                    _vm.element.options.appearance === "card"
                      ? "card-inner"
                      : "",
                    "book-shelf-padding"
                  ],
                  style: _vm.shelfBg
                },
                [
                  _c("public-widget-item-nav-title", {
                    staticStyle: { padding: "0 15px" },
                    attrs: {
                      "title-data": _vm.element.titleData,
                      "title-color": _vm.element.options.titleColor
                    }
                  }),
                  _vm._v(" "),
                  _vm.element.options.style === "picture"
                    ? _c(
                        "div",
                        _vm._l(_vm.bookShelfParts, function(item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "book-shelf-picture" },
                            [
                              index < _vm.element.content.size
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "book-picture",
                                      style: {
                                        height: _vm.shelfHeight(item),
                                        "justify-content":
                                          _vm.element.options.style ===
                                          "pictureTwo"
                                            ? "space-between"
                                            : ""
                                      }
                                    },
                                    _vm._l(item, function(itemBook, indexBook) {
                                      return _c(
                                        "div",
                                        {
                                          key: indexBook,
                                          class: [
                                            _vm.element.options.style ===
                                            "pictureTwo"
                                              ? "picture-two-item"
                                              : "picture-item"
                                          ]
                                        },
                                        [
                                          _c("book-shelf-item", {
                                            attrs: {
                                              value: itemBook,
                                              "cover-type": _vm.eBookType(item),
                                              options: _vm.element.options,
                                              "book-cover": 1
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    }),
                                    0
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.element.options.showBgImg
                                ? _c(
                                    "div",
                                    {
                                      style: {
                                        height:
                                          _vm.element.options.appearance !==
                                          "card"
                                            ? "35px"
                                            : "32px"
                                      }
                                    },
                                    [
                                      _c("img", {
                                        class: [
                                          _vm.element.options.appearance !==
                                          "card"
                                            ? "shelf-bg-bottom"
                                            : "shelf-bg-bottom-card"
                                        ],
                                        attrs: {
                                          src: require("../../assets/microPage/shelf-bg-bottom.png")
                                        }
                                      })
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.element.options.namePosition === "0"
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "book-picture",
                                      style: {
                                        "justify-content":
                                          _vm.element.options.style ===
                                          "pictureTwo"
                                            ? "space-between"
                                            : "",
                                        "margin-top": !_vm.element.options
                                          .showBgImg
                                          ? "5px"
                                          : ""
                                      }
                                    },
                                    _vm._l(item, function(itemBook, indexBook) {
                                      return _c(
                                        "div",
                                        {
                                          key: indexBook,
                                          class: [
                                            _vm.element.options.style ===
                                            "pictureTwo"
                                              ? "picture-two-item"
                                              : "picture-item"
                                          ]
                                        },
                                        [
                                          _c("book-shelf-item", {
                                            attrs: {
                                              value: itemBook,
                                              "cover-type": _vm.eBookType(item),
                                              options: _vm.element.options,
                                              "book-cover": 2
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    }),
                                    0
                                  )
                                : _vm._e()
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.element.options.style === "line"
                    ? _c(
                        "div",
                        _vm._l(_vm.bookShelfParts, function(item, index) {
                          return _c(
                            "div",
                            { key: item.id },
                            [
                              index < _vm.element.content.size
                                ? _c("book-shelf-item", {
                                    attrs: {
                                      value: item,
                                      options: _vm.element.options
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.element.options.style === "slide"
                    ? _c(
                        "slider",
                        {
                          staticStyle: {
                            "padding-left": "15px",
                            width: "100%",
                            "box-sizing": "border-box"
                          },
                          attrs: {
                            options: Object.assign({}, _vm.sliderinit, {
                              slidesToScroll: 3
                            })
                          }
                        },
                        _vm._l(_vm.bookShelfParts, function(item, index) {
                          return _c(
                            "slideritem",
                            {
                              key: index,
                              style: {
                                width: "115px",
                                "margin-bottom": "15px",
                                "padding-right":
                                  index === _vm.bookShelfParts.length - 1
                                    ? "15px"
                                    : 0
                              }
                            },
                            [
                              index < _vm.element.content.size
                                ? _c("book-shelf-item", {
                                    attrs: {
                                      value: item,
                                      options: _vm.element.options
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ])
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.element.type === "app_home_book_shelf"
      ? _c(
          "div",
          {
            style: {
              color: _vm.element.options.color.font,
              "padding-top": _vm.getMarginTop,
              "padding-bottom": _vm.getMarginBottom
            }
          },
          [
            _c("div", { class: [_vm.getCard(_vm.element)] }, [
              _c(
                "div",
                {
                  class: [
                    _vm.element.options.appearance === "card"
                      ? "card-inner"
                      : "",
                    "book-shelf-padding"
                  ],
                  style: _vm.shelfBg
                },
                [
                  _c("public-widget-item-nav-title", {
                    staticStyle: { padding: "0 15px" },
                    attrs: {
                      "title-data": _vm.element.titleData,
                      "title-color": _vm.element.options.titleColor
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "100%", height: "100px" } })
                ],
                1
              )
            ])
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.element.type === "live"
      ? _c(
          "div",
          {
            style: {
              color: _vm.element.options.color.font,
              "padding-top": _vm.getMarginTop,
              "padding-bottom": _vm.getMarginBottom
            }
          },
          [
            _c("div", { class: [_vm.getCard(_vm.element)] }, [
              _c(
                "div",
                {
                  staticClass: "flex-column image-all-padding live ",
                  class: [
                    _vm.element.options.appearance === "card"
                      ? "card-inner"
                      : ""
                  ],
                  style: { background: _vm.element.options.color.background }
                },
                [
                  _c("public-widget-item-nav-title", {
                    attrs: { "title-data": _vm.element.titleData }
                  }),
                  _vm._v(" "),
                  _vm.element.options.style === "picture"
                    ? _c(
                        "div",
                        _vm._l(_vm.bookParts, function(item, index) {
                          return _c(
                            "div",
                            {
                              key: "live_" + index,
                              staticStyle: {
                                "box-shadow": "0 2px 6px 0 rgba(0, 0, 0, 0.1)",
                                "border-radius": "4px"
                              },
                              style: { "margin-bottom": "15px" }
                            },
                            [
                              index < _vm.element.content.size
                                ? _c("live-item", {
                                    attrs: {
                                      "my-course-item": item,
                                      "is-line": false
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _c(
                        "div",
                        _vm._l(_vm.bookParts, function(item, index) {
                          return _c(
                            "div",
                            {
                              key: "live_" + index,
                              staticClass: "live-line-border"
                            },
                            [
                              index < _vm.element.content.size
                                ? _c("live-item", {
                                    attrs: {
                                      "my-course-item": item,
                                      "is-line": true
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        }),
                        0
                      )
                ],
                1
              )
            ])
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.element.type === "knowledge_goods" ||
    _vm.element.type === "ranking_list"
      ? _c(
          "div",
          {
            style: {
              color: _vm.element.options.color.font,
              "padding-top": _vm.getMarginTop,
              "padding-bottom": _vm.getMarginBottom
            }
          },
          [
            _c("div", { class: [_vm.getCard(_vm.element)] }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex-column image-all-padding background-normal knowledge-goods",
                  class: [
                    _vm.element.options.appearance === "card"
                      ? "card-inner"
                      : ""
                  ],
                  style: { background: _vm.element.options.color.background }
                },
                [
                  _c("public-widget-item-nav-title", {
                    attrs: { "title-data": _vm.element.titleData }
                  }),
                  _vm._v(" "),
                  _c("recommend-ranking-list", {
                    attrs: {
                      element: _vm.element,
                      "recommend-rule": _vm.element.content.recommendRule,
                      recommend: _vm.element.content.recommend,
                      "parts-length": _vm.bookParts.length
                    }
                  }),
                  _vm._v(" "),
                  _vm.element.options.style === "line"
                    ? _c("content-list-show", {
                        attrs: {
                          list: _vm.bookParts,
                          size: _vm.element.content.size,
                          card: _vm.element.options.appearance === "card",
                          options: _vm.element.options,
                          type: _vm.element.type,
                          "recommend-rule": _vm.element.content.recommendRule,
                          content: _vm.element.content
                        }
                      })
                    : _vm.element.options.style === "smallPicture"
                    ? _c("content-small-picture", {
                        attrs: {
                          size: _vm.element.content.size,
                          list: _vm.bookParts,
                          card: _vm.element.options.appearance === "card",
                          options: _vm.element.options,
                          type: _vm.element.type,
                          "recommend-rule": _vm.element.content.recommendRule,
                          content: _vm.element.content
                        }
                      })
                    : _c(
                        "slider",
                        {
                          attrs: {
                            options: Object.assign({}, _vm.sliderinit, {
                              slidesToScroll: 3
                            })
                          }
                        },
                        _vm._l(_vm.bookParts, function(item, index) {
                          return _c(
                            "slideritem",
                            {
                              key: index,
                              style: {
                                width: "120px",
                                "margin-bottom": "15px",
                                "margin-right": "15px"
                              }
                            },
                            [
                              _c("div", { staticStyle: { width: "100%" } }, [
                                index < _vm.element.content.size
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        { staticClass: "scroll-item" },
                                        [
                                          _c("resource-item", {
                                            attrs: {
                                              resource: item,
                                              type: "roll",
                                              "is-rank-list":
                                                _vm.element.type ===
                                                "ranking_list",
                                              "cur-icon-index": _vm.element
                                                .content.recommendRule
                                                ? index + 2
                                                : index + 1
                                            }
                                          }),
                                          _vm._v(" "),
                                          _vm.element.type === "knowledge_goods"
                                            ? _c("resource-type", {
                                                attrs: {
                                                  "resource-type":
                                                    item.resourceType
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "hidden-line book-title scroll-title"
                                        },
                                        [_vm._v(_vm._s(item.title))]
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            ]
                          )
                        }),
                        1
                      )
                ],
                1
              )
            ])
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.element.type === "famous_teachers"
      ? _c(
          "div",
          {
            style: {
              "padding-top": _vm.getMarginTop,
              "padding-bottom": _vm.getMarginBottom,
              color: _vm.element.options.color.font
            }
          },
          [
            _c("div", { class: [_vm.getCard(_vm.element)] }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex-column image-all-padding background-normal",
                  class: [
                    _vm.element.options.appearance === "card"
                      ? "card-inner"
                      : ""
                  ],
                  style: { background: _vm.element.options.color.background }
                },
                [
                  _c("public-widget-item-nav-title", {
                    attrs: { "title-data": _vm.element.titleData }
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.bookParts, function(item, index) {
                    return _c("div", { key: "famous_teachers_" + index }, [
                      index < _vm.element.content.size
                        ? _c(
                            "div",
                            {
                              staticClass: "flex-row",
                              staticStyle: { "margin-bottom": "15px" }
                            },
                            [
                              item.cover
                                ? _c("el-image", {
                                    staticClass: "teacher-cover",
                                    attrs: {
                                      fit: "cover",
                                      src: _vm.getImgUrl(
                                        item.cover,
                                        "style/width-limit-128"
                                      )
                                    }
                                  })
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "common-flex-center error-background teacher-cover"
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: {
                                          width: "40px",
                                          height: "13px"
                                        },
                                        attrs: {
                                          src: require("../../assets/images/taozhiLogo.png")
                                        }
                                      })
                                    ]
                                  ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "flex-column flex-space-between",
                                  style: {
                                    width:
                                      _vm.element.options.appearance === "card"
                                        ? "225px"
                                        : "255rpx",
                                    "padding-left": "15px"
                                  }
                                },
                                [
                                  _c("span", { staticClass: "live-title" }, [
                                    _vm._v(_vm._s(item.name))
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "flex-column" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "more-font hidden-one-line common-line-height"
                                      },
                                      [_vm._v(_vm._s(item.introduction))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "free-price common-line-height"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            item.resourceCount === 0
                                              ? "暂无作品"
                                              : item.resourceCount + "个作品"
                                          )
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  })
                ],
                2
              )
            ])
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.element.type === "test_paper"
      ? _c(
          "div",
          {
            style: {
              "padding-top": _vm.getMarginTop,
              "padding-bottom": _vm.getMarginBottom,
              color: _vm.element.options.color.font
            }
          },
          [
            _c("div", { class: [_vm.getCard(_vm.element)] }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex-column image-all-padding background-normal",
                  class: [
                    _vm.element.options.appearance === "card"
                      ? "card-inner"
                      : ""
                  ],
                  style: { background: _vm.element.options.color.background }
                },
                [
                  _c("public-widget-item-nav-title", {
                    attrs: { "title-data": _vm.element.titleData }
                  }),
                  _vm._v(" "),
                  _vm.element.content.recommendRule
                    ? _c("paper-recommend", {
                        attrs: {
                          "item-data": _vm.paperRecommend,
                          "show-bak-img": _vm.element.content.showBakImg,
                          "recommend-bak-img":
                            _vm.element.content.recommendBakImg,
                          options: _vm.element.options
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.bookParts, function(item, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        index < _vm.element.content.size
                          ? _c("test-paper", {
                              attrs: {
                                "item-data": item,
                                "is-only-one": _vm.paperIsOnly
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ])
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.element.type === "test_questions"
      ? _c(
          "div",
          {
            style: {
              "padding-top": _vm.getMarginTop,
              "padding-bottom": _vm.getMarginBottom,
              color: _vm.element.options.color.font
            }
          },
          [
            _c("div", { class: [_vm.getCard(_vm.element)] }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex-column image-all-padding background-normal",
                  class: [
                    _vm.element.options.appearance === "card"
                      ? "card-inner"
                      : ""
                  ],
                  style: { background: _vm.element.options.color.background }
                },
                [
                  _c("public-widget-item-nav-title", {
                    attrs: { "title-data": _vm.element.titleData }
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.bookParts, function(item, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        index < _vm.element.content.size
                          ? _c("test-question", {
                              attrs: {
                                "item-data": item,
                                options: _vm.element.options,
                                "is-answer-state":
                                  _vm.element.content.isAnswerState,
                                "is-show-question-index":
                                  _vm.isShowQuestionIndex,
                                "is-type-navigate": _vm.isTypeNavigate,
                                "data-index": index + 1
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ])
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.element.type === "micro_audio"
      ? _c(
          "div",
          {
            style: {
              "padding-top": _vm.getMarginTop,
              "padding-bottom": _vm.getMarginBottom,
              color: _vm.element.options.color.font
            }
          },
          [
            _c("div", { class: [_vm.getCard(_vm.element)] }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex-column common-micro-fifteen-padding background-normal",
                  class: [
                    _vm.element.options.appearance === "card"
                      ? "card-inner"
                      : ""
                  ],
                  style: { background: _vm.element.options.color.background }
                },
                [
                  _c("public-widget-item-nav-title", {
                    attrs: { "title-data": _vm.element.titleData }
                  }),
                  _vm._v(" "),
                  _c("micro-audio-item", {
                    attrs: {
                      size: _vm.element.content.size,
                      "list-data": _vm.bookParts,
                      model: _vm.element.model,
                      options: _vm.element.options
                    }
                  })
                ],
                1
              )
            ])
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.element.type === "micro_video"
      ? _c(
          "div",
          {
            style: {
              "padding-top": _vm.getMarginTop,
              "padding-bottom": _vm.getMarginBottom,
              color: _vm.element.options.color.font
            }
          },
          [
            _c("div", { class: [_vm.getCard(_vm.element)] }, [
              _c(
                "div",
                {
                  staticClass: "flex-column  background-normal",
                  class: [
                    _vm.element.options.appearance === "card"
                      ? "card-inner"
                      : "",
                    _vm.element.options.innerMargin === "margin"
                      ? "micro-video-padding"
                      : ""
                  ],
                  style: { background: _vm.element.options.color.background }
                },
                [
                  _c("public-widget-item-nav-title", {
                    style: {
                      padding:
                        _vm.element.options.innerMargin === "margin"
                          ? "0 0 0 15px"
                          : "15px 15px 0 15px"
                    },
                    attrs: { "title-data": _vm.element.titleData }
                  }),
                  _vm._v(" "),
                  _c("micro-video", {
                    style: {
                      "padding-right":
                        _vm.element.options.style !== "row" &&
                        _vm.element.options.innerMargin === "margin"
                          ? "15px"
                          : 0
                    },
                    attrs: {
                      size: _vm.element.content.size,
                      "list-data": _vm.bookParts,
                      model: _vm.element.model,
                      options: _vm.element.options
                    }
                  })
                ],
                1
              )
            ])
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.element.type === "micro_word"
      ? _c(
          "div",
          {
            style: {
              "padding-top": _vm.getMarginTop,
              "padding-bottom": _vm.getMarginBottom,
              color: _vm.element.options.color.font
            }
          },
          [
            _c("div", { class: [_vm.getCard(_vm.element)] }, [
              _c(
                "div",
                {
                  class: [
                    _vm.element.options.appearance === "card"
                      ? "card-inner"
                      : ""
                  ]
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex-column image-all-padding word-bg" },
                    [
                      _c("public-widget-item-nav-title", {
                        staticStyle: { "padding-right": "15px" },
                        attrs: {
                          "title-data": Object.assign(
                            {},
                            _vm.element.titleData,
                            {
                              title:
                                _vm.element.titleData.style === "default"
                                  ? _vm.element.options.style === "read"
                                    ? "会认字"
                                    : "会写字"
                                  : _vm.element.titleData.title
                            }
                          ),
                          "icon-title":
                            _vm.element.options.style === "read" ? "认" : "写",
                          "title-color": "#2A536B"
                        }
                      }),
                      _vm._v(" "),
                      _c("word-item", {
                        attrs: {
                          list: _vm.element.content.parts,
                          "is-read": _vm.element.options.style === "read",
                          options: _vm.element.options
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "word-footer-bg",
                    style: {
                      height:
                        _vm.element.options.appearance === "card"
                          ? "36px"
                          : "40px"
                    }
                  })
                ]
              )
            ])
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.element.type === "micro_polyphonic"
      ? _c(
          "div",
          {
            style: {
              "padding-top": _vm.getMarginTop,
              "padding-bottom": _vm.getMarginBottom,
              color: _vm.element.options.color.font
            }
          },
          [
            _c("div", { class: [_vm.getCard(_vm.element)] }, [
              _c(
                "div",
                {
                  class: [
                    _vm.element.options.appearance === "card"
                      ? "card-inner"
                      : ""
                  ]
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex-column image-all-padding word-bg" },
                    [
                      _c("public-widget-item-nav-title", {
                        staticStyle: { "padding-right": "15px" },
                        attrs: {
                          "title-data": _vm.element.titleData,
                          "icon-title": "音",
                          "title-color": "#2A536B"
                        }
                      }),
                      _vm._v(" "),
                      _vm._l(_vm.element.content.parts, function(item, index) {
                        return _c(
                          "div",
                          { key: item.openId },
                          [
                            _c("polyphonic-character", {
                              attrs: {
                                value: item,
                                options: _vm.element.options,
                                "is-more": _vm.isMore
                              },
                              on: { handleMore: _vm.handleMore }
                            })
                          ],
                          1
                        )
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "word-footer-bg",
                    style: {
                      height:
                        _vm.element.options.appearance === "card"
                          ? "36px"
                          : "40px"
                    }
                  })
                ]
              )
            ])
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.element.type === "micro_terms"
      ? _c(
          "div",
          {
            style: {
              "padding-top": _vm.getMarginTop,
              "padding-bottom": _vm.getMarginBottom,
              color: _vm.element.options.color.font
            }
          },
          [
            _c("div", { class: [_vm.getCard(_vm.element)] }, [
              _c(
                "div",
                {
                  class: [
                    _vm.element.options.appearance === "card"
                      ? "card-inner"
                      : ""
                  ]
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex-column image-all-padding word-bg" },
                    [
                      _c("public-widget-item-nav-title", {
                        attrs: {
                          "title-data": _vm.element.titleData,
                          "icon-title": "词",
                          "title-color": "#2A536B",
                          "show-dictation": _vm.element.content.parts.length > 0
                        }
                      }),
                      _vm._v(" "),
                      _vm._l(_vm.element.content.parts, function(item) {
                        return _c(
                          "div",
                          { key: item.openId },
                          [
                            _c("terms", {
                              attrs: {
                                value: item,
                                options: _vm.element.options
                              }
                            })
                          ],
                          1
                        )
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "word-footer-bg",
                    style: {
                      height:
                        _vm.element.options.appearance === "card"
                          ? "36px"
                          : "40px"
                    }
                  })
                ]
              )
            ])
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.element.type === "coupon"
      ? _c(
          "div",
          {
            style: {
              "padding-top": _vm.getMarginTop,
              "padding-bottom": _vm.getMarginBottom,
              color: _vm.element.options.color.font
            }
          },
          [
            _c("div", { class: [_vm.getCard(_vm.element)] }, [
              _c(
                "div",
                {
                  class: [
                    _vm.element.options.appearance === "card"
                      ? "card-inner"
                      : ""
                  ],
                  style: {
                    background: _vm.element.options.color.background,
                    padding: "15px 15px 4px 15px"
                  }
                },
                [
                  _c("public-widget-item-nav-title", {
                    attrs: { "title-data": _vm.element.titleData }
                  }),
                  _vm._v(" "),
                  _vm.element.options.style === "line"
                    ? _c(
                        "div",
                        { staticClass: "flex-column" },
                        _vm._l(_vm.bookParts, function(item, index) {
                          return _c("div", { key: index }, [
                            index < _vm.element.content.size
                              ? _c(
                                  "div",
                                  { staticClass: "coupon" },
                                  [
                                    _c("coupon-item", {
                                      attrs: {
                                        "item-info": item,
                                        "is-sideslip": false,
                                        "is-open":
                                          _vm.element.options.appearance ===
                                          "open"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ])
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.element.options.style === "slide"
                    ? _c(
                        "slider",
                        {
                          staticClass: "sideslip-coupon",
                          attrs: {
                            options: Object.assign({}, _vm.sliderinit, {
                              slidesToScroll: 3
                            })
                          }
                        },
                        _vm._l(_vm.bookParts, function(item, index) {
                          return _c(
                            "slideritem",
                            {
                              key: item.id,
                              staticStyle: {
                                width: "220px",
                                "font-size": "14px"
                              }
                            },
                            [
                              index < _vm.element.content.size
                                ? _c(
                                    "div",
                                    { staticClass: "coupon-no-warp" },
                                    [
                                      _c("coupon-item", {
                                        attrs: {
                                          "item-info": item,
                                          "is-sideslip": true,
                                          "is-open":
                                            _vm.element.options.appearance ===
                                            "open"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          )
                        }),
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ])
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.element.type === "partner_info"
      ? _c(
          "div",
          {
            style: {
              "padding-top": _vm.getMarginTop,
              "padding-bottom": _vm.getMarginBottom
            }
          },
          [
            _c("partner-info", {
              style: {
                background: _vm.element.options.color.background,
                color: _vm.element.options.color.font
              },
              attrs: { element: _vm.element }
            })
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.element.type === "official_account" || _vm.element.type === "applets"
      ? _c(
          "div",
          {
            style: {
              color: _vm.element.options.color.font,
              "padding-top": _vm.getMarginTop,
              "padding-bottom": _vm.getMarginBottom
            }
          },
          [
            _c("div", { class: [_vm.getCard(_vm.element)] }, [
              _c(
                "div",
                {
                  class: [
                    _vm.element.options.appearance === "card"
                      ? "card-inner"
                      : "",
                    "official"
                  ],
                  style: { background: _vm.element.options.color.background }
                },
                [
                  _c("div", { staticClass: "flex-row align-center" }, [
                    _c("div", { staticClass: "head-img" }),
                    _vm._v(" "),
                    _vm.element.content.headImg
                      ? _c("img", {
                          staticClass: "official-logo-img",
                          attrs: {
                            src:
                              _vm.element.content.type === 1
                                ? _vm.getImgUrl(
                                    _vm.element.content.headImg,
                                    "style/width-limit-64"
                                  )
                                : _vm.element.content.headImg
                          }
                        })
                      : _c(
                          "div",
                          {
                            staticClass: "common-flex-center official-logo-img",
                            staticStyle: { "background-color": "#EFEFEF" }
                          },
                          [
                            _c("img", {
                              staticClass: "common-no-default",
                              staticStyle: { width: "40px", height: "13px" },
                              attrs: {
                                src: require("../../assets/images/taozhiLogo.png")
                              }
                            })
                          ]
                        ),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex-column" }, [
                      _c("span", { staticStyle: { "font-size": "18px" } }, [
                        _vm._v(_vm._s(_vm.element.content.nickName))
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "official-describe" }, [
                        _vm._v(_vm._s(_vm.element.content.detail))
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "follow-btn",
                      attrs: { size: "mini", round: "" }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.element.type === "official_account"
                            ? "关注"
                            : "查看"
                        )
                      )
                    ]
                  )
                ],
                1
              )
            ])
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.element.type === "bookstore"
      ? _c(
          "div",
          {
            style: {
              color: _vm.element.options.color.font,
              "padding-top": _vm.getMarginTop,
              "padding-bottom": _vm.getMarginBottom
            }
          },
          [
            _c("div", { class: [_vm.getCard(_vm.element)] }, [
              _c(
                "div",
                {
                  class: [
                    _vm.element.options.appearance === "card"
                      ? "card-inner"
                      : "",
                    "bookstore"
                  ],
                  style: { background: _vm.element.options.color.background }
                },
                [
                  _c("public-widget-item-nav-title", {
                    attrs: { "title-data": _vm.element.titleData }
                  }),
                  _vm._v(" "),
                  _c("micro-bookstore")
                ],
                1
              )
            ])
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.element.type === "countdown"
      ? _c(
          "div",
          {
            style: {
              "padding-top": _vm.getMarginTop,
              "padding-bottom": _vm.getMarginBottom
            }
          },
          [
            _c("div", { class: [_vm.getCard(_vm.element)] }, [
              _c(
                "div",
                {
                  class: [
                    _vm.element.options.appearance === "card"
                      ? "card-inner"
                      : ""
                  ],
                  style: { background: _vm.element.options.color.background }
                },
                [
                  _c("countdown", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      options: _vm.element.options,
                      value: _vm.element.content
                    }
                  })
                ],
                1
              )
            ])
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.element.type === "cloud_retail_partner"
      ? _c(
          "div",
          {
            style: {
              "padding-top": _vm.getMarginTop,
              "padding-bottom": _vm.getMarginBottom
            }
          },
          [
            _c(
              "div",
              {
                class: [_vm.getCard(_vm.element)],
                staticStyle: { width: "100%" }
              },
              [
                _c("cloud-retail-partner", {
                  class: [
                    _vm.element.options.appearance === "card"
                      ? "card-inner"
                      : ""
                  ],
                  style: {
                    "background-color": _vm.element.options.color.background,
                    width: "100%"
                  },
                  attrs: { element: _vm.element }
                })
              ],
              1
            )
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.element.type === "cloud_retail_goods"
      ? _c(
          "div",
          {
            style: {
              "padding-top": _vm.getMarginTop,
              "padding-bottom": _vm.getMarginBottom
            }
          },
          [
            _c(
              "div",
              {
                class: [_vm.getCard(_vm.element)],
                staticStyle: { width: "100%" }
              },
              [
                _c(
                  "div",
                  {
                    class: [
                      _vm.element.options.appearance === "card"
                        ? "card-inner"
                        : "",
                      _vm.element.options.style === "slide"
                        ? "cloud-goods-slide"
                        : "image-all-padding"
                    ],
                    style: {
                      background:
                        _vm.element.options.style === "card"
                          ? _vm.htmlData.htmlBackgroundColor
                          : _vm.element.options.color.background
                    }
                  },
                  [
                    _c("public-widget-item-nav-title", {
                      style: {
                        "padding-right":
                          _vm.element.options.style === "slide" ? "15px" : "0"
                      },
                      attrs: {
                        type: _vm.element.type,
                        "title-data": _vm.element.titleData,
                        "title-color": "#181818",
                        "more-color": "#7A7C7B"
                      }
                    }),
                    _vm._v(" "),
                    _c("cloud-retail-goods", {
                      attrs: {
                        "is-type-card": _vm.isTypeNavigate,
                        "show-reality": _vm.element.price.showReality,
                        "show-original": _vm.element.price.showOriginal,
                        options: _vm.element.options,
                        size: _vm.element.content.size,
                        list: _vm.bookParts,
                        "right-list": _vm.rightList,
                        "left-list": _vm.leftList
                      }
                    })
                  ],
                  1
                )
              ]
            )
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.element.type === "tool"
      ? _c(
          "div",
          {
            style: {
              "padding-top": _vm.getMarginTop,
              "padding-bottom": _vm.getMarginBottom
            }
          },
          [
            _c("div", { class: [_vm.getCard(_vm.element)] }, [
              _c(
                "div",
                {
                  class: [
                    _vm.element.options.appearance === "card"
                      ? "card-inner"
                      : "",
                    "bookstore"
                  ],
                  style: { background: _vm.element.options.color.background }
                },
                [
                  _c("public-widget-item-nav-title", {
                    attrs: {
                      type: _vm.element.type,
                      "title-data": _vm.element.titleData
                    }
                  }),
                  _vm._v(" "),
                  _c("cloud-retail-tool", {
                    attrs: {
                      value: _vm.element.content,
                      options: _vm.element.options
                    }
                  })
                ],
                1
              )
            ])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }