"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getVocabularyList = getVocabularyList;
exports.getCharNum = getCharNum;
exports.getVocabulary = getVocabulary;
exports.addVocabulary = addVocabulary;
exports.deleteVocabulary = deleteVocabulary;
exports.getVocabularyByOpenIds = getVocabularyByOpenIds;
exports.updateVocabulary = updateVocabulary;

var _main = _interopRequireDefault(require("@/utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/library/admin/vocabulary/"));
/* 获取词列表 */

function getVocabularyList(params) {
  return request.get('findPage', {
    params: params
  });
}
/* 获取所有词的字数 */


function getCharNum() {
  return request.get('getCharNum');
}
/* 查询词的详情/校验词是否存在 */


function getVocabulary(params) {
  return request.get('checkVocabulary', {
    params: params
  });
}
/* 添加词语 */


function addVocabulary(data, params) {
  return request.post('addVocabulary', data, {
    params: params
  });
}
/* 删除词语 */


function deleteVocabulary(vocabularyOpenId) {
  return request.delete("deleteVocabulary/".concat(vocabularyOpenId));
}
/* 更新词语信息 */


function updateVocabulary(data, params) {
  return request.put("updateVocabulary/".concat(data.vocabularyOpenId), data, {
    params: params
  });
}
/* 根据多个openIds获取词语详情 */


function getVocabularyByOpenIds(params) {
  return request.get('getVocabulary', {
    params: params
  });
}