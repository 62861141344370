var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "resource-price-bg" },
      [
        !_vm.resourceObj.independentSale
          ? _c("span", { staticClass: "vip-tag" }, [_vm._v("不支持单独售卖")])
          : _c("span", { staticClass: "vip-tag" }, [
              _vm.resourceObj.vipOnly
                ? _c("span", { staticClass: "vip-tag" }, [_vm._v("VIP专享")])
                : _c("span", [
                    _vm.resourceObj.price <= 0
                      ? _c("span", [_vm._v("免费")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.resourceObj.price
                      ? _c("span", [
                          _vm._v("原价：¥"),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("moneyTransform")(_vm.resourceObj.price)
                              )
                            )
                          ]),
                          _vm._v("，")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.resourceObj.countPrice
                      ? _c("span", [
                          _vm._v(
                            "折后价：¥" +
                              _vm._s(
                                _vm._f("moneyTransform")(
                                  _vm.resourceObj.countPrice
                                )
                              ) +
                              "，"
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.resourceObj.vipDiscount
                      ? _c("span", { staticClass: "vip-tag" }, [
                          _vm._v(
                            "VIP折后价：¥" +
                              _vm._s(
                                _vm._f("moneyTransform")(
                                  _vm.resourceObj.vipCountPrice
                                )
                              )
                          )
                        ])
                      : !_vm.resourceObj.vipDiscount &&
                        _vm.resourceObj.price > 0
                      ? _c("span", { staticClass: "vip-tag" }, [
                          _vm._v("VIP免费")
                        ])
                      : _vm._e()
                  ])
            ]),
        _vm._v(" "),
        _c("span", [_vm._v("|")]),
        _vm._v(" "),
        _c(
          "el-tooltip",
          { staticClass: "timer", attrs: { effect: "dark", placement: "top" } },
          [
            _c(
              "div",
              {
                staticStyle: { "line-height": "20px" },
                attrs: { slot: "content" },
                slot: "content"
              },
              [
                _vm._v(
                  "\n        更新时间：" +
                    _vm._s(
                      _vm._f("parseTime")(
                        _vm.resourceObj.updatedAt,
                        "{y}-{m}-{d} {h}:{i}"
                      )
                    )
                ),
                _c("br"),
                _vm._v(
                  "\n        创建时间：" +
                    _vm._s(
                      _vm._f("parseTime")(
                        _vm.resourceObj.createdAt,
                        "{y}-{m}-{d} {h}:{i}"
                      )
                    ) +
                    "\n      "
                )
              ]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "timer-state" }, [
              _vm._v(
                "更新时间：" +
                  _vm._s(_vm.getDateDiff(_vm.resourceObj.updatedAt))
              )
            ])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }