var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "draggable-options" },
    [
      _vm.value.length === 1 && _vm.getShowCopy(_vm.elementData)
        ? _c(
            "div",
            {
              staticClass: "delete move-border",
              on: {
                click: function($event) {
                  return _vm.moveCopy(_vm.index)
                }
              }
            },
            [_c("i", { staticClass: "iconfont icon-copy option-icon" })]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.value.length === 1
        ? _c(
            "el-popover",
            {
              attrs: { placement: "left-start", width: "287" },
              model: {
                value: _vm.deleteVisible,
                callback: function($$v) {
                  _vm.deleteVisible = $$v
                },
                expression: "deleteVisible"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "flex-row flex-space-between delete-popover-nav"
                },
                [
                  _c("span", [_vm._v("提示")]),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "el-icon-close",
                    staticStyle: { "font-weight": "700" }
                  })
                ]
              ),
              _vm._v(" "),
              _c("P", [_vm._v("确定要删除该组件吗?")]),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "text-align": "right", margin: "0" } },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.deleteVisible = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.handleWidgetDelete(_vm.index)
                        }
                      }
                    },
                    [_vm._v("确定")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "delete",
                  attrs: { slot: "reference" },
                  slot: "reference"
                },
                [
                  _c("i", {
                    staticClass: "el-icon-circle-close",
                    staticStyle: { "font-weight": "700" }
                  })
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.value.length > 1
        ? _c(
            "div",
            [
              _vm.index !== 0
                ? _c(
                    "div",
                    {
                      staticClass: "delete move-border",
                      on: {
                        click: function($event) {
                          return _vm.moveUp(_vm.index)
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "iconfont icon-fanhuidingbu option-icon"
                      })
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.index !== _vm.value.length - 1
                ? _c(
                    "div",
                    {
                      staticClass: "delete move-border",
                      on: {
                        click: function($event) {
                          return _vm.moveDown(_vm.index)
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "iconfont icon-fanhuidibu option-icon"
                      })
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.getShowCopy(_vm.elementData)
                ? _c(
                    "div",
                    {
                      staticClass: "delete move-border",
                      on: {
                        click: function($event) {
                          return _vm.moveCopy(_vm.index)
                        }
                      }
                    },
                    [_c("i", { staticClass: "iconfont icon-copy option-icon" })]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-popover",
                {
                  attrs: { placement: "left-start", width: "287" },
                  model: {
                    value: _vm.deleteVisible,
                    callback: function($$v) {
                      _vm.deleteVisible = $$v
                    },
                    expression: "deleteVisible"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex-row flex-space-between delete-popover-nav"
                    },
                    [
                      _c("span", [_vm._v("提示")]),
                      _vm._v(" "),
                      _c("i", { staticClass: "el-icon-close" })
                    ]
                  ),
                  _vm._v(" "),
                  _c("P", [_vm._v("确定要删除该组件吗?")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { "text-align": "right", margin: "0" } },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.deleteVisible = false
                            }
                          }
                        },
                        [_vm._v("取消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.handleWidgetDelete(_vm.index)
                            }
                          }
                        },
                        [_vm._v("确定")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "delete",
                      attrs: { slot: "reference" },
                      slot: "reference"
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-circle-close",
                        staticStyle: { "font-weight": "700" }
                      })
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }