"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isCollection = isCollection;

var _main = _interopRequireDefault(require("../../organizationLibs/typeIs/main"));

var _Collection = _interopRequireDefault(require("./Collection"));

function isCollection(data) {
  return _main.default.isObject(data) && data instanceof _Collection.default;
}