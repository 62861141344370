"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _common = require("../../../utils/common");

var _AudioBookVipIcon = _interopRequireDefault(require("../AudioBookShelf/AudioBookVipIcon"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var videoCover = require("../../../assets/cover/cover-video.png");

var audioCover = require("../../../assets/cover/cover-audio.png");

var microPageAudio = require("../../../assets/cover/micro-page-audio.png");

var audioBookCover = require("../../../assets/cover/cover-audiobook.png");

var microPageAudioBook = require("../../../assets/cover/micro-page-audiobook.png");

var albumCover = require("../../../assets/cover/cover-album.png");

var filesCover = require("../../../assets/cover/cover-files.png");

var microPageFiles = require("../../../assets/cover/micro-page-files.png");

var imgTxtCover = require("../../../assets/cover/cover-imgTxt.png");

var fileCover = require("../../../assets/cover/cover-file.png");

var microPageFile = require("../../../assets/cover/micro-page-file.png");

var liveCover = require("../../../assets/cover/cover-live.png");

var _default2 = {
  name: 'ResourceItem',
  components: {
    AudioBookVipIcon: _AudioBookVipIcon.default
  },
  props: {
    resource: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    type: {
      type: String,
      default: 'line'
    },
    card: {
      type: Boolean,
      default: false
    },
    curIconIndex: {
      type: Number,
      default: 0
    },
    isRankList: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isAudio: function isAudio() {
      return this.resource.resourceType === 2 || this.resource.resourceType === 8 || this.resource.resourceType === 10;
    }
  },
  methods: {
    // 处理图片
    getImgUrl: function getImgUrl(cover) {
      var style = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'style/width-limit-256';

      if (cover) {
        return (0, _common.imageUrlFun)(cover, style);
      }

      var path;

      switch (this.resource.resourceType) {
        case 1:
          {
            path = videoCover;
            break;
          }

        case 2:
          {
            path = this.type === 'line' ? audioCover : microPageAudio;
            break;
          }

        case 3:
          {
            path = this.type === 'line' ? audioBookCover : microPageAudioBook;
            break;
          }

        case 4:
          {
            path = albumCover;
            break;
          }

        case 8:
          {
            path = this.type === 'line' ? filesCover : microPageFiles;
            break;
          }

        case 9:
          {
            path = imgTxtCover;
            break;
          }

        case 10:
          {
            path = this.type === 'line' ? fileCover : microPageFile;
            break;
          }

        case 11:
          {
            path = liveCover;
            break;
          }

        default:
          {
            path = albumCover;
          }
      }

      return path;
    }
  }
};
exports.default = _default2;