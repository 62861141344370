"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRadicalList = getRadicalList;
exports.getRadicalsNumber = getRadicalsNumber;

var _main = _interopRequireDefault(require("@/utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/library/admin/chineseRadicals/"));

function getRadicalList() {
  return request.get('radicalsTree');
}

function getRadicalsNumber() {
  return request.get('radicalsNumber');
}