"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _DialogFilterPanel = _interopRequireDefault(require("@/components/Resource/SelectResourceDialog/Header/DialogFilterPanel.vue"));

var _searchbtn = _interopRequireDefault(require("@/components/publictop/searchbtn.vue"));

var _Category = _interopRequireDefault(require("@/components/Resource/Selector/Category.vue"));

var _main = _interopRequireDefault(require("@/organizationLibs/powerCache/v1.0/main"));

var _BookSearch = _interopRequireDefault(require("./BookSearch"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var _default2 = {
  name: 'index',
  components: {
    Category: _Category.default,
    BookSearch: _BookSearch.default,
    DialogFilterPanel: _DialogFilterPanel.default,
    SearchButton: _searchbtn.default
  },
  props: {
    isShowSelect: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    selectData: {
      type: String,
      default: ''
    },
    audioBookQuery: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    bookQuery: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      keyword: undefined,
      select: 'audioBook',
      isRefresh: false,
      remember: false
    };
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(val) {
        this.$emit('input', val);
      }
    },
    resourceShelfType: function resourceShelfType() {
      if (this.select === 'ebook') {
        return -1;
      }

      if (this.select === 'ebookCase') {
        return -2;
      }

      return 3;
    }
  },
  watch: {
    selectData: {
      deep: true,
      immediate: true,
      handler: function handler(val) {
        this.select = val;
      }
    },
    'valueProxy.type': function valueProxyType() {
      this.searchBook();
    }
  },
  created: function created() {
    var store = _main.default.get('micro_book_shelf_select');

    if (!store) {
      _main.default.put('micro_book_shelf_select', {
        remember: true
      });

      this.remember = true;
    } else {
      this.remember = store.remember;
    }
  },
  methods: {
    // 记住所选
    handleRemember: function handleRemember(val) {
      var store = _main.default.get('micro_book_shelf_select');

      if (store && val) {
        _main.default.put('micro_book_shelf_select', _objectSpread(_objectSpread({}, store), {}, {
          remember: val
        }));

        return;
      }

      _main.default.put('micro_book_shelf_select', {
        remember: val
      });
    },
    changeSelectBook: function changeSelectBook(val) {
      if (val === 'book') {
        this.bookQuery.name = undefined;
        this.bookQuery.page = 1;
      } else {
        this.valueProxy.name = undefined;
        this.valueProxy.page = 1;
      }

      this.searchBook();
    },

    /* 查询图书列表条件转换 */
    getParam: function getParam(query) {
      var params = JSON.parse(JSON.stringify(query));
      params.sectionCode = params.sectionCode.length > 0 ? params.sectionCode.join(',') : undefined;
      params.subjectCode = params.subjectCode.length > 0 ? params.subjectCode.join(',') : undefined;
      params.editionCode = params.editionCode.length > 0 ? params.editionCode.join(',') : undefined;
      params.gradeCode = params.gradeCode.length > 0 ? params.gradeCode.join(',') : undefined;
      params.volumeCode = params.volumeCode.length > 0 ? params.volumeCode.join(',') : undefined;
      params.bookAreaId = params.bookAreaId.length > 0 ? params.bookAreaId.join(',') : undefined;
      params.periodCode = params.periodCode.length > 0 ? params.periodCode.join(',') : undefined;
      params.bookSeriesId = params.bookSeriesId.length > 0 ? params.bookSeriesId.join(',') : undefined;
      return params;
    },
    searchBook: function searchBook() {
      if (this.select === 'book') {
        this.$emit('searchBook', this.bookQuery);
        return;
      }

      this.$emit('searchResource', this.select);
    },
    search: function search() {
      if (this.isShowSelect) {
        this.$emit('search', this.select, this.keyword.trim(), false);
      } else {
        this.$emit('search', this.keyword.trim());
      }
    },
    handleSelectSearch: function handleSelectSearch(val) {
      this.$emit('search', val, this.keyword, true);
    }
  }
};
exports.default = _default2;