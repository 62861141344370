import { render, staticRenderFns } from "./SinglePaper.vue?vue&type=template&id=101bb77d&scoped=true&"
import script from "./SinglePaper.vue?vue&type=script&lang=js&"
export * from "./SinglePaper.vue?vue&type=script&lang=js&"
import style0 from "./SinglePaper.vue?vue&type=style&index=0&id=101bb77d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "101bb77d",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/front-end_micro-page_oo0i/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('101bb77d')) {
      api.createRecord('101bb77d', component.options)
    } else {
      api.reload('101bb77d', component.options)
    }
    module.hot.accept("./SinglePaper.vue?vue&type=template&id=101bb77d&scoped=true&", function () {
      api.rerender('101bb77d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/SelectSinglePaper/SinglePaper.vue"
export default component.exports