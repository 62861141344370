var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "rule-content flex-row align-center flex-space-between"
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "rule-form",
          attrs: { model: _vm.valueProxy, "label-width": "90px" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "关键词" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入关键词" },
                model: {
                  value: _vm.valueProxy.keyword,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "keyword", $$v)
                  },
                  expression: "valueProxy.keyword"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "选择分类" } },
            [
              _c("el-cascader", {
                ref: "cloudCategory",
                staticClass: "category-box",
                attrs: {
                  options: _vm.options,
                  clearable: "",
                  props: _vm.props
                },
                on: { change: _vm.changeData },
                model: {
                  value: _vm.classList,
                  callback: function($$v) {
                    _vm.classList = $$v
                  },
                  expression: "classList"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "商品类型" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "sync-all-input",
                  attrs: { clearable: "", placeholder: "请选择" },
                  model: {
                    value: _vm.valueProxy.resourceType,
                    callback: function($$v) {
                      _vm.$set(_vm.valueProxy, "resourceType", $$v)
                    },
                    expression: "valueProxy.resourceType"
                  }
                },
                _vm._l(_vm.typeOptions, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "选择品牌" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "sync-all-input",
                  attrs: {
                    filterable: true,
                    clearable: "",
                    placeholder: "请选择品牌"
                  },
                  on: { change: _vm.getSeriesListData },
                  model: {
                    value: _vm.valueProxy.brandOpenId,
                    callback: function($$v) {
                      _vm.$set(_vm.valueProxy, "brandOpenId", $$v)
                    },
                    expression: "valueProxy.brandOpenId"
                  }
                },
                _vm._l(_vm.brandOptions, function(item) {
                  return _c("el-option", {
                    key: item.brandOpenId,
                    attrs: { label: item.title, value: item.brandOpenId }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "商品系列" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "sync-all-input",
                  attrs: {
                    disabled: !_vm.valueProxy.brandOpenId,
                    filterable: true,
                    clearable: "",
                    placeholder: "请选择商品系列"
                  },
                  model: {
                    value: _vm.valueProxy.seriesCode,
                    callback: function($$v) {
                      _vm.$set(_vm.valueProxy, "seriesCode", $$v)
                    },
                    expression: "valueProxy.seriesCode"
                  }
                },
                _vm._l(_vm.seriesList, function(item) {
                  return _c("el-option", {
                    key: item.seriesOpenId,
                    attrs: { label: item.title, value: item.seriesOpenId }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "同步信息" } }, [
            _c(
              "div",
              { staticClass: "flex-row" },
              [
                _c("select-param-sync-info", {
                  staticClass: "sync-select",
                  attrs: {
                    size: "small",
                    filterable: true,
                    placeholder: "学段",
                    "query-option": "FindXDList"
                  },
                  model: {
                    value: _vm.valueProxy.sectionCode,
                    callback: function($$v) {
                      _vm.$set(_vm.valueProxy, "sectionCode", $$v)
                    },
                    expression: "valueProxy.sectionCode"
                  }
                }),
                _vm._v(" "),
                _c("select-param-sync-info", {
                  staticClass: "sync-select",
                  attrs: {
                    size: "small",
                    filterable: true,
                    placeholder: "学科",
                    "query-option": "FindSubjectList"
                  },
                  model: {
                    value: _vm.valueProxy.subjectCode,
                    callback: function($$v) {
                      _vm.$set(_vm.valueProxy, "subjectCode", $$v)
                    },
                    expression: "valueProxy.subjectCode"
                  }
                }),
                _vm._v(" "),
                _c("select-param-sync-info", {
                  staticClass: "sync-select",
                  attrs: {
                    size: "small",
                    filterable: true,
                    placeholder: "版本",
                    "query-option": "FindBBList"
                  },
                  model: {
                    value: _vm.valueProxy.editionCode,
                    callback: function($$v) {
                      _vm.$set(_vm.valueProxy, "editionCode", $$v)
                    },
                    expression: "valueProxy.editionCode"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-row margin-top-ten" },
              [
                _c("select-param-sync-info", {
                  staticClass: "sync-select",
                  attrs: {
                    size: "small",
                    filterable: true,
                    placeholder: "年级",
                    "query-option": "FindGradeList"
                  },
                  model: {
                    value: _vm.valueProxy.gradeCode,
                    callback: function($$v) {
                      _vm.$set(_vm.valueProxy, "gradeCode", $$v)
                    },
                    expression: "valueProxy.gradeCode"
                  }
                }),
                _vm._v(" "),
                _c("select-param-sync-info", {
                  staticClass: "sync-select",
                  attrs: {
                    size: "small",
                    filterable: true,
                    placeholder: "阶段",
                    "query-option": "FindJDList"
                  },
                  model: {
                    value: _vm.valueProxy.volumeCode,
                    callback: function($$v) {
                      _vm.$set(_vm.valueProxy, "volumeCode", $$v)
                    },
                    expression: "valueProxy.volumeCode"
                  }
                }),
                _vm._v(" "),
                _c("select-param-sync-info", {
                  staticClass: "sync-select",
                  attrs: {
                    size: "small",
                    filterable: true,
                    placeholder: "选必修",
                    "query-option": "FindElective"
                  },
                  model: {
                    value: _vm.valueProxy.electiveCode,
                    callback: function($$v) {
                      _vm.$set(_vm.valueProxy, "electiveCode", $$v)
                    },
                    expression: "valueProxy.electiveCode"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "版次信息" } },
            [
              _c("select-param-sync-info", {
                staticClass: "sync-all-input",
                attrs: {
                  filterable: true,
                  "query-option": "FindAll",
                  placeholder: "版次"
                },
                model: {
                  value: _vm.valueProxy.periodCode,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "periodCode", $$v)
                  },
                  expression: "valueProxy.periodCode"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "地区信息" } },
            [
              _c("select-param-sync-info", {
                staticClass: "sync-all-input",
                attrs: {
                  filterable: true,
                  "query-option": "FindArea",
                  placeholder: "地区"
                },
                model: {
                  value: _vm.valueProxy.areaCode,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "areaCode", $$v)
                  },
                  expression: "valueProxy.areaCode"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "创建时间" } },
            [
              _c("el-date-picker", {
                staticClass: "form-date",
                attrs: {
                  "picker-options": _vm.pickerOptions,
                  "default-time": ["00:00:00", "23:59:59"],
                  type: "datetimerange",
                  align: "left",
                  "unlink-panels": "",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "value-format": "yyyy-MM-dd HH:mm:ss"
                },
                model: {
                  value: _vm.startDate,
                  callback: function($$v) {
                    _vm.startDate = $$v
                  },
                  expression: "startDate"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "上架时间" } },
            [
              _c("el-date-picker", {
                staticClass: "form-date",
                attrs: {
                  "picker-options": _vm.pickerOptions,
                  "default-time": ["00:00:00", "23:59:59"],
                  type: "datetimerange",
                  align: "left",
                  "unlink-panels": "",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "value-format": "yyyy-MM-dd HH:mm:ss"
                },
                model: {
                  value: _vm.endDate,
                  callback: function($$v) {
                    _vm.endDate = $$v
                  },
                  expression: "endDate"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "教师可购" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.valueProxy.teacherBuy,
                    callback: function($$v) {
                      _vm.$set(_vm.valueProxy, "teacherBuy", $$v)
                    },
                    expression: "valueProxy.teacherBuy"
                  }
                },
                [
                  _c("el-radio-button", { attrs: { label: "0" } }, [
                    _vm._v("全部")
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "1" } }, [
                    _vm._v("仅教师可购")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "排序条件" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "sync-all-input",
                  attrs: { clearable: "", placeholder: "请选择" },
                  model: {
                    value: _vm.valueProxy.sortBy,
                    callback: function($$v) {
                      _vm.$set(_vm.valueProxy, "sortBy", $$v)
                    },
                    expression: "valueProxy.sortBy"
                  }
                },
                _vm._l(_vm.orderOptions, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "排序方式" } },
            [
              _c(
                "el-radio-group",
                {
                  staticClass: "common-radio-btn-width",
                  model: {
                    value: _vm.valueProxy.sortOrder,
                    callback: function($$v) {
                      _vm.$set(_vm.valueProxy, "sortOrder", $$v)
                    },
                    expression: "valueProxy.sortOrder"
                  }
                },
                [
                  _c("el-radio-button", { attrs: { label: "asc" } }, [
                    _vm._v("正序")
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "desc" } }, [
                    _vm._v("倒序")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "rule-result" }, [
        _c("div", { staticClass: "rule-result-title" }, [_vm._v("规则内容")]),
        _vm._v(" "),
        _c("div", { staticClass: "result flex-column" }, [
          _c("div", { staticClass: "margin-top-ten" }, [
            _c("span", { staticClass: "common-result-title" }, [
              _vm._v("关键词")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "common-ten-left" }, [
              _vm._v(_vm._s(_vm._f("beautifulText")(_vm.valueProxy.keyword)))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "margin-top-ten flex-row" }, [
            _c("div", { staticClass: "common-result-title" }, [
              _vm._v("选择分类")
            ]),
            _vm._v(" "),
            _vm.className.length > 0
              ? _c(
                  "div",
                  { staticClass: "class-list" },
                  _vm._l(_vm.className, function(item, index) {
                    return _c(
                      "span",
                      { key: index, staticClass: "common-ten-left" },
                      [_vm._v(_vm._s(item))]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _c("span", { staticClass: "common-ten-left" }, [_vm._v("--")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "margin-top-ten" }, [
            _c("span", { staticClass: "common-result-title" }, [
              _vm._v("商品类型")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "common-ten-left" }, [
              _vm._v(_vm._s(_vm._f("beautifulText")(_vm.typeName)))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "margin-top-ten" }, [
            _c("span", { staticClass: "common-result-title" }, [
              _vm._v("商品品牌")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "common-ten-left" }, [
              _vm._v(_vm._s(_vm._f("beautifulText")(_vm.brandName)))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "margin-top-ten" }, [
            _c("span", { staticClass: "common-result-title" }, [
              _vm._v("商品系列")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "common-ten-left" }, [
              _vm._v(_vm._s(_vm._f("beautifulText")(_vm.seriesName)))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "margin-top-ten" }, [
            _c("span", { staticClass: "common-result-title" }, [
              _vm._v("同步信息")
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "sync-info flex-row align-center margin-top-ten" },
              [
                _c("div", { staticClass: "sync-info-width" }, [
                  _c("span", { staticClass: "sync-text common-ten-right" }, [
                    _vm._v("学段")
                  ]),
                  _vm._v(_vm._s(_vm._f("beautifulText")(_vm.section)))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "sync-info-width" }, [
                  _c("span", { staticClass: "sync-text common-ten-right" }, [
                    _vm._v("学科")
                  ]),
                  _vm._v(_vm._s(_vm._f("beautifulText")(_vm.subject)))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "sync-info-width" }, [
                  _c("span", { staticClass: "sync-text common-ten-right" }, [
                    _vm._v("版本")
                  ]),
                  _vm._v(_vm._s(_vm._f("beautifulText")(_vm.edition)))
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "sync-info flex-row align-center margin-top-ten" },
              [
                _c("div", { staticClass: "sync-info-width" }, [
                  _c("span", { staticClass: "sync-text common-ten-right" }, [
                    _vm._v("年级")
                  ]),
                  _vm._v(_vm._s(_vm._f("beautifulText")(_vm.grade)))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "sync-info-width" }, [
                  _c("span", { staticClass: "sync-text common-ten-right" }, [
                    _vm._v("阶段")
                  ]),
                  _vm._v(_vm._s(_vm._f("beautifulText")(_vm.volume)))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "sync-info-width" }, [
                  _c("span", { staticClass: "sync-text common-ten-right" }, [
                    _vm._v("必修")
                  ]),
                  _vm._v(_vm._s(_vm._f("beautifulText")(_vm.elective)))
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "margin-top-ten" }, [
            _c("span", { staticClass: "common-result-title" }, [
              _vm._v("版次信息")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "common-ten-left" }, [
              _vm._v(_vm._s(_vm._f("beautifulText")(_vm.period)))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "margin-top-ten" }, [
            _c("span", { staticClass: "common-result-title" }, [
              _vm._v("地区信息")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "common-ten-left" }, [
              _vm._v(_vm._s(_vm._f("beautifulText")(_vm.area)))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "margin-top-ten" }, [
            _c("span", { staticClass: "common-result-title" }, [
              _vm._v("创建时间")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "common-ten-left" }, [
              _vm._v(
                _vm._s(
                  _vm._f("beautifulText")(_vm.valueProxy.createStartTime)
                ) +
                  " 至 " +
                  _vm._s(_vm._f("beautifulText")(_vm.valueProxy.createdEndTime))
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "margin-top-ten" }, [
            _c("span", { staticClass: "common-result-title" }, [
              _vm._v("发布时间")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "common-ten-left" }, [
              _vm._v(
                _vm._s(
                  _vm._f("beautifulText")(_vm.valueProxy.publishStartTime)
                ) +
                  " 至 " +
                  _vm._s(_vm._f("beautifulText")(_vm.valueProxy.publishEndTime))
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "margin-top-ten" }, [
            _c("span", { staticClass: "common-result-title" }, [
              _vm._v("教师可购")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "common-ten-left" }, [
              _vm._v(
                _vm._s(_vm.valueProxy.teacherBuy == 1 ? "仅教师可购" : "全部")
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "margin-top-ten" }, [
            _c("span", { staticClass: "common-result-title" }, [
              _vm._v("排序条件")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "common-ten-left" }, [
              _vm._v(_vm._s(_vm._f("beautifulText")(_vm.orderResultBy)))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "margin-top-ten" }, [
            _c("span", { staticClass: "common-result-title" }, [
              _vm._v("排序方式")
            ]),
            _vm._v(" "),
            _vm.valueProxy.orderMode === "asc"
              ? _c("span", { staticClass: "common-ten-left" }, [_vm._v("正序")])
              : _vm._e(),
            _vm._v(" "),
            _vm.valueProxy.orderMode === "desc"
              ? _c("span", { staticClass: "common-ten-left" }, [_vm._v("倒序")])
              : _vm._e()
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }