"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _common = require("@/utils/common");

var _resource = require("@/utils/resource");

var _const = require("../../../utils/const");

var _oss = require("../../../utils/oss");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'ResourceTitle',
  props: {
    resourceObj: {
      type: Object,
      default: null
    },
    // 直播对象
    showPage: {
      type: Function,
      default: function _default() {
        return {};
      }
    },
    isEdit: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      showVal: _common.showVal,
      imageUrlFun: _common.imageUrlFun,
      getResourceInfo: _resource.getResourceInfo,
      FILE_ID: _const.FILE_ID,
      ALBUM_ID: _const.ALBUM_ID,
      AUDIO_ID: _const.AUDIO_ID,
      LIVE_ID: _const.LIVE_ID,
      // 直播对应的ID
      ossUrl: _oss.ossUrl,
      liveStateList: [{
        label: '预告',
        value: 1,
        className: 'live-state-herald'
      }, {
        label: '即将开始',
        value: 2,
        className: 'live-state-herald'
      }, {
        label: '直播中',
        value: 3,
        className: 'live-ing'
      }, {
        label: '已结束',
        value: 4,
        className: 'live-end'
      }, {
        label: '回放',
        value: 5,
        className: 'live-replay'
      }],
      defaultCover: 'https://open.oss.taozhi.online/production/assets/mp/resource/select-resource/book_cover.png?x-oss-process=style%2Fthumbnail'
    };
  },
  methods: {
    getResourceCover: function getResourceCover() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          cover = _ref.cover,
          resourceType = _ref.resourceType;

      // 获取封面图片
      if ([5, 6].includes(resourceType)) {
        var name = resourceType === 5 ? 'jinxinbiCover' : 'vipCover';
        return "https://open.oss.taozhi.online/develop/mp/orderCover/".concat(name, ".png?x-oss-process=style%2Fthumbnail");
      }

      return this.ossUrl(cover, this.defaultCover, 'style/thumbnail');
    },

    /**
     * 显示标题
     */
    displayTitle: function displayTitle(data) {
      if (data.resourceTitle && data.resourceTitle.trim().length > 0) {
        return data.resourceTitle ? data.resourceTitle : '--';
      }

      return data.title ? data.title : '--';
    },
    // 判断直播状态
    getLiveObj: function getLiveObj(liveObj) {
      var liveStateObj = this.liveStateList.filter(function (live) {
        return live.value === liveObj.liveStateNum;
      });
      return liveStateObj ? liveStateObj[0] : {};
    },
    resourceImgName: function resourceImgName(resourceType) {
      var imgClass = '';

      if (resourceType === 2 || resourceType === 8 || resourceType === 10 || resourceType === 15) {
        imgClass = 'resource-img2-bg';
      } else if (resourceType === 3) {
        imgClass = 'resource-img3-bg';
      } else {
        imgClass = 'resource-img-bg';
      }

      return imgClass;
    }
  }
};
exports.default = _default2;