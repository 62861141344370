"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.function.name");

var _WordCheckList = _interopRequireDefault(require("../Word/WordCheckList"));

var _MoreBottom = _interopRequireDefault(require("../Word/MoreBottom"));

var _microWord = require("../../../utils/microWord");

var _common = require("../../../utils/common");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'PolyphonicCharacter',
  components: {
    WordCheckList: _WordCheckList.default
  },
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    options: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      activeName: undefined,
      isMore: undefined
    };
  },
  computed: {
    widthStyle: function widthStyle() {
      var val = this.value.pinyinList.length;

      switch (val) {
        case 1:
          return '100%';

        case 2:
          return '50%';

        case 3:
          return '33%';

        case 4:
          return '25%';

        case 5:
          return '20%';

        default:
          return 'auto';
      }
    },
    comCheckList: function comCheckList() {
      return (0, _microWord.filterCheckList)(this.value);
    },
    newImgSrc: function newImgSrc() {
      if (!this.value.name) {
        return undefined;
      }

      var data = escape(this.value.name).replace(/\%u/g, '');

      if (!data) {
        return undefined;
      }

      return "".concat(process.env.VUE_APP_IMG_URL_PREFIX, "production/assets/characterKaiTi/").concat(data, ".svg");
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler: function handler(val) {
        if (val.pinyinList.length > 0) {
          this.activeName = val.pinyinList[0].id; // if (this.options.style !== 'nav') { return; }
          // val.pinyinList.forEach(((item) => {
          //   item.showCheck = this.getCheck(item.checkList);
          // }));
        }
      }
    }
  },
  methods: {
    handleMore: function handleMore() {
      this.isMore = this.isMore === 'up' ? 'down' : 'up';
    },
    getPriCheckList: function getPriCheckList(list) {
      var result = JSON.parse(JSON.stringify(list));
      result = result.filter(function (item) {
        return item.check;
      });
      return result;
    },
    getCheck: function getCheck(list) {
      var _this = this;

      if (list.length <= 0) {
        return false;
      }

      var data = list.some(function (item) {
        if (!item.check) {
          return undefined;
        }

        var result = item.content;

        if (item.label === 'chineseCharBishun') {
          result = _this.value.chineseCharBishun.bishunJson;
        }

        return result;
      });
      return data;
    },
    bgCover: function bgCover(val) {
      var list = ['blue-bg', 'pink-bg', 'cyan-bg', 'green-bg', 'orange-bg'];
      return list[val % 5];
    }
  }
};
exports.default = _default2;