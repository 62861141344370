import { render, staticRenderFns } from "./ResourceType.vue?vue&type=template&id=64c923a5&"
import script from "./ResourceType.vue?vue&type=script&lang=js&"
export * from "./ResourceType.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/front-end_micro-page_oo0i/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('64c923a5')) {
      api.createRecord('64c923a5', component.options)
    } else {
      api.reload('64c923a5', component.options)
    }
    module.hot.accept("./ResourceType.vue?vue&type=template&id=64c923a5&", function () {
      api.rerender('64c923a5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/MicroPage/ResourceContentItem/ResourceType.vue"
export default component.exports