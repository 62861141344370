"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.appContentComponents = exports.appHomeNavComponents = exports.appBasicComponents = exports.appHomeComponents = void 0;
var appHomeComponents = [{
  type: 'app_carousel',
  title: '轮播图',
  id: 1,
  activeIcon: 'material/imageList/e7c45c0156eca98e0c0e87da9dda0af8.png',
  defaultIcon: 'material/cover/5af30589261e514e25c5c3e20a164fdf.png',
  isActive: false,
  options: {
    style: 'carousel',
    // carousel：轮播; slide：左右滑动
    appearance: 'open',
    imageHeight: 130,
    // 图片高度
    spacingTop: false,
    //  up：上间距 down：下间距
    spacingBottom: false,
    //  up：上间距 down：下间距
    immerse: false,
    // 是否为沉浸式
    color: {
      background: '#ffffff',
      font: '#000000'
    }
  },
  content: {
    size: 2,
    parts: [{
      image: '',
      id: 1
    }, {
      image: '',
      id: 2
    }]
  }
}, {
  type: 'app_image_navigate',
  title: '图标导航',
  id: 2,
  activeIcon: 'material/imageList/50f246bb4ad2c77ec7ce752a84104b6a.png',
  defaultIcon: 'material/cover/bc10b826e7e5bfae915fe349e3bb323d.png',
  isActive: false,
  options: {
    appearance: 'open',
    shape: 'round',
    // round：圆; square：方
    spacingTop: false,
    //  up：上间距 down：下间距
    spacingBottom: false,
    //  up：上间距 down：下间距
    color: {
      background: '#ffffff',
      font: '#000000'
    }
  },
  content: {
    // 内容设置
    size: 5,
    parts: [{
      image: '',
      title: '导航1'
    }, {
      image: '',
      title: '导航2'
    }, {
      image: '',
      title: '导航3'
    }, {
      image: '',
      title: '导航1'
    }, {
      image: '',
      title: '导航2'
    }, {
      image: '',
      title: '导航3'
    }, {
      image: '',
      title: '导航1'
    }, {
      image: '',
      title: '导航2'
    }, {
      image: '',
      title: '导航3'
    }, {
      image: '',
      title: '全部'
    }]
  }
}, {
  type: 'app_audio_book',
  title: '点学书',
  id: 3,
  activeIcon: 'material/imageList/50f246bb4ad2c77ec7ce752a84104b6a.png',
  defaultIcon: 'material/cover/bc10b826e7e5bfae915fe349e3bb323d.png',
  options: {
    appearance: 'open',
    // open：展开; card：卡片
    style: 'line',
    // picture：书籍展示1 pictureTwo: 书籍展示2 line:图文列表 slide 滚动
    spacingTop: false,
    //  up：上间距 down：下间距
    spacingBottom: false,
    //  up：上间距 down：下间距
    color: {
      background: '#ffffff',
      font: '#000000'
    },
    resourceColor: '#999999',
    priceColor: '#F85862',
    showPrice: true,
    showBgImg: false,
    titleColor: '#101010',
    namePosition: '0',
    // '0' 图片下方 '1' 图片内下方
    rowNum: 2,
    showResourceTitle: true,
    showAllTitle: false
  },
  titleData: {
    style: 'custom',
    // default：默认 custom：自定义 none：无
    title: '点学书',
    more: '更多',
    isShowMore: true,
    cover: ''
  },
  setTitle: true,
  content: {
    // 内容设置
    size: 5,
    parts: []
  }
}, {
  type: 'app_home_book_shelf',
  title: '首页书架',
  id: 19,
  activeIcon: 'material/imageList/50f246bb4ad2c77ec7ce752a84104b6a.png',
  defaultIcon: 'material/cover/bc10b826e7e5bfae915fe349e3bb323d.png',
  isActive: false,
  options: {
    appearance: 'open',
    // open：展开; card：卡片
    style: 'line',
    // picture：书籍展示1 pictureTwo: 书籍展示2 line:图文列表
    spacingTop: false,
    //  up：上间距 down：下间距
    spacingBottom: false,
    //  up：上间距 down：下间距
    color: {
      background: '#ffffff',
      font: '#000000'
    },
    resourceColor: '#999999',
    priceColor: '#F85862',
    showPrice: false,
    showBgImg: false,
    titleColor: '#101010',
    namePosition: '0',
    // '0' 图片下方 '1' 图片内下方
    rowNum: 2,
    showResourceTitle: true,
    showAllTitle: false
  },
  titleData: {
    style: 'default',
    // default：默认 custom：自定义 none：无
    title: '我的书架',
    more: '更多',
    isShowMore: false,
    cover: ''
  },
  setTitle: true,
  content: {
    size: '3',
    parts: []
  }
}];
exports.appHomeComponents = appHomeComponents;
var appBasicComponents = [{
  type: 'micro_title',
  id: 4,
  title: '标题',
  activeIcon: 'material/imageList/d72b7bfb70b94a5355cd2be3b0469139.png',
  defaultIcon: 'material/imageList/01df719d17be6a2ffa19cee9b03be3ac.png',
  isActive: false,
  options: {
    appearance: 'open',
    // open：展开; card：卡片
    spacingTop: false,
    //  up：上间距 down：下间距
    spacingBottom: false,
    //  up：上间距 down：下间距
    color: {
      background: '#ffffff',
      mainFont: '#000000',
      subFont: '#999999'
    },
    backgroundImage: 'assets/taozhi/app/static/micro_title_bg.png',
    showBgImg: false
  },
  content: {
    align: 'center',
    mainTitle: '主标题标题名称',
    subTitle: '副标题名称',
    mainSize: 14,
    subSize: 12,
    mainWeight: 400,
    subWeight: 400
  }
}, {
  type: 'rich_text',
  title: '富文本',
  id: 5,
  activeIcon: 'material/imageList/40769ec196cf23483f8a05b7ec45cea0.png',
  defaultIcon: 'material/cover/8aff04243eccc1077f0dd91b8361e1e4.png',
  isActive: false,
  options: {
    appearance: 'open',
    // open：展开; card：卡片
    innerMargin: 'margin',
    // fill margin
    spacingTop: false,
    //  up：上间距 down：下间距
    spacingBottom: false,
    //  up：上间距 down：下间距
    color: {
      background: '#ffffff',
      font: '#000000'
    }
  },
  content: {
    title: '标题名称',
    // 标题
    text: '',
    isShowTitle: true
  }
}, {
  type: 'image_advert',
  title: '图片',
  id: 6,
  activeIcon: 'material/imageList/f8676aa6a33d1169d1d24cc1937b86a6.png',
  defaultIcon: 'material/cover/f39c6e131629beebf64c2c1d2a47a22b.png',
  isActive: false,
  options: {
    appearance: 'open',
    // open：展开; card：卡片
    imageHeight: 200,
    // 高度
    innerMargin: 'margin',
    // fill margin
    spacingTop: false,
    //  up：上间距 down：下间距
    spacingBottom: false,
    //  up：上间距 down：下间距
    color: {
      background: '#ffffff',
      font: '#000000'
    }
  },
  content: {
    size: 1,
    // -1 轮播  1-3正常
    parts: [{
      image: '',
      id: 1
    }]
  }
}, {
  type: 'micro_video',
  title: '视频',
  id: 7,
  activeIcon: 'material/cover/5c83cea7e3eb7ade4bae946e295e1774.png',
  defaultIcon: 'material/cover/d3283e26cd12641890ba92b981a4fdf5.png',
  isActive: false,
  options: {
    appearance: 'open',
    // open：展开; card：卡片
    style: 'line',
    // line:音频列表 column：纵向列表
    spacingTop: false,
    //  up：上间距 down：下间距
    spacingBottom: false,
    //  up：上间距 down：下间距
    color: {
      background: '#ffffff',
      font: '#000000'
    },
    autoPlay: false,
    isScreenFull: false,
    // 允许全屏
    screenFull: undefined,
    // 全屏 默认 undefined 横屏 -90 竖屏 0
    screenWidth: 16,
    screenHeight: 9,
    showTitle: true // 显示作品标题

  },
  titleData: {
    style: 'custom',
    // default：默认 custom：自定义 none：无
    title: '视频',
    more: '更多',
    isShowMore: true,
    cover: ''
  },
  setTitle: true,
  content: {
    size: '3',
    parts: []
  }
}, {
  type: 'micro_audio',
  title: '音频',
  id: 8,
  activeIcon: 'material/cover/7861f8ef06ac3ddfa6c8f62e8961933e.png',
  defaultIcon: 'material/cover/356dae283966dd8f6eab7ee9c818c9f0.png',
  isActive: false,
  options: {
    appearance: 'open',
    // open：展开; card：卡片
    style: 'line',
    // line:音频列表 column：纵向列表
    spacingTop: false,
    //  up：上间距 down：下间距
    spacingBottom: false,
    //  up：上间距 down：下间距
    color: {
      background: '#ffffff',
      font: '#000000'
    },
    autoPlay: false,
    isScreenFull: false,
    // 允许全屏
    screenFull: undefined,
    // 全屏 默认 undefined 横屏 -90 竖屏 0
    screenWidth: 16,
    screenHeight: 9,
    showTitle: true // 显示作品标题

  },
  titleData: {
    style: 'custom',
    // default：默认 custom：自定义 none：无
    title: '音频',
    more: '更多',
    isShowMore: true,
    cover: ''
  },
  setTitle: true,
  content: {
    size: '3',
    parts: []
  }
}, {
  type: 'carousel',
  title: '幻灯片',
  id: 9,
  activeIcon: 'material/imageList/e7c45c0156eca98e0c0e87da9dda0af8.png',
  defaultIcon: 'material/cover/5af30589261e514e25c5c3e20a164fdf.png',
  isActive: false,
  options: {
    style: 'carousel',
    // carousel：轮播; slide：左右滑动
    appearance: 'open',
    imageHeight: 150,
    // 图片高度
    spacingTop: false,
    //  up：上间距 down：下间距
    spacingBottom: false,
    //  up：上间距 down：下间距
    immerse: false,
    // 是否为沉浸式
    color: {
      background: '#ffffff',
      font: '#000000'
    }
  },
  content: {
    size: 2,
    parts: [{
      image: '',
      id: 1
    }, {
      image: '',
      id: 2
    }]
  }
}];
exports.appBasicComponents = appBasicComponents;
var appHomeNavComponents = [{
  type: 'image_navigate',
  title: '图标导航',
  id: 10,
  activeIcon: 'material/imageList/50f246bb4ad2c77ec7ce752a84104b6a.png',
  defaultIcon: 'material/cover/bc10b826e7e5bfae915fe349e3bb323d.png',
  isActive: false,
  options: {
    appearance: 'open',
    shape: 'round',
    // round：圆; square：方
    spacingTop: false,
    //  up：上间距 down：下间距
    spacingBottom: false,
    //  up：上间距 down：下间距
    color: {
      background: '#ffffff',
      font: '#000000'
    }
  },
  content: {
    // 内容设置
    size: 3,
    parts: [{
      title: '导航1',
      image: '',
      id: 1
    }, {
      title: '导航2',
      image: '',
      id: 2
    }, {
      title: '导航3',
      image: '',
      id: 3
    }]
  }
}, {
  type: 'picture_navigate',
  title: '图片导航',
  id: 11,
  activeIcon: 'material/cover/903c5aaa92928b7b80ef54c0a134bba2.png',
  defaultIcon: 'material/cover/5c9cccfc5c056a6a66f003f6863a2c48.png',
  isActive: false,
  options: {
    appearance: 'open',
    style: 'outsideBottom',
    // outsideBottom 图片下方 innerBottom 图片内下方 innerCenter 图片内居中
    spacingTop: false,
    //  up：上间距 down：下间距
    spacingBottom: false,
    //  up：上间距 down：下间距
    color: {
      background: '#ffffff',
      font: '#000000'
    }
  },
  content: {
    // 内容设置
    size: 3,
    // 单行个数
    parts: [{
      title: '导航1',
      image: '',
      id: 1
    }, {
      title: '导航2',
      image: '',
      id: 2
    }, {
      title: '导航3',
      image: '',
      id: 3
    }]
  }
}, {
  type: 'types_navigate',
  title: '分类导航',
  id: 12,
  activeIcon: 'material/imageList/7f5ca0a3c253b4721edcb846fbabe8ec.png',
  defaultIcon: 'material/cover/0117bd32eb0e255c89f0aa30ce907f31.png',
  isActive: false,
  options: {
    // 基础设置
    appearance: 'open',
    // open：展开; card：卡片
    style: 'line',
    // smallPicture：小图显示 line:图文列表
    spacingTop: false,
    //  up：上间距 down：下间距
    spacingBottom: false,
    //  up：上间距 down：下间距
    color: {
      activityFont: '#409EFF',
      underline: '#409EFF',
      background: '#ffffff',
      font: '#000000'
    }
  },
  content: [{
    title: '分类1',
    id: 1,
    parts: []
  }, {
    title: '分类2',
    id: 2,
    parts: []
  }]
}];
exports.appHomeNavComponents = appHomeNavComponents;
var appContentComponents = [{
  type: 'knowledge_goods',
  title: '知识商品',
  id: 13,
  activeIcon: 'material/imageList/88323d66b9210dbac3d7b2333f47490f.png',
  defaultIcon: 'material/cover/ec08b1283c0c0fdf0667ac0b5ce4c9f4.png',
  isActive: false,
  options: {
    appearance: 'open',
    // open：展开; card：卡片
    style: 'line',
    // smallPicture：小图显示 line:图文列表 slide：左右滚动
    spacingTop: false,
    //  up：上间距 down：下间距
    spacingBottom: false,
    //  up：上间距 down：下间距
    color: {
      background: '#ffffff',
      font: '#000000'
    }
  },
  titleData: {
    style: 'default',
    // default：默认 custom：自定义 none：无
    title: '知识商品',
    more: '更多',
    isShowMore: true,
    cover: ''
  },
  setTitle: true,
  content: {
    rule: 'custom',
    // default：默认 custom：自定义
    size: '2',
    // 数量
    recommendRule: false,
    // 推荐
    selectRecommend: false,
    // 是选择的 还是默认的
    recommend: undefined,
    // {title: '知识商品名称知识商品名称知识商知识商品名称知', resourceType: 1, price: 0,}
    parts: [],
    // 作品选择
    partsOptions: {} // 条件选择

  }
}, {
  type: 'book_shelf',
  title: '书架',
  id: 14,
  activeIcon: 'material/imageList/50f246bb4ad2c77ec7ce752a84104b6a.png',
  defaultIcon: 'material/cover/bc10b826e7e5bfae915fe349e3bb323d.png',
  isActive: false,
  options: {
    appearance: 'open',
    // open：展开; card：卡片
    style: 'line',
    // picture：书籍展示1 pictureTwo: 书籍展示2 line:图文列表
    spacingTop: false,
    //  up：上间距 down：下间距
    spacingBottom: false,
    //  up：上间距 down：下间距
    color: {
      background: '#ffffff',
      font: '#000000'
    },
    resourceColor: '#999999',
    priceColor: '#F85862',
    showPrice: false,
    showBgImg: false,
    titleColor: '#101010',
    namePosition: '0',
    // '0' 图片下方 '1' 图片内下方
    rowNum: 2,
    showResourceTitle: true,
    showAllTitle: false
  },
  titleData: {
    style: 'default',
    // default：默认 custom：自定义 none：无
    title: '书架',
    more: '更多',
    isShowMore: true,
    cover: ''
  },
  setTitle: true,
  content: {
    size: '3',
    parts: []
  }
}, {
  type: 'ranking_list',
  title: '排行榜',
  id: 15,
  activeIcon: 'material/imageList/88323d66b9210dbac3d7b2333f47490f.png',
  defaultIcon: 'material/cover/ec08b1283c0c0fdf0667ac0b5ce4c9f4.png',
  isActive: false,
  options: {
    appearance: 'open',
    // open：展开; card：卡片
    style: 'line',
    // smallPicture：小图显示 line:图文列表 slide：左右滚动
    spacingTop: false,
    //  up：上间距 down：下间距
    spacingBottom: false,
    //  up：上间距 down：下间距
    color: {
      background: '#ffffff',
      font: '#000000'
    }
  },
  titleData: {
    style: 'default',
    // default：默认 custom：自定义 none：无
    title: '排行榜',
    more: '更多',
    isShowMore: true,
    cover: ''
  },
  setTitle: true,
  content: {
    rule: 'custom',
    // default：默认 custom：自定义
    size: '2',
    // 数量
    recommendRule: false,
    // 推荐
    selectRecommend: false,
    // 是选择的 还是默认的
    recommend: undefined,
    // {title: '知识商品名称知识商品名称知识商知识商品名称知', resourceType: 1, price: 0,}
    parts: [],
    // 作品选择
    partsOptions: {} // 条件选择

  }
}];
exports.appContentComponents = appContentComponents;