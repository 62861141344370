var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.recommendRule
    ? _c(
        "div",
        {
          staticClass: "flex-column knowledge-recommend",
          class: [_vm.partsLength > 0 ? "bottom-border" : ""]
        },
        [
          _c(
            "div",
            {
              staticClass: "recommend",
              style: { background: _vm.isSpecial ? "#EFEFEF" : "" }
            },
            [
              _vm.recommend
                ? _c("el-image", {
                    class: [_vm.coverClass],
                    attrs: {
                      fit: "cover",
                      src: _vm.getImgUrl(
                        _vm.recommend.cover,
                        "style/width-limit-512"
                      )
                    }
                  })
                : _c(
                    "div",
                    {
                      staticClass: "common-flex-center error-background",
                      staticStyle: {
                        width: "100%",
                        height: "194px",
                        "border-radius": "4px"
                      }
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "80px", height: "26px" },
                        attrs: {
                          src: require("@/assets/images/taozhiLogo.png")
                        }
                      })
                    ]
                  ),
              _vm._v(" "),
              _vm.element.type === "knowledge_goods" && _vm.recommend
                ? _c("div", { staticClass: "recommend-type" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.recommendType(
                            _vm.recommend ? _vm.recommend.resourceType : 1
                          )
                        ) + "课程"
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm.recommend.price &&
                      _vm.recommend.price !== 0 &&
                      !_vm.recommend.price.vipOnly
                        ? _c("div", [
                            _vm.recommend.discount > 0 &&
                            _vm.recommend.discount < 1
                              ? _c("span", [
                                  _c("span", { staticClass: "price" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.priceShowByCent(
                                          _vm.recommend.price *
                                            _vm.item.content.recommend.discount
                                        )
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "discount-original-price" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.priceShowByCent(
                                            _vm.recommend.price.price
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ])
                              : _c("text", { staticClass: "price" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.priceShowByCent(_vm.recommend.price)
                                    )
                                  )
                                ])
                          ])
                        : _c("span", { staticClass: "price" }, [_vm._v("免费")])
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.element.type === "ranking_list"
                ? _c("div", { class: ["rankingTag", "rankingTag1"] })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("span", { staticClass: "live-title hidden-one-line" }, [
            _vm._v(_vm._s(_vm.recommend ? _vm.recommend.title : "知识商品"))
          ]),
          _vm._v(" "),
          _vm.element.type === "ranking_list" && _vm.recommend
            ? _c("ranking-info", {
                staticStyle: { "margin-top": "5px" },
                attrs: {
                  content: _vm.element.content,
                  value: _vm.recommend,
                  "price-options": Object.assign({}, _vm.element.options, {
                    priceColor: "#F85862"
                  })
                }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }