var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "polyphonic-item" },
    [
      _vm.newImgSrc
        ? _c(
            "div",
            { staticClass: "polyphonic-name common-flex-center" },
            [
              _c("el-image", {
                staticClass: "name-cover",
                attrs: { src: _vm.newImgSrc }
              })
            ],
            1
          )
        : _c("div", { staticClass: "polyphonic-name common-flex-center" }, [
            _vm._v(_vm._s(_vm.value.name))
          ]),
      _vm._v(" "),
      _vm.options.style === "line"
        ? _c(
            "div",
            { staticClass: "line" },
            [
              _vm._l(_vm.value.pinyinList, function(item, index) {
                return _c(
                  "div",
                  { key: item.id },
                  [
                    _c("div", { class: ["pinyin", _vm.bgCover(index)] }, [
                      _vm._v(_vm._s(item.pinyin))
                    ]),
                    _vm._v(" "),
                    _vm._l(item.checkList, function(n, nIndex) {
                      return _c(
                        "div",
                        { key: nIndex },
                        [
                          n.check && n.content
                            ? _c("word-check-list", {
                                attrs: { value: _vm.value, item: n }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              }),
              _vm._v(" "),
              _vm.comCheckList.length > 0
                ? _c("div", { staticClass: "interval" })
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.options.style === "nav"
        ? _c(
            "div",
            { staticClass: "polyphonic-slider" },
            _vm._l(_vm.value.pinyinList, function(item, index) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "tab-item",
                  style: { width: _vm.widthStyle }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "slider-pinyin",
                      style: {
                        "border-bottom":
                          _vm.activeName === item.id
                            ? "2px solid #00A2FF"
                            : "2px solid transparent"
                      },
                      on: {
                        click: function($event) {
                          _vm.activeName = item.id
                        }
                      }
                    },
                    [_vm._v("\n        " + _vm._s(item.pinyin) + "\n      ")]
                  )
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.options.style === "nav"
        ? _c(
            "div",
            { staticClass: "navigate" },
            _vm._l(_vm.value.pinyinList, function(item) {
              return _c("div", { key: item.id }, [
                _vm.activeName === item.id
                  ? _c(
                      "div",
                      [
                        _vm._l(item.checkList, function(n, nIndex) {
                          return _c(
                            "div",
                            { key: nIndex },
                            [
                              n.check && n.content
                                ? _c("word-check-list", {
                                    attrs: { value: _vm.value, item: n }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        }),
                        _vm._v(" "),
                        _vm.comCheckList.length > 0
                          ? _c("div", { staticClass: "interval" })
                          : _vm._e()
                      ],
                      2
                    )
                  : _vm._e()
              ])
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.comCheckList, function(item, index) {
        return _c(
          "div",
          { key: index },
          [
            _c("word-check-list", {
              attrs: {
                value: _vm.value,
                item: item,
                options: _vm.options,
                "cur-index": index,
                "is-more": _vm.isMore,
                "last-length": _vm.comCheckList.length
              },
              on: { handleMore: _vm.handleMore }
            })
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }