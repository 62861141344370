var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "word-custom-dialog",
      attrs: {
        title: "选择内容",
        visible: _vm.visible,
        "append-to-body": "",
        "close-on-click-modal": false,
        width: "700px",
        "before-close": _vm.cancel
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("div", { staticClass: "word-custom-form" }, [
        _c(
          "div",
          { staticClass: "flex-row common-form" },
          [
            _c("div", { staticClass: "common-label" }, [_vm._v("名称")]),
            _vm._v(" "),
            _c("el-input", {
              staticClass: "form-input",
              attrs: {
                clearable: "",
                maxlength: "4",
                "show-word-limit": "",
                placeholder: "请输入名称"
              },
              model: {
                value: _vm.ruleForm.name,
                callback: function($$v) {
                  _vm.$set(_vm.ruleForm, "name", $$v)
                },
                expression: "ruleForm.name"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex-row" },
          [
            _c("div", { staticClass: "common-label" }, [_vm._v("内容")]),
            _vm._v(" "),
            _c("editor", {
              ref: "editor",
              attrs: {
                config: { initialFrameWidth: 570, zIndex: 3000 },
                "is-init-content": true
              },
              model: {
                value: _vm.ruleForm.content,
                callback: function($$v) {
                  _vm.$set(_vm.ruleForm, "content", $$v)
                },
                expression: "ruleForm.content"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.cancel } },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.submit }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }