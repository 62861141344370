var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "micro-page-view" },
    [
      _c("div", { staticClass: "micro-page-content" }, [
        _c(
          "div",
          { staticClass: "edit-left" },
          [
            _c(
              "el-menu",
              {
                staticClass: "el-menu-vertical-demo",
                attrs: {
                  "active-text-color": "#ffd04b",
                  "default-openeds": _vm.openeds
                }
              },
              [
                _vm.isAppHome
                  ? _c(
                      "el-submenu",
                      { attrs: { index: "5" } },
                      [
                        _c("template", { slot: "title" }, [_vm._v("首页组件")]),
                        _vm._v(" "),
                        _c(
                          "draggable",
                          {
                            staticClass: "group-class",
                            attrs: {
                              list: _vm.appHomeComponents,
                              group: {
                                name: "components",
                                pull: "clone",
                                put: false
                              },
                              sort: false,
                              filter: ".not-draggable"
                            },
                            on: { choose: _vm.chooseComp }
                          },
                          _vm._l(_vm.appHomeComponents, function(item) {
                            return _c(
                              "div",
                              { key: item.id, attrs: { id: item.id } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "group-class-div",
                                    class: _vm.noMicroPageId
                                      ? "not-draggable"
                                      : "component-item",
                                    on: {
                                      mouseenter: function($event) {
                                        return _vm.handleEnter(
                                          "basicComponents",
                                          item.id
                                        )
                                      },
                                      mouseleave: function($event) {
                                        return _vm.handleLeave(
                                          "basicComponents",
                                          item.id
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("el-image", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            item.isActive && !_vm.noMicroPageId,
                                          expression:
                                            "item.isActive && !noMicroPageId"
                                        }
                                      ],
                                      staticClass: "competent-default-icon",
                                      attrs: {
                                        src: _vm.getImgUrl(item.activeIcon)
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("el-image", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            !item.isActive || _vm.noMicroPageId,
                                          expression:
                                            "!item.isActive || noMicroPageId"
                                        }
                                      ],
                                      staticClass: "competent-default-icon",
                                      attrs: {
                                        src: _vm.getImgUrl(item.defaultIcon)
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "component-item-title" },
                                      [_vm._v(_vm._s(item.title))]
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-submenu",
                  { attrs: { index: "1" } },
                  [
                    _c("template", { slot: "title" }, [
                      _vm._v(
                        _vm._s(
                          _vm.isCloudRetailPartner || _vm.isAppHome
                            ? "基础"
                            : "基础组件"
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "draggable",
                      {
                        staticClass: "group-class",
                        attrs: {
                          list: _vm.basicComponents,
                          group: {
                            name: "components",
                            pull: "clone",
                            put: false
                          },
                          sort: false,
                          filter: ".not-draggable"
                        },
                        on: { choose: _vm.chooseComp }
                      },
                      _vm._l(_vm.basicComponents, function(item) {
                        return _c(
                          "div",
                          { key: item.id, attrs: { id: item.id } },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "group-class-div",
                                class: _vm.noMicroPageId
                                  ? "not-draggable"
                                  : "component-item",
                                on: {
                                  mouseenter: function($event) {
                                    return _vm.handleEnter(
                                      "basicComponents",
                                      item.id
                                    )
                                  },
                                  mouseleave: function($event) {
                                    return _vm.handleLeave(
                                      "basicComponents",
                                      item.id
                                    )
                                  }
                                }
                              },
                              [
                                _c("el-image", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        item.isActive && !_vm.noMicroPageId,
                                      expression:
                                        "item.isActive && !noMicroPageId"
                                    }
                                  ],
                                  staticClass: "competent-default-icon",
                                  attrs: { src: _vm.getImgUrl(item.activeIcon) }
                                }),
                                _vm._v(" "),
                                _c("el-image", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        !item.isActive || _vm.noMicroPageId,
                                      expression:
                                        "!item.isActive || noMicroPageId"
                                    }
                                  ],
                                  staticClass: "competent-default-icon",
                                  attrs: {
                                    src: _vm.getImgUrl(item.defaultIcon)
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "component-item-title" },
                                  [_vm._v(_vm._s(item.title))]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "el-submenu",
                  { attrs: { index: "2" } },
                  [
                    _c("template", { slot: "title" }, [
                      _vm._v(
                        _vm._s(
                          _vm.isCloudRetailPartner || _vm.isAppHome
                            ? "导航"
                            : "内容组件"
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "draggable",
                      {
                        staticClass: "group-class",
                        attrs: {
                          list: _vm.classComponents,
                          group: {
                            name: "components",
                            pull: "clone",
                            put: false
                          },
                          sort: false,
                          filter: ".not-draggable"
                        },
                        on: { choose: _vm.chooseComp, start: _vm.start }
                      },
                      _vm._l(_vm.classComponents, function(item) {
                        return _c(
                          "div",
                          { key: item.id, attrs: { id: item.id } },
                          [
                            !_vm.noMicroPageId
                              ? _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content:
                                        item.title + "每个页面只能存在一个",
                                      placement: "bottom",
                                      disabled: !_vm.getFilterClass(item)
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "group-class-div noMove",
                                        class: _vm.getFilterClass(item)
                                          ? "not-draggable"
                                          : "component-item",
                                        on: {
                                          mouseenter: function($event) {
                                            return _vm.handleEnter(
                                              "classComponents",
                                              item.id
                                            )
                                          },
                                          mouseleave: function($event) {
                                            return _vm.handleLeave(
                                              "classComponents",
                                              item.id
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("el-image", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                item.isActive &&
                                                !_vm.getFilterClass(item),
                                              expression:
                                                "item.isActive && !getFilterClass(item)"
                                            }
                                          ],
                                          staticClass: "competent-default-icon",
                                          attrs: {
                                            src: _vm.getImgUrl(item.activeIcon)
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("el-image", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                !item.isActive ||
                                                _vm.getFilterClass(item),
                                              expression:
                                                "!item.isActive || getFilterClass(item)"
                                            }
                                          ],
                                          staticClass: "competent-default-icon",
                                          attrs: {
                                            src: _vm.getImgUrl(item.defaultIcon)
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "component-item-title"
                                          },
                                          [_vm._v(_vm._s(item.title))]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.noMicroPageId
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "group-class-div noMove not-draggable",
                                    on: {
                                      mouseenter: function($event) {
                                        return _vm.handleEnter(
                                          "classComponents",
                                          item.id
                                        )
                                      },
                                      mouseleave: function($event) {
                                        return _vm.handleLeave(
                                          "classComponents",
                                          item.id
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("el-image", {
                                      staticClass: "competent-default-icon",
                                      attrs: {
                                        src: _vm.getImgUrl(item.defaultIcon)
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "component-item-title" },
                                      [_vm._v(_vm._s(item.title))]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "el-submenu",
                  { attrs: { index: "3" } },
                  [
                    _c("template", { slot: "title" }, [
                      _vm._v(
                        _vm._s(
                          _vm.isCloudRetailPartner || _vm.isAppHome
                            ? "内容"
                            : "营销组件"
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "draggable",
                      {
                        staticClass: "group-class noMove",
                        attrs: {
                          list: _vm.marketingComponents,
                          group: {
                            name: "components",
                            pull: "clone",
                            put: false
                          },
                          sort: false,
                          filter: ".not-draggable"
                        },
                        on: { choose: _vm.chooseComp, start: _vm.start }
                      },
                      _vm._l(_vm.marketingComponents, function(item) {
                        return _c(
                          "div",
                          { key: item.id, attrs: { id: item.id } },
                          [
                            !_vm.noMicroPageId
                              ? _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content:
                                        item.title + "每个页面只能存在一个",
                                      placement: "bottom",
                                      disabled: !(
                                        _vm.filterList.includes(item.type) &&
                                        item.type !== "coupon"
                                      )
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "group-class-div noMove",
                                        class:
                                          _vm.filterList.includes(item.type) &&
                                          item.type !== "coupon"
                                            ? "not-draggable"
                                            : "component-item",
                                        on: {
                                          mouseenter: function($event) {
                                            return _vm.handleEnter(
                                              "marketingComponents",
                                              item.id
                                            )
                                          },
                                          mouseleave: function($event) {
                                            return _vm.handleLeave(
                                              "marketingComponents",
                                              item.id
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("el-image", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                item.isActive &&
                                                !(
                                                  _vm.filterList.includes(
                                                    item.type
                                                  ) && item.type !== "coupon"
                                                ),
                                              expression:
                                                "item.isActive && !(filterList.includes(item.type) && item.type !=='coupon')"
                                            }
                                          ],
                                          staticClass: "competent-default-icon",
                                          attrs: {
                                            src: _vm.getImgUrl(item.activeIcon)
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("el-image", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                !item.isActive ||
                                                (_vm.filterList.includes(
                                                  item.type
                                                ) &&
                                                  item.type !== "coupon"),
                                              expression:
                                                "!item.isActive || (filterList.includes(item.type) && item.type !=='coupon')"
                                            }
                                          ],
                                          staticClass: "competent-default-icon",
                                          attrs: {
                                            src: _vm.getImgUrl(item.defaultIcon)
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "component-item-title"
                                          },
                                          [_vm._v(_vm._s(item.title))]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.noMicroPageId
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "group-class-div noMove not-draggable"
                                  },
                                  [
                                    _c("el-image", {
                                      staticClass: "competent-default-icon",
                                      attrs: {
                                        src: _vm.getImgUrl(item.defaultIcon)
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "component-item-title" },
                                      [_vm._v(_vm._s(item.title))]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ],
                  2
                ),
                _vm._v(" "),
                !_vm.isAppHome
                  ? _c(
                      "el-submenu",
                      { attrs: { index: "4" } },
                      [
                        _c("template", { slot: "title" }, [
                          _vm._v(
                            _vm._s(_vm.isCloudRetailPartner ? "营销" : "其他")
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "draggable",
                          {
                            staticClass: "group-class",
                            attrs: {
                              list: _vm.otherComponents,
                              group: {
                                name: "components",
                                pull: "clone",
                                put: false
                              },
                              sort: false,
                              filter: ".not-draggable"
                            },
                            on: { choose: _vm.chooseComp, start: _vm.start }
                          },
                          _vm._l(_vm.otherComponents, function(item) {
                            return _c(
                              "div",
                              { key: item.id, attrs: { id: item.id } },
                              [
                                !_vm.noMicroPageId
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            item.title + "每个页面只能存在一个",
                                          placement: "bottom",
                                          disabled: !_vm.filterList.includes(
                                            item.type
                                          )
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "group-class-div noMove",
                                            class: _vm.filterList.includes(
                                              item.type
                                            )
                                              ? "not-draggable"
                                              : "component-item",
                                            on: {
                                              mouseenter: function($event) {
                                                return _vm.handleEnter(
                                                  "otherComponents",
                                                  item.id
                                                )
                                              },
                                              mouseleave: function($event) {
                                                return _vm.handleLeave(
                                                  "otherComponents",
                                                  item.id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("el-image", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    item.isActive &&
                                                    !_vm.filterList.includes(
                                                      item.type
                                                    ),
                                                  expression:
                                                    "item.isActive && !filterList.includes(item.type) "
                                                }
                                              ],
                                              staticClass:
                                                "competent-default-icon",
                                              attrs: {
                                                src: _vm.getImgUrl(
                                                  item.activeIcon
                                                )
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("el-image", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    !item.isActive ||
                                                    _vm.filterList.includes(
                                                      item.type
                                                    ),
                                                  expression:
                                                    "!item.isActive || filterList.includes(item.type)"
                                                }
                                              ],
                                              staticClass:
                                                "competent-default-icon",
                                              attrs: {
                                                src: _vm.getImgUrl(
                                                  item.defaultIcon
                                                )
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "component-item-title"
                                              },
                                              [_vm._v(_vm._s(item.title))]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.noMicroPageId
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "group-class-div noMove not-draggable"
                                      },
                                      [
                                        _c("el-image", {
                                          staticClass: "competent-default-icon",
                                          attrs: {
                                            src: _vm.getImgUrl(item.defaultIcon)
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "component-item-title"
                                          },
                                          [_vm._v(_vm._s(item.title))]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          }),
                          0
                        )
                      ],
                      2
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { ref: "scrollbar", staticClass: "center-scrollbar" }, [
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.editLoading,
                expression: "editLoading"
              },
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.editLoading,
                expression: "editLoading"
              }
            ],
            staticClass: "app-loading"
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              class: [
                "center-app",
                _vm.isCloudRetailPartner || _vm.isAppHome
                  ? "cloud-retail-bg"
                  : ""
              ],
              style: {
                "background-image":
                  _vm.htmlData.showBgImg &&
                  _vm.htmlData.bgImg &&
                  ((_vm.isCloudRetailPartner && _vm.isCloudHome) ||
                    _vm.isAppHome)
                    ? "url(" + _vm.getImgUrl(_vm.htmlData.bgImg) + ")"
                    : "",
                "background-color":
                  _vm.isCloudRetailPartner || _vm.isAppHome
                    ? _vm.htmlData.htmlBackgroundColor
                    : ""
              }
            },
            [
              _c(
                "div",
                {
                  class: [_vm.htmlData.showNavigateBackground ? "app-nav" : ""]
                },
                [
                  _c(
                    "div",
                    {
                      class: [
                        _vm.htmlData.showNavigateBackground ||
                        !_vm.partnerImmerse ||
                        !_vm.htmlData.showBgImg
                          ? ""
                          : "immerse-phone-nav",
                        "phone-navigation"
                      ],
                      style: _vm.navigationTime
                    },
                    [
                      _c("i", {
                        staticClass: "iconfont icon-xinhao nav-signal-icon"
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("07：06")]),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "iconfont icon-dianchi nav-battery-icon"
                      })
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.isCloudRetailPartner && !_vm.isAppHome
                    ? _c("div", { staticClass: "phone-title-position" }, [
                        _c(
                          "div",
                          {
                            class: [
                              _vm.htmlData.showNavigateBackground
                                ? "phone-title"
                                : ""
                            ],
                            style: _vm.isShowNavigate
                          },
                          [
                            _vm.htmlData.showNavigateBackground
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "nav-title",
                                    style: { "max-width": _vm.titleWidth }
                                  },
                                  [_vm._v(_vm._s(_vm.htmlData.htmlTitle))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.htmlData.showBack
                              ? _c(
                                  "div",
                                  {
                                    class: [
                                      "common-nav-height",
                                      !_vm.htmlData.showNavigateBackground
                                        ? "immerse-back "
                                        : "nav-back-icon"
                                    ],
                                    style: {
                                      color:
                                        _vm.htmlData.navigateFontAndIconColor,
                                      "font-size": !_vm.htmlData
                                        .showNavigateBackground
                                        ? "16px"
                                        : "20px"
                                    }
                                  },
                                  [
                                    _c("div", {
                                      class: [
                                        !_vm.htmlData.showNavigateBackground
                                          ? "navigate-background"
                                          : "",
                                        "iconfont icon-fanhui"
                                      ]
                                    })
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                class: [
                                  "common-nav-height",
                                  _vm.htmlData.showNavigateBackground
                                    ? "nav-shear"
                                    : "immerse-shear"
                                ]
                              },
                              [
                                _c("micro-page-navigation", {
                                  attrs: {
                                    "navigate-font-and-icon-color":
                                      _vm.htmlData.navigateFontAndIconColor,
                                    "nav-check-list":
                                      _vm.htmlData.navigateCheckList,
                                    "show-navigate-background":
                                      _vm.htmlData.showNavigateBackground
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isCloudRetailPartner
                    ? _c(
                        "div",
                        {
                          staticClass: "phone-title-position cloud-retail-nav",
                          style: {
                            "background-color": !_vm.isCloudHome
                              ? "#FFFFFF"
                              : ""
                          }
                        },
                        [
                          _vm.isCloudHome
                            ? _c(
                                "div",
                                { staticClass: "flex-row align-center" },
                                [
                                  _c("el-image", {
                                    staticClass: "partner-img",
                                    attrs: {
                                      fit: "cover",
                                      src: _vm.getImgUrl(
                                        _vm.partnerInfo.partnerLogo,
                                        "style/width-limit-256"
                                      )
                                    }
                                  }),
                                  _vm._v(" "),
                                  !_vm.htmlData.onlyShowParLogo
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.partnerInfo.storeName)
                                        )
                                      ])
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.isCloudHome
                            ? _c(
                                "div",
                                {
                                  staticClass: "flex-row align-center",
                                  staticStyle: { width: "87px" }
                                },
                                [_c("i", { class: ["iconfont icon-fanhui"] })]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.isCloudHome
                            ? _c(
                                "div",
                                {
                                  style: { "max-width": "calc(100% - 184px)" }
                                },
                                [_vm._v(_vm._s(_vm.htmlData.htmlTitle))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "mini-programe",
                            attrs: {
                              src: require("../../assets/mini-programe.png")
                            }
                          })
                        ]
                      )
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _vm.htmlData.showNavigateBackground
                ? _c("div", {
                    style: {
                      height: _vm.isCloudRetailPartner ? "80px" : " 64px",
                      width: "100%"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("widget-form", {
                ref: "widgetForm",
                class: [_vm.isCloudRetailPartner ? "cloud-retail-bg" : ""],
                style: {
                  "background-color": _vm.htmlData.bgImg
                    ? ""
                    : _vm.htmlData.htmlBackgroundColor,
                  "background-image":
                    _vm.htmlData.showBgImg &&
                    _vm.htmlData.bgImg &&
                    _vm.isCloudRetailPartner &&
                    !_vm.isCloudHome
                      ? "url(" + _vm.getImgUrl(_vm.htmlData.bgImg) + ")"
                      : ""
                },
                attrs: {
                  "is-cloud-retail-partner": _vm.isCloudRetailPartner,
                  "html-data": _vm.htmlData,
                  data: _vm.widgetForm,
                  select: _vm.widgetFormSelect
                },
                on: {
                  "update:select": function($event) {
                    _vm.widgetFormSelect = $event
                  },
                  changeTabs: _vm.changeTabs
                }
              }),
              _vm._v(" "),
              _vm.isCloudRetailPartner
                ? _c(
                    "div",
                    { staticClass: "nav-btn-fixed" },
                    _vm._l(_vm.navigateCheckList, function(item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "nav-btn" },
                        [
                          _c("el-image", {
                            staticClass: "cover",
                            attrs: { src: item.icon }
                          })
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.widgetForm.list.length,
                  expression: "!widgetForm.list.length"
                }
              ],
              staticClass: "empty-info"
            },
            [_vm._v("从左侧拖入或点选组件进行表单设计")]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "edit-right-config" },
          [
            _c(
              "el-tabs",
              {
                attrs: { stretch: true },
                model: {
                  value: _vm.activeName,
                  callback: function($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName"
                }
              },
              [
                _c(
                  "el-tab-pane",
                  { attrs: { label: "页面设置", name: "html" } },
                  [
                    _c("html-config", {
                      attrs: {
                        "is-cloud-retail-partner": _vm.isCloudRetailPartner,
                        "is-app-home": _vm.isAppHome,
                        "html-data": _vm.htmlData,
                        "class-data": _vm.classData,
                        "show-nav-popover": _vm.activeName === "html"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-tab-pane",
                  { attrs: { label: "组件设置", name: "component" } },
                  [
                    _c("widget-config", {
                      attrs: {
                        "is-cloud-retail-partner": _vm.isCloudRetailPartner,
                        "is-app-home": _vm.isAppHome,
                        data: _vm.widgetFormSelect,
                        "class-data": _vm.classData
                      },
                      on: {
                        changeImmerseSwitch: _vm.changeImmerseSwitch,
                        handleTypeConfigUpdate: _vm.handleTypeConfigUpdate
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "submit-dialogue",
          attrs: { visible: _vm.openSubmit, title: "微页保存", width: "420px" },
          on: {
            "update:visible": function($event) {
              _vm.openSubmit = $event
            }
          }
        },
        [
          _c("span", [_vm._v("将内容编辑完整更有利于效果展示哦~")]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "flex-row flex-space-between align-center",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("div", { staticClass: "submit-loading" }, [
                _vm.submitLoading
                  ? _c("i", { staticClass: "el-icon-loading" })
                  : _vm._e(),
                _vm._v(" "),
                _vm.submitLoading ? _c("span", [_vm._v("保存中...")]) : _vm._e()
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.openSubmit = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", disabled: _vm.submitLoading },
                      on: { click: _vm.submitMicroPage }
                    },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }