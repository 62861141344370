"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _extent = require("@/utils/time/extent");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'CPMGeFilterOptionsTimeAndSorting',
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: this.pickerShortcutsOnClickBuilder(7)
        }, {
          text: '最近一个月',
          onClick: this.pickerShortcutsOnClickBuilder(30)
        }, {
          text: '最近三个月',
          onClick: this.pickerShortcutsOnClickBuilder(90)
        }]
      },
      orderOptions: [{
        value: 'id',
        label: '默认排序'
      }, {
        value: 'resourceEncode',
        label: '作品编号'
      }, {
        value: 'title',
        label: '作品标题'
      }, {
        value: 'createdAt',
        label: '添加时间'
      }, {
        value: 'publishedAt',
        label: '发布时间'
      }, {
        value: 'updatedAt',
        label: '编辑时间'
      }],
      orderMode: [{
        key: 'asc',
        value: '正序'
      }, {
        key: 'desc',
        value: '倒序'
      }],
      options: [{
        key: '0',
        value: '全部'
      }, {
        key: '1',
        value: '是'
      }, {
        key: '2',
        value: '否'
      }]
    };
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    },
    createdAt: {
      get: function get() {
        if (!this.valueProxy.createdAtStart) {
          return undefined;
        }

        if (!this.valueProxy.createdAtEnd) {
          return undefined;
        }

        return [this.valueProxy.createdAtStart, this.valueProxy.createdAtEnd];
      },
      set: function set(value) {
        this.$set(this.valueProxy, 'createdAtStart', value[0]);
        this.$set(this.valueProxy, 'createdAtEnd', value[1]);
      }
    }
  },
  methods: {
    pickerShortcutsOnClickBuilder: function pickerShortcutsOnClickBuilder() {
      var days = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      return function (picker) {
        var start = new Date();
        start.setTime(start.getTime() - days * _extent.oneDay);
        picker.$emit('pick', [start, new Date()]);
      };
    }
  }
};
exports.default = _default2;