"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var microPage = {
  state: {
    partnerOpenId: undefined,
    partnerInfo: undefined,
    microPageInfo: {},
    microPageId: undefined,
    isAdmin: true,
    resourceNum: [],
    currentQuestionPlay: {
      // 正在播放的
      openId: undefined,
      isAnswer: false
    },
    currentFilterList: [],
    // 当前组件所有type
    activeType: {},
    // 分类组件的选中
    currentResourcePlay: {
      // 音频视频
      openId: undefined,
      isAudio: false,
      model: undefined
    },
    isCloudRetail: false,
    isCloudRetailHome: false,
    isAppHome: false,
    totalLoading: {
      loading: false,
      txt: ''
    }
  },
  mutations: {
    SET_PARTNER_OPENID: function SET_PARTNER_OPENID(state, partnerOpenId) {
      state.partnerOpenId = partnerOpenId;
    },
    SET_IS_ADMIN: function SET_IS_ADMIN(state, isAdmin) {
      state.isAdmin = isAdmin;
    },
    SET_MICRO_PAGE_ID: function SET_MICRO_PAGE_ID(state, microPageId) {
      state.microPageId = microPageId;
    },
    SET_MICRO_PAGE_INFO: function SET_MICRO_PAGE_INFO(state, microPageInfo) {
      state.microPageInfo = microPageInfo;
    },
    SET_PARTNER_INFO: function SET_PARTNER_INFO(state, partnerInfo) {
      state.partnerInfo = partnerInfo;
    },
    SET_RESOURCE_NUM: function SET_RESOURCE_NUM(state, resourceNum) {
      state.resourceNum = resourceNum;
    },
    SET_CURRENT_QUESTION_PLAY: function SET_CURRENT_QUESTION_PLAY(state, val) {
      state.currentQuestionPlay = val;
    },
    SET_CURRENT_FILTER_LIST: function SET_CURRENT_FILTER_LIST(state, val) {
      state.currentFilterList = val;
    },
    SET_ACTIVE_TYPE: function SET_ACTIVE_TYPE(state, val) {
      state.activeType = val;
    },
    SET_CURRENT_RESOURCE_PLAY: function SET_CURRENT_RESOURCE_PLAY(state, val) {
      state.currentResourcePlay = val;
    },
    SET_IS_CLOUD_RETAIL: function SET_IS_CLOUD_RETAIL(state, val) {
      state.isCloudRetail = val;
    },
    SET_IS_CLOUD_RETAIL_HOME: function SET_IS_CLOUD_RETAIL_HOME(state, val) {
      state.isCloudRetailHome = val;
    },
    SET_IS_APP_HOME: function SET_IS_APP_HOME(state, val) {
      state.isAppHome = val;
    },
    SET_TOTAL_LOADING: function SET_TOTAL_LOADING(state, val) {
      state.totalLoading = val;
    }
  },
  actions: {
    setPartnerOpenId: function setPartnerOpenId(_ref, state) {
      var commit = _ref.commit;
      commit('SET_PARTNER_OPENID', state);
    },
    setIsAdmin: function setIsAdmin(_ref2, state) {
      var commit = _ref2.commit;
      commit('SET_IS_ADMIN', state);
    },
    setMicroPageId: function setMicroPageId(_ref3, state) {
      var commit = _ref3.commit;
      commit('SET_MICRO_PAGE_ID', state);
    },
    setMicroPageInfo: function setMicroPageInfo(_ref4, state) {
      var commit = _ref4.commit;
      commit('SET_MICRO_PAGE_INFO', state);
    },
    setPartnerInfo: function setPartnerInfo(_ref5, state) {
      var commit = _ref5.commit;
      commit('SET_PARTNER_INFO', state);
    },
    setResourceNum: function setResourceNum(_ref6, state) {
      var commit = _ref6.commit;
      commit('SET_RESOURCE_NUM', state);
    },
    setCurrentQuestionPlay: function setCurrentQuestionPlay(_ref7, val) {
      var commit = _ref7.commit;
      commit('SET_CURRENT_QUESTION_PLAY', val);
    },
    setCurrentFilterList: function setCurrentFilterList(_ref8, state) {
      var commit = _ref8.commit;
      commit('SET_CURRENT_FILTER_LIST', state);
    },
    setActiveType: function setActiveType(_ref9, state) {
      var commit = _ref9.commit;
      commit('SET_ACTIVE_TYPE', state);
    },
    setCurrentResourcePlay: function setCurrentResourcePlay(_ref10, val) {
      var commit = _ref10.commit;
      commit('SET_CURRENT_RESOURCE_PLAY', val);
    },
    setCloudRetail: function setCloudRetail(_ref11, val) {
      var commit = _ref11.commit;
      commit('SET_IS_CLOUD_RETAIL', val);
    },
    setCloudRetailHome: function setCloudRetailHome(_ref12, val) {
      var commit = _ref12.commit;
      commit('SET_IS_CLOUD_RETAIL_HOME', val);
    },
    setAppHome: function setAppHome(_ref13, val) {
      var commit = _ref13.commit;
      commit('SET_IS_APP_HOME', val);
    },
    setTotalLoading: function setTotalLoading(_ref14, val) {
      var commit = _ref14.commit;
      commit('SET_TOTAL_LOADING', val);
    }
  }
};
var _default = microPage;
exports.default = _default;