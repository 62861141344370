"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "collect", {
  enumerable: true,
  get: function get() {
    return _collect.default;
  }
});
Object.defineProperty(exports, "Collection", {
  enumerable: true,
  get: function get() {
    return _Collection.default;
  }
});

var _collect = _interopRequireDefault(require("./collection/collect"));

var _Collection = _interopRequireDefault(require("./collection/Collection"));