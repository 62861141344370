var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "special-page" },
    [
      _c(
        "el-radio-group",
        {
          staticClass: "radio-group",
          model: {
            value: _vm.value.specialPageLink,
            callback: function($$v) {
              _vm.$set(_vm.value, "specialPageLink", $$v)
            },
            expression: "value.specialPageLink"
          }
        },
        [
          _c(
            "el-radio",
            { staticClass: "radio", attrs: { label: "口算APP" } },
            [_vm._v("口算APP")]
          ),
          _vm._v(" "),
          _c(
            "el-radio",
            { staticClass: "radio", attrs: { label: "淘知名师" } },
            [_vm._v("淘知名师")]
          ),
          _vm._v(" "),
          _c("el-radio", { staticClass: "radio", attrs: { label: "客服" } }, [
            _vm._v("客服")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }