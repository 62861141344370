"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("./SearchGroup/index"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'CPMGrHeaderIndex',
  components: {
    SearchGroup: _index.default
  },
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    searchPlaceholder: {
      type: String,
      default: undefined
    },
    showRemember: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    searchChanged: function searchChanged() {
      this.$emit('search-changed');
    }
  }
};
exports.default = _default2;