"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.number.constructor");

var _price = require("../../utils/price");

var _common = require("../../utils/common");

var _AudioBookPrice = _interopRequireDefault(require("./AudioBookShelf/AudioBookPrice"));

var _AudioBookVipIcon = _interopRequireDefault(require("./AudioBookShelf/AudioBookVipIcon"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var audioBookCover = require("../../assets/cover/cover-audiobook.png");

var bookCover = require("../../assets/cover/cover-book.png");

var ebookCover = require("../../assets/cover/ebookDefault.png");

var caseCover = require("../../assets/cover/caseDefault.png");

var _default2 = {
  name: 'BookShelfItem',
  components: {
    AudioBookVipIcon: _AudioBookVipIcon.default,
    AudioBookPrice: _AudioBookPrice.default
  },
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    options: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    bookCover: {
      type: Number,
      default: undefined
    },
    coverType: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      priceShowByCent: _price.priceShowByCent
    };
  },
  computed: {
    showTitle: function showTitle() {
      if (this.value.isAudioBook !== 1) {
        return !!(this.value.title && this.options.showResourceTitle !== false || this.options.showPrice && this.value.price >= 0);
      }

      return this.value.name && this.options.showResourceTitle !== false;
    },
    lineHeight: function lineHeight() {
      var isEbook = this.value.isAudioBook === 2 || this.value.isAudioBook === 3;

      if (this.options.showBgImg) {
        return isEbook && this.value.coverType === 1 ? '42px' : '83px';
      }

      return isEbook && this.value.coverType === 1 ? '47px' : '88px';
    },
    ebookHeight: function ebookHeight() {
      if (this.options.appearance !== 'card') {
        return this.coverType ? '74px' : '150px';
      }

      return this.coverType ? '67px' : '135px';
    },
    pictureHeight: function pictureHeight() {
      var isEbook = this.value.isAudioBook === 2 || this.value.isAudioBook === 3;

      if (this.options.appearance !== 'card') {
        return isEbook && this.value.coverType === 1 ? '74px' : '150px';
      }

      return isEbook && this.value.coverType === 1 ? '67px' : '135px';
    },
    isRowEbook: function isRowEbook() {
      if (this.value.isAudioBook === 2 || this.value.isAudioBook === 3) {
        return this.value.coverType && this.value.coverType === 1;
      }

      return false;
    }
  },
  methods: {
    // 处理图片
    getImgUrl: function getImgUrl(val) {
      var style = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'style/width-limit-256';

      if ((val.isAudioBook === 2 || val.isAudioBook === 3) && val.coverDisplay) {
        return (0, _common.imageUrlFun)(val.coverDisplay, style);
      }

      if (val.cover && (val.isAudioBook === 1 || val.isAudioBook === 0)) {
        return (0, _common.imageUrlFun)(val.cover, style);
      }

      if (val.isAudioBook === 2 || val.isAudioBook === 3) {
        return val.coverType === 1 ? caseCover : ebookCover;
      }

      return this.value.isAudioBook === 0 ? audioBookCover : bookCover;
    }
  }
};
exports.default = _default2;