var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "select-img",
      style: {
        width: _vm.imgWidthHeight.width,
        height: _vm.imgWidthHeight.height
      }
    },
    [
      !_vm.path
        ? _c("div", { staticClass: "no-path" }, [
            _vm.type === "partner_info"
              ? _c(
                  "div",
                  {
                    staticClass: "no-img no-img-background",
                    style: {
                      width: _vm.imgWidthHeight.width,
                      height: _vm.imgWidthHeight.height
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "el-icon-picture-outline background-icon"
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.iconText))])
                  ]
                )
              : _vm.type === "official_account" || _vm.type === "applets"
              ? _c(
                  "div",
                  {
                    staticClass:
                      "no-img official-background flex-column common-flex-center",
                    style: {
                      width: _vm.imgWidthHeight.width,
                      height: _vm.imgWidthHeight.height
                    }
                  },
                  [
                    _c("i", { class: [_vm.iconName, "icon"] }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.iconText))])
                  ]
                )
              : _c(
                  "div",
                  {
                    staticClass: "no-img flex-column common-flex-center",
                    style: {
                      width: _vm.imgWidthHeight.width,
                      height: _vm.imgWidthHeight.height
                    }
                  },
                  [
                    _c("i", { staticClass: "el-icon-picture-outline icon" }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.iconText))])
                  ]
                ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "no-path-mask",
                style: {
                  width: _vm.imgWidthHeight.width,
                  height: _vm.imgWidthHeight.height
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "common-flex-center",
                    style: {
                      width: _vm.imgWidthHeight.width,
                      height: _vm.imgWidthHeight.height
                    }
                  },
                  [
                    _c("div", { staticClass: "no-path-mask-center" }, [
                      _c(
                        "i",
                        {
                          staticClass: "iconfont icon-gongdantupian icon-size",
                          on: { click: _vm.handleUpload }
                        },
                        [_vm._v(" 本地")]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        ref: "upload",
                        staticStyle: { display: "none" },
                        attrs: { type: "file", accept: "image/*" },
                        on: { change: _vm.changeUpload }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "divider" }),
                      _vm._v(" "),
                      _c(
                        "i",
                        {
                          staticClass: "iconfont icon-sucaiku icon-size",
                          on: {
                            click: function($event) {
                              _vm.visible = true
                            }
                          }
                        },
                        [_vm._v(" 素材库")]
                      )
                    ])
                  ]
                )
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.path
        ? _c(
            "div",
            {
              staticClass: "echo",
              style: {
                width: _vm.imgWidthHeight.width,
                height: _vm.imgWidthHeight.height
              }
            },
            [
              _c("el-image", {
                staticClass: "show-img",
                attrs: {
                  fit: "cover",
                  src: _vm.getImgUrl(_vm.path, "style/width-limit-1024")
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "echo-mask",
                  style: {
                    width: _vm.imgWidthHeight.width,
                    height: _vm.imgWidthHeight.height
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "common-flex-center",
                      style: {
                        width: _vm.imgWidthHeight.width,
                        height: _vm.imgWidthHeight.height
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-sort echo-icon",
                        on: {
                          click: function($event) {
                            _vm.visible = true
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "el-icon-delete",
                        on: { click: _vm.handleDelete }
                      })
                    ]
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("select-material-dialogue", {
        attrs: {
          visible: _vm.visible,
          "material-classification-open-id":
            _vm.dataItem.materialClassificationOpenId,
          "is-personal": _vm.dataItem.isPersonal,
          "material-open-id": _vm.dataItem.materialOpenId
        },
        on: {
          "update:visible": function($event) {
            _vm.visible = $event
          },
          submitMaterial: _vm.submitMaterial
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }