"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mapToArray = mapToArray;
exports.mapToObject = mapToObject;

require("core-js/modules/web.dom.iterable");

var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

var _polyfill = require("../../polyfill");

function mapToArray(map) {
  return (0, _toConsumableArray2.default)(map.values());
}

function mapToObject(map) {
  return (0, _polyfill.objectFromEntries)(map.entries());
}