"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _names = _interopRequireDefault(require("./base/names"));

var _type = _interopRequireDefault(require("./base/type"));

var _check = require("./base/check");

var _expandCheck = require("./expandCheck");

var _default = {
  names: _names.default,
  type: _type.default,
  // 基本类型
  isUndefined: _check.isUndefined,
  isNull: _check.isNull,
  isBoolean: _check.isBoolean,
  isString: _check.isString,
  isNumber: _check.isNumber,
  isObject: _check.isObject,
  isSymbol: _check.isSymbol,
  isFunction: _check.isFunction,
  // expand
  isInt: _expandCheck.isInt,
  isFloat: _expandCheck.isFloat,
  isNaN: _expandCheck.isNaN,
  isInfinity: _expandCheck.isInfinity,
  isArray: _expandCheck.isArray,
  isTrue: _expandCheck.isTrue,
  isFalse: _expandCheck.isFalse,
  isNone: _expandCheck.isNone,
  isTruthy: _expandCheck.isTruthy,
  isFalsy: _expandCheck.isFalsy,
  isBlank: _expandCheck.isBlank,
  isEmpty: _expandCheck.isEmpty
};
exports.default = _default;