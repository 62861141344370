"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.is-integer");

require("core-js/modules/es6.number.is-finite");

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.number.is-nan");

var _typeof2 = _interopRequireDefault(require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime-corejs2/helpers/typeof"));

/**
 * 将一个值转化为 integer。
 * 如果该值无效，将会返回 undefined。
 *
 * @param {*} value
 * @returns {?number}
 */
function anyToInteger(value) {
  try {
    switch ((0, _typeof2.default)(value)) {
      case 'undefined':
        return value;

      case 'function':
        // 计算出结果
        return anyToInteger(value());

      case 'number':
        // NaN 或者 Infinity
        if (Number.isNaN(value) || !Number.isFinite(value)) {
          return undefined;
        } // float


        if (!Number.isInteger(value)) {
          return anyToInteger(parseInt(value, 10));
        }

        return value;

      default:
        // 格式化数值
        return anyToInteger(parseInt(value, 10));
    }
  } catch (e) {
    console.warn(e.message);
    return undefined;
  }
}

var _default = anyToInteger;
exports.default = _default;