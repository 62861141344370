"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'CPMGeFilterOptionsStatusFiltering',
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      quota: [{
        key: '0',
        value: '全部'
      }, {
        key: '1',
        value: '限购'
      }, {
        key: '2',
        value: '不限'
      }],
      teacherBuy: [{
        key: '0',
        value: '全部'
      }, {
        key: '1',
        value: '仅教师可购'
      }, {
        key: '2',
        value: '不限'
      }],
      options: [{
        key: '0',
        value: '全部'
      }, {
        key: '1',
        value: '是'
      }, {
        key: '2',
        value: '否'
      }]
    };
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  }
};
exports.default = _default2;