"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  navigateButtonLists: [{
    icon: 'iconfont icon-fenxiang',
    title: '店铺分享'
  }, {
    icon: 'iconfont icon-guanzhu',
    title: '店铺关注'
  }, {
    icon: 'iconfont icon-sousuo',
    title: '课程搜索'
  }, {
    icon: 'iconfont icon-newfankui',
    title: '反馈'
  }],
  navList: [{
    icon: 'iconfont icon-fenxiang',
    title: '店铺分享'
  }, {
    icon: 'iconfont icon-guanzhu',
    title: '店铺关注'
  }, {
    icon: 'iconfont icon-sousuo',
    title: '课程搜索'
  }],
  cloudRetailList: [{
    icon: "".concat(process.env.VUE_APP_OSS_URL, "production/assets/taozhi/shopMiniProgram/shareAndTop/home-icon.png"),
    title: '首页',
    type: 'home'
  }, {
    icon: "".concat(process.env.VUE_APP_OSS_URL, "production/assets/taozhi/shopMiniProgram/shareAndTop/share-icon.png"),
    title: '店铺分享',
    type: 'share'
  }, {
    icon: "".concat(process.env.VUE_APP_OSS_URL, "production/assets/taozhi/shopMiniProgram/shareAndTop/phone-icon.png"),
    title: '联系电话',
    type: 'phone'
  }, {
    icon: "".concat(process.env.VUE_APP_OSS_URL, "production/assets/taozhi/shopMiniProgram/shareAndTop/kf.png"),
    title: '客服',
    type: 'kf'
  }, {
    icon: "".concat(process.env.VUE_APP_OSS_URL, "production/assets/taozhi/shopMiniProgram/shareAndTop/top-icon.png"),
    title: '滚动置顶',
    type: 'top'
  }]
};
exports.default = _default;