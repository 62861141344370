var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "add-dialog",
      attrs: {
        title: "添加图片",
        width: "500px",
        "append-to-body": "",
        visible: _vm.visible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.handleCancel
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, "label-width": "80px", rules: _vm.rule }
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "picName", label: "图片名称" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入图片名称", clearable: "" },
                model: {
                  value: _vm.form.picName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "picName", $$v)
                  },
                  expression: "form.picName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { "label-width": "0px", prop: "path" } },
            [
              _c(
                "div",
                {
                  staticClass: "upload-drag",
                  on: {
                    dragenter: _vm.handleDragenter,
                    drop: _vm.handelDrop,
                    dragover: _vm.handleDragover
                  }
                },
                [
                  _c("div", { staticClass: "upload-img" }, [
                    _c("input", {
                      ref: "myUpload",
                      staticClass: "file-upload",
                      attrs: {
                        id: "myUpload",
                        type: "file",
                        accept: "image/*"
                      },
                      on: { change: _vm.handelUpload }
                    })
                  ]),
                  _vm._v(" "),
                  !_vm.form.path
                    ? _c("div", { staticClass: "upload-title" }, [
                        _vm._v(
                          "\n          拖拽图片至此，或点击上传  （支持\n          jpg / png格式，小于2M）\n        "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.form.path
                    ? _c(
                        "div",
                        { staticClass: "show-img" },
                        [
                          _c("el-image", {
                            staticClass: "img-cover",
                            attrs: { fit: "cover", src: _vm.form.path }
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "mask" }, [
                            _c("div", { staticClass: "mask-center" }, [
                              _c(
                                "i",
                                {
                                  staticClass:
                                    "iconfont icon-tihuan common-icon change"
                                },
                                [
                                  _c("input", {
                                    ref: "myChangeUpload",
                                    staticClass: "change-upload",
                                    attrs: {
                                      id: "myChangeUpload",
                                      type: "file",
                                      accept: "image/*"
                                    },
                                    on: { change: _vm.handelChangeUpload }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c("i", {
                                staticClass: "el-icon-delete common-icon",
                                on: { click: _vm.deleteImg }
                              })
                            ])
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取消")
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitLoading },
              on: { click: _vm.submit }
            },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }