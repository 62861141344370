"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/es6.object.keys");

require("core-js/modules/es6.function.name");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/web.dom.iterable");

var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

var _sortablejs = require("sortablejs");

var _WordCustomDialog = _interopRequireDefault(require("./WordCustomDialog"));

var _microWord = require("../../utils/microWord");

var _character = _interopRequireDefault(require("../../assets/json/character"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var _default2 = {
  name: 'WordContentDialog',
  components: {
    WordCustomDialog: _WordCustomDialog.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    type: {
      type: String,
      default: undefined
    }
  },
  data: function data() {
    return {
      tableData: [],
      customDialog: false,
      customData: {},
      checkList: []
    };
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler: function handler(val) {
        this.initData(val.checkList);
      }
    }
  },
  methods: {
    initData: function initData(val) {
      var _this = this;

      this.checkList = [];
      var obj = JSON.parse(JSON.stringify(_character.default));
      this.tableData = this.value.type === 'micro_terms' ? obj.termsData : obj.tableData;

      if (val.length <= 0) {
        this.$nextTick(function () {
          _this.drag();
        });
        return;
      }

      var wordList = this.value.type !== 'micro_terms' ? obj.list : obj.termList;
      this.tableData = (0, _toConsumableArray2.default)(val);
      this.$nextTick(function () {
        _this.tableData.forEach(function (item) {
          (0, _microWord.wordCheckData)(item, _this.value, _this.type);
          item.disable = !wordList.includes(item.label); // 判断是否是新加的  新加的为全数字

          if (item.check) {
            _this.$refs.multipleTable.toggleRowSelection(item);

            _this.checkList.push(item.label);
          }
        });

        _this.drag();
      });
    },
    addCustom: function addCustom() {
      this.customData = {};
      this.customDialog = true;
    },
    submitCustom: function submitCustom(val, isEdit) {
      this.customData = {};

      if (!isEdit) {
        this.tableData.push(_objectSpread(_objectSpread({}, val), {}, {
          disable: true,
          check: true
        }));
        this.$refs.multipleTable.toggleRowSelection(this.tableData[this.tableData.length - 1]);
        return;
      }

      this.tableData.forEach(function (item) {
        if (item.label === val.label) {
          item.name = val.name;
          item.content = val.content;
        }
      });
    },
    // 删除
    deleteData: function deleteData(val) {
      var _this2 = this;

      this.tableData.forEach(function (item, index) {
        if (val.label === item.label) {
          _this2.tableData.splice(index, 1);
        }
      });
    },
    // 编辑
    editData: function editData(val) {
      this.customData = _objectSpread({}, val);
      this.customDialog = true;
    },
    drag: function drag() {
      var _this3 = this;

      var el = document.querySelectorAll('.word-drag-table tbody')[0];

      _sortablejs.Sortable.create(el, {
        onEnd: function onEnd(e) {
          var arr = _this3.tableData;
          arr.splice(e.newIndex, 0, arr.splice(e.oldIndex, 1)[0]);

          _this3.$nextTick(function () {
            _this3.tableData = arr;
          });
        }
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      var _this4 = this;

      this.checkList = [];
      var list = val.map(function (item) {
        return item.label;
      });
      this.tableData.forEach(function (item) {
        if (list.includes(item.label)) {
          _this4.checkList.push(item.label);
        }
      });
    },
    submit: function submit() {
      var _this5 = this;

      var list = [];
      this.tableData.forEach(function (item) {
        if (_this5.checkList.includes(item.label)) {
          item.check = true;
          (0, _microWord.wordCheckData)(item, _this5.value, _this5.type);
        } else {
          item.check = false;
        }

        list.push(item);
      });
      this.$emit('submit', list, this.value);
    },
    cancel: function cancel() {
      this.$emit('update:visible', false);
    }
  }
};
exports.default = _default2;