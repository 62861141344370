"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  initialFrameWidth: 850,
  // 页面宽度
  autoHeightEnabled: false,
  // 开启自动高度
  autoFloatEnabled: false,
  // 保持 toolbar 的位置不动
  maximumWords: undefined,
  // 最大字符限制 默认无限
  toolbars: [['paragraph', // 段落格式
  'fontfamily', // 字体
  'fontsize', // 字号
  'bold', // 加粗
  'italic', // 斜体
  'underline', // 下划线
  'strikethrough', // 删除线
  'superscript', // 上标
  'subscript', // 下标
  'removeformat', // 清除格式
  'formatmatch', // 格式刷
  'autotypeset', // 自动排版
  'blockquote', // 引用
  'pasteplain', // 纯文本粘贴模式
  '|', 'forecolor', // 字体颜色
  'backcolor', // 背景色
  'insertorderedlist', // 有序列表
  'insertunorderedlist', // 无序列表
  'fullscreen', // 全屏
  '|', 'rowspacingtop', // 段前距
  'rowspacingbottom', // 段后距
  'lineheight' // 行间距
  ], ['directionalityltr', // 从左向右输入
  'directionalityrtl', // 从右向左输入
  'indent', // 首行缩进
  '|', 'justifyleft', // 居左对齐
  'justifyright', // 居右对齐
  'justifycenter', // 居中对齐
  'justifyjustify', // 两端对齐
  '|', 'link', // 超链接
  'unlink', // 取消链接
  'anchor', // 锚点
  '|', 'insertimage', // 多图上传
  'emotion', // 表情
  '|', 'horizontal', // 分隔线
  'inserttable', // 插入表格
  'preview', // 预览
  'searchreplace', // 查询替换
  '|', '135editor', '|', 'undo', // 撤销
  'redo', // 重做
  'kityformula', // 公式
  'button', // 填空
  'notes' // 注释
  ]]
};
exports.default = _default;