import { render, staticRenderFns } from "./AddClassificationDialog.vue?vue&type=template&id=7f91d246&scoped=true&"
import script from "./AddClassificationDialog.vue?vue&type=script&lang=js&"
export * from "./AddClassificationDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f91d246",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/front-end_micro-page_oo0i/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7f91d246')) {
      api.createRecord('7f91d246', component.options)
    } else {
      api.reload('7f91d246', component.options)
    }
    module.hot.accept("./AddClassificationDialog.vue?vue&type=template&id=7f91d246&scoped=true&", function () {
      api.rerender('7f91d246', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/MicroClassCategory/MicroClassification/AddClassificationDialog.vue"
export default component.exports