var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "read" },
    [
      _vm.options.style === "read"
        ? _c(
            "div",
            {
              class: [
                "flex-row",
                _vm.value.cover ? "cover-view" : "flex-justify-center"
              ]
            },
            [
              _vm.value.cover
                ? _c("el-image", {
                    class:
                      _vm.options.appearance !== "card"
                        ? "read-cover"
                        : "card-read-cover",
                    attrs: {
                      fit: "cover",
                      src: _vm.getImgUrl(
                        _vm.value.cover,
                        "style/width-limit-256"
                      )
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: [
                    "read-word",
                    _vm.options.appearance !== "card" ? "" : "card-read-word"
                  ]
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: " common-flex-center",
                      class: [
                        "read-pin",
                        _vm.options.appearance !== "card" ? "" : "card-read-pin"
                      ]
                    },
                    [_vm._v(_vm._s(_vm.getPinYin(_vm.value)))]
                  ),
                  _vm._v(" "),
                  _vm.newImgSrc
                    ? _c(
                        "div",
                        {
                          class: [
                            "read-name",
                            "common-flex-center",
                            _vm.options.appearance !== "card"
                              ? ""
                              : "card-item-name"
                          ]
                        },
                        [
                          _c("el-image", {
                            staticClass: "name-cover",
                            attrs: { src: _vm.newImgSrc }
                          })
                        ],
                        1
                      )
                    : _c(
                        "span",
                        {
                          class: [
                            "read-name",
                            "common-flex-center",
                            _vm.options.appearance !== "card"
                              ? ""
                              : "card-read-name"
                          ]
                        },
                        [_vm._v(_vm._s(_vm.value.name))]
                      )
                ]
              )
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "write-view flex-row" },
            [
              _vm.value.strokeOrder
                ? _c("el-image", {
                    class: [
                      _vm.options.appearance !== "card"
                        ? "write-cover"
                        : "card-write-cover"
                    ],
                    attrs: { src: _vm.getImgUrl(_vm.value.strokeOrder) }
                  })
                : _c(
                    "div",
                    {
                      class: [
                        "no-stroke",
                        _vm.options.appearance !== "card"
                          ? "write-cover stroke"
                          : "card-write-cover card-stroke"
                      ]
                    },
                    [
                      _vm.newImgSrc
                        ? _c("el-image", {
                            staticClass: "write-name-cover",
                            attrs: { src: _vm.newImgSrc }
                          })
                        : _c("div", [_vm._v(_vm._s(_vm.value.name))])
                    ],
                    1
                  ),
              _vm._v(" "),
              _c("div", { staticClass: "write-info" }, [
                _c(
                  "div",
                  {
                    class: [
                      _vm.options.appearance !== "card"
                        ? "write-text-margin"
                        : "card-write-text-margin"
                    ]
                  },
                  [
                    _c("span", { staticClass: "common-write-text" }, [
                      _vm._v("拼音：")
                    ]),
                    _vm._v(_vm._s(_vm.getPinYin(_vm.value)) + "\n      ")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: [
                      _vm.options.appearance !== "card"
                        ? "write-text-margin"
                        : "card-write-text-margin"
                    ]
                  },
                  [
                    _c("span", { staticClass: "common-write-text" }, [
                      _vm._v("部首：")
                    ]),
                    _vm._v(_vm._s(_vm.value.radicalsName) + "\n      ")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: [
                      _vm.options.appearance !== "card"
                        ? "write-text-margin"
                        : "card-write-text-margin"
                    ]
                  },
                  [
                    _c("span", { staticClass: "common-write-text" }, [
                      _vm._v("结构：")
                    ]),
                    _vm._v(_vm._s(_vm.value.structureName) + "\n      ")
                  ]
                )
              ])
            ],
            1
          ),
      _vm._v(" "),
      _vm._l(_vm.checkList, function(item, index) {
        return _c(
          "div",
          { key: index },
          [
            _c("word-check-list", {
              attrs: {
                value: _vm.value,
                item: item,
                options: _vm.options,
                "is-more": _vm.isMore,
                "cur-index": index,
                "last-length": _vm.checkList.length
              },
              on: { handleMore: _vm.handleMore }
            })
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }