var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.rightAnswerContent
    ? _c("div", { staticClass: "zhu-guan-ti" }, [
        _c("div", { staticClass: "answer-content" }, [
          _vm._v("\n    答案:\n    "),
          _c("div", { staticClass: "content-test" }, [
            _vm._v(_vm._s(_vm.rightAnswerContent))
          ]),
          _vm._v(" "),
          _vm.answerConfirm
            ? _c("div", [
                _vm._v(
                  "您的答案: " + _vm._s(_vm.answerRight ? "已判对" : "已判错")
                )
              ])
            : _vm._e()
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }