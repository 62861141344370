var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "commodity" },
    [
      _c(
        "el-image",
        {
          staticClass: "cover",
          attrs: { src: _vm.getImgUrlWl32(_vm.commodity.cover), fit: "cover" }
        },
        [
          _c(
            "div",
            {
              staticClass: "image-slot",
              attrs: { slot: "error" },
              slot: "error"
            },
            [_c("i", { staticClass: "el-icon-picture-outline" })]
          )
        ]
      ),
      _vm._v(" "),
      _c("p", { staticClass: "title" }, [_vm._v(_vm._s(_vm.commodity.title))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }