"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.audioBookRequestList = audioBookRequestList;
exports.bookList = bookList;
exports.getCommodityBookList = getCommodityBookList;
exports.getPageList = getPageList;
exports.getBookMenuList = getBookMenuList;
exports.getAudioBookList = getAudioBookList;

var _main = _interopRequireDefault(require("../utils/request/main"));

var _store = _interopRequireDefault(require("../store"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/audiobook/admin/"));
var requestMp = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/audiobook/mp/"));
/**
 * 获取点学书列表
 * @param params
 * @returns {Promise<*>}
 */

function audioBookRequestList(params) {
  return request.get('/book/request/list', {
    params: params
  });
}
/**
 * 获取点学书列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function bookList(params) {
  return request.get('/book/request/list', {
    params: params
  });
}
/**
 * 获取点学书商品信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getCommodityBookList(params) {
  if (_store.default.state.microPage.isAdmin) {
    return request.get('/book/admin', {
      params: params
    });
  }

  return requestMp.get('/book/mp', {
    params: params
  });
}
/**
 * 获取书页列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getPageList(params) {
  if (_store.default.state.microPage.isAdmin) {
    return request.get('/page/request/list', {
      params: params
    });
  }

  return requestMp.get('/page/request/list', {
    params: params
  });
}
/**
 * 图书目录页
 * @param {Object} bookOpenId
 * @returns {Promise<*>}
 */


function getBookMenuList(bookOpenId) {
  if (_store.default.state.microPage.isAdmin) {
    return request.get("page/menu/".concat(bookOpenId));
  }

  return requestMp.get("page/menu/".concat(bookOpenId));
}
/**
 * 获取点学书列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAudioBookList(params) {
  return request.get('/book/request/list', {
    params: params
  });
}