"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.handleBlankUeditor = handleBlankUeditor;
exports.removeWidth = removeWidth;
Object.defineProperty(exports, "Editor", {
  enumerable: true,
  get: function get() {
    return _Editor.default;
  }
});

require("core-js/modules/es6.regexp.constructor");

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.regexp.split");

var _Editor = _interopRequireDefault(require("./components/Editor.vue"));

// 转换为十六进制方法
function colorHex(string) {
  // 十六进制颜色值的正则表达式
  var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;

  if (/^(rgb|RGB)/.test(string)) {
    var aColor = string.replace(/(?:\(|\)|rgb|RGB)*/g, '').split(',');
    var strHex = '#';

    for (var i = 0; i < aColor.length; i++) {
      var hex = Number(aColor[i]).toString(16);

      if (hex === '0') {
        hex += hex;
      }

      strHex += hex;
    }

    if (strHex.length !== 7) {
      strHex = string;
    }

    return strHex;
  }

  if (reg.test(string)) {
    var aNum = string.replace(/#/, '').split('');

    if (aNum.length === 6) {
      return string;
    }

    if (aNum.length === 3) {
      var numHex = '#';

      for (var _i = 0; _i < aNum.length; _i += 1) {
        numHex += aNum[_i] + aNum[_i];
      }

      return numHex;
    }
  } else {
    return string;
  }
}

function getColorToStyle(val) {
  var isSpan = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var result;
  var reg = isSpan ? new RegExp('[^-]color:(.*?);', 'gi') : new RegExp('color: (.*?); font-weight: 700;', 'gi');
  val.replace(reg, function ($0, $1) {
    result = $1 ? $1.trim() : undefined;
    result = result ? colorHex(result) : undefined;
  });
  return result;
}
/**
 * 获取公式自定义width
 * @param val
 * @returns {*}
 */


function handleKityformulaStyle(val) {
  var result;
  var reg = new RegExp('[^-]width:(.*?)px;', 'gi');
  val.replace(reg, function ($0, $1) {
    result = $1 ? $1.trim() : $1;
  });
  return result;
}
/**
 * 处理公式 标签
 * @param str
 * @returns {{color: undefined, content: undefined}}
 */


function getKityformula(str, size) {
  var value = 0;
  var newImage = new Image();
  var reg = new RegExp('<img(.*?)src="(.*?)"(.*?)/>', 'gi');
  str = str.replace(reg, function ($0, $1, $2, $3) {
    // 如果有width 就用width 无则获取图片本身的宽度
    var widthData = handleKityformulaStyle($1 + $3);

    if (widthData) {
      value += parseInt(widthData, 0);
    } else {
      newImage.src = $2;
      value += newImage.width;
    }

    return '';
  });
  str = str.replace('<div(.*?)>', '');
  str = str.replace('</div>', '');
  str = str.replace('<p(.*?)>', '');
  str = str.replace('</p>', '');
  return "".concat(str.length * size + value + 40, "px");
}
/**
 * 处理span 标签
 * @param str
 * @returns {{color: undefined, content: undefined}}
 */


function getSpan(str) {
  var value = {
    color: undefined,
    content: undefined
  };
  var reg = new RegExp('(.*?)<span style="(.*?)">(.*?)</span>(.*)', 'gi');
  str.replace(reg, function ($0, $1, $2, $3, $4) {
    // 处理样式找出颜色
    value.color = getColorToStyle($2, true);
    value.content = $1 + $3 + $4;
  });
  return value;
}

function getContentText(str) {
  var regEm = new RegExp('<\/?em>', 'gi');
  var regStrong = new RegExp('<\/?strong>', 'gi');
  var regSpan = new RegExp('<span(.*?)>(.*?)</span>', 'gi');
  str = str.replace(regEm, '');
  str = str.replace(regStrong, '');
  str.replace(regSpan, function ($0, $1, $2) {
    str = $2;
  });
  return str;
}
/**
 * 获取font-size
 * @param val
 */


function getFontSize(val) {
  var result = 14;
  var reg = new RegExp('font-size:(.*?)px;', 'gi');
  val.replace(reg, function ($0, $1) {
    result = $1 ? $1.trim() : result;
  });
  return Number(result);
}
/**
 * 处理填空的width
 * @param str 富文本内容
 * @returns {*}
 */


function getWidth(str) {
  if (!str) {
    return str;
  }

  var value;
  var regTest = /<span style="(.*?)">(.*?)<ed style="width: auto; box-sizing: border-box; display: inline-block; padding: 0 20px; color:(.*?); font-weight: 700; border-bottom: 1px solid #000000; text-indent: 0">(.*?)<\/ed>/gi; // 不带span 默认为16px

  if (!regTest.test(str)) {
    var noSpanReg = new RegExp('<ed style="width: auto; box-sizing: border-box; display: inline-block; padding: 0 20px; color:(.*?); font-weight: 700; border-bottom: 1px solid #000000; text-indent: 0">(.*?)</ed>', 'gi');
    value = str.replace(noSpanReg, function ($0, $1, $2) {
      var data = getSpan($1);
      var dataColor = data.color ? data.color : $1;
      dataColor = dataColor.trim() ? dataColor.trim() : '#00a2ff';
      var content = getContentText(data.content ? data.content : $2);
      var dataWidth = getKityformula(content, 16);
      return "<ed style=\"width: ".concat(dataWidth, "; box-sizing: border-box; display: inline-block; padding: 0 20px; color: ").concat(dataColor, "; font-weight: 700; border-bottom: 1px solid #000000\">").concat(content, "</ed>");
    });
  } // 带span


  var reg = new RegExp('<span style="(.*?)">(.*?)<ed style="width: auto; box-sizing: border-box; display: inline-block; padding: 0 20px; color:(.*?); font-weight: 700; border-bottom: 1px solid #000000; text-indent: 0">(.*?)</ed>', 'gi');
  value = str.replace(reg, function ($0, $1, $2, $3, $4) {
    var size = getFontSize($1);
    var data = getSpan($3);
    var dataColor = data.color ? data.color : $3;
    dataColor = dataColor.trim() ? dataColor.trim() : '#00a2ff';
    var content = getContentText(data.content ? data.content : $4);
    var dataWidth = getKityformula(content, size);
    return "<span style=\"".concat($1, "\">").concat($2, "<ed style=\"width: ").concat(dataWidth, "; box-sizing: border-box; display: inline-block; padding: 0 20px; color: ").concat(dataColor, "; font-weight: 700; border-bottom: 1px solid #000000\">").concat(content, "</ed>");
  });
  return value;
}
/**
 * 保存时处理填空数据
 * @param val 富文本内容
 * @returns {*}
 */


function handleBlankUeditor(val) {
  if (!val) {
    return val;
  }

  var result = val;
  var reg = new RegExp('<ed style="(.*?)">', 'gi'); // 处理多余的样式

  result = result.replace(reg, function ($0, $1) {
    var colorData = getColorToStyle($1);
    colorData = colorData || '#00a2ff';
    return "<ed style=\"width: auto; box-sizing: border-box; display: inline-block; padding: 0 20px; color: ".concat(colorData, "; font-weight: 700; border-bottom: 1px solid #000000\">");
  }); // 获取 width 、去掉内容中多余的标签

  result = getWidth(result);
  return result;
}
/**
 * 初始化时处理 填空 width 变 auto
 * @param val 富文本内容
 * @returns {*}
 */


function removeWidth(val) {
  if (!val) {
    return val;
  }

  var reg = new RegExp('<ed style="width: (.{1,6}); box-sizing: border-box; display: inline-block; padding: 0 20px; color: (.{1,20}); font-weight: 700; border-bottom: 1px solid #000000">', 'gi');
  val = val.replace(reg, function ($0, $1, $2) {
    return "<ed style=\"width: auto; box-sizing: border-box; display: inline-block; padding: 0 20px; color: ".concat($2, "; font-weight: 700; border-bottom: 1px solid #000000; text-indent: 0\">");
  });
  return val;
} // eslint-disable-line import/prefer-default-export