"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'AudioBookVipIcon',
  props: {
    value: {
      type: Object,
      default: function _default() {}
    },
    iconStyle: {
      type: Object,
      default: function _default() {
        return {
          width: '65px',
          height: '23px'
        };
      }
    }
  },
  data: function data() {
    return {
      userIsVip: true
    };
  },
  computed: {
    showVipIcon: function showVipIcon() {
      if (this.userIsVip && this.value.vipOnly) {
        return true;
      }

      if (this.userIsVip && this.value.vipDiscount === 0 && this.value.price && this.value.price !== 0 && !this.value.vipOnly) {
        return true;
      }

      if (this.userIsVip && this.value.vipDiscount > 0 && this.value.vipDiscount < 1 && this.value.price !== 0 && !this.value.vipOnly) {
        return true;
      }

      return false;
    }
  }
};
exports.default = _default2;