var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "partner-describe" }, [
    _c(
      "div",
      {
        staticClass: "partner-name",
        style: {
          color: _vm.element.options.color.partnerName,
          "text-align": _vm.element.options.style !== "tile" ? "center" : "left"
        }
      },
      [_vm._v(_vm._s(_vm.element.content.partnerName))]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        class: [
          _vm.element.options.style !== "tile"
            ? "partner-num"
            : "tile-partner-num"
        ],
        style: { color: _vm.element.options.color.statistics }
      },
      _vm._l(_vm.element.content.parts, function(item, index) {
        return _c("span", { key: item }, [
          _vm._v("\n      " + _vm._s(_vm.getStatistics(item))),
          index < _vm.element.content.parts.length - 1
            ? _c("span", { staticClass: "spot" }, [_vm._v("·")])
            : _vm._e()
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }