"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.commodityList = commodityList;
exports.commodityAlbumList = commodityAlbumList;
exports.commodityVideoList = commodityVideoList;
exports.commodityBookList = commodityBookList;
exports.batchSetPrice = batchSetPrice;
exports.getAllOrderList = getAllOrderList;
exports.getOrderDetailList = getOrderDetailList;
exports.getCommodityDetailList = getCommodityDetailList;
exports.updateExpressNumber = updateExpressNumber;
exports.updatePostInfo = updatePostInfo;
exports.getConsume = getConsume;
exports.getGiftsInfo = getGiftsInfo;
exports.getRefund = getRefund;
exports.getRefundStstus = getRefundStstus;
exports.getCoinCount = getCoinCount;
exports.getGiftsInfoIds = getGiftsInfoIds;
exports.updayeCommodityInfo = updayeCommodityInfo;
exports.getCommodityTemporaryInfo = getCommodityTemporaryInfo;
exports.activationList = activationList;
exports.getUseCodeList = getUseCodeList;
exports.disabledCode = disabledCode;
exports.createCode = createCode;
exports.getTermsData = getTermsData;
exports.getTermsToday = getTermsToday;
exports.getTermsThisYear = getTermsThisYear;
exports.getIncomeRatio = getIncomeRatio;
exports.getRevenueRecords = getRevenueRecords;
exports.getMerchantIncome = getMerchantIncome;
exports.getMerchantChild = getMerchantChild;
exports.getRecordsNotPage = getRecordsNotPage;
exports.getTotal = getTotal;
exports.getMpTotal = getMpTotal;
exports.getVipTotal = getVipTotal;
exports.getadministratorAdd = getadministratorAdd;
exports.updataRemarksinfo = updataRemarksinfo;
exports.invalid = invalid;
exports.recovery = recovery;
exports.transferOrder = transferOrder;
exports.getByResourceOpenIds = getByResourceOpenIds;
exports.updateBatch = updateBatch;
exports.balanceOrderRefund = balanceOrderRefund;
exports.getBalanceOrderRefundStatistics = getBalanceOrderRefundStatistics;
exports.balancerechargerefund = balancerechargerefund;
exports.getBalanceOrderRefundByOpenId = getBalanceOrderRefundByOpenId;
exports.getDownloaddetailedlist = getDownloaddetailedlist;
exports.downloadOrderFormCSV = downloadOrderFormCSV;
exports.exportRevenueRecord = exportRevenueRecord;
exports.exportRevenuerecordReport = exportRevenuerecordReport;
exports.getSalesConversionRate = getSalesConversionRate;
exports.patchOrderDetailList = patchOrderDetailList;
exports.getPayTypeStatistics = getPayTypeStatistics;
exports.getChannelStatistics = getChannelStatistics;
exports.getGradeStatistics = getGradeStatistics;
exports.getSectionStatistics = getSectionStatistics;
exports.getCommoditySnapshot = getCommoditySnapshot;
exports.alterBalanceOrder = alterBalanceOrder;
exports.orderFormPayNotify = orderFormPayNotify;
exports.getGiftsTemporaryInfo = getGiftsTemporaryInfo;
exports.getfinancialCheckOrder = getfinancialCheckOrder;
exports.getfinancialCheckOrderincome = getfinancialCheckOrderincome;
exports.downloadOrderFormCSVnew = downloadOrderFormCSVnew;

var _main = _interopRequireDefault(require("../utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/order/admin/"));
var requestClient = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/order/client/"));
/**
 * 收入记录报表导出
 * @param {String} params
 * @returns {Promise<*>}
 */

function exportRevenuerecordReport(params) {
  return request.get('/export/revenueRecordsStatistics/partnerCSV', {
    params: params
  });
}
/**
 * 销售转化率漏斗图
 * @param {String} params
 * @returns {Promise<*>}
 */


function getSalesConversionRate(params) {
  return request.get('/statistics/getSalesConversionRate', {
    params: params
  });
}
/**
 * 商户收入报表导出
 * @param {String} params
 * @returns {Promise<*>}
 */


function exportRevenueRecord(params) {
  return request.get('/export/revenueRecordsStatistics/csv', {
    params: params
  });
}
/**
 * 订单导出
 * @param {String} params
 * @returns {Promise<*>}
 */


function downloadOrderFormCSV(params) {
  return request.get('/export/orderForm/downloadOrderFormCSV', {
    params: params
  });
}
/**
 * 财务查账订单导出csv(新的异步)
 * @param {String} params
 * @returns {Promise<*>}
 */


function downloadOrderFormCSVnew(params) {
  return request.get('/financialCheckOrder/orderForm/downloadOrderFormCSV', {
    params: params
  });
}
/**
 * 下载详情
 * @param {String} params
 * @returns {Promise<*>}
 */


function getDownloaddetailedlist(params) {
  return request.get('/export/orderForm/withdrawalPreviewReport', {
    params: params
  });
}
/**
 * 根据余额充值订单信息 openId 查询 余额充值订单信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getBalanceOrderRefundByOpenId(params) {
  return request.get('/balanceOrderRefund/getBalanceOrderRefundByOpenId', {
    params: params
  });
}
/**
 * 余额充值退款
 * @param {Object} params
 * @returns {Promise<*>}
 */


function balancerechargerefund(params) {
  return request.get('/balanceOrderRefund/refund', {
    params: params
  });
}
/**
 * 分页查询余额充值
 * @param {Object} params
 * @returns {Promise<*>}
 */


function balanceOrderRefund(params) {
  return request.get('/balanceOrderRefund/getBalanceOrderRefundList', {
    params: params
  });
}
/**
 *余额充值统计
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getBalanceOrderRefundStatistics(params) {
  return request.get('/balanceOrderRefund/getBalanceOrderRefundStatistics', {
    params: params
  });
}
/**
 * 分页查询商品正式信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function commodityList(params) {
  return request.get('/commodity', {
    params: params
  });
}
/**
 * 分页获取专辑商品信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function commodityAlbumList(params) {
  return request.get('/commodity/album/list', {
    params: params
  });
}
/**
 * 查询视频商品信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function commodityVideoList(params) {
  return request.get('/commodity/video/list', {
    params: params
  });
}
/**
 * 获取点学书商品信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function commodityBookList(params) {
  return request.get('/commodity/book/list', {
    params: params
  });
}
/**
 * 批量修改商品价格
 * @param {Object} params
 * @returns {Promise<*>}
 */


function batchSetPrice(params) {
  return request.patch('/commodity/request/batch/price', undefined, {
    params: params
  });
}
/**
 * 分页查询订单列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAllOrderList(params) {
  return request.get('/order_form', {
    params: params
  });
}
/**
 * 财务查账分页查询订单列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getfinancialCheckOrder(params) {
  return request.get('/financialCheckOrder/getOrderList', {
    params: params
  });
}
/**
 * 财务查账查询订单列表时的收入统计
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getfinancialCheckOrderincome(params) {
  return request.get('/financialCheckOrder/income/category/count', {
    params: params
  });
}
/**
 * 查询指定的订单
 * @param {String} openId
 * @returns {Promise<*>}
 */


function getOrderDetailList(openId) {
  return request.get("/order_form/".concat(openId));
}
/**
 * 更新指定的订单
 * @param {String} openId
 * @returns {Promise<*>}
 */


function patchOrderDetailList(openId, data) {
  return request.patch("/order_form/".concat(openId), data);
}
/**
 * 查询指定的商品信息
 * @param {String} openId
 * @returns {Promise<*>}
 */


function getCommodityDetailList(openId) {
  return request.get("/commodity/".concat(openId));
}
/**
 * 查询指定的商品交易快照
 * @param {String} openId
 * @returns {Promise<*>}
 */


function getCommoditySnapshot(openId) {
  return request.get("/order/snapshot/".concat(openId));
}
/**
 * 更新订单的快递单号
 * @param {Object} params
 * @param {String} openId
 * @returns {Promise<*>}
 */


function updateExpressNumber(params, openId) {
  return request.patch("/order_form/postInfo/".concat(openId), undefined, {
    params: params
  });
}
/**
 * 更新邮寄信息 updatepostInfo
 * @param {String} openId
 * @param {Object} params
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updatePostInfo(openId, params, data) {
  return request.patch("/order_form/address/".concat(openId), data, {
    params: params
  });
}
/**
 * 获取用户在指定商家的消费次数
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getConsume(params) {
  return request.get('/order_form/consume/count/', {
    params: params
  });
}
/**
 * 获取多个商品正式信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getGiftsInfo(params) {
  return request.get('/commodity/getByOpenIds', {
    params: params
  });
}
/**
 * 更新退款原因
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getRefund(params) {
  return request.get('/pingPay/refundsAdmin', {
    params: params
  });
}
/**
 * 获取订单正在退款中的退款记录
 * @param {String} orderId
 * @returns {Promise<*>}
 */


function getRefundStstus(orderId) {
  return request.get("/order_refund/getInRefunding/".concat(orderId));
}
/**
 * 订单收入统计 getcashJinxingbi
 * @param {String} params
 * @returns {Promise<*>}
 */


function getCoinCount(params) {
  return request.get('/order_form/income/category/count', {
    params: params
  });
}
/**
 * 获取多个商品正式信息 giftslistget
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function getGiftsInfoIds(openIds) {
  return request.get('/commodity/getByOpenIds', {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 根据 open id 更新商品临时信息
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updayeCommodityInfo(openId, data) {
  return request.patch('/commodity/request', data, {
    params: {
      openId: openId
    }
  });
}
/**
 * 根据 orderId  更新订单信息
 * @param {String} orderId
 * @param {Object} remarks
 * @returns {Promise<*>}
 */


function updataRemarksinfo(orderId, remarks) {
  return request.patch("/order_form/remarks/".concat(orderId), undefined, {
    params: {
      remarks: remarks
    }
  });
}
/**
 * 根据资源的 open id 获取商品临时信息
 * @param {String} openId
 * @returns {Promise<*>}
 */


function getCommodityTemporaryInfo(openId) {
  return request.get("/commodity/request/getByResourceOpenId/".concat(openId));
}
/**
 * 激活卡
 * @param {Object} params
 * @returns {Promise<*>}
 */


function activationList(params) {
  return request.get('/livelyGroup/getLivelyGroupList', {
    params: params
  });
}
/**
 * 使用记录（根据批次ID获取激活卡）
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getUseCodeList(params) {
  return request.get('/lively/getLivelyList', {
    params: params
  });
}
/**
 * 禁用激活卡组
 * @param {Number} id
 * @returns {Promise<*>}
 */


function disabledCode(id) {
  return request.patch("/livelyGroup/disabledActivationCard/".concat(id));
}
/**
 * 生成激活卡
 * @param {Object} data
 * @returns {Promise<*>}
 */


function createCode(data) {
  return request.post('/livelyGroup/generatingActivationCard', data);
}
/**
 * 列表统计
 * @returns {Promise<*>}
 */


function getTermsData() {
  return request.get('/statistics/getTotal');
}
/**
 * 刷新 今日收入总和
 * @returns {Promise<*>}
 */


function getTermsToday() {
  return request.get('/statistics/getTotalIncomeToday');
}
/**
 * 刷新 本年收入总和
 * @returns {Promise<*>}
 */


function getTermsThisYear() {
  return request.get('/statistics/getTotalIncomeThisYear');
}
/**
 * 收入统计（收入比例）
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getIncomeRatio(params) {
  return request.get('/statistics/getStatisticsIncomeProportion', {
    params: params
  });
}
/**
 * 获取支付方式 统计
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getPayTypeStatistics(params) {
  return request.get('/statistics/getPayTypeStatistics', {
    params: params
  });
}
/**
 * 获取产品应用统计
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getChannelStatistics(params) {
  return request.get('/statistics/getChannelStatistics', {
    params: params
  });
}
/**
 * 获取年级支付方式
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getGradeStatistics(params) {
  return request.get('/statistics/getGradeStatistics', {
    params: params
  });
}
/**
 * 获取xueduan支付方式
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getSectionStatistics(params) {
  return request.get('/statistics/getSectionStatistics', {
    params: params
  });
}
/**
 * 商户收入api
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getMerchantIncome(params) {
  return request.get('/statistics/partnerStatistics', {
    params: params
  });
}
/**
 * 商户收入子项详情
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getMerchantChild(params) {
  return request.get('/statistics/getCommodityStatisticsByPartnerOpenId', {
    params: params
  });
}
/**
 * 收入记录(带分页)
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getRevenueRecords(params) {
  return request.get('/statistics/revenueRecordsStatistics', {
    params: params
  });
}
/**
 * 收入记录
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getRecordsNotPage(params) {
  return request.get('/statistics/revenueRecordsStatisticsAll', {
    params: params
  });
}
/**
 * 总量
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getTotal(params) {
  return request.get('/statistics/summation', {
    params: params
  });
}
/**
 * 总量
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getMpTotal(params) {
  return request.get('/statistics/mpSummation', {
    params: params
  });
}
/**
 * 获取所有有效的vip商品列表
 * @returns {Promise<*>}
 */


function getVipTotal() {
  return request.get('/commodity/getVipCommodities');
}
/**
 * 后台新增订单
* @param {Object} data
 * @returns {Promise<*>}
 */


function getadministratorAdd(data) {
  return request.post('/order_form/administrator/add', data);
}
/**
 * 把订单设为失效
 * @param {String} orderId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function invalid(orderId, params) {
  return request.patch("/order_form/invalid/".concat(orderId), undefined, {
    params: params
  });
}
/**
 * 恢复失效的订单
 * @param {String} orderId
 * @returns {Promise<*>}
 */


function recovery(orderId) {
  return request.patch("/order_form/restore/valid/".concat(orderId));
}
/**
 * 转移
 * @param {String} orderId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function transferOrder(orderId, params) {
  console.log(orderId, params);
  return request.patch("/order_form/transfer/".concat(orderId), undefined, {
    params: params
  });
}
/**
 * 获取多个商品正式信息
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function getByResourceOpenIds(resourceOpenIds) {
  return request.get('/commodity/request/getByResourceOpenIds', {
    params: {
      resourceOpenIds: resourceOpenIds
    }
  });
}
/**
 * 更新批量价格
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateBatch(data) {
  return request.patch('/commodity/request/batch/update', data);
}
/**
 * 修改订单记录(内部人员使用)
 *
 * @param {string} balanceOrderId 余额充值订单 open id
 * @returns {Promise<*>}
 */


function alterBalanceOrder() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      balanceOrderId = _ref.balanceOrderId;

  return requestClient.post('ipa/alterBalanceOrder', undefined, {
    params: {
      balanceOrderId: balanceOrderId,
      serviceOpenId: 'sysdef202002062043soqk79cr'
    }
  });
}
/**
 * 支付异步通知 【自己测试用】(补单)
 *
 * @param orderId 【必填】，无默认值，订单的 orderIf
 * @param payPrice 【必填】，无默认值，支付的价格
 * @returns {Promise<*>}
 */


function orderFormPayNotify() {
  var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      orderId = _ref2.orderId,
      payPrice = _ref2.payPrice;

  return request.post('order_form/pay/notify', undefined, {
    params: {
      orderId: orderId,
      payPrice: payPrice,
      chargeId: 'MNM81XG2ZN',
      payOrderNumber: 'MNM81XG2ZN',
      channel: 'applepay_iap',
      isSuccess: true,
      inIosSandbox: false
    }
  });
}
/**
 * 根据 open ids 批量获取商品临时信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getGiftsTemporaryInfo(params) {
  return request.get('/commodity/request/getByResourceOpenIds', {
    params: params
  });
}