"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _common = require("../../../utils/common");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'Countdown',
  props: {
    options: {
      type: Object,
      default: function _default() {}
    },
    value: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      hour: '00',
      minute: '00',
      second: '00',
      timeFn: '',
      overTime: false,
      timeOut: false,
      isNotStart: ''
    };
  },
  computed: {
    bgImg: function bgImg() {
      if (this.options.bgMode === 'default' && this.options.showBgImg) {
        var type = 'big-bg';
        var title = this.value.notStartTitle && this.value.showNotStartTitle;

        if (this.options.style === 'two') {
          type = this.isNotStart === 'noStart' && title ? 'middle-bg' : 'small-bg';
        }

        if (this.options.style === 'one') {
          type = this.isNotStart === 'noStart' && title ? 'big-bg' : 'middle-two';
        }

        return "url(".concat(process.env.VUE_APP_IMG_URL_PREFIX, "production/assets/taozhi/shopMiniProgram/home/cutdown/").concat(this.options.modeType, "/").concat(type, ".png)");
      }

      return this.options.showBgImg && this.options.backgroundImage ? "url(".concat(this.getImgUrl(this.options.backgroundImage), ")") : '';
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function handler(val) {
        var start = val.startTime ? new Date(val.startTime).getTime() : '';
        var end = val.endTime ? new Date(val.endTime).getTime() : '';
        var curTime = new Date().getTime();

        if (end < curTime || !end) {
          this.isNotStart = 'end';
        } else if (start > curTime || !start) {
          this.isNotStart = 'noStart';
        } else {
          this.isNotStart = 'start';
        }
      }
    }
  },
  methods: {
    handTime: function handTime() {
      var _this = this;

      try {
        var end = new Date(this.value.endTime).getTime();
        var start = new Date(this.value.startTime).getTime();
        this.timeData(end, start); // 开始时间>结束时间

        if (end - start <= 0) {
          return;
        }

        if (this.overTime) {
          return;
        }

        this.timeFn = setInterval(function () {
          _this.timeData(end, start);
        }, 1000);
      } catch (e) {
        this.overTime = true;
        this.clearTime();
      }
    },
    timeData: function timeData() {
      var end = new Date(this.value.endTime).getTime();
      var start = new Date(this.value.startTime).getTime();
      var curTime = new Date().getTime();
      var data = start > curTime ? end - start : end - curTime;

      if (this.overTime) {
        return;
      }

      var _filterTime = (0, _common.filterTime)(data / 1000, 'object'),
          h = _filterTime.h,
          m = _filterTime.m,
          s = _filterTime.s;

      console.log(h, m, s, data);
      this.hour = h;
      this.minute = m;
      this.second = s;

      if (data <= 0) {
        this.overTime = true;
        this.clearTime();
      }
    },
    clearTime: function clearTime() {
      clearInterval(this.timeFn);
      this.hour = '00';
      this.minute = '00';
      this.second = '00';
    },
    getImgUrl: function getImgUrl(url, style) {
      return (0, _common.ossUploadUrl)(url, style);
    }
  }
};
exports.default = _default2;