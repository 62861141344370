"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _price = require("../../../utils/CloudProgarm/price");

var _price2 = require("../../../utils/price");

//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'GoodItemInfo',
  props: {
    item: {
      type: Object,
      default: function _default() {}
    },
    showOriginal: {
      type: Boolean,
      default: true
    },
    // 显示原价
    showReality: {
      type: Boolean,
      default: true
    },
    // 显示实际价格
    showCart: {
      type: Boolean,
      default: false
    },
    isAllTitle: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      priceShowByCent: _price2.priceShowByCent,
      priceShow: _price.priceShow,
      getPriceFn: _price.getPriceFn
    };
  },
  computed: {
    getOrginalPrice: function getOrginalPrice() {
      if (!this.item) {
        return '';
      }

      if (this.item.resourceType == 22 || this.item.resourceType == 21) {
        return this.item.orginalPrice > 0 ? this.priceShow(this.item.orginalPrice) : "".concat(this.priceShow(this.item.totalPrice), "\u8D77");
      }

      if (this.item.isSingle || !this.item.isSingle && !this.item.isHasSpecific || !this.item.isSingle && this.item.specificType == 0) {
        return this.priceShow(this.item.orginalPrice);
      }

      return (0, _price.getPriceFn)(this.item);
    }
  },
  methods: {
    getShowOriPrice: function getShowOriPrice(val) {
      if (val.resourceType == 22 || val.resourceType == 21) {
        return false;
      }

      if (val.isSingle || !val.isSingle && !val.isHasSpecific || !val.isSingle && val.specificType == 0) {
        var displayPrice = this.priceShow(val.displayPrice);
        return displayPrice !== this.priceShow(val.orginalPrice, true);
      }

      return false;
    },
    addShopCart: function addShopCart(val) {
      this.$emit('addShopCart', val);
    }
  }
};
exports.default = _default2;