"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _index = _interopRequireDefault(require("../SelectDialogue/index"));

var _index2 = _interopRequireDefault(require("../SelectRuleAndResource/index"));

var _SelectResourceNotRule = _interopRequireDefault(require("../SelectRuleAndResource/SelectResourceNotRule"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'PublicSelectAddContent',
  components: {
    SelectResourceNotRule: _SelectResourceNotRule.default,
    SelectRuleAndResource: _index2.default,
    SelectDialogue: _index.default
  },
  props: {
    value: {
      type: String,
      default: undefined
    },
    type: {
      type: String,
      default: 'live'
    },
    partsData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    ruleData: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    isShowNumber: {
      type: Boolean,
      default: true
    },
    storeName: {
      type: String,
      default: 'micro_resource'
    },
    modelKey: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      proxyNumber: 0,
      openResource: false,
      visible: false,
      resultList: [],
      isShowRuleAdd: true,
      openNotRuleResource: false
    };
  },
  computed: {
    titleName: function titleName() {
      var name = '';

      if (this.type === 'coupon') {
        name = '优惠券';
      }

      if (this.type === 'famous_teachers') {
        name = '导师';
      }

      return name;
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler: function handler(val) {
        this.proxyNumber = val;
      }
    },
    ruleData: {
      deep: true,
      immediate: true,
      handler: function handler() {
        if (this.type === 'types_navigate') {
          this.isShowRuleAdd = this.ruleData.rule === 'custom' ? this.ruleData.content.length <= 0 : !this.ruleData.partsOptions || JSON.stringify(this.ruleData.partsOptions) === '{}';
        } else {
          this.isShowRuleAdd = this.ruleData.rule === 'custom' ? this.ruleData.parts.length <= 0 : !this.ruleData.parts || JSON.stringify(this.ruleData.parts) === '{}';
        }
      }
    },
    proxyNumber: function proxyNumber(val) {
      this.$emit('input', val);
      this.$emit('changeSize');
    }
  },
  methods: {
    restoreDefault: function restoreDefault() {
      this.$emit('restoreDefault');
    },
    restoreRuleDefault: function restoreRuleDefault() {
      this.$emit('restoreDefault', this.ruleData);
    },
    submitDialogue: function submitDialogue(val) {
      this.resultList = val;

      if (['test_paper', 'cloud_retail_goods', 'knowledge_goods', 'ranking_list'].includes(this.type)) {
        this.$emit('submitRuleDialogue', val);
      } else {
        this.$emit('submitDialogue', val);
      }

      if (this.type !== 'cloud_retail_goods') {
        this.visible = false;
      }
    },
    // 选择作品
    selectResourceSubmit: function selectResourceSubmit(val, rule) {
      if (this.type === 'types_navigate' || this.type === 'knowledge_goods' || this.type === 'ranking_list' || this.type === 'cloud_retail_goods') {
        if (rule === 'custom') {
          this.resultList = val;
        }

        this.$emit('submitRuleDialogue', val, this.ruleData, rule);
      } else {
        this.resultList = val;
        this.$emit('submitDialogue', val);
      }

      if (this.type !== 'cloud_retail_goods') {
        this.openResource = false;
        this.openNotRuleResource = false;
      }
    },
    addContent: function addContent() {
      if (this.type === 'knowledge_goods' || this.type === 'ranking_list' || this.type === 'types_navigate' || this.type === 'cloud_retail_goods') {
        this.openResource = true;
      } else if (this.type === 'micro_audio' || this.type === 'micro_video') {
        this.openNotRuleResource = true;
      } else {
        this.visible = true;
      }
    }
  }
};
exports.default = _default2;