"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CPMGeSearchButtonIndex',
  data: function data() {
    return {
      customizeSearch: false
    };
  },
  methods: {
    downUpHandler: function downUpHandler() {
      this.customizeSearch = !this.customizeSearch;
    },
    searchChanged: function searchChanged() {
      this.$emit('search-changed');
    }
  }
};
exports.default = _default;