var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "book"
    },
    [
      _c("search", {
        attrs: {
          "select-data": _vm.select,
          "is-show-select": true,
          "book-query": _vm.query
        },
        on: {
          searchBook: _vm.handleSearchBook,
          searchResource: _vm.searchResource
        },
        model: {
          value: _vm.searchParams,
          callback: function($$v) {
            _vm.searchParams = $$v
          },
          expression: "searchParams"
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "book-content" }, [
        _vm.select === "book"
          ? _c(
              "div",
              { staticClass: "book-list" },
              _vm._l(_vm.list, function(item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "book-item flex-column",
                    on: {
                      click: function($event) {
                        return _vm.checkClick(item, "book")
                      }
                    }
                  },
                  [
                    _c("el-image", {
                      staticClass: "book-cover",
                      attrs: { fit: "cover", src: _vm.getImgUrl(item) }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "book-name hidden-line" }, [
                      _vm._v(_vm._s(item.name))
                    ]),
                    _vm._v(" "),
                    _vm.checkList.includes(item.bookOpenId)
                      ? _c("div", { staticClass: "delete book-cover" }, [
                          _c("img", {
                            staticClass: "delete-img",
                            attrs: {
                              src: require("@/assets/microPage/dui.png")
                            }
                          })
                        ])
                      : _vm._e()
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.select === "audioBook"
          ? _c(
              "div",
              { staticClass: "book-list" },
              _vm._l(_vm.audioBookList, function(item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "book-item flex-column",
                    on: {
                      click: function($event) {
                        return _vm.checkClick(item, "audioBook")
                      }
                    }
                  },
                  [
                    _c("el-image", {
                      staticClass: "book-cover",
                      attrs: { fit: "cover", src: _vm.getImgUrl(item) }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "book-name hidden-line" }, [
                      _vm._v(_vm._s(item.title))
                    ]),
                    _vm._v(" "),
                    _vm.checkAudioBookList.includes(item.resourceOpenId)
                      ? _c("div", { staticClass: "delete book-cover" }, [
                          _c("img", {
                            staticClass: "delete-img",
                            attrs: {
                              src: require("@/assets/microPage/dui.png")
                            }
                          })
                        ])
                      : _vm._e()
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.select === "ebook"
          ? _c(
              "div",
              { staticClass: "book-list" },
              _vm._l(_vm.ebookList, function(item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "book-item flex-column",
                    style: { "margin-top": item.coverType === 1 ? "77px" : "" },
                    on: {
                      click: function($event) {
                        return _vm.checkClick(item, "ebook")
                      }
                    }
                  },
                  [
                    _c("el-image", {
                      staticClass: "book-cover",
                      style: { height: item.coverType === 1 ? "77px" : "" },
                      attrs: { fit: "cover", src: _vm.getImgUrl(item) }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "book-name hidden-line" }, [
                      _vm._v(_vm._s(item.title))
                    ]),
                    _vm._v(" "),
                    _vm.checkEbookList.includes(item.openId)
                      ? _c(
                          "div",
                          {
                            staticClass: "delete book-cover",
                            style: {
                              height: item.coverType === 1 ? "77px" : ""
                            }
                          },
                          [
                            _c("img", {
                              staticClass: "delete-img",
                              attrs: {
                                src: require("@/assets/microPage/dui.png")
                              }
                            })
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.select === "ebookCase"
          ? _c(
              "div",
              { staticClass: "book-list" },
              _vm._l(_vm.ebookCase, function(item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "book-item flex-column",
                    style: { "margin-top": item.coverType === 1 ? "77px" : "" },
                    on: {
                      click: function($event) {
                        return _vm.checkClick(item, "ebookCase")
                      }
                    }
                  },
                  [
                    _c("el-image", {
                      staticClass: "book-cover",
                      style: { height: item.coverType === 1 ? "77px" : "" },
                      attrs: { fit: "cover", src: _vm.getImgUrl(item) }
                    }),
                    _vm._v(" "),
                    item.coverReferenceType === 2
                      ? _c(
                          "div",
                          {
                            staticClass: "ebookcase-position-title book-cover",
                            style: {
                              height: item.coverType === 1 ? "77px" : ""
                            }
                          },
                          [_vm._v(_vm._s(item.title))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", { staticClass: "book-name hidden-line" }, [
                      _vm._v(_vm._s(item.title))
                    ]),
                    _vm._v(" "),
                    _vm.checkEbookCaseList.includes(item.openId)
                      ? _c(
                          "div",
                          {
                            staticClass: "delete book-cover",
                            style: {
                              height: item.coverType === 1 ? "77px" : ""
                            }
                          },
                          [
                            _c("img", {
                              staticClass: "delete-img",
                              attrs: {
                                src: require("@/assets/microPage/dui.png")
                              }
                            })
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.select === "book"
        ? _c("table-footer", {
            attrs: {
              "footer-check-all": _vm.checkAll,
              query: _vm.query,
              "total-elements": _vm.totalElements
            },
            on: {
              pagination: _vm.getBookList,
              handleListCheckAll: _vm.handleListCheckAll
            }
          })
        : _c("table-footer", {
            attrs: {
              "footer-check-all": _vm.checkAll,
              query: _vm.searchParams,
              "total-elements": _vm.totalElements
            },
            on: {
              pagination: function($event) {
                return _vm.searchResource(_vm.select)
              },
              handleListCheckAll: _vm.handleListCheckAll
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }