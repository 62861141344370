"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _Editor = _interopRequireDefault(require("../../organizationLibs/editor/components/Editor"));

var _utils = require("../../utils");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 这个不知道为什么 用el-form 和富文本两个同时用 点击保存 第一遍不触发保存方法 需要点击两次
// 富文本换成el-input就不会
var _default2 = {
  name: 'WordCustomDialog',
  components: {
    Editor: _Editor.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    // const validatorText = (rule, value, callback) => {
    //   if (!this.ruleForm.content) {
    //     return callback(new Error('请填写内容'));
    //   }
    //   callback();
    // };
    return {
      ruleForm: {
        name: undefined,
        content: ''
      },
      // rules: {
      //   name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
      //   // content: [{ required: true, validator: validatorText, trigger: ['blur', 'change'] }],
      // },
      content: undefined
    };
  },
  watch: {
    editData: {
      immediate: true,
      handler: function handler(val) {
        if (val && JSON.stringify(val) !== '{}') {
          this.ruleForm.name = val.name;
          this.ruleForm.content = val.content;
          this.ruleForm.label = val.label;
        }
      }
    }
  },
  methods: {
    submit: function submit() {
      try {
        this.ruleForm.name = this.ruleForm.name ? this.ruleForm.name.trim() : undefined;
        this.ruleForm.content = this.$refs.editor.getContent();

        if (!this.ruleForm.name || !this.ruleForm.content) {
          return this.$notify.info({
            title: '提示',
            message: '请填写完整信息'
          });
        }

        this.ruleForm.label = this.ruleForm.label ? this.ruleForm.label : (0, _utils.createUniqueString)();
        this.$emit('submit', this.ruleForm, JSON.stringify(this.editData) !== '{}');
        this.cancel();
      } catch (e) {
        console.log(e);
      }
    },
    cancel: function cancel() {
      // 清空
      this.$emit('update:visible', false);
      this.ruleForm.name = undefined;
      this.ruleForm.content = '';
      this.ruleForm.label = undefined;
    }
  }
};
exports.default = _default2;