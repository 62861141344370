"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  tableData: [{
    name: '字义',
    label: 'wordMean'
  }, {
    name: '组词',
    label: 'groupWords'
  }, {
    name: '造句',
    label: 'sentenceMake'
  }, {
    name: '书写',
    label: 'writeNotes'
  }, {
    name: '字源',
    label: 'etymology'
  }, {
    name: '巧记',
    label: 'ingeniousNotes'
  }, {
    name: '笔顺',
    label: 'chineseCharBishun'
  }, {
    name: '字迷',
    label: 'charRiddle'
  }, {
    name: '字歌',
    label: 'charSong'
  }, {
    name: '易错提示',
    label: 'errorPronePrompt'
  }, {
    name: '形近字',
    label: 'nearCharList'
  }, {
    name: '字族',
    label: 'charFamily'
  }],
  list: ['wordMean', 'groupWords', 'sentenceMake', 'writeNotes', 'etymology', 'ingeniousNotes', 'chineseCharBishun', 'charRiddle', 'charSong', 'errorPronePrompt', 'nearCharList', 'charFamily'],
  polyphonicPrivate: [{
    name: '字义',
    label: 'wordMean'
  }, {
    name: '组词',
    label: 'groupWords'
  }, {
    name: '造句',
    label: 'sentenceMake'
  }],
  polyphonicCommon: [{
    name: '书写',
    label: 'writeNotes'
  }, {
    name: '字源',
    label: 'etymology'
  }, {
    name: '巧记',
    label: 'ingeniousNotes'
  }, {
    name: '笔顺',
    label: 'chineseCharBishun'
  }, {
    name: '字迷',
    label: 'charRiddle'
  }, {
    name: '字歌',
    label: 'charSong'
  }, {
    name: '易错提示',
    label: 'errorPronePrompt'
  }, {
    name: '形近字',
    label: 'nearCharList'
  }, {
    name: '字族',
    label: 'charFamily'
  }],
  polyphonicPriList: ['wordMean', 'groupWords', 'sentenceMake'],
  polyphonicComList: ['writeNotes', 'etymology', 'ingeniousNotes', 'chineseCharBishun', 'charRiddle', 'charSong', 'errorPronePrompt', 'nearCharList', 'charFamily'],
  termsData: [{
    name: '同义词',
    label: 'synonymList'
  }, {
    name: '近义词',
    label: 'nearList'
  }, {
    name: '反义词',
    label: 'antonymList'
  }, {
    name: '释义',
    label: 'interpretation'
  }, {
    name: '例句',
    label: 'exampleSentence'
  }, {
    name: '典故',
    label: 'anecdote'
  }, {
    name: '配图',
    label: 'pictures'
  }],
  termList: ['synonymList', 'nearList', 'antonymList', 'interpretation', 'exampleSentence', 'pictures', 'anecdote']
};
exports.default = _default;