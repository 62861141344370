"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _common = require("../../../utils/common");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'PaperRecommend',
  props: {
    options: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    itemData: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    recommendBakImg: {
      type: String,
      default: undefined
    },
    showBakImg: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    difficultDegree: function difficultDegree() {
      if (!this.itemData.difficultDegree) {
        return 0;
      }

      return parseInt(this.itemData.difficultDegree, 0);
    }
  },
  methods: {
    // 处理图片
    getImgUrl: function getImgUrl(cover) {
      var style = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'style/width-limit-640';

      if (cover) {
        if (cover.substring(0, 4) === 'http') {
          return cover;
        }
      }

      var imgAllUrl = (0, _common.imageUrlFun)(cover, style);
      return imgAllUrl;
    }
  }
};
exports.default = _default2;