"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFollowBookList = getFollowBookList;
exports.getFollowBookResourceList = getFollowBookResourceList;

var _main = _interopRequireDefault(require("../utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/english-conversation/admin/"));
var requestMp = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/file-management/mp/filePackage/"));
/**
 * 获取英语会话列表
 * @param {Object} params
 * @returns {Promise<*>}
 */

function getFollowBookList(params) {
  return request.get('/conversation/request/list', {
    params: params
  });
}
/**
 * 获取英语会话列表(用于商品)
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getFollowBookResourceList(params) {
  return request.get('/conversation/order/list', {
    params: params
  });
}