var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "micro-audio" }, [
    _c("div", {
      directives: [
        { name: "show", rawName: "v-show", value: false, expression: "false" }
      ],
      attrs: { id: "microAudio" + _vm.model }
    }),
    _vm._v(" "),
    _vm.options.style === "column"
      ? _c(
          "div",
          _vm._l(_vm.list, function(itemData, index) {
            return _c("div", { key: index }, [
              index < _vm.size
                ? _c(
                    "div",
                    { staticClass: "audio-column" },
                    [
                      itemData.cover
                        ? _c("el-image", {
                            staticClass: "column-cover",
                            attrs: {
                              fit: "cover",
                              src: _vm.getImgUrl(
                                itemData.cover,
                                "style/width-limit-128"
                              )
                            }
                          })
                        : _c("img", {
                            staticClass: "column-cover",
                            attrs: {
                              src: require("@/assets/cover/cover-audio.png")
                            }
                          }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex-row align-center column-info" },
                        [
                          _c(
                            "div",
                            { staticClass: "flex-column column-title-time" },
                            [
                              _c(
                                "span",
                                { staticClass: "title hidden-one-line" },
                                [_vm._v(_vm._s(itemData.title))]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "time-download" },
                                [
                                  _c("el-image", {
                                    staticClass: "timer",
                                    attrs: {
                                      src: _vm.getImgUrl(
                                        _vm.imgPath + "timer.png",
                                        "style/width-limit-80",
                                        true
                                      )
                                    }
                                  }),
                                  _vm._v(
                                    _vm._s(
                                      _vm.getAudioTime(itemData.duration)
                                    ) + "\n                "
                                  ),
                                  _vm.options.downLoad && itemData.isDownload
                                    ? _c("el-image", {
                                        staticClass: "timer download",
                                        attrs: {
                                          src: _vm.getImgUrl(
                                            _vm.imgPath + "download-normal.png",
                                            "style/width-limit-80",
                                            true
                                          )
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.options.downLoad && itemData.isDownload
                                    ? _c("span", [_vm._v("下载")])
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          ),
                          _vm._v(" "),
                          !itemData.isPlay
                            ? _c(
                                "div",
                                {
                                  staticClass: "play-btn",
                                  on: {
                                    click: function($event) {
                                      return _vm.playAudio(itemData, index)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    staticClass: "play-btn-img",
                                    attrs: {
                                      src: require("@/assets/audio/album_playIcon.png")
                                    }
                                  })
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass: "play-view",
                                  on: {
                                    click: function($event) {
                                      return _vm.pauseAudio(itemData, index)
                                    }
                                  }
                                },
                                [
                                  _c("el-progress", {
                                    attrs: {
                                      type: "circle",
                                      percentage: _vm.audioInfo.cricleProgress,
                                      width: 33,
                                      "stroke-width": 3,
                                      "show-text": false
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("img", {
                                    staticClass: "pause-btn",
                                    attrs: {
                                      src: require("@/assets/audio/globalStopIcon.png")
                                    }
                                  })
                                ],
                                1
                              )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ])
          }),
          0
        )
      : _c("div", { staticClass: "flex-column audio-line" }, [
          _c(
            "div",
            { staticClass: "first-audio flex-row align-center" },
            [
              _vm.list[_vm.currentIndex].cover
                ? _c("el-image", {
                    staticClass: "line-cover",
                    attrs: {
                      fit: "cover",
                      src: _vm.getImgUrl(
                        _vm.list[_vm.currentIndex].cover,
                        "style/width-limit-128"
                      )
                    }
                  })
                : _c("img", {
                    staticClass: "line-cover",
                    attrs: { src: require("@/assets/cover/cover-audio.png") }
                  }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "line" },
                [
                  _c("div", { staticClass: "line-title hidden-one-line" }, [
                    _vm._v(_vm._s(_vm.list[_vm.currentIndex].title))
                  ]),
                  _vm._v(" "),
                  _c("el-slider", {
                    attrs: {
                      size: "mine",
                      max: _vm.list[_vm.currentIndex].duration
                    },
                    on: { change: _vm.handleSeek },
                    model: {
                      value: _vm.audioInfo.progress,
                      callback: function($$v) {
                        _vm.$set(_vm.audioInfo, "progress", $$v)
                      },
                      expression: "audioInfo.progress"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "line-play flex-row flex-space-between align-center"
                    },
                    [
                      _c("el-image", {
                        staticClass: "common-img-icon",
                        attrs: {
                          src: _vm.isLoop
                            ? _vm.getImgUrl(
                                _vm.imgPath + "loop.png",
                                "style/width-limit-80",
                                true
                              )
                            : _vm.getImgUrl(
                                _vm.imgPath + "no-loop.png",
                                "style/width-limit-80",
                                true
                              )
                        },
                        on: {
                          click: function($event) {
                            _vm.isLoop = !_vm.isLoop
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "el-image",
                        {
                          staticClass: "common-img-icon",
                          attrs: {
                            src: _vm.getImgUrl(
                              _vm.imgPath + "back.png",
                              "style/width-limit-80",
                              true
                            )
                          },
                          on: {
                            click: function($event) {
                              return _vm.backAudio(_vm.currentIndex)
                            }
                          }
                        },
                        [_vm._v(">")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "play-btn",
                          on: {
                            click: function($event) {
                              return _vm.handlePauseOrPlay(
                                _vm.list[_vm.currentIndex],
                                _vm.currentIndex
                              )
                            }
                          }
                        },
                        [
                          _vm.list[_vm.currentIndex].isPlay
                            ? _c("img", {
                                staticClass: "play-btn-img",
                                attrs: {
                                  src: require("@/assets/audio/album_stopIcon.png")
                                }
                              })
                            : _c("img", {
                                staticClass: "play-btn-img",
                                attrs: {
                                  src: require("@/assets/audio/album_playIcon.png")
                                }
                              })
                        ]
                      ),
                      _vm._v(" "),
                      _c("el-image", {
                        staticClass: "common-img-icon",
                        attrs: {
                          src: _vm.getImgUrl(
                            _vm.imgPath + "next.png",
                            "style/width-limit-80",
                            true
                          )
                        },
                        on: {
                          click: function($event) {
                            return _vm.nextAudio(_vm.currentIndex)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.options.downLoad &&
                      _vm.list[_vm.currentIndex].isDownload
                        ? _c("el-image", {
                            staticClass: "common-img-icon",
                            attrs: {
                              src: _vm.getImgUrl(
                                _vm.imgPath + "download.png",
                                "style/width-limit-80",
                                true
                              )
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.size > 1 && _vm.list.length > 1
            ? _c(
                "div",
                { staticClass: "scroll-audio" },
                _vm._l(_vm.list, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      on: {
                        click: function($event) {
                          return _vm.playAudio(item, index)
                        }
                      }
                    },
                    [
                      index < _vm.size
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex-row align-center flex-space-between scroll-item",
                              style: {
                                "border-bottom":
                                  index >= _vm.size - 1 ||
                                  index >= _vm.list.length - 1
                                    ? "none"
                                    : "1px solid #E5E5E5",
                                color:
                                  _vm.currentIndex === index
                                    ? "#00A2FF"
                                    : "#668190"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex-row align-center scroll-info"
                                },
                                [
                                  _c("el-image", {
                                    staticClass: "common-img-icon",
                                    attrs: {
                                      src:
                                        _vm.currentIndex === index
                                          ? _vm.getImgUrl(
                                              _vm.imgPath +
                                                "audio-active-logo.png",
                                              "style/width-limit-80",
                                              true
                                            )
                                          : _vm.getImgUrl(
                                              _vm.imgPath + "audio-logo.png",
                                              "style/width-limit-80",
                                              true
                                            )
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "scroll-title",
                                      style: {
                                        width:
                                          _vm.options.downLoad &&
                                          item.isDownload
                                            ? "170px"
                                            : "210px"
                                      }
                                    },
                                    [_vm._v(_vm._s(item.title))]
                                  )
                                ],
                                1
                              ),
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.getAudioTime(item.duration)) +
                                  "\n            "
                              ),
                              _vm._v(" "),
                              _vm.options.downLoad && item.isDownload
                                ? _c("el-image", {
                                    staticClass: "common-img-icon",
                                    attrs: {
                                      src:
                                        _vm.currentIndex === index
                                          ? _vm.getImgUrl(
                                              _vm.imgPath + "download.png",
                                              "style/width-limit-80",
                                              true
                                            )
                                          : _vm.getImgUrl(
                                              _vm.imgPath +
                                                "line-normal-download.png",
                                              "style/width-limit-80",
                                              true
                                            )
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                }),
                0
              )
            : _vm._e()
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }