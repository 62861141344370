"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCurrentUrl = getCurrentUrl;
exports.getCurrentDomain = getCurrentDomain;
exports.isEffectiveDomain = isEffectiveDomain;
exports.getSsoUrl = getSsoUrl;
exports.toLoginPage = toLoginPage;

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

require("core-js/modules/es6.regexp.split");

var _url = _interopRequireDefault(require("url"));

var _qs = _interopRequireDefault(require("qs"));

var _main = _interopRequireDefault(require("../../typeIs/main"));

var _config = require("../config");

var _auth = require("../auth");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/**
 * 获取当前页面的 url
 *
 * @returns {string|undefined}
 */
function getCurrentUrl() {
  try {
    return window.location.href;
  } catch (e) {
    console.error(e);
    return undefined;
  }
}
/**
 * 获取当前页面的 domain
 *
 * @returns {string|undefined}
 */


function getCurrentDomain() {
  try {
    if (!_main.default.isString(window.location.hostname) && _main.default.isEmpty(window.location.hostname)) {
      return undefined;
    } // 将 hostname 拆分成数组


    var hostList = window.location.hostname.trim().split('.'); // 取出数组中最后两位

    var separator = hostList.length > 1 ? hostList.length - 2 : 0;
    var domain = hostList.slice(separator).join('.'); // 返回 domain

    return domain.length > 0 ? ".".concat(domain) : domain;
  } catch (e) {
    console.error(e);
    return undefined;
  }
}
/**
 * 获取当前页面的 domain 是否符合 sso 的设置
 *
 * @returns {boolean}
 */


function isEffectiveDomain() {
  try {
    return (0, _config.getConfig)().domain === getCurrentDomain();
  } catch (e) {
    console.error(e);
    return false;
  }
}
/**
 * 生成 back url 地址
 *
 * @returns {string}
 */


function generateBackUrl() {
  try {
    // 解析 url
    var _nodeUrl$parse = _url.default.parse(window.location.href),
        protocol = _nodeUrl$parse.protocol,
        host = _nodeUrl$parse.host,
        queryString = _nodeUrl$parse.query,
        pathname = _nodeUrl$parse.pathname; // 产生新的 query 参数


    var backRouter = JSON.stringify({
      name: 'Home'
    });

    var query = _qs.default.stringify(_objectSpread(_objectSpread({}, _qs.default.parse(queryString)), {}, {
      back_router: backRouter
    })); // 产生跳转的地址


    return "".concat(protocol, "//").concat(host).concat(pathname, "?").concat(query);
  } catch (e) {
    return window.location.href;
  }
}
/**
 * 获取 sso 的登录地址
 * @returns {string} sso 登录地址
 */


function getSsoUrl() {
  try {
    var query = _qs.default.stringify({
      back_url: generateBackUrl(),
      register_from: (0, _config.getConfig)().registerFrom,
      login_type: 'admin'
    });

    return "".concat((0, _config.getConfig)().ssoUrl, "?").concat(query);
  } catch (e) {
    return undefined;
  }
}
/**
 * 将用户引导到登录页面
 */


function toLoginPage() {
  (0, _auth.clearLoginStatus)();
  window.location.href = getSsoUrl();
}