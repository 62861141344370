"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _index = _interopRequireDefault(require("../../../Pagination/index"));

//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'TableFooter',
  components: {
    Pagination: _index.default
  },
  props: {
    query: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    totalElements: {
      type: Number,
      default: 0
    },
    footerCheckAll: {
      type: Boolean,
      default: false
    },
    isNotPage: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      indeterminate: false,
      checkAll: false
    };
  },
  watch: {
    footerCheckAll: {
      deep: true,
      immediate: true,
      handler: function handler(val) {
        this.checkAll = val;
      }
    }
  },
  methods: {
    handleCheckAllChange: function handleCheckAllChange(val) {
      this.$emit('handleListCheckAll', val);

      if (val) {
        this.checkAll = true;
        this.indeterminate = false;
      } else {
        this.checkAll = false;
        this.indeterminate = false;
      }
    },
    pagination: function pagination() {
      this.$emit('pagination');
    }
  }
};
exports.default = _default2;