"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAllMp = getAllMp;
exports.getRequestMpByPartnerOpenId = getRequestMpByPartnerOpenId;
exports.getByPartnerOpenId = getByPartnerOpenId;
exports.adminChangePartnerInfo = adminChangePartnerInfo;
exports.getRequestFinance = getRequestFinance;
exports.getFinance = getFinance;
exports.getPartnerTitle = getPartnerTitle;
exports.mpCategory = mpCategory;
exports.getPartner = getPartner;
exports.getByResourcesId = getByResourcesId;
exports.selectResourceSync = selectResourceSync;
exports.partnerListForXiaoYan = partnerListForXiaoYan;
exports.getPartners = getPartners;
exports.getRequestMp = getRequestMp;
exports.getAuthPartnerList = getAuthPartnerList;
exports.getparternerListByuserid = getparternerListByuserid;
exports.getfindAllPartnerPrivilege = getfindAllPartnerPrivilege;
exports.savePartnerPrivilege = savePartnerPrivilege;
exports.addPartner = addPartner;
exports.passInitialCheck = passInitialCheck;
exports.rejectInitialCheck = rejectInitialCheck;
exports.softDeletePartner = softDeletePartner;
exports.replyPar = replyPar;
exports.getStorePartners = getStorePartners;
exports.getBookActivityPage = getBookActivityPage;

var _main = _interopRequireDefault(require("../utils/request/main"));

var _store = _interopRequireDefault(require("../store"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/mp/admin"));
var requestMp = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/mp/mp"));
var requestClient = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/mp/client"));
/**
 * 根据 partnerOpenId 恢复商户
 * @param {String} partnerOpenId
 * @returns {Promise<*>}
 */

function replyPar(partnerOpenId) {
  return request.get("/partner/request/reply/".concat(partnerOpenId));
}
/**
 * 根据 partnerOpenId 软删除商户
 * @param {String} partnerOpenId
 * @returns {Promise<*>}
 */


function softDeletePartner(partnerOpenId) {
  return request.delete("/partner/request/".concat(partnerOpenId));
}
/**
 * 根据 partner open id预审核驳回
 * @param {String} partnerOpenId
 * @returns {Promise<*>}
 */


function rejectInitialCheck(openId) {
  return request.patch("/partner/request/turnDownInitialCheck/".concat(openId));
}
/**
 * 根据 partner open id预审核通过
 * @param {String} partnerOpenId
 * @returns {Promise<*>}
 */


function passInitialCheck(openId) {
  return request.patch("/partner/request/passInitialCheck/".concat(openId));
}
/**
 * 保存商户权限
 * @param {Number} id
 * @returns {Promise<*>}
 */


function savePartnerPrivilege(params, data) {
  return request.post('/partnerPrivilege/store', data, {
    params: params
  });
}
/**
 * 以目录树的形式返回所有的权限菜单商户权限(新增)
 * @param {String} params
 * @returns {Promise<*>}
 */


function getfindAllPartnerPrivilege(params) {
  return request.get('/partnerPrivilege/findAllPartnerPrivilege', {
    params: params
  });
}
/**
 * 根据userOpenId获取商户列表
 * @param {String} params
 * @returns {Promise<*>}
 */


function getparternerListByuserid(params) {
  return request.get('/partner/request/getPartners', {
    params: params
  });
}
/**
 * 分页查询商户临时信息
 * @param {Number} params
 * @returns {Promise<*>}
 */


function getAllMp(params) {
  return request.get('/partner/request', {
    params: params
  });
}
/**
 * 根据 openId 获取指定的商户临时信息
 * @param {String} openId
 * @returns {Promise<*>}
 */


function getRequestMpByPartnerOpenId(openId) {
  return request.get("/partner/request/".concat(openId));
}
/**
 * 通过用户的 openId 获取商户正式信息
 * @param {String} openId
 * @returns {Promise<*>}
 */


function getRequestMp(openId) {
  return request.get("/partner/getByUserOpenId/".concat(openId));
}
/**
 * 获取指定的商户正式信息
 * @param {String} openId
 * @returns {Promise<*>}
 */


function getByPartnerOpenId(openId) {
  return request.get("/partner/".concat(openId));
}
/**
 * 更新商户临时信息，不需要审核
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function adminChangePartnerInfo(openId, data) {
  return request.patch('/partner/request/', data, {
    params: {
      openId: openId
    }
  });
}
/**
 * 新建商户
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addPartner(data) {
  return request.post('/partner/request/', data);
}
/**
 * 获取指定的财务临时信息
 * @param {String} openId
 * @returns {Promise<*>}
 */


function getRequestFinance(openId) {
  return request.get("/finance/request/".concat(openId));
}
/**
 * 获取指定的商户财务正式信息
 * @param {String} openId
 * @returns {Promise<*>}
 */


function getFinance(openId) {
  return request.get("/finance/".concat(openId));
}
/**
 * 根据多个用户的 open id 查询商户临时信息
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function getPartnerTitle(openIds) {
  return request.get('/partner/request/getByUserOpenIds', {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 根据资源类型和商户的 openId 获取分类列表
 * @param {Number} id
 * @param {Object} params
 * @returns {Promise<*>}
 */


function mpCategory(id, params) {
  if (_store.default.state.microPage.isAdmin) {
    return request.get("/category/resourceId/".concat(id), {
      params: params
    });
  }

  return requestMp.get("/category/resourceId/".concat(id), {
    params: params
  });
}
/**
 * 获取指定的商户正式信息
 * @param {String} openId
 * @returns {Promise<*>}
 */


function getPartner(openId) {
  return request.get("/partner/".concat(openId));
}
/**
 * 根据资源类型和商户的 openId 获取分类列表
 * @param {Number} id
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getByResourcesId(id, params) {
  return request.get("/category/resourceId/".concat(id), {
    params: params
  });
}
/**
 * 分页查询指定商户的 教辅同步信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function selectResourceSync(params) {
  return request.get('/resourceSync/partnerOpenId', {
    params: params
  });
}
/**
 * 分页查询商户临时信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function partnerListForXiaoYan(params) {
  return request.get('/partner/request/forXiaoYan', {
    params: params
  });
}
/**
 * 商户正式
 * @param {String} params
 * @returns {Promise<*>}
 */


function getAuthPartnerList(params) {
  return request.get('/partner/', {
    params: params
  });
}
/**
 * 批量查询商户信息
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function getPartners(openIds) {
  return request.get('/partner/request/getPartnerByOpenIds/forMap', {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 获取云零售店铺信息
 * @returns {Promise<*>}
 * @param params
 */


function getStorePartners(params) {
  return requestMp.get('/retail/store/base', {
    params: params
  });
}
/**
 * 获取云零售领书活动
 * @returns {Promise<*>}
 * @param params
 */


function getBookActivityPage(params) {
  return requestClient.get('/bookActivity/show/list', {
    params: params
  });
}