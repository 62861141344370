"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _navigateButtonList = _interopRequireDefault(require("../../assets/json/navigateButtonList"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'MicroPageNavigation',
  props: {
    navCheckList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    showNavigateBackground: {
      type: Boolean,
      default: false
    },
    navigateFontAndIconColor: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      navList: [],
      navigateButtonLists: _navigateButtonList.default.navigateButtonLists,
      navigateDrop: undefined,
      navigateBackground: 'nav-icon-margin',
      showMore: false
    };
  },
  computed: {
    iconFontSize: function iconFontSize() {
      return {
        'font-size': this.showNavigateBackground ? '20px' : '16px'
      };
    }
  },
  watch: {
    navCheckList: {
      deep: true,
      immediate: true,
      handler: function handler(val) {
        this.getNavList(val);
      }
    },
    showNavigateBackground: {
      deep: true,
      immediate: true,
      handler: function handler(val) {
        this.navigateBackground = val ? 'nav-icon-margin' : 'navigate-background';

        if (!val) {
          this.navigateDrop = {
            'background-color': 'rgba(0,0,0, .8)',
            color: '#FFFFFF'
          };
        } else {
          this.navigateDrop = {
            'background-color': '#FFFFFF',
            color: '#000000'
          };
        }
      }
    }
  },
  mounted: function mounted() {
    var _this = this;

    document.addEventListener('click', function (e) {
      var more = document.getElementById('moreList');

      if (more && !more.contains(e.target)) {
        _this.showMore = false;
      }
    });
  },
  methods: {
    handleMore: function handleMore() {
      console.log(1111);
      this.showMore = !this.showMore;
    },
    getNavList: function getNavList(val) {
      var _this2 = this;

      this.navList = [];

      if (val.length <= 0) {
        return;
      }

      val.forEach(function (item) {
        _this2.navigateButtonLists.forEach(function (n) {
          if (item === n.title) {
            _this2.navList.push(n);
          }
        });
      });
    }
  }
};
exports.default = _default2;