var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "audio-detail" },
    [
      _c("div", { staticClass: "audio" }, [
        _c(
          "div",
          { staticClass: "audio-info" },
          [
            _c("el-image", {
              staticClass: "cover",
              attrs: {
                src: _vm.getImageUrl(
                  _vm.bookData.cover,
                  "style/width-limit-80"
                ),
                fit: "cover"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "title-info" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.bookData.title))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "info" }, [
                _vm._v(_vm._s(_vm.getResourceInfo(_vm.bookData)))
              ])
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "check-back" },
          [
            _c(
              "el-checkbox",
              {
                staticClass: "check-text",
                on: { change: _vm.handleCheck },
                model: {
                  value: _vm.checked,
                  callback: function($$v) {
                    _vm.checked = $$v
                  },
                  expression: "checked"
                }
              },
              [_vm._v("记住当前图书")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "separate" }),
            _vm._v(" "),
            _c("div", { staticClass: "back", on: { click: _vm.goBack } }, [
              _c("i", { staticClass: "iconfont icon-fanhui1 icon" }),
              _vm._v("图书列表")
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "audio-content"
        },
        [
          _c(
            "div",
            { staticClass: "search" },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "small" },
                  on: { change: _vm.handleListType },
                  model: {
                    value: _vm.listType,
                    callback: function($$v) {
                      _vm.listType = $$v
                    },
                    expression: "listType"
                  }
                },
                [
                  _c("el-radio-button", { attrs: { label: "全部" } }),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "目录页" } })
                ],
                1
              ),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "search-input",
                attrs: {
                  size: "small",
                  placeholder: "请输入页码",
                  clearable: ""
                },
                model: {
                  value: _vm.searchVal,
                  callback: function($$v) {
                    _vm.searchVal = $$v
                  },
                  expression: "searchVal"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.list.length > 0
            ? _c(
                "div",
                { staticClass: "audio-list" },
                _vm._l(_vm.list, function(item) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      staticClass: "audio-item",
                      on: {
                        click: function($event) {
                          return _vm.handleClick(item)
                        }
                      }
                    },
                    [
                      _c("div", { attrs: { id: item.id } }, [
                        _c(
                          "div",
                          { staticClass: "book-page-img" },
                          [
                            _c("el-image", {
                              class: [
                                "audio-cover",
                                _vm.active === item.id ? "active-border" : ""
                              ],
                              attrs: {
                                src: _vm.getImageUrl(
                                  item.imgUrl,
                                  "style/width-limit-128"
                                ),
                                lazy: "",
                                fit: "cover"
                              }
                            }),
                            _vm._v(" "),
                            item.imgUrl
                              ? _c("div", { staticClass: "preview-bg" }, [
                                  _c("div", { staticClass: "preview" }, [
                                    _c("i", {
                                      staticClass: "el-icon-zoom-in",
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.onPreview(item.imgUrl)
                                        }
                                      }
                                    })
                                  ])
                                ])
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "audio-title" }, [
                          _vm._v(_vm._s(item.sequence) + "/ " + _vm._s(item.id))
                        ])
                      ])
                    ]
                  )
                }),
                0
              )
            : _c("div", { staticClass: "no-data" }, [
                _vm._v("\n      暂无数据\n    ")
              ])
        ]
      ),
      _vm._v(" "),
      _vm.showPreview && _vm.previewData
        ? _c("el-image-viewer", {
            attrs: {
              "on-close": _vm.closeViewer,
              "url-list": [_vm.previewData]
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }