var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "errorContainer" }, [
    _c("div", [
      _c("div", { staticClass: "desText" }, [_vm._v("欢迎！")]),
      _vm._v(" "),
      _c("div", { staticClass: "noRightvisit" }, [
        _c("span", [_vm._v("你好,")]),
        _c("span", [_vm._v(_vm._s(_vm.userInfo.nickName))])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "contactAdministrator",
          staticStyle: { "margin-top": "20px" }
        },
        [
          _c("div", { staticClass: "item-text" }, [
            _vm._v("OpenID:" + _vm._s(_vm.userInfo.openId))
          ]),
          _vm._v(" "),
          _c("span", {
            staticClass: "copy-btn",
            on: {
              click: function($event) {
                return _vm.copyOpenId(_vm.userInfo.openId, $event)
              }
            }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "contactAdministrator",
          staticStyle: { "margin-top": "10px" }
        },
        [_vm._v("当前无法使用总后台，请联系管理员授权。")]
      ),
      _vm._v(" "),
      _vm._m(0)
    ]),
    _vm._v(" "),
    _vm._m(1)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "contacts" }, [
      _c("p", [_vm._v("张久瑞")]),
      _vm._v(" "),
      _c("p", [_vm._v("手机: 17600223682 | QQ: 382399795")]),
      _vm._v(" "),
      _c("p", [_vm._v("王圣洁")]),
      _vm._v(" "),
      _c("p", [_vm._v("手机: 15689237033 | QQ: 553743394")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "errorImage" }, [
      _c("img", {
        attrs: { src: require("@/assets/401_images/401.png"), alt: "401" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }