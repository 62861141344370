"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'CouponItem',
  props: {
    itemInfo: {
      type: Object,
      default: function _default() {
        return {
          discountType: undefined,
          usedAmount: 0,
          thresholdAmount: undefined,
          discount: undefined
        };
      }
    },
    isSideslip: {
      type: Boolean,
      default: false
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    couponWidth: function couponWidth() {
      if (this.isSideslip) {
        return '200px';
      }

      return this.isOpen ? '345px' : '316px';
    }
  }
};
exports.default = _default2;