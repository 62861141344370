var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "word-terms" },
    [
      _c(
        "div",
        {
          staticClass:
            "flex-row align-center flex-space-between margin-bottom-fourteen"
        },
        [
          _c("span", { staticClass: "book-title" }, [_vm._v("内容来源")]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "title-icon",
              style: {
                color: _vm.valueProxy.length > 0 ? "" : "#999999",
                cursor: _vm.valueProxy.length > 0 ? "" : "not-allowed"
              },
              on: { click: _vm.openBatch }
            },
            [
              _c("i", { staticClass: "el-icon-s-tools" }),
              _vm._v("批量设置内容\n    ")
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "draggable",
        {
          model: {
            value: _vm.valueProxy,
            callback: function($$v) {
              _vm.valueProxy = $$v
            },
            expression: "valueProxy"
          }
        },
        _vm._l(_vm.valueProxy, function(item, index) {
          return _c(
            "div",
            { key: item.openId, staticClass: "list-item" },
            [
              _c(
                "div",
                { staticClass: "flex-row align-center flex-space-between" },
                [
                  _c(
                    "div",
                    { staticClass: "flex-row align-center" },
                    [
                      _c("span", [
                        _vm._v(_vm._s(index + 1) + "." + _vm._s(item.name))
                      ]),
                      _vm._v(" "),
                      item.pinyinList && _vm.type === "micro_word"
                        ? _c(
                            "el-select",
                            {
                              staticClass: "select-yin",
                              attrs: { placeholder: "请选择", size: "mini" },
                              on: {
                                change: function($event) {
                                  return _vm.handleSelect(item, index)
                                }
                              },
                              model: {
                                value: item.pinyin,
                                callback: function($$v) {
                                  _vm.$set(item, "pinyin", $$v)
                                },
                                expression: "item.pinyin"
                              }
                            },
                            _vm._l(item.pinyinList, function(n, nIndex) {
                              return _c("el-option", {
                                key: nIndex,
                                attrs: { label: n.pinyin, value: n.pinyin }
                              })
                            }),
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex-row align-center" }, [
                    _c("i", {
                      staticClass: "el-icon-delete delete-icon",
                      on: {
                        click: function($event) {
                          return _vm.handleDelete(item, index)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("i", {
                      staticClass: "iconfont icon-tuozhuaiyidong draggable-icon"
                    })
                  ])
                ]
              ),
              _vm._v(" "),
              (item.checkList.length <= 0 && _vm.type !== "micro_polyphonic") ||
              (_vm.type === "micro_polyphonic" &&
                _vm.getPolyphonicContent(item) <= 0)
                ? _c(
                    "el-button",
                    {
                      staticClass: "add-btn select-btn",
                      attrs: { icon: "el-icon-plus" },
                      on: {
                        click: function($event) {
                          return _vm.selectContent(item)
                        }
                      }
                    },
                    [_vm._v("选择内容")]
                  )
                : _c(
                    "div",
                    {
                      staticClass:
                        "select-btn check-btn flex-row flex-space-between align-center",
                      on: {
                        click: function($event) {
                          return _vm.selectContent(item)
                        }
                      }
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "共选" +
                            _vm._s(
                              _vm.type === "micro_polyphonic"
                                ? _vm.getPolyphonicContent(item)
                                : _vm.getCheckLength(item)
                            ) +
                            "项"
                        )
                      ]),
                      _vm._v(" "),
                      _c("i", { staticClass: "el-icon-edit edit-icon" })
                    ]
                  )
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "el-button",
        {
          staticClass: "add-btn",
          attrs: { icon: "el-icon-plus", type: "primary" },
          on: { click: _vm.handleAdd }
        },
        [_vm._v("添加" + _vm._s(_vm.addText))]
      ),
      _vm._v(" "),
      _vm.wordDialog
        ? _c("select-word-dialog", {
            attrs: {
              visible: _vm.wordDialog,
              title: _vm.title,
              type: _vm.wordType,
              "show-type": _vm.showType,
              "replace-map": _vm.replaceMap,
              placeholder: _vm.placeholder,
              "check-list": _vm.valueProxy
            },
            on: {
              "update:visible": function($event) {
                _vm.wordDialog = $event
              },
              getSelectWord: _vm.getSelectWord
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.wordContent
        ? _c("word-content-dialog", {
            attrs: {
              type: _vm.type,
              visible: _vm.wordContent,
              value: _vm.curContent
            },
            on: {
              "update:visible": function($event) {
                _vm.wordContent = $event
              },
              submit: _vm.submitWordContent
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.batchWord
        ? _c("batch-word-content", {
            attrs: {
              visible: _vm.batchWord,
              value: _vm.valueProxy,
              type: _vm.type
            },
            on: {
              "update:visible": function($event) {
                _vm.batchWord = $event
              },
              submit: _vm.submitBatch
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.openPolyphonic
        ? _c("polyphonic-content-dialog", {
            attrs: { visible: _vm.openPolyphonic, value: _vm.curContent },
            on: {
              "update:visible": function($event) {
                _vm.openPolyphonic = $event
              },
              submit: _vm.submitPolyphonic
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }