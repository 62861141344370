var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "test-question" },
    [
      _c("div", {
        staticClass: "question-stem",
        domProps: { innerHTML: _vm._s(_vm.questionStem) }
      }),
      _vm._v(" "),
      _vm.itemData.video
        ? _c(
            "div",
            {
              staticClass: "question-video common-margin-top",
              style: { height: _vm.videoHeight }
            },
            [
              _c("div", {
                staticClass: "video-preview",
                style: {
                  width: "100%",
                  height: _vm.videoHeight,
                  "border-radius": "17px"
                },
                attrs: { id: "stemVideo" + _vm.itemData.examQuestionOpenId }
              }),
              _vm._v(" "),
              !_vm.isPlayVideo
                ? _c(
                    "div",
                    [
                      _vm.itemData.stemVideoCover
                        ? _c("el-image", {
                            style: {
                              width: "100%",
                              height: _vm.videoHeight,
                              "border-radius": "17px"
                            },
                            attrs: {
                              fit: "cover",
                              src: _vm.itemData.stemVideoCover
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("el-image", {
                        staticClass: "play-btn",
                        attrs: {
                          src:
                            "http://taozhi-open.oss-cn-beijing.aliyuncs.com/develop/assets/taozhi/app/static/question/playBtn.png"
                        },
                        on: {
                          click: function($event) {
                            return _vm.playAboutVideoBtn(
                              _vm.itemData.video,
                              "stemVideo" + _vm.itemData.examQuestionOpenId,
                              "stem"
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "video-mark" })
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.itemData.images
        ? _c(
            "div",
            { staticClass: "common-margin-top" },
            [
              _c("el-image", {
                style: _vm.imgStyle,
                attrs: { fit: "cover", src: _vm.getImgUrl(_vm.itemData.images) }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.itemData.audio
        ? _c("div", { staticClass: "question-audio common-margin-top" }, [
            _c("audio", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false"
                }
              ],
              attrs: {
                src: _vm.audioPath(_vm.audioInfo.audio),
                id: _vm.audioInfo.uniqueIndex
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "laba-left",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.playAudio(_vm.audioInfo.uniqueIndex)
                  }
                }
              },
              [
                !_vm.isPlay
                  ? _c("img", {
                      staticClass: "laba",
                      attrs: { src: require("@/assets/question/laba.png") }
                    })
                  : _c("img", {
                      staticClass: "laba",
                      attrs: { src: require("@/assets/question/labaPlay.gif") }
                    })
              ]
            ),
            _vm._v(" "),
            _vm.isShowIcon
              ? _c("img", {
                  staticClass: "cx-laba",
                  attrs: { src: require("@/assets/question/cxLaba.png") },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.rePlay(_vm.itemData)
                    }
                  }
                })
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.itemData.examQuestionTypeCode === 1001 ||
      _vm.itemData.examQuestionTypeCode === 1002
        ? _c("dan-xuan", {
            attrs: {
              "option-text": _vm.itemData.optionText,
              "exam-question-type-code": _vm.itemData.examQuestionTypeCode
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.itemData.examQuestionTypeCode === 1003 ? _c("pan-duan") : _vm._e(),
      _vm._v(" "),
      _vm.itemData.examQuestionTypeCode === 1008
        ? _c("zhu-guan-ti", {
            attrs: {
              "answer-right": _vm.itemData.answerRight,
              "answer-confirm": _vm.itemData.answerConfirm,
              "right-answer-content": _vm.itemData.rightAnswerContent
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.itemData.answerConfirm || !_vm.isAnswerState
        ? _c("div", { staticClass: "right-answer-text" }, [
            _vm._v("\n    答案："),
            _vm.itemData.examQuestionTypeCode === 1001 ||
            _vm.itemData.examQuestionTypeCode === 1002
              ? _c("span", { staticClass: "right-text" }, [
                  _vm._v(_vm._s(_vm.itemData.rightAnswerContent.toUpperCase()))
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.itemData.examQuestionTypeCode === 1003
              ? _c("span", { staticClass: "right-text" }, [
                  _vm._v(
                    _vm._s(
                      _vm.itemData.rightAnswerContent === "yes"
                        ? "正确"
                        : "错误"
                    )
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.itemData.questionAnalysis &&
      (_vm.itemData.answerConfirm || !_vm.isAnswerState) &&
      (_vm.itemData.questionAnalysis.analysisVideo ||
        _vm.itemData.questionAnalysis.analysis)
        ? _c("div", { staticClass: "answer common-margin-top" }, [
            _c(
              "div",
              { staticClass: "flex-row flex-justify-center" },
              [
                _c("el-image", {
                  staticClass: "explain-img",
                  attrs: {
                    fit: "cover",
                    src:
                      "http://taozhi-open.oss-cn-beijing.aliyuncs.com/develop/assets/taozhi/app/static/question/questionExplain.png"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.itemData.questionAnalysis.analysisVideo
              ? _c(
                  "div",
                  { staticClass: "question-answer-title common-margin-top" },
                  [
                    _c("span", { staticClass: "question-title" }, [
                      _vm._v("视频解析")
                    ]),
                    _vm._v(" "),
                    _c("el-image", {
                      staticClass: "title-img",
                      attrs: {
                        src:
                          "http://taozhi-open.oss-cn-beijing.aliyuncs.com/develop/assets/taozhi/app/static/question/titleIcon.png",
                        fit: "cover"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.itemData.questionAnalysis.analysisVideo
              ? _c(
                  "div",
                  {
                    staticClass: "question-video common-margin-top",
                    style: { height: _vm.videoHeight }
                  },
                  [
                    _c("div", {
                      staticClass: "video-preview",
                      style: {
                        width: "100%",
                        height: _vm.videoHeight,
                        "border-radius": "17px"
                      },
                      attrs: {
                        id: "analysis" + _vm.itemData.examQuestionOpenId
                      }
                    }),
                    _vm._v(" "),
                    !_vm.isAnalysisVideoPlay
                      ? _c(
                          "div",
                          [
                            _vm.itemData.questionAnalysis.analysisVideoCover
                              ? _c("el-image", {
                                  style: {
                                    width: "100%",
                                    height: _vm.videoHeight,
                                    "border-radius": "17px"
                                  },
                                  attrs: {
                                    fit: "cover",
                                    src:
                                      _vm.itemData.questionAnalysis
                                        .analysisVideoCover
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("el-image", {
                              staticClass: "play-btn",
                              attrs: {
                                src:
                                  "http://taozhi-open.oss-cn-beijing.aliyuncs.com/develop/assets/taozhi/app/static/question/playBtn.png"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.playAboutVideoBtn(
                                    _vm.itemData.questionAnalysis.analysisVideo,
                                    "analysis" +
                                      _vm.itemData.examQuestionOpenId,
                                    "analysis"
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "video-mark" })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.itemData.questionAnalysis.analysis
              ? _c(
                  "div",
                  { staticClass: "question-answer-title common-margin-top" },
                  [
                    _c("span", { staticClass: "question-title" }, [
                      _vm._v("题目解析")
                    ]),
                    _vm._v(" "),
                    _c("el-image", {
                      staticClass: "title-img",
                      attrs: {
                        src:
                          "http://taozhi-open.oss-cn-beijing.aliyuncs.com/develop/assets/taozhi/app/static/question/titleIcon.png",
                        fit: "cover"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.itemData.questionAnalysis.analysis
              ? _c("div", {
                  staticClass: "common-margin-top",
                  domProps: {
                    innerHTML: _vm._s(_vm.itemData.questionAnalysis.analysis)
                  }
                })
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "footer common-margin-top" }, [
        _c(
          "div",
          { staticClass: "flex-row" },
          [
            _vm.isAnswerState
              ? _c(
                  "div",
                  { staticClass: "flex-row" },
                  [
                    _vm.itemData.examQuestionTypeCode === 1008 &&
                    !_vm.itemData.answerConfirm
                      ? _c(
                          "el-button",
                          {
                            staticClass: "btn-left common-btn",
                            attrs: { type: "danger", size: "mini", round: "" },
                            on: {
                              click: function($event) {
                                return _vm.submit(_vm.itemData, "error")
                              }
                            }
                          },
                          [_vm._v("判错")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.itemData.examQuestionTypeCode === 1008 &&
                    !_vm.itemData.answerConfirm
                      ? _c(
                          "el-button",
                          {
                            staticClass: "common-btn",
                            attrs: { type: "primary", size: "mini", round: "" },
                            on: {
                              click: function($event) {
                                return _vm.submit(_vm.itemData, "right")
                              }
                            }
                          },
                          [_vm._v("判对")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.itemData.examQuestionTypeCode !== 1008 &&
                    !_vm.itemData.answerConfirm
                      ? _c(
                          "el-button",
                          {
                            staticClass: "common-btn",
                            attrs: { type: "primary", size: "mini", round: "" },
                            on: {
                              click: function($event) {
                                return _vm.submit(_vm.itemData)
                              }
                            }
                          },
                          [_vm._v("确认答案")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.itemData.answerConfirm
                      ? _c(
                          "el-button",
                          {
                            staticClass: "common-btn",
                            attrs: { type: "primary", size: "mini", round: "" },
                            on: {
                              click: function($event) {
                                return _vm.againSubmit(_vm.itemData)
                              }
                            }
                          },
                          [_vm._v("再答一次")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "btn-left common-btn",
                attrs: { type: "primary", size: "mini", round: "" }
              },
              [_vm._v("+错题本")]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }