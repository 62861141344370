"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/es6.object.keys");

require("core-js/modules/es6.function.name");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/web.dom.iterable");

var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

var _sortablejs = require("sortablejs");

var _WordCustomDialog = _interopRequireDefault(require("./WordCustomDialog"));

var _character = _interopRequireDefault(require("../../assets/json/character"));

var _microWord = require("../../utils/microWord");

var _main = _interopRequireDefault(require("../../organizationLibs/powerCache/v1.0/main"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var _default2 = {
  name: 'PolyphonicContentDialog',
  components: {
    WordCustomDialog: _WordCustomDialog.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      activeName: 0,
      customDialog: false,
      tableData: [],
      customData: {},
      privateList: [],
      curAddCustom: undefined,
      pinyinList: [],
      curPinyinList: [],
      checkList: [],
      loading: false,
      isFirst: false
    };
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler: function handler(val) {
        if (val && JSON.stringify(val) !== '{}') {
          val = JSON.parse(JSON.stringify(val));
          this.init(val);
        }
      }
    }
  },
  methods: {
    init: function init(val) {
      var _this = this;

      try {
        this.loading = true;
        this.isFirst = true;
        var list = JSON.parse(JSON.stringify(_character.default));
        var polyphonicPrivate = list.polyphonicPrivate,
            polyphonicPriList = list.polyphonicPriList,
            polyphonicCommon = list.polyphonicCommon,
            polyphonicComList = list.polyphonicComList;
        this.pinyinList = (0, _toConsumableArray2.default)(val.pinyinList);
        this.curPinyinList = this.pinyinList[0].checkList.length > 0 ? this.pinyinList[0].checkList : (0, _toConsumableArray2.default)(polyphonicPrivate);
        this.pinyinList[0].checkList = (0, _toConsumableArray2.default)(this.curPinyinList);
        this.tableData = val.checkList.length > 0 ? (0, _toConsumableArray2.default)(val.checkList) : (0, _toConsumableArray2.default)(polyphonicCommon);
        this.$nextTick(function () {
          // 私有列表回显
          if (_this.pinyinList[0].checkList.length > 0) {
            _this.curPinyinList.forEach(function (item) {
              item.disable = !polyphonicPriList.includes(item.label); // 判断是否是新加的  新加的为全数字

              if (item.check && _this.$refs.privateTable) {
                _this.$refs.privateTable.toggleRowSelection(item);
              }
            });
          } // 共有列表回显


          if (val.checkList.length > 0) {
            _this.tableData.forEach(function (item) {
              item.disable = !polyphonicComList.includes(item.label); // 判断是否是新加的  新加的为全数字

              if (item.check && _this.$refs.multipleTable) {
                _this.$refs.multipleTable.toggleRowSelection(item);

                _this.checkList.push(item.label);
              }
            });
          }

          _this.isFirst = false;
          _this.loading = false;

          _this.drag();

          _this.dragPrivate();
        });
      } catch (e) {
        this.isFirst = false;
        console.log(e);
      }
    },
    addCustom: function addCustom(val) {
      this.curAddCustom = val;
      this.customDialog = true;
    },
    handleClick: function handleClick() {
      var _this2 = this;

      this.loading = true;
      this.isFirst = true;
      var data = this.pinyinList[this.activeName].checkList;
      this.pinyinList[this.activeName].checkList = data.length > 0 ? data : JSON.parse(JSON.stringify(_character.default.polyphonicPrivate));
      this.curPinyinList = this.pinyinList[this.activeName].checkList;
      this.$nextTick(function () {
        if (_this2.curPinyinList.length > 0) {
          _this2.curPinyinList.forEach(function (item) {
            if (item.check) {
              _this2.$refs.privateTable.toggleRowSelection(item);
            }
          });
        }

        _this2.loading = false;
        _this2.isFirst = false;
      });
    },
    handlePrivateChange: function handlePrivateChange(val) {
      if (this.isFirst) {
        return;
      }

      var list = val.map(function (item) {
        return item.label;
      });
      this.pinyinList[this.activeName].checkList.forEach(function (item) {
        item.check = list.includes(item.label);
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      var _this3 = this;

      this.checkList = [];
      var list = val.map(function (item) {
        return item.label;
      });
      this.tableData.forEach(function (item) {
        if (list.includes(item.label)) {
          _this3.checkList.push(item.label);
        }
      });
    },
    // 删除
    deleteData: function deleteData(val, type) {
      var _this4 = this;

      if (type === 'tableData') {
        this.tableData.forEach(function (item, index) {
          if (val.label === item.label) {
            _this4.tableData.splice(index, 1);
          }
        });
        return;
      }

      this.privateList.forEach(function (item, index) {
        if (val.label === item.label) {
          _this4.curPinyinList.splice(index, 1);

          _this4.pinyinList[_this4.activeName].checkList = (0, _toConsumableArray2.default)(_this4.curPinyinList);
        }
      });
    },
    // 编辑
    editData: function editData(val, type) {
      this.curAddCustom = type;
      this.customData = _objectSpread({}, val);
      this.customDialog = true;
    },
    drag: function drag() {
      var _this5 = this;

      var el = document.querySelectorAll('.word-drag-table tbody')[0];

      _sortablejs.Sortable.create(el, {
        onEnd: function onEnd(e) {
          var arr = _this5.tableData;
          arr.splice(e.newIndex, 0, arr.splice(e.oldIndex, 1)[0]);

          _this5.$nextTick(function () {
            _this5.tableData = arr;
            var checkList = arr.filter(function (item) {
              return _this5.checkList.includes(item.label);
            });

            _this5.handleSelectionChange(checkList);
          });
        }
      });
    },
    dragPrivate: function dragPrivate() {
      var _this6 = this;

      var el = document.querySelectorAll('.private-drag-table tbody')[0];

      _sortablejs.Sortable.create(el, {
        onEnd: function onEnd(e) {
          var arr = _this6.curPinyinList;
          arr.splice(e.newIndex, 0, arr.splice(e.oldIndex, 1)[0]);

          _this6.$nextTick(function () {
            // 赋值给 this.pinyinList
            _this6.pinyinList[_this6.activeName].checkList = (0, _toConsumableArray2.default)(arr);
            _this6.curPinyinList = arr;
          });
        }
      });
    },
    submit: function submit() {
      var _this7 = this;

      var list = [];
      var commonList = []; // 处理私有

      this.pinyinList.forEach(function (item) {
        item.checkList.forEach(function (n) {
          if (n.check) {
            (0, _microWord.wordCheckData)(n, _objectSpread(_objectSpread({}, _this7.value), {}, {
              pinyin: item.pinyin
            }));
          }
        });
        list.push(item);
      }); // 处理共有

      this.tableData.forEach(function (item) {
        if (_this7.checkList.includes(item.label)) {
          (0, _microWord.wordCheckData)(item, _this7.value);
          item.check = true;
        } else {
          item.check = false;
        }

        commonList.push(item);
      });
      this.$emit('submit', list, commonList, this.value);
    },
    submitCustom: function submitCustom(val, isEdit) {
      this.customData = {}; // 共有

      if (this.curAddCustom === 'common') {
        if (!isEdit) {
          this.tableData.push(_objectSpread(_objectSpread({}, val), {}, {
            disable: true,
            check: true
          }));
          this.$refs.multipleTable.toggleRowSelection(this.tableData[this.tableData.length - 1]);
          return;
        }

        this.tableData.forEach(function (item) {
          if (item.label === val.label) {
            item.name = val.name;
            item.content = val.content;
          }
        });
        return;
      } // 私有


      if (!isEdit) {
        this.curPinyinList.push(_objectSpread(_objectSpread({}, val), {}, {
          disable: true,
          check: true
        }));
        this.pinyinList[this.activeName].checkList = (0, _toConsumableArray2.default)(this.curPinyinList);
        this.$refs.privateTable.toggleRowSelection(this.curPinyinList[this.curPinyinList.length - 1]);
        return;
      }

      this.curPinyinList.forEach(function (item) {
        if (item.label === val.label) {
          item.name = val.name;
          item.content = val.content;
        }
      });
      this.pinyinList[this.activeName].checkList.forEach(function (item) {
        if (item.label === val.label) {
          item.name = val.name;
          item.content = val.content;
        }
      });
      this.pinyinList[this.activeName].checkList = (0, _toConsumableArray2.default)(this.curPinyinList);
    },
    cancel: function cancel() {
      this.$emit('update:visible', false);
    }
  }
};
exports.default = _default2;