"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.regexp.constructor");

var _main = require("../../organizationLibs/editor/main");

//
//
//
//
//
//
//
//
var _default2 = {
  name: 'RichText',
  props: {
    richText: {
      type: String,
      default: ''
    },
    content: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      rich: undefined
    };
  },
  watch: {
    richText: {
      deep: true,
      immediate: true,
      handler: function handler(val) {
        this.getRichText(val);
      }
    }
  },
  methods: {
    // 区分 如果图片有style 按照style原样返回 若没有加width:100%等样式
    handleImg: function handleImg(val) {
      var reg = new RegExp('<img(.*?)src="(.*?)"(.*?)/>', 'gi');
      val = val.replace(reg, function ($0, $1, $2, $3) {
        var data = $1 ? $1.trim() : undefined;

        if (!data) {
          return "<img style=\"width:100%; height:auto; display:blok\" src=\"".concat($2, "\"").concat($3, "/>");
        }

        if (data === 'style="max-width: 100%; height: auto"') {
          return "<img style=\"width:100%; height:auto; display:blok\" src=\"".concat($2, "\"").concat($3, "/>");
        }

        return "<img".concat($1, "src=\"").concat($2, "\"").concat($3, "/>");
      });
      return val;
    },
    // 处理富文本
    getRichText: function getRichText(richtext) {
      if (richtext != null) {
        var regex = new RegExp('<figure', 'gi');
        var regexStart = new RegExp('<section', 'gi');
        var regexEnd = new RegExp('</section', 'gi');
        var regexWraper = new RegExp('data-role="outer"', 'gi');
        richtext = richtext.replace(regex, '<div');
        richtext = richtext.replace(regexStart, '<div');
        richtext = richtext.replace(regexEnd, '</div');
        richtext = richtext.replace(regexWraper, 'style="box-sizing: border-box; padding: 15px 20px 0;"');
      }

      richtext = (0, _main.handleBlankUeditor)(richtext);
      var regWidth = new RegExp('<ed style="width: (.{1,6}); box-sizing: border-box; display: inline-block; padding: 0 20px; color: (.{7,18}); font-weight: 700; border-bottom: 1px solid (.{6,18})">', 'gi');
      richtext = richtext.replace(regWidth, function ($0, $1, $2, $3) {
        return "<ed style=\"width: ".concat($1, "; box-sizing: border-box; display: inline-block; text-align: center; color: ").concat($2, "; font-weight: 700; border-bottom: 1px solid ").concat($3, "\">");
      });
      this.rich = this.handleImg(richtext);
    }
  }
};
exports.default = _default2;