var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "external-link" },
    [
      _c("el-input", {
        staticClass: "input",
        attrs: { placeholder: "请输入链接名称...", clearable: "" },
        model: {
          value: _vm.valueProxy.linkTitle,
          callback: function($$v) {
            _vm.$set(_vm.valueProxy, "linkTitle", $$v)
          },
          expression: "valueProxy.linkTitle"
        }
      }),
      _vm._v(" "),
      _c("el-input", {
        attrs: {
          type: "textarea",
          placeholder: "请输入链接地址...",
          autosize: { minRows: 8, maxRows: 8 }
        },
        model: {
          value: _vm.valueProxy.linkHref,
          callback: function($$v) {
            _vm.$set(_vm.valueProxy, "linkHref", $$v)
          },
          expression: "valueProxy.linkHref"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }