"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.videoRequestList = videoRequestList;
exports.getCommodityVideoList = getCommodityVideoList;
exports.getVideoInfo = getVideoInfo;

var _main = _interopRequireDefault(require("../utils/request/main"));

var _store = _interopRequireDefault(require("../store"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/video-management/admin/"));
var requestMp = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/video-management/mp/"));
/**
 * 查询视频列表
 * @param {Object} params
 * @returns {Promise<*>}
 */

function videoRequestList(params) {
  return request.get('/video/request/list', {
    params: params
  });
}
/**
 * 查询视频商品信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getCommodityVideoList(params) {
  if (_store.default.state.microPage.isAdmin) {
    return request.get('/video/admin', {
      params: params
    });
  }

  return requestMp.get('/video/mp', {
    params: params
  });
}
/**
 * 获取视频详情
 * @param {String} openId
 * @returns {Promise<*>}
 */


function getVideoInfo(openId) {
  return request.get("/video/request/".concat(openId));
}