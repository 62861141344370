"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/es6.object.keys");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

require("core-js/modules/web.dom.iterable");

var _Display = _interopRequireDefault(require("./Display.vue"));

var _UEditor = _interopRequireDefault(require("./UEditor.vue"));

var _defaultConfig = _interopRequireDefault(require("../defaultConfig"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// UEditor 默认配置
var _default2 = {
  name: 'EditorEditor',
  components: {
    Display: _Display.default,
    UEditor: _UEditor.default
  },
  props: {
    value: {
      type: String,
      default: undefined
    },
    config: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    // UEditor 配置
    readonly: {
      type: Boolean,
      default: false
    },
    // 是否可编辑
    isShowFullScreen: {
      type: Boolean,
      default: true
    },
    // 是否显示全屏 默认显示
    isInitContent: {
      type: Boolean,
      default: false
    } // 是否需要初始化的时候处理数据

  },
  computed: {
    editorConfig: function editorConfig() {
      // UEditor 配置
      if (!this.isShowFullScreen) {
        var list = JSON.parse(JSON.stringify(_defaultConfig.default));
        var newTool = [];
        list.toolbars[0].forEach(function (item) {
          if (item !== 'fullscreen') {
            newTool.push(item);
          }
        });
        list.toolbars[0] = newTool;
        return _objectSpread(_objectSpread(_objectSpread({}, list), this.config), {}, {
          readonly: false
        });
      }

      return _objectSpread(_objectSpread(_objectSpread({}, _defaultConfig.default), this.config), {}, {
        readonly: false
      });
    },
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    // 为了获取不缩放的图片准备的
    getContent: function getContent() {
      return this.$refs.showEditor.getContents();
    }
  }
};
exports.default = _default2;