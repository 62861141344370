"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/web.dom.iterable");

var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

var _sortablejs = require("sortablejs");

var _character = _interopRequireDefault(require("../../assets/json/character"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'BatchWordContent',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    type: {
      type: String,
      default: undefined
    }
  },
  data: function data() {
    return {
      wordList: [],
      wordCheck: [],
      checkList: [],
      tableData: [],
      privateData: []
    };
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler: function handler(val) {
        this.init(val);
      }
    }
  },
  methods: {
    init: function init(val) {
      var _this = this;

      if (this.type === 'micro_terms') {
        this.tableData = JSON.parse(JSON.stringify(_character.default.termsData));
      }

      if (this.type === 'micro_word') {
        this.tableData = JSON.parse(JSON.stringify(_character.default.tableData));
      }

      if (this.type === 'micro_polyphonic') {
        this.privateData = JSON.parse(JSON.stringify(_character.default.polyphonicPrivate));
        this.tableData = JSON.parse(JSON.stringify(_character.default.polyphonicCommon));
      }

      this.wordList = (0, _toConsumableArray2.default)(val);
      this.$nextTick(function () {
        if (!_this.$refs.wordList) {
          return;
        }

        _this.wordList.forEach(function (item) {
          _this.$refs.wordList.toggleRowSelection(item);

          _this.wordCheck.push(item.openId);

          _this.drag();
        });
      });
    },
    drag: function drag() {
      var _this2 = this;

      var el = document.querySelectorAll('.word-drag-table tbody')[0];

      _sortablejs.Sortable.create(el, {
        onEnd: function onEnd(e) {
          var arr = _this2.tableData;
          arr.splice(e.newIndex, 0, arr.splice(e.oldIndex, 1)[0]);

          _this2.$nextTick(function () {
            _this2.tableData = arr;
          });
        }
      });

      if (this.type === 'micro_polyphonic') {
        var privateVal = document.querySelectorAll('.private-drag-table tbody')[0];

        _sortablejs.Sortable.create(privateVal, {
          onEnd: function onEnd(e) {
            var arr = _this2.privateData;
            arr.splice(e.newIndex, 0, arr.splice(e.oldIndex, 1)[0]);

            _this2.$nextTick(function () {
              _this2.privateData = arr;
            });
          }
        });
      }
    },
    handleWordChange: function handleWordChange(val) {
      this.wordCheck = val.length > 0 ? val.map(function (item) {
        return item.openId;
      }) : [];
    },
    handleCheckChange: function handleCheckChange(val) {
      var list = val.map(function (item) {
        return item.label;
      });
      this.tableData.forEach(function (item) {
        if (list.includes(item.label)) {
          item.check = true;
        }
      });
    },
    handlePrivateCheck: function handlePrivateCheck(val) {
      var list = val.map(function (item) {
        return item.label;
      });
      this.privateData.forEach(function (item) {
        if (list.includes(item.label)) {
          item.check = true;
        }
      });
    },
    submit: function submit() {
      this.$emit('submit', this.wordCheck, this.tableData, this.privateData);
      this.cancel();
    },
    cancel: function cancel() {
      this.$emit('update:visible', false);
    }
  }
};
exports.default = _default2;