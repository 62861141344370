var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "micro-video" },
    [
      _vm.options.style === "line"
        ? _c(
            "div",
            { staticClass: "line" },
            _vm._l(_vm.list, function(item, index) {
              return _c("div", { key: index }, [
                index < _vm.size
                  ? _c(
                      "div",
                      {
                        staticClass: "common-video",
                        style: { height: _vm.heightStyle }
                      },
                      [
                        !item.isPlay
                          ? _c(
                              "div",
                              [
                                item.cover
                                  ? _c("el-image", {
                                      staticClass: "video-cover",
                                      style: { height: _vm.heightStyle },
                                      attrs: {
                                        fit: "cover",
                                        src: _vm.getImgUrl(
                                          item.cover,
                                          "style/width-limit-256"
                                        )
                                      }
                                    })
                                  : _c("img", {
                                      staticClass: "video-cover",
                                      style: { height: _vm.heightStyle },
                                      attrs: {
                                        src: require("@/assets/cover/cover-video.png")
                                      }
                                    }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "video-mask",
                                    style: {
                                      height: _vm.heightStyle,
                                      width:
                                        _vm.options.style === "row"
                                          ? "calc( 100% - 15px )"
                                          : "100%"
                                    }
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "play-btn",
                                      attrs: {
                                        src: require("@/assets/audio/play.png")
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.playVideo(
                                            item,
                                            index,
                                            "" + _vm.model + item.resourceOpenId
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "video-play",
                          style: { height: _vm.heightStyle },
                          attrs: { id: "" + _vm.model + item.resourceOpenId }
                        })
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                index < _vm.size
                  ? _c("div", [
                      _vm.options.showTitle === false
                        ? _c("div", {
                            class: [
                              _vm.options.innerMargin === "fill" &&
                              _vm.size === "1"
                                ? ""
                                : "no-line"
                            ]
                          })
                        : _c(
                            "div",
                            {
                              class: [
                                "line-title",
                                index === _vm.list.length - 1
                                  ? "last-line-title"
                                  : ""
                              ]
                            },
                            [_vm._v(_vm._s(item.title))]
                          )
                    ])
                  : _vm._e()
              ])
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.options.style !== "line"
        ? _c(
            "div",
            {
              staticClass: "common-video no-line",
              style: {
                height: _vm.heightStyle,
                "padding-right": _vm.options.style === "row" ? "15px" : 0
              }
            },
            [
              !_vm.list[_vm.currentIndex].isPlay
                ? _c(
                    "div",
                    [
                      _vm.list[_vm.currentIndex].cover
                        ? _c("el-image", {
                            staticClass: "video-cover",
                            style: { height: _vm.heightStyle },
                            attrs: {
                              fit: "cover",
                              src: _vm.getImgUrl(
                                _vm.list[_vm.currentIndex].cover,
                                "style/width-limit-256"
                              )
                            }
                          })
                        : _c("img", {
                            staticClass: "video-cover",
                            style: { height: _vm.heightStyle },
                            attrs: {
                              src: require("@/assets/cover/cover-video.png")
                            }
                          }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "video-mask",
                          style: {
                            height: _vm.heightStyle,
                            width:
                              _vm.options.style === "row"
                                ? "calc( 100% - 15px )"
                                : "100%"
                          }
                        },
                        [
                          _c("img", {
                            staticClass: "play-btn",
                            attrs: { src: require("@/assets/audio/play.png") },
                            on: {
                              click: function($event) {
                                return _vm.playVideo(
                                  _vm.list[_vm.currentIndex],
                                  _vm.currentIndex,
                                  _vm.model
                                )
                              }
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _vm.options.showTitle
                        ? _c(
                            "div",
                            { staticClass: "no-line-title hidden-line" },
                            [_vm._v(_vm._s(_vm.list[_vm.currentIndex].title))]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", {
                staticClass: "video-play",
                style: {
                  height: _vm.heightStyle,
                  width:
                    _vm.options.style === "row" ? "calc( 100% - 15px )" : "100%"
                },
                attrs: { id: _vm.model }
              })
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.options.style === "row" && _vm.list.length > 1 && _vm.size > 1
        ? _c(
            "slider",
            {
              ref: "slider",
              staticClass: "list-row",
              attrs: { options: _vm.sliderinit }
            },
            _vm._l(_vm.list, function(item, index) {
              return _c("slideritem", { key: index, staticClass: "item-row" }, [
                _vm.size > index
                  ? _c(
                      "div",
                      {
                        staticClass: "item-row",
                        on: {
                          click: function($event) {
                            return _vm.handelClickVideo(
                              item,
                              index,
                              "" + _vm.model
                            )
                          }
                        }
                      },
                      [
                        item.cover
                          ? _c("el-image", {
                              staticClass: "item-row-cover",
                              attrs: {
                                fit: "cover",
                                src: _vm.getImgUrl(
                                  item.cover,
                                  "style/width-limit-128"
                                )
                              }
                            })
                          : _c("img", {
                              staticClass: "item-row-cover",
                              attrs: {
                                src: require("@/assets/cover/cover-video.png")
                              }
                            }),
                        _vm._v(" "),
                        _c("div", { staticClass: "row-mask" }, [
                          _c(
                            "span",
                            {
                              staticClass: "hidden-line",
                              style: {
                                color:
                                  _vm.currentIndex === index
                                    ? "#00A2FF"
                                    : "#FFFFFF"
                              }
                            },
                            [_vm._v(_vm._s(item.title))]
                          )
                        ])
                      ],
                      1
                    )
                  : _vm._e()
              ])
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.options.style === "column" && _vm.list.length > 1 && _vm.size > 1
        ? _c(
            "div",
            { staticClass: "list-column" },
            _vm._l(_vm.list, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  on: {
                    click: function($event) {
                      return _vm.handelClickVideo(item, index, "" + _vm.model)
                    }
                  }
                },
                [
                  _vm.size > index
                    ? _c(
                        "div",
                        {
                          staticClass: "flex-row align-center item-column",
                          style: {
                            "border-bottom":
                              index >= _vm.size - 1 ||
                              index >= _vm.list.length - 1
                                ? "none"
                                : "1px solid #E5E5E5"
                          }
                        },
                        [
                          _c("el-image", {
                            staticClass: "video-logo",
                            attrs: {
                              src:
                                _vm.currentIndex !== index
                                  ? _vm.getImgUrl(
                                      _vm.imgPath + "video-logo.png",
                                      "style/width-limit-80",
                                      true
                                    )
                                  : _vm.getImgUrl(
                                      _vm.imgPath + "video-active-logo.png",
                                      "style/width-limit-80",
                                      true
                                    )
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "title",
                              style: {
                                color:
                                  _vm.currentIndex === index ? "#00A2FF" : ""
                              }
                            },
                            [_vm._v(_vm._s(item.title))]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            }),
            0
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }