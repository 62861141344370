"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.array.find");

//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'PartnerDescribe',
  props: {
    element: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  methods: {
    getStatistics: function getStatistics(val) {
      if (!val) {
        return undefined;
      }

      if (this.$store.state.microPage.resourceNum.length <= 0) {
        return undefined;
      }

      var data = this.$store.state.microPage.resourceNum.find(function (item) {
        return item.value === val;
      });
      return data.number + data.name;
    }
  }
};
exports.default = _default2;