var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-menu",
    {
      staticClass: "html-menu",
      attrs: { "default-active": "1", "default-openeds": _vm.openeds }
    },
    [
      _c(
        "el-submenu",
        { attrs: { index: "1" } },
        [
          _c("template", { slot: "title" }, [
            _c("span", [_vm._v(_vm._s(_vm.isCloudHome ? "店铺主页" : "页面"))])
          ]),
          _vm._v(" "),
          _c("el-menu-item-group", { staticStyle: { padding: "0 20px" } }, [
            _c("div", { staticClass: "flex-column" }, [
              _vm.isCloudHome
                ? _c(
                    "div",
                    { staticClass: "common-background" },
                    [
                      _c("span", { staticClass: "book-title" }, [
                        _vm._v("商城名称")
                      ]),
                      _vm._v(" "),
                      _c("el-input", {
                        staticClass: "margin-top-bottom-seventeen",
                        attrs: { disabled: "", placeholder: "请输入商城名称" },
                        model: {
                          value: _vm.partnerInfo.storeName,
                          callback: function($$v) {
                            _vm.$set(_vm.partnerInfo, "storeName", $$v)
                          },
                          expression: "partnerInfo.storeName"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isCloudHome && !_vm.isAppHome
                ? _c(
                    "div",
                    { staticClass: "common-background" },
                    [
                      _c("span", { staticClass: "book-title" }, [
                        _vm._v("页面名称")
                      ]),
                      _vm._v(" "),
                      _c("el-input", {
                        staticClass: "margin-top-bottom-seventeen",
                        attrs: { placeholder: "请输入页面名称" },
                        on: { change: _vm.changeTitle },
                        model: {
                          value: _vm.data.htmlTitle,
                          callback: function($$v) {
                            _vm.$set(_vm.data, "htmlTitle", $$v)
                          },
                          expression: "data.htmlTitle"
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "book-title" }, [
                        _vm._v("所属分类")
                      ]),
                      _vm._v(" "),
                      _c("class-category", {
                        staticClass:
                          "class-category margin-top-bottom-seventeen",
                        attrs: {
                          "show-update": true,
                          refresh: _vm.refreshClass,
                          "partner-open-id": _vm.classData.partnerOpenId
                        },
                        on: {
                          "update:refresh": function($event) {
                            _vm.refreshClass = $event
                          }
                        },
                        model: {
                          value: _vm.classData,
                          callback: function($$v) {
                            _vm.classData = $$v
                          },
                          expression: "classData"
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "book-title" }, [
                        _vm._v("微页页面分享设置")
                      ]),
                      _vm._v(" "),
                      _c("select-material-btn", {
                        staticClass: "margin-top-bottom-seventeen",
                        attrs: {
                          "data-item": _vm.data.shareData,
                          "icon-text": "添加图片",
                          path: _vm.data.shareData.path,
                          "img-width-height": {
                            width: "100px",
                            height: "100px"
                          }
                        },
                        on: { submitMaterial: _vm.submitMaterial }
                      }),
                      _vm._v(" "),
                      !_vm.isCloudRetailPartner
                        ? _c("el-input", {
                            attrs: {
                              type: "textarea",
                              autosize: { minRows: 3, maxRows: 4 },
                              placeholder: "请输入微页描述",
                              clearable: ""
                            },
                            model: {
                              value: _vm.data.shareData.describe,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.data.shareData,
                                  "describe",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "data.shareData.describe"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isCloudRetailPartner
                ? _c(
                    "div",
                    { staticStyle: { "margin-top": "14px" } },
                    [
                      _vm.isCloudHome
                        ? _c("div", { staticClass: "book-title" }, [
                            _vm._v("店铺logo")
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isCloudHome
                        ? _c("el-image", {
                            staticClass:
                              "partner-img margin-top-bottom-seventeen",
                            attrs: {
                              fit: "cover",
                              src: _vm.getImgUrl(
                                _vm.partnerInfo.partnerLogo,
                                "style/width-limit-256"
                              )
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isCloudHome
                        ? _c(
                            "div",
                            { staticClass: "flex-row flex-space-between" },
                            [
                              _c(
                                "span",
                                { staticClass: "second-title-color" },
                                [_vm._v("仅显示Logo")]
                              ),
                              _vm._v(" "),
                              _c("el-switch", {
                                attrs: { "active-color": "#13ce66" },
                                model: {
                                  value: _vm.data.onlyShowParLogo,
                                  callback: function($$v) {
                                    _vm.$set(_vm.data, "onlyShowParLogo", $$v)
                                  },
                                  expression: "data.onlyShowParLogo"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "book-title margin-top-bottom-seventeen"
                        },
                        [_vm._v("店铺背景图")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex-row flex-space-between margin-bottom-fourteen"
                        },
                        [
                          _c("span", { staticClass: "second-title-color" }, [
                            _vm._v("是否显示店铺背景图")
                          ]),
                          _vm._v(" "),
                          _c("el-switch", {
                            attrs: { "active-color": "#13ce66" },
                            model: {
                              value: _vm.data.showBgImg,
                              callback: function($$v) {
                                _vm.$set(_vm.data, "showBgImg", $$v)
                              },
                              expression: "data.showBgImg"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.isCloudRetailPartner
                        ? _c("select-material-btn", {
                            attrs: {
                              "data-item": _vm.data,
                              type: "pageBg",
                              "icon-text": "添加背景图片",
                              path: _vm.data.bgImg,
                              "img-width-height": {
                                width: "190px",
                                height: "80px"
                              }
                            },
                            on: { submitMaterial: _vm.submitMaterialBg }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isAppHome
                ? _c(
                    "div",
                    { staticStyle: { "margin-top": "14px" } },
                    [
                      _c("span", { staticClass: "book-title color-bottom" }, [
                        _vm._v("页面背景图")
                      ]),
                      _vm._v(" "),
                      _c("select-material-btn", {
                        attrs: {
                          "data-item": _vm.data,
                          type: "pageBg",
                          "icon-text": "添加背景图片",
                          path: _vm.data.bgImg,
                          "img-width-height": { width: "190px", height: "80px" }
                        },
                        on: { submitMaterial: _vm.submitMaterialBg }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "margin-top": "14px" } },
                [
                  _c("span", { staticClass: "book-title color-bottom" }, [
                    _vm._v("页面背景颜色")
                  ]),
                  _vm._v(" "),
                  _c("color-picker", {
                    attrs: { "default-color": "#F4F8F8" },
                    model: {
                      value: _vm.data.htmlBackgroundColor,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "htmlBackgroundColor", $$v)
                      },
                      expression: "data.htmlBackgroundColor"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              !_vm.isCloudRetailPartner && !_vm.isAppHome
                ? _c("div", {
                    staticClass: "dividing-line margin-top-bottom-seventeen"
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isCloudRetailPartner && !_vm.isAppHome
                ? _c(
                    "div",
                    { staticClass: "flex-column" },
                    [
                      _c("div", { staticClass: "flex-row align-center" }, [
                        _c("span", { staticClass: "book-title" }, [
                          _vm._v("导航设置")
                        ]),
                        _vm._v(" "),
                        _c("i", {
                          staticClass: "el-icon-info",
                          staticStyle: {
                            "margin-left": "5px",
                            color: "#409EFF",
                            "font-size": "14px"
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex-row flex-space-between common-margin-top"
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "book-title color-bottom second-title-color"
                            },
                            [_vm._v("返回按钮" + _vm._s(_vm.data.showBack))]
                          ),
                          _vm._v(" "),
                          _c("el-switch", {
                            attrs: { "active-color": "#13ce66" },
                            model: {
                              value: _vm.data.showBack,
                              callback: function($$v) {
                                _vm.$set(_vm.data, "showBack", $$v)
                              },
                              expression: "data.showBack"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio-group",
                        {
                          attrs: { size: "mini" },
                          on: { change: _vm.changeNavigateRadio },
                          model: {
                            value: _vm.data.navigateRadio,
                            callback: function($$v) {
                              _vm.$set(_vm.data, "navigateRadio", $$v)
                            },
                            expression: "data.navigateRadio"
                          }
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "白色" } }),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "主题色" } }),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "自定义" } })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.data.navigateRadio === "自定义",
                              expression: "data.navigateRadio === '自定义'"
                            }
                          ]
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "common-margin-top common-margin-bottom"
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "book-title color-bottom second-title-color"
                                },
                                [_vm._v("导航背景颜色")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex-row align-center flex-space-between"
                                },
                                [
                                  _c("color-picker", {
                                    attrs: { "default-color": "#FFFFFF" },
                                    model: {
                                      value: _vm.data.navigateBackground,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.data,
                                          "navigateBackground",
                                          $$v
                                        )
                                      },
                                      expression: "data.navigateBackground"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("el-checkbox", {
                                    staticStyle: { "margin-left": "12px" },
                                    on: {
                                      change: _vm.changeShowNavigateBackground
                                    },
                                    model: {
                                      value: _vm.data.showNavigateBackground,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.data,
                                          "showNavigateBackground",
                                          $$v
                                        )
                                      },
                                      expression: "data.showNavigateBackground"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "common-margin-bottom" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "book-title color-bottom second-title-color"
                                },
                                [_vm._v("导航字体与图标色")]
                              ),
                              _vm._v(" "),
                              _c("color-picker", {
                                attrs: { "default-color": "#FFFFFF" },
                                model: {
                                  value: _vm.data.navigateFontAndIconColor,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.data,
                                      "navigateFontAndIconColor",
                                      $$v
                                    )
                                  },
                                  expression: "data.navigateFontAndIconColor"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isCloudRetailPartner && !_vm.isAppHome
                ? _c("div", {
                    staticClass: "dividing-line margin-top-bottom-seventeen"
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isCloudRetailPartner && !_vm.isAppHome
                ? _c(
                    "div",
                    { staticClass: "flex-column" },
                    [
                      _c("span", { staticClass: "book-title" }, [
                        _vm._v("顶部字体颜色")
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "common-margin-top",
                          attrs: { size: "mini" },
                          model: {
                            value: _vm.data.topFontColor,
                            callback: function($$v) {
                              _vm.$set(_vm.data, "topFontColor", $$v)
                            },
                            expression: "data.topFontColor"
                          }
                        },
                        [
                          _c(
                            "el-radio-button",
                            { attrs: { label: "#FFFFFF" } },
                            [_vm._v("白色")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio-button",
                            { attrs: { label: "#000000" } },
                            [_vm._v("黑色")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isAppHome
                ? _c("div", {
                    staticClass: "dividing-line common-margin-bottom"
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isAppHome
                ? _c(
                    "div",
                    { staticClass: "flex-column common-margin-bottom" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "flex-row flex-space-between",
                          on: { click: _vm.refreshNav }
                        },
                        [
                          _c("span", { staticClass: "book-title" }, [
                            _vm._v(
                              _vm._s(
                                _vm.isCloudRetailPartner ? "首页" : "导航"
                              ) + "按钮"
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "second-title-color refresh-nav" },
                            [
                              _vm._v("重置"),
                              _c("i", {
                                staticClass: "el-icon-refresh refresh-nav-icon"
                              })
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-checkbox-group",
                        {
                          staticClass: "checked-group common-margin-top",
                          model: {
                            value: _vm.data.navigateCheckList,
                            callback: function($$v) {
                              _vm.$set(_vm.data, "navigateCheckList", $$v)
                            },
                            expression: "data.navigateCheckList"
                          }
                        },
                        [
                          _vm._l(_vm.data.navList, function(item, index) {
                            return _c(
                              "div",
                              {
                                key: "el_check" + index,
                                staticClass:
                                  "all-border radius-four navigate-radio"
                              },
                              [
                                _vm.isCloudRetailPartner
                                  ? _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "navigate-radio-absolute",
                                        attrs: { label: item.type }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: false,
                                                expression: "false"
                                              }
                                            ]
                                          },
                                          [_vm._v(_vm._s(item.title))]
                                        )
                                      ]
                                    )
                                  : _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "navigate-radio-absolute",
                                        attrs: { label: item.title }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: false,
                                                expression: "false"
                                              }
                                            ]
                                          },
                                          [_vm._v(_vm._s(item.title))]
                                        )
                                      ]
                                    ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex-column flex-justify-center align-center flex-warp"
                                  },
                                  [
                                    !_vm.isCloudRetailPartner
                                      ? _c("i", {
                                          staticClass: "check-icon",
                                          class: item.icon
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("el-image", {
                                      staticClass: "check-img",
                                      attrs: { src: _vm.getImgUrl(item.icon) }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticStyle: { "margin-top": "5px" } },
                                      [_vm._v(_vm._s(item.title))]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "el-popover",
                            {
                              attrs: { placement: "left", trigger: "manual" },
                              model: {
                                value: _vm.navPopoverShow,
                                callback: function($$v) {
                                  _vm.navPopoverShow = $$v
                                },
                                expression: "navPopoverShow"
                              }
                            },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.addNavList,
                                    callback: function($$v) {
                                      _vm.addNavList = $$v
                                    },
                                    expression: "addNavList"
                                  }
                                },
                                _vm._l(_vm.navigateButtonLists, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      _c(
                                        "el-checkbox",
                                        { attrs: { label: item.title } },
                                        [_vm._v(_vm._s(item.title))]
                                      )
                                    ],
                                    1
                                  )
                                }),
                                0
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "margin-top": "12px",
                                    "border-top": "1px solid #EBEBEB",
                                    "font-size": "12px",
                                    "padding-top": "12px"
                                  }
                                },
                                [
                                  _c(
                                    "el-link",
                                    {
                                      attrs: { underline: false },
                                      on: {
                                        click: function($event) {
                                          _vm.navPopoverShow = false
                                        }
                                      }
                                    },
                                    [_vm._v("取消")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-link",
                                    {
                                      attrs: {
                                        type: "primary",
                                        underline: false
                                      },
                                      on: { click: _vm.handelSubmit }
                                    },
                                    [_vm._v("确定")]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              !_vm.isCloudRetailPartner
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "add-nav-button all-border navigate-radio",
                                      style: {
                                        "margin-right":
                                          (_vm.data.navList.length + 1) % 3 ===
                                          0
                                            ? "0"
                                            : "10px"
                                      },
                                      attrs: { slot: "reference" },
                                      on: { click: _vm.handelShowPopover },
                                      slot: "reference"
                                    },
                                    [
                                      _c("i", { staticClass: "el-icon-plus" }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { "margin-top": "10px" }
                                        },
                                        [_vm._v("添加按钮")]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                : _vm._e()
            ])
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }