"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _common = require("../../../utils/common");

//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'PartnerLogo',
  props: {
    element: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  computed: {
    partnerBorder: function partnerBorder() {
      if (this.element.options.style === 'tile') {
        return 'none';
      }

      return this.element.options.style === 'default' ? '4px solid #fff' : '2px solid #fff';
    }
  },
  methods: {
    // 处理图片
    getImgUrl: function getImgUrl(cover) {
      var style = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'style/width-limit-32';
      var imgAllUrl = (0, _common.imageUrlFun)(cover, style);
      return imgAllUrl;
    }
  }
};
exports.default = _default2;