var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "common-color-border" }, [
    _c(
      "div",
      { staticClass: "flex-row align-center" },
      [
        _c("el-color-picker", {
          attrs: { size: "mini", "show-alpha": _vm.showAlpha },
          model: {
            value: _vm.colorData,
            callback: function($$v) {
              _vm.colorData = $$v
            },
            expression: "colorData"
          }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "common-color-text" }, [
          _vm._v(_vm._s(_vm.colorData))
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c("i", {
      staticClass: "el-icon-refresh icon",
      on: { click: _vm.changeDefault }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }