"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _vuedraggable = _interopRequireDefault(require("vuedraggable"));

var _WidgetFormItem = _interopRequireDefault(require("./WidgetFormItem"));

var _microPage = require("../../utils/microPage");

var _DraggableOptions = _interopRequireDefault(require("./DraggableOptions"));

var _utils = require("../../utils");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var _default = {
  name: 'WidgetForm',
  components: {
    DraggableOptions: _DraggableOptions.default,
    Draggable: _vuedraggable.default,
    WidgetFormItem: _WidgetFormItem.default
  },
  props: ['htmlData', 'data', 'select', 'isCloudRetailPartner'],
  data: function data() {
    return {
      selectWidget: this.select,
      activeIndex: undefined,
      deleteVisible: false
    };
  },
  computed: {
    isShowNavigate: function isShowNavigate() {
      if (this.htmlData.showNavigateBackground) {
        return [{
          background: this.htmlData.navigateBackground
        }, {
          color: this.htmlData.navigateFontAndIconColor
        }];
      }

      return {
        color: ' #fff'
      };
    },
    navigationTime: function navigationTime() {
      if (this.htmlData.showNavigateBackground) {
        return [{
          background: this.htmlData.navigateBackground
        }, {
          color: this.htmlData.topFontColor
        }];
      }

      return {
        color: this.htmlData.topFontColor
      };
    }
  },
  watch: {
    select: function select(val) {
      this.selectWidget = val;
    },
    selectWidget: {
      deep: true,
      handler: function handler(val) {
        this.$emit('update:select', val);
      }
    }
  },
  mounted: function mounted() {
    // eslint-disable-next-line func-names
    document.body.ondrop = function (event) {
      var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

      if (isFirefox) {
        event.preventDefault();
        event.stopPropagation();
      }
    };
  },
  methods: {
    handleActiveIndex: function handleActiveIndex(val) {
      this.activeIndex = val;
    },
    handleSelectWidget: function handleSelectWidget(val) {
      var isType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var index = arguments.length > 2 ? arguments[2] : undefined;
      this.selectWidget = _objectSpread({}, val);

      if (isType) {
        this.activeIndex = undefined;
      } else {
        this.activeIndex = index;
      }

      this.$emit('changeTabs', 'component');
    },
    // 从分类导航传过来的 右侧属性
    changeTabs: function changeTabs() {
      this.$emit('changeTabs', 'component');
    },
    handleMoveEnd: function handleMoveEnd(_ref) {
      var newIndex = _ref.newIndex;
      this.activeIndex = newIndex;
      this.selectWidget = this.data.list[newIndex];
    },
    handleWidgetAdd: function handleWidgetAdd(evt) {
      this.$emit('changeTabs', 'component');
      var newIndex = evt.newIndex;
      this.$store.dispatch('setActiveType', undefined);
      this.activeIndex = newIndex; // 为拖拽到容器的元素添加唯一 key

      var key = "".concat(Date.parse(new Date()), "_").concat(Math.ceil(Math.random() * 99999));
      var data = (0, _microPage.deepCopy)(this.data.list[newIndex]);

      if (data.type === 'carousel' || data.type === 'picture_navigate' || data.type === 'image_advert' || data.type === 'image_navigate' || data.type === 'app_carousel' || data.type === 'app_image_navigate') {
        var newContent = (0, _microPage.deepCopy)(this.data.list[newIndex].content);
        newContent.parts.forEach(function (item) {
          item.id = (0, _utils.createUniqueString)();
        });
        data = _objectSpread(_objectSpread({}, data), {}, {
          content: newContent
        });
      }

      if (data.type === 'types_navigate') {
        data.content.forEach(function (item) {
          item.id = (0, _utils.createUniqueString)();
          item.parts = [];
        });
      }

      var newOptions = (0, _microPage.setAddDraggableData)(data);
      this.$set(this.data.list, newIndex, _objectSpread(_objectSpread({}, newOptions), {}, {
        key: key,
        model: "".concat(this.data.list[newIndex].type, "_").concat(key)
      }));
      this.selectWidget = this.data.list[newIndex];
      var stateData = this.$store.state.microPage.currentFilterList;
      stateData.push(data.type);
      this.$store.dispatch('setCurrentFilterList', stateData);
    },
    //   克隆组件
    handleWidgetClone: function handleWidgetClone(index) {
      var _this = this;

      var cloneData = _objectSpread(_objectSpread({}, this.data.list[index]), {}, {
        options: _objectSpread({}, this.data.list[index].options),
        key: "".concat(Date.parse(new Date()), "_").concat(Math.ceil(Math.random() * 99999))
      });

      if (this.data.list[index].type === 'radio' || this.data.list[index].type === 'checkbox' || this.data.list[index].type === 'select') {
        cloneData = _objectSpread(_objectSpread({}, cloneData), {}, {
          options: _objectSpread(_objectSpread({}, cloneData.options), {}, {
            options: cloneData.options.options.map(function (item) {
              return _objectSpread({}, item);
            })
          })
        });
      }

      this.data.list.splice(index, 0, cloneData);
      this.$nextTick(function () {
        _this.selectWidget = _this.data.list[index + 1];
      });
    }
  }
};
exports.default = _default;