var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "cloud-retail-tool-list",
        _vm.options.style === "row" ? "row-tool-list" : ""
      ]
    },
    _vm._l(_vm.parts, function(item, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "item-position",
          style: {
            width: _vm.widthStyle,
            height: _vm.heightStyle,
            "margin-top":
              item.nav === "商品报价" && _vm.options.style === "column"
                ? "15px"
                : 0
          }
        },
        [
          _c("div", { staticClass: "cloud-retail-tool-item" }, [
            _c(
              "div",
              { staticClass: "common-flex" },
              [
                _c("el-image", {
                  staticClass: "add-icon",
                  attrs: {
                    mode: "fit",
                    src: _vm.getImg(
                      "assets/taozhi/shopMiniProgram/home/add-icon.png"
                    )
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "title" }, [
                  _vm._v(_vm._s(item.title))
                ]),
                _vm._v(" "),
                _c("el-image", {
                  staticClass: "next-icon",
                  attrs: {
                    mode: "fit",
                    src: _vm.getImg(
                      "assets/taozhi/shopMiniProgram/home/" +
                        (item.nav !== "商品报价"
                          ? "active-next-icon"
                          : "blue-next-icon") +
                        ".png"
                    )
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "describe" }, [
              _vm._v(_vm._s(item.describe))
            ])
          ]),
          _vm._v(" "),
          _c("el-image", {
            staticClass: "img-bg",
            attrs: { src: _vm.getPath(item), fit: "fill" }
          })
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }