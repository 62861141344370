"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _main = _interopRequireDefault(require("@/organizationLibs/powerCache/v1.0/main"));

var _index = _interopRequireDefault(require("../../SearchButton/index"));

var _index2 = _interopRequireDefault(require("../../FilterOptions/index"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'CPMGrHeaderSearchGroupIndex',
  components: {
    SearchButton: _index.default,
    FilterOptions: _index2.default
  },
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    placeholder: {
      type: String,
      default: '商品名称、编号搜索'
    },
    showRemember: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      resourceTypeOptions: [{
        value: 20,
        label: '实物商品'
      }, {
        value: 21,
        label: '数字商品'
      }, {
        value: 22,
        label: '组合商品'
      }],
      remember: false
    };
  },
  computed: {
    valueProxy: {
      get: function get() {
        var store = _main.default.get("select-product-remember_".concat(this.$store.state.microPage.partnerOpenId));

        this.remember = store ? store.remember : false;
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    handleRemember: function handleRemember(val) {
      var store = _main.default.get("select-product-remember_".concat(this.$store.state.microPage.partnerOpenId));

      if (!store) {
        store = {};
      }

      store.remember = val;
      console.log(store);

      _main.default.put("select-product-remember_".concat(this.$store.state.microPage.partnerOpenId), store);
    },
    searchChanged: function searchChanged() {
      this.$emit('search-changed');
    }
  }
};
exports.default = _default2;