"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDictionary = getDictionary;
exports.addItem = addItem;
exports.updateItem = updateItem;
exports.deleteItem = deleteItem;
exports.restoreItem = restoreItem;
exports.getResourceType = getResourceType;
exports.getResourceTypeAll = getResourceTypeAll;
exports.addResourceType = addResourceType;
exports.updateResourceType = updateResourceType;
exports.deleteResourceType = deleteResourceType;
exports.restoreResourceType = restoreResourceType;
exports.getApplicationAll = getApplicationAll;
exports.addApplication = addApplication;
exports.updateApplication = updateApplication;
exports.deleteApplication = deleteApplication;
exports.restoreApplication = restoreApplication;
exports.getApplicationList = getApplicationList;
exports.addSocialApplication = addSocialApplication;
exports.updateSocialApplication = updateSocialApplication;
exports.deleteSocialApplication = deleteSocialApplication;
exports.restoreSocialApplication = restoreSocialApplication;
exports.syncSectionAdd = syncSectionAdd;
exports.getAllSyncSection = getAllSyncSection;
exports.updateSyncSection = updateSyncSection;
exports.deleteSyncSection = deleteSyncSection;
exports.syncSubjectAdd = syncSubjectAdd;
exports.getAllSyncSubject = getAllSyncSubject;
exports.updateSyncSubject = updateSyncSubject;
exports.deleteSyncSubject = deleteSyncSubject;
exports.syncGradeAdd = syncGradeAdd;
exports.getAllSyncGrade = getAllSyncGrade;
exports.updateSyncGrade = updateSyncGrade;
exports.deleteSyncGrade = deleteSyncGrade;
exports.syncEditionAdd = syncEditionAdd;
exports.getAllSyncEdition = getAllSyncEdition;
exports.updateSyncEdition = updateSyncEdition;
exports.deleteSyncEdition = deleteSyncEdition;
exports.syncVolumeAdd = syncVolumeAdd;
exports.getAllSyncVolume = getAllSyncVolume;
exports.updateSyncVolume = updateSyncVolume;
exports.deleteSyncVolume = deleteSyncVolume;
exports.syncPeriodAdd = syncPeriodAdd;
exports.getAllSyncPeriod = getAllSyncPeriod;
exports.updateSyncPeriod = updateSyncPeriod;
exports.deleteSyncPeriod = deleteSyncPeriod;
exports.getRegimentationAll = getRegimentationAll;
exports.addRegimentation = addRegimentation;
exports.editRegimentation = editRegimentation;
exports.deleteRegimentation = deleteRegimentation;
exports.getRegimentationAllByParentId = getRegimentationAllByParentId;
exports.getRegimentationById = getRegimentationById;
exports.getSyncSubject = getSyncSubject;
exports.getSyncSection = getSyncSection;
exports.getSyncEdition = getSyncEdition;
exports.getSyncGrade = getSyncGrade;
exports.getSyncVolume = getSyncVolume;
exports.getSyncPeriod = getSyncPeriod;
exports.getCategoryView = getCategoryView;
exports.getAllPostCompany = getAllPostCompany;
exports.getPostCompanyById = getPostCompanyById;
exports.addPostCompany = addPostCompany;
exports.deletePostCompany = deletePostCompany;
exports.updatePostCompany = updatePostCompany;
exports.getfindAllByActive = getfindAllByActive;
exports.getResourceTypeList = getResourceTypeList;
exports.getDictionaryList = getDictionaryList;
exports.getApplicationPageList = getApplicationPageList;
exports.getAllPostCompanyList = getAllPostCompanyList;
exports.getAllSyncVolumeList = getAllSyncVolumeList;
exports.getAllSyncSubjectList = getAllSyncSubjectList;
exports.getAllSyncPeriodList = getAllSyncPeriodList;
exports.getAllSyncSectionList = getAllSyncSectionList;
exports.getAllSyncEditionList = getAllSyncEditionList;
exports.getAllSyncGradeList = getAllSyncGradeList;
exports.getDictionaryPageList = getDictionaryPageList;
exports.getAreaList = getAreaList;
exports.getAreaChildrenList = getAreaChildrenList;
exports.addArea = addArea;
exports.updateArea = updateArea;
exports.delArea = delArea;
exports.getAreaDetail = getAreaDetail;
exports.getSchool = getSchool;
exports.addSchool = addSchool;
exports.updateSchool = updateSchool;
exports.delSchool = delSchool;
exports.getBookAreaArray = getBookAreaArray;
exports.addBookArea = addBookArea;
exports.updateBookArea = updateBookArea;
exports.delBookArea = delBookArea;
exports.getAllBookArea = getAllBookArea;
exports.getAllAreaList = getAllAreaList;
exports.areaAll = areaAll;
exports.addGradeSubject = addGradeSubject;
exports.updateEditionCodes = updateEditionCodes;
exports.addGradeSubjectBatch = addGradeSubjectBatch;
exports.delGradeSubjectBatch = delGradeSubjectBatch;
exports.delGradeSubject = delGradeSubject;
exports.getSubjectByGradeCode = getSubjectByGradeCode;
exports.getEditionList = getEditionList;
exports.getUserTagList = getUserTagList;
exports.addUserTag = addUserTag;
exports.updateUserTag = updateUserTag;
exports.deleteTag = deleteTag;
exports.getSyncEditionBySubjectCode = getSyncEditionBySubjectCode;
exports.getgetBoothsList = getgetBoothsList;
exports.getPageList = getPageList;
exports.addPage = addPage;
exports.updatePage = updatePage;
exports.deletePage = deletePage;
exports.getBannerListApi = getBannerListApi;
exports.addBoothName = addBoothName;
exports.updateBoothName = updateBoothName;
exports.removeByBoothCode = removeByBoothCode;
exports.batchEnable = batchEnable;
exports.getAllPageApi = getAllPageApi;
exports.getPageTreeApi = getPageTreeApi;
exports.jumpAllowApi = jumpAllowApi;
exports.getByPageCode = getByPageCode;
exports.enableBannerApi = enableBannerApi;
exports.resetSequenceByPageCodeSort = resetSequenceByPageCodeSort;
exports.getAllPageApiList = getAllPageApiList;
exports.getAllPageInfoApi = getAllPageInfoApi;
exports.getAllBoothListApi = getAllBoothListApi;
exports.getPageUrlByAppProductOpenIdAndPageCode = getPageUrlByAppProductOpenIdAndPageCode;
exports.getPageTreemanerApi = getPageTreemanerApi;
exports.getJumpPageLists = getJumpPageLists;
exports.getBrandList = getBrandList;
exports.getSyncElective = getSyncElective;
exports.getGetProductGroupTree = getGetProductGroupTree;
exports.getGetRegimentationTree = getGetRegimentationTree;
exports.getSeriesList = getSeriesList;
exports.getSubjectListByGradeCodes = getSubjectListByGradeCodes;

var _main = _interopRequireDefault(require("../../utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/common/dictionary/mp/"));
var requestAdmin = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/common/dictionary/admin/"));
var commonRequest = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/common/dictionary/common/"));
var dictionaryRequest = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/common/dictionary/"));
/**
 * 查询所有微服务应用
 * @returns {Promise<*>}
 */

function getApplicationAll() {
  return request.get('/service/backend');
}
/**
 * 增加微服务应用
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addApplication(data) {
  return request.post('/service/backend', data);
}
/**
 * 修改微服务应用
 * @param {String} id
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateApplication(id, data) {
  return request.patch("/service/backend/".concat(id), data);
}
/**
 * 删除微服务应用
 * @param {String} id
 * @returns {Promise<*>}
 */


function deleteApplication(id) {
  return request.delete("/service/backend/".concat(id));
}
/**
 * 恢复删除的微服务应用
 * @param {String} id
 * @returns {Promise<*>}
 */


function restoreApplication(id) {
  return request.post("/service/backend/restore/".concat(id));
}
/**
 * 查询所有应用
 * @returns {Promise<*>}
 */


function getApplicationList() {
  return commonRequest.get('/service_social/getAll');
}
/**
 * 查询社会化应用
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getApplicationPageList(params) {
  return request.get('/service_social', {
    params: params
  });
}
/**
 * 增加社会化应用
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addSocialApplication(data) {
  return request.post('/service_social', data);
}
/**
 * 修改指定的社会化应用
 * @param {String} id
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateSocialApplication(id, data) {
  return request.patch("/service_social/".concat(id), data);
}
/**
 * 删除社会化应用
 * @param {String} id
 * @returns {Promise<*>}
 */


function deleteSocialApplication(id) {
  return request.delete("/service_social/".concat(id));
}
/**
 * 恢复删除的社会化应用
 * @param {String} id
 * @returns {Promise<*>}
 */


function restoreSocialApplication(id) {
  return request.post("/service_social/restore/".concat(id));
}
/**
 * 查询资源类型
 * @returns {Promise<*>}
 */


function getResourceType() {
  return commonRequest.get('/resource/type');
}
/**
 * 获取所有的系统资源类型
 * @returns {Promise<*>}
 */


function getResourceTypeAll() {
  return commonRequest.get('/resource/type/getAll');
}
/**
 * 获取所有的系统资源类型
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getResourceTypeList(params) {
  return request.get('/resource/type', {
    params: params
  });
}
/**
 * 添加资源类型
 * @param {Object} data
 * @returns {*}
 */


function addResourceType(data) {
  return request.post('/resource/type', data);
}
/**
 * 编辑资源类型
 * @param {String} id
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateResourceType(id, data) {
  return request.patch("/resource/type/".concat(id), data);
}
/**
 * 删除资源类型
 * @param {String} id
 * @returns {Promise<*>}
 */


function deleteResourceType(id) {
  return request.delete("/resource/type/".concat(id));
}
/**
 * 恢复资源类型
 * @param {String} id
 * @returns {Promise<*>}
 */


function restoreResourceType(id) {
  return request.post("/resource/type/restore/".concat(id));
}
/**
 * 查詢产品应用列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getDictionary(params) {
  return commonRequest.get('/service/getAll', {
    params: params
  });
}
/**
 * 查詢产品应用列表(用于下拉列表)
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getDictionaryList() {
  return commonRequest.get('/service/getAll');
}
/**
 * 查詢产品应用列表（分页）
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getDictionaryPageList(params) {
  return request.get('/service', {
    params: params
  });
}
/**
 * 增加产品应用
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addItem(data) {
  return request.post('/service', data);
}
/**
 * 修改系统产品应用
 * @param {String} id
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateItem(id, data) {
  return request.patch("/service/".concat(id), data);
}
/**
 * 删除系统产品应用
 * @param {String} id
 * @returns {Promise<*>}
 */


function deleteItem(id) {
  return request.delete("/service/".concat(id));
}
/**
 * 恢复已删除系统产品应用
 * @param {String} id
 * @returns {Promise<*>}
 */


function restoreItem(id) {
  return request.post("/service/restore/".concat(id));
}
/**
 * 新增学段表信息
 * @param {Object} data
 * @returns {Promise<*>}
 */


function syncSectionAdd(data) {
  return request.post('/syncSection/add', data);
}
/**
 * 获取所有学段表信息
 * @returns {Promise<*>}
 */


function getAllSyncSection() {
  return request.get('/syncSection/getAll');
}
/**
 * 获取学段表信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAllSyncSectionList(params) {
  return request.get('/syncSection', {
    params: params
  });
}
/**
 * 更新学段表信息
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateSyncSection(id, data) {
  return request.patch("/syncSection/update/".concat(id), data);
}
/**
 * 根据id删除学段表信息（假删除）
 */


function deleteSyncSection(id) {
  return request.delete("/syncSection/".concat(id));
}
/**
 * 新增学科表信息
 * @param {Object} data
 * @returns {Promise<*>}
 */


function syncSubjectAdd(data) {
  return request.post('/syncSubject/add', data);
}
/**
 * 获取所有学科表信息
 * @returns {Promise<*>}
 */


function getAllSyncSubject() {
  return request.get('/syncSubject/getAll');
}
/**
 * 获取学科表信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAllSyncSubjectList(params) {
  return request.get('/syncSubject', {
    params: params
  });
}
/**
 * 更新学科表信息
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateSyncSubject(id, data) {
  return request.patch("/syncSubject/update/".concat(id), data);
}
/**
 * 根据id删除学科表信息（假删除）
 */


function deleteSyncSubject(id) {
  return request.delete("/syncSubject/".concat(id));
}
/**
 * 新增年级表信息
 * @param {Object} data
 * @returns {Promise<*>}
 */


function syncGradeAdd(data) {
  return request.post('/syncGrade/add', data);
}
/**
 * 获取所有年级表信息
 * @returns {Promise<*>}
 */


function getAllSyncGrade() {
  return request.get('/syncGrade/getAll');
}
/**
 * 获取年级表信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAllSyncGradeList(params) {
  return request.get('/syncGrade', {
    params: params
  });
}
/**
 * 更新年级表信息
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateSyncGrade(id, data) {
  return request.patch("/syncGrade/update/".concat(id), data);
}
/**
 * 根据id删除年级表信息（假删除）
 */


function deleteSyncGrade(id) {
  return request.delete("/syncGrade/".concat(id));
}
/**
 * 新增版本表信息
 * @param {Object} data
 * @returns {Promise<*>}
 */


function syncEditionAdd(data) {
  return request.post('/syncEdition/add', data);
}
/**
 * 获取所有版本表信息
 * @returns {Promise<*>}
 */


function getAllSyncEdition() {
  return request.get('/syncEdition/getAll');
}
/**
 * 根据学科查询所关联的版本列表
 * @returns {Promise<*>}
 */


function getSyncEditionBySubjectCode(params) {
  return request.get('/gradeSubject/getEditionsBySubjectCode', {
    params: params
  });
}
/**
 * 获取版本表信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAllSyncEditionList(params) {
  return request.get('/syncEdition', {
    params: params
  });
}
/**
 * 更新版本表信息
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateSyncEdition(id, data) {
  return request.patch("/syncEdition/update/".concat(id), data);
}
/**
 * 根据id删除版本表信息（假删除）
 */


function deleteSyncEdition(id) {
  return request.delete("/syncEdition/".concat(id));
}
/**
 * 新增阶段表信息
 * @param {Object} data
 * @returns {Promise<*>}
 */


function syncVolumeAdd(data) {
  return request.post('/syncVolume/add', data);
}
/**
 * 获取所有阶段表信息
 * @returns {Promise<*>}
 */


function getAllSyncVolume() {
  return request.get('/syncVolume/getAll');
}
/**
 * 获取阶段表信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAllSyncVolumeList(params) {
  return request.get('/syncVolume', {
    params: params
  });
}
/**
 * 更新阶段表信息
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateSyncVolume(id, data) {
  return request.patch("/syncVolume/update/".concat(id), data);
}
/**
 * 根据id删除阶段表信息（假删除）
 */


function deleteSyncVolume(id) {
  return request.delete("/syncVolume/".concat(id));
}
/**
 * 新增版次表信息
 * @param {Object} data
 * @returns {Promise<*>}
 */


function syncPeriodAdd(data) {
  return request.post('/syncPeriod/add', data);
}
/**
 * 获取所有版次表信息
 * @returns {Promise<*>}
 */


function getAllSyncPeriod() {
  return request.get('/syncPeriod/getAll');
}
/**
 * 获取版次表信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAllSyncPeriodList(params) {
  return request.get('/syncPeriod', {
    params: params
  });
}
/**
 * 更新版次表信息
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateSyncPeriod(id, data) {
  return request.patch("/syncPeriod/update/".concat(id), data);
}
/**
 * 根据id删除版次表信息（假删除）
 * @param {Number} id
 * @returns {Promise<*>}
 */


function deleteSyncPeriod(id) {
  return request.delete("/syncPeriod/".concat(id));
}
/**
 * 获取所有最高类目信息
 * @returns {Promise<*>}
 */


function getRegimentationAll() {
  return request.get('/regimentation/getRegimentationAll');
}
/**
 * 新增类目信息
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addRegimentation(data) {
  return request.post('/regimentation/add', data);
}
/**
 * 修改类目信息
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise<*>}
 */


function editRegimentation(id, data) {
  return request.patch("/regimentation/update/".concat(id), data);
}
/**
 * 根据id删除类目信息
 * @param id
 * @returns {AxiosPromise}
 */


function deleteRegimentation(id) {
  return request.delete("/regimentation/".concat(id));
}
/**
 * 根据 parentId 获取二级类目信息
 * @param params
 * @returns {AxiosPromise<any>}
 */


function getRegimentationAllByParentId(params) {
  return request.get('/regimentation/getRegimentationAllByParentId', {
    params: params
  });
}
/**
 * 根据id获取类目信息
 * @param {Number} id
 * @returns {Promise<*>}
 */


function getRegimentationById(id) {
  return request.get("/regimentation/getRegimentationById/".concat(id));
}
/**
 * 获取所有学科表信息
 * @returns {Promise<*>}
 */


function getSyncSubject() {
  return request.get('/syncSubject/getAll');
}
/**
 * 获取所有学段表信息
 * @returns {Promise<*>}
 */


function getSyncSection() {
  return request.get('/syncSection/getAll');
}
/**
 * 获取所有版本表信息
 * @returns {Promise<*>}
 */


function getSyncEdition() {
  return request.get('/syncEdition/getAll');
}
/**
 * 获取所有年级表信息
 * @returns {Promise<*>}
 */


function getSyncGrade() {
  return request.get('/syncGrade/getAll');
}
/**
 * 获取所有阶段表信息
 * @returns {Promise<*>}
 */


function getSyncVolume() {
  return request.get('/syncVolume/getAll');
}
/**
 * 获取所有版次表信息
 * @returns {Promise<*>}
 */


function getSyncPeriod() {
  return request.get('/syncPeriod/getAll');
}
/**
 * 获取所有类目表信息
 * @returns {Promise<*>}
 */


function getCategoryView() {
  return request.get('/regimentation/getRegimentationTree');
}
/**
 * 获取所有物流渠道信息
 * @returns {Promise<*>}
 */


function getAllPostCompany() {
  return request.get('/postCompany/getAll');
}
/**
 * 获取所有物流渠道信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAllPostCompanyList(params) {
  return request.get('/postCompany', {
    params: params
  });
}
/**
 * 根据id获取物流渠道信息
 * @param {Number} id
 * @returns {Promise<*>}
 */


function getPostCompanyById(id) {
  return request.get("/postCompany/getPostCompanyById/".concat(id));
}
/**
 * 新增物流渠道信息
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addPostCompany(data) {
  return request.post('/postCompany/add', data);
}
/**
 * 根据id删除物流渠道信息(假删除)
 * @param id
 * @returns {AxiosPromise}
 */


function deletePostCompany(id) {
  return request.delete("/postCompany/".concat(id));
}
/**
 * 根据id修改物流渠道信息
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updatePostCompany(id, data) {
  return request.patch("/postCompany/update/".concat(id), data);
}
/**
 * 获取所有已激活的物流渠道
 * @returns {Promise<*>}
 */


function getfindAllByActive() {
  return request.get('/postCompany/findAllByActive');
}
/**
 * 查询地区列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAreaList(params) {
  return request.get('/area', {
    params: params
  });
}
/**
 * 查询下级地区列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAreaChildrenList(params) {
  return request.get('/area/getChildren', {
    params: params
  });
}
/**
 * 新增地区信息
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addArea(data) {
  return request.post('/area', data);
}
/**
 * 修改地区信息
 * @param {String} id
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateArea(id, data) {
  return request.put("/area/".concat(id), data);
}
/**
 * 删除地区信息
 * @param {String} id
 * @param {Object} data
 * @returns {Promise<*>}
 */


function delArea(id) {
  return request.delete("/area/".concat(id));
}
/**
 * 根据行政区域编码查询地区信息
 * @param {Object} code
 * @returns {Promise<*>}
 */


function getAreaDetail(code) {
  return request.get('/area/getArea', {
    params: {
      code: code
    }
  });
}
/**
 * 查询省市县三级数据列表
 * @returns {Promise<*>}
 */


function getAllAreaList() {
  return request.get('/area/all');
}
/**
 * 查询学校列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getSchool(params) {
  return request.get('/school', {
    params: params
  });
}
/**
 * 新增学校
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addSchool(data) {
  return request.post('/school', data);
}
/**
 * 修改学校信息
 * @param {String} id
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateSchool(id, data) {
  return request.put("/school/".concat(id), data);
}
/**
 * 删除学校信息
 * @param {String} id
 * @param {Object} data
 * @returns {Promise<*>}
 */


function delSchool(id) {
  return request.delete("/school/".concat(id));
}
/**
 * 查询图书地区列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getBookAreaArray(params) {
  return request.get('/bookArea', {
    params: params
  });
}
/**
 * 新增学校
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addBookArea(data) {
  return request.post('/bookArea', data);
}
/**
 * 修改图书地区
 * @param {String} id
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateBookArea(id, data) {
  return request.put("/bookArea/".concat(id), data);
}
/**
 * 删除图书地区
 * @param {String} id
 * @param {Object} data
 * @returns {Promise<*>}
 */


function delBookArea(id) {
  return request.delete("/bookArea/".concat(id));
}
/**
 * 查询所有图书地区
 * @returns {Promise<*>}
 */


function getAllBookArea() {
  return request.get('/bookArea/all');
}
/**
 * 获取所有省市县
 *
 * @returns {Promise<*>}
 */


function areaAll() {
  return request.get('area/all');
}
/**
 * 增加新增年级学科关系
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addGradeSubject(data) {
  return request.post('/gradeSubject', data);
}
/**
 * 更新年级学科关系中的版本信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function updateEditionCodes(params) {
  return request.put('/gradeSubject/updateEditionCodes', null, {
    params: params
  });
}
/**
 * 批量添加年级学科关系
 * @param {Object} params
 * @returns {Promise<*>}
 */


function addGradeSubjectBatch(params) {
  return request.post('/gradeSubject/addBatch', null, {
    params: params
  });
}
/**
 * 批量删除年级学科关系
 * @param {Object} params
 * @returns {Promise<*>}
 */


function delGradeSubjectBatch(params) {
  return request.delete('/gradeSubject/deleteBatch', {
    params: params
  });
}
/**
 * 删除年级学科信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function delGradeSubject(params) {
  return request.delete('/gradeSubject', {
    params: params
  });
}
/**
 * 查询指定年级的学科列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getSubjectByGradeCode(params) {
  return request.get('/gradeSubject/getByGradeCode', {
    params: params
  });
}
/**
 * 查询指定的年级学科信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getEditionList(params) {
  return request.get('/gradeSubject/getByGradeCodeAndSubjectCode', {
    params: params
  });
}
/**
 * 分页查询标签列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getUserTagList(params) {
  return request.get('/tag', {
    params: params
  });
}
/**
 * 新增标签
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addUserTag(data) {
  return request.post('/tag', data);
}
/**
 * 修改标签信息
 * @param {String} id
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateUserTag(id, data) {
  return request.put("/tag/update/".concat(id), data);
}
/**
 * 删除标签信息
 * @param {String} id
 * @returns {Promise<*>}
 */


function deleteTag(id) {
  return request.delete("/tag/".concat(id));
}
/**
 * 分页查询展位列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getgetBoothsList(params) {
  return request.get('/booth/getBoothsList', {
    params: params
  });
}
/**
 * 分页查询页面列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getPageList(params) {
  return request.get('/page/getPageList', {
    params: params
  });
}
/**
 * 获取全部未删除的页面
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAllPageApiList() {
  return request.get('/page/getAllPage');
}
/**
 * 获取页面 结构
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAllPageApi(params) {
  return request.get('/page/getPageTreeByAppProductOpenIds', {
    params: params
  });
}
/**
 * 获取所有页面信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAllPageInfoApi() {
  return request.get('/page/getAllPageDict');
}
/**
 * 获取所有展位信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAllBoothListApi() {
  return request.get('booth/getAllBooth');
}
/**
 * 获取页面-展位树状结构
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getPageTreeApi(params) {
  return request.get('/page/getBoothTreeByAppProductOpenIds', {
    params: params
  });
}
/**
 * 获取页面-展位树状结构2
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getPageTreemanerApi(params) {
  return request.get('/page/getPageTreeJumpByAppProductOpenIds', {
    params: params
  });
}
/**
 * 重置能否跳转
 * @param {String} pageCode
 * @returns {Promise<*>}
 */


function jumpAllowApi(pageCode, params) {
  return request.post("/page/resetJumpByPageCode/".concat(pageCode), undefined, {
    params: params
  });
}
/**
 * 是否启用横幅
 * @param {String} pageCode
 * @returns {Promise<*>}
 */


function enableBannerApi(pageCode, params) {
  return request.post("/page/resetEnableByPageCode/".concat(pageCode), undefined, {
    params: params
  });
}
/**
 * 重置排序
 * @param {String} pageCode
 * @returns {Promise<*>}
 */


function resetSequenceByPageCodeSort(pageCode, params) {
  return request.post("/page/resetSequenceByPageCode/".concat(pageCode), undefined, {
    params: params
  });
}
/**
 * 根据pageCode获取页面详情
 * @param {Object} pageCode
 * @returns {Promise<*>}
 */


function getByPageCode(pageCode) {
  return request.get("/page/getByPageCode/".concat(pageCode));
}
/**
 * 新增页面
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addPage(data) {
  return request.post('/page/store', data);
}
/**
 * 编辑页面信息
 * @param {String} id
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updatePage(id, data) {
  return request.post("/page/update/".concat(id), data);
}
/**
 * 删除页面
 * @param {String} id
 * @returns {Promise<*>}
 */


function deletePage(id) {
  return request.post("/page/removeByPageCode/".concat(id));
}
/**
 * 分页查询页面列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getBannerListApi(pageCode) {
  return request.get("/booth/getAllByPageCode/".concat(pageCode));
}
/**
 * 新增展位
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addBoothName(data) {
  return request.post('/booth/add', data);
}
/**
 * 更新展位
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateBoothName(id, data) {
  return request.post("/booth/update/".concat(id), data);
}
/**
 * 删除展位
 * @param {Object} data
 * @returns {Promise<*>}
 */


function removeByBoothCode(boothCode) {
  return request.post("/booth/removeByBoothCode/".concat(boothCode));
}
/**
 * 启用关闭展位包括批量
 * @param {Object} data
 * @returns {Promise<*>}
 */


function batchEnable(params) {
  return request.post('/booth/batchEnable/', undefined, {
    params: params
  });
}
/**
 * 根据系统openId和页面code获取页面路径
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getPageUrlByAppProductOpenIdAndPageCode(params) {
  return request.get('/page/getPageUrlByAppProductOpenIdAndPageCode', {
    params: params
  });
}
/**
 * 获取能跳转分页查询页面列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getJumpPageLists(params) {
  return request.get('/page/getJumpPageLists', {
    params: params
  });
}
/**
 * 品牌方列表
 *
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getBrandList(params) {
  return request.get('/brand/list', {
    params: params
  });
}
/**
 * 选必修列表
 *
 * @param {object} params
 * @returns {Promise<*>}
 */


function getSyncElective(params) {
  return request.get('syncElective', {
    params: params
  });
}
/**
 * 查询商品分类
 *
 * @returns {Promise<*>}
 */


function getGetProductGroupTree(params) {
  return requestAdmin.get('product/group/getProductGroupTree', {
    params: params
  });
}
/**
 * 查询二级类目列表
 *
 * @returns {Promise<*>}
 */


function getGetRegimentationTree() {
  return requestAdmin.get('product/regimentation/getRegimentationTree');
}
/**
 * 系列
 *
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getSeriesList(params) {
  return request.get('/series/list', {
    params: params
  });
}
/**
 * 根据年级code获取级联的学科数据(去重)
 * @returns {Promise<*>}
 * @param {Object} params
 */


function getSubjectListByGradeCodes(params) {
  return dictionaryRequest.get('client/gradeSubject/getSubjectListByGradeCodes', {
    params: params
  });
}