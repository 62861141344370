"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

require("core-js/modules/web.dom.iterable");

var _sortablejs = require("sortablejs");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'SelectResult',
  props: {
    resultList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    type: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      checkList: [],
      checkAll: false,
      indeterminate: false,
      list: [],
      rowKey: 'resourceOpenId',
      dragClassName: 'drag-result-table',
      isShowToolTip: true
    };
  },
  watch: {
    resultList: {
      deep: true,
      immediate: true,
      handler: function handler(val) {
        this.initData(val);
      }
    }
  },
  methods: {
    initData: function initData(val) {
      var _this = this;

      this.isShowToolTip = true;

      if (this.type === 'famous_teachers') {
        this.rowKey = 'famousTeacherOpenId';
        this.dragClassName = 'drag-famous-table';
      }

      if (this.type === 'coupon') {
        this.rowKey = 'couponOpenId';
        this.dragClassName = 'drag-coupon-table';
      }

      if (this.type === 'book_shelf') {
        this.rowKey = 'openId';
        this.dragClassName = 'drag-shelf-table';
      }

      if (this.type === 'test_paper') {
        this.rowKey = 'examPaperOpenId';
        this.dragClassName = 'drag-shelf-table';
      }

      if (this.type === 'test_questions') {
        this.rowKey = 'examQuestionOpenId';
        this.dragClassName = 'drag-shelf-table';
      }

      if (this.type === 'live' || this.type === 'knowledge_goods') {
        this.rowKey = 'resourceOpenId';
        this.dragClassName = 'drag-result-table';
      }

      if (this.type === 'cloud_retail_goods') {
        this.rowKey = 'productOpenId';
        this.dragClassName = 'drag-product-table';
      }

      this.list = val;
      this.$emit('handleResult', this.list); // 处理托拽

      this.$nextTick(function () {
        _this.drag();
      });
    },
    drag: function drag() {
      var _this2 = this;

      var el = document.querySelectorAll(".".concat(this.dragClassName, " tbody"))[0];

      _sortablejs.Sortable.create(el, {
        onChoose: function onChoose(e) {
          _this2.isShowToolTip = false;
        },
        onEnd: function onEnd(e) {
          _this2.isShowToolTip = true;
          var arr = _this2.list;
          arr.splice(e.newIndex, 0, arr.splice(e.oldIndex, 1)[0]);

          _this2.$nextTick(function () {
            _this2.list = arr;
          });
        }
      });
    },
    deleteResult: function deleteResult(val) {
      if (this.type === 'coupon') {
        this.list = this.list.filter(function (item) {
          return item.couponOpenId !== val.couponOpenId;
        });
      } else if (this.type === 'book_shelf') {
        var newList = [];
        this.list.forEach(function (item) {
          if (item.isAudioBook === 0 && item.resourceOpenId !== val.resourceOpenId) {
            newList.push(item);
          }

          if (item.isAudioBook === 1 && item.bookOpenId !== val.bookOpenId) {
            newList.push(item);
          }

          if (item.isAudioBook === 2 && item.openId !== val.openId) {
            newList.push(item);
          }

          if (item.isAudioBook === 3 && item.openId !== val.openId) {
            newList.push(item);
          }
        });
        this.list = newList;
      } else if (this.type === 'famous_teachers') {
        this.list = this.list.filter(function (item) {
          return item.famousTeacherOpenId !== val.famousTeacherOpenId;
        });
      } else if (this.type === 'live') {
        this.list = this.list.filter(function (item) {
          return item.resourceOpenId !== val.resourceOpenId;
        });
      } else if (this.type === 'knowledge_goods') {
        this.list = this.list.filter(function (item) {
          return item.resourceOpenId !== val.resourceOpenId;
        });
      } else if (this.type === 'test_paper') {
        this.list = this.list.filter(function (item) {
          return item.examPaperOpenId !== val.examPaperOpenId;
        });
      } else if (this.type === 'test_questions') {
        this.list = this.list.filter(function (item) {
          return item.examQuestionOpenId !== val.examQuestionOpenId;
        });
      } else if (this.type === 'cloud_retail_goods') {
        this.list = this.list.filter(function (item) {
          return item.productOpenId !== val.productOpenId;
        });
      }

      this.$emit('handleDeleteResult', this.list);
      this.$emit('handleResult', this.list);
    },
    bathDeleteResult: function bathDeleteResult() {
      var _this3 = this;

      var checkNewList = (0, _toConsumableArray2.default)(this.checkList);

      if (this.type === 'coupon') {
        checkNewList = checkNewList.map(function (item) {
          return item.couponOpenId;
        });
        this.list = this.list.filter(function (item) {
          return !checkNewList.includes(item.couponOpenId);
        });
      } else if (this.type === 'book_shelf') {
        this.checkList.forEach(function (item) {
          if (item.isAudioBook === 0) {
            _this3.list = _this3.list.filter(function (n) {
              if (n.isAudioBook === 0 && item.resourceOpenId !== n.resourceOpenId) {
                return item;
              }

              if (n.isAudioBook !== 0) {
                return item;
              }
            });
          } else if (item.isAudioBook === 1) {
            _this3.list = _this3.list.filter(function (n) {
              if (n.isAudioBook === 1 && item.bookOpenId !== n.bookOpenId) {
                return item;
              }

              if (n.isAudioBook !== 1) {
                return item;
              }
            });
          } else if (item.isAudioBook === 2) {
            _this3.list = _this3.list.filter(function (n) {
              if (n.isAudioBook === 2 && item.openId !== n.openId) {
                return item;
              }

              if (n.isAudioBook !== 2) {
                return item;
              }
            });
          } else if (item.isAudioBook === 3) {
            _this3.list = _this3.list.filter(function (n) {
              if (n.isAudioBook === 3 && item.openId !== n.openId) {
                return item;
              }

              if (n.isAudioBook !== 3) {
                return item;
              }
            });
          }
        });
      } else if (this.type === 'famous_teachers') {
        checkNewList = checkNewList.map(function (item) {
          return item.famousTeacherOpenId;
        });
        this.list = this.list.filter(function (item) {
          return !checkNewList.includes(item.famousTeacherOpenId);
        });
      } else if (this.type === 'live') {
        checkNewList = checkNewList.map(function (item) {
          return item.resourceOpenId;
        });
        this.list = this.list.filter(function (item) {
          return !checkNewList.includes(item.resourceOpenId);
        });
      } else if (this.type === 'knowledge_goods') {
        checkNewList = checkNewList.map(function (item) {
          return item.resourceOpenId;
        });
        this.list = this.list.filter(function (item) {
          return !checkNewList.includes(item.resourceOpenId);
        });
      } else if (this.type === 'test_paper') {
        checkNewList = checkNewList.map(function (item) {
          return item.examPaperOpenId;
        });
        this.list = this.list.filter(function (item) {
          return !checkNewList.includes(item.examPaperOpenId);
        });
      } else if (this.type === 'test_questions') {
        checkNewList = checkNewList.map(function (item) {
          return item.examQuestionOpenId;
        });
        this.list = this.list.filter(function (item) {
          return !checkNewList.includes(item.examQuestionOpenId);
        });
      }

      this.$emit('handleDeleteResult', this.list);
      this.$emit('handleResult', this.list);
    },
    clearList: function clearList() {
      this.list = [];
      this.$emit('handleDeleteResult', []);
      this.$emit('handleResult', []);
    },
    handleGroup: function handleGroup(val) {
      // this.$emit('handleResult', val);
      if (val.length === this.list.length) {
        this.checkAll = true;
        this.indeterminate = false;
        this.checkList = val;
      }

      if (val.length < this.list.length && val.length > 0) {
        this.checkAll = false;
        this.indeterminate = true;
        this.checkList = val;
      }

      if (val.length === 0) {
        this.checkAll = false;
        this.indeterminate = false;
        this.checkList = [];
      }
    },
    handleSelect: function handleSelect(val) {
      var _this4 = this;

      if (val.length > 0) {
        this.list.forEach(function (item) {
          if (_this4.$refs.multipleList) {
            _this4.$refs.multipleList.toggleRowSelection(item, val.includes(item.couponOpenId));
          }
        });
      } else if (this.$refs.multipleList) {
        this.$refs.multipleList.clearSelection();
      }
    },
    handleCheckAllChange: function handleCheckAllChange(val) {
      var _this5 = this;

      if (val) {
        this.checkAll = true;
        this.indeterminate = false;
        this.list.forEach(function (item) {
          if (_this5.$refs.multipleList) {
            _this5.$refs.multipleList.toggleRowSelection(item);
          }
        });
      } else {
        this.checkAll = false;
        this.indeterminate = false;

        if (this.$refs.multipleList) {
          this.$refs.multipleList.clearSelection();
        }
      }
    }
  }
};
exports.default = _default2;