var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "grading-list-layout"
    },
    [
      _c("product-header", {
        attrs: { "show-remember": _vm.showRemember },
        on: { "search-changed": _vm.search },
        scopedSlots: _vm._u(
          [
            {
              key: "left",
              fn: function() {
                return [_vm._t("header-left")]
              },
              proxy: true
            }
          ],
          null,
          true
        ),
        model: {
          value: _vm.query,
          callback: function($$v) {
            _vm.query = $$v
          },
          expression: "query"
        }
      }),
      _vm._v(" "),
      _c("product-content-table", {
        ref: "multipleList",
        staticStyle: { width: "760px" },
        attrs: {
          data: _vm.list,
          height: _vm.heightStyle,
          "product-type": _vm.productType
        },
        on: { handleSelectionChange: _vm.handleSelectionChange },
        scopedSlots: _vm._u(
          [
            {
              key: "additional",
              fn: function() {
                return [_vm._t("content-additional")]
              },
              proxy: true
            },
            {
              key: "action",
              fn: function() {
                return [_vm._t("content-action")]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      }),
      _vm._v(" "),
      _c("table-footer", {
        attrs: {
          "footer-check-all": _vm.checkAll,
          query: _vm.query,
          "total-elements": _vm.totalElements
        },
        on: {
          pagination: _vm.updateData,
          handleListCheckAll: _vm.handleListCheckAll
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }