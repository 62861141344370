"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

var _ShelfContent = _interopRequireDefault(require("./ShelfContent"));

var _microPage = require("../../utils/microPage");

//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'SelectResourceNotRule',
  components: {
    ShelfContent: _ShelfContent.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    valueResult: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    modelKey: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      refresh: false,
      result: [],
      submitResultList: [],
      title: '选择作品',
      resourceType: 1,
      storeName: undefined
    };
  },
  methods: {
    initData: function initData() {
      this.refresh = true;

      var _refreshSelectStore = (0, _microPage.refreshSelectStore)(this.type),
          storeName = _refreshSelectStore.storeName,
          title = _refreshSelectStore.title;

      this.storeName = storeName;
      this.title = title;
      this.resourceType = this.type === 'micro_audio' ? 2 : 1;
      var val = (0, _microPage.deepCopy)(this.valueResult);
      this.result = (0, _toConsumableArray2.default)(val);
    },
    handleSubmit: function handleSubmit() {
      // 存入缓存
      (0, _microPage.setTypesNavigateStore)(this.submitResultList, this.storeName, this.modelKey);
      this.$emit('selectResourceSubmit', this.submitResultList);
      this.cancelDialogue();
    },
    handleResult: function handleResult(val) {
      this.submitResultList = val;
    },
    cancelDialogue: function cancelDialogue() {
      this.$emit('update:visible', false);
    }
  }
};
exports.default = _default2;