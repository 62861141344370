"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

var _qs = _interopRequireDefault(require("qs"));

var _common = require("../../utils/common");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'liveItem',
  props: {
    myCourseItem: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    isLine: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      stateItem: 0
    };
  },
  watch: {
    myCourseItem: {
      deep: true,
      immediate: true,
      handler: function handler(val) {
        this.liveStatus();
      }
    }
  },
  methods: {
    // 图片路径拼接新方法
    ossUrlFun: function ossUrlFun(url, style) {
      var image = "https://open.oss.taozhi.online/production/assets/taozhi/app/".concat(url);

      var query = _qs.default.stringify({
        'x-oss-process': style
      });

      return query ? "".concat(image, "?").concat(query) : image;
    },
    // 处理图片
    getImgUrl: function getImgUrl(cover) {
      var style = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'style/width-limit-32';
      var imgAllUrl = (0, _common.imageUrlFun)(cover, style);
      return imgAllUrl;
    },
    liveStatus: function liveStatus() {
      var _this = this;

      var timeStr = this.myCourseItem.startTime;
      var startTime = new Date(timeStr.replace(/-/g, '/')).getTime();
      var nowTime = new Date().getTime();
      var endTime = new Date(startTime + this.myCourseItem.liveLength * 1000).getTime();
      var endDelayTime = '';

      if (this.myCourseItem.delayPlay) {
        endDelayTime = new Date(endTime + this.myCourseItem.endDelayTime * 1000).getTime();
      }

      var preEnterTime = new Date(startTime - this.myCourseItem.preEnterTime * 1000).getTime();

      if (startTime <= nowTime && endTime >= nowTime) {
        // 直播中
        this.stateItem = 2;
      } else if (startTime > nowTime) {
        // 未开始
        this.stateItem = 1;

        if (this.userCourseList && this.userCourseList.length > 0) {
          var res = this.userCourseList.indexOf(this.myCourseItem.resourceOpenId);

          if (res != -1) {
            this.stateItem = 5;
          }
        }

        if (this.userCourseAll) {
          this.stateItem = 6;
        } // 提前进入时间


        if (this.myCourseItem.preEnterTime > 0 && preEnterTime <= nowTime) {
          this.stateItem = 7; // 一小时倒计时，进入直播间

          this.setInter = setInterval(function () {
            nowTime = new Date().getTime();
            var hours = Math.floor((startTime - nowTime) / 1000 / 60 / 60 % 24);
            var minute = Math.floor((startTime - nowTime) / 1000 / 60 % 60);
            var second = Math.floor((startTime - nowTime) / 1000 % 60);
            _this.countDown = "\u5373\u5C06\u5F00\u59CB ".concat(hours < 10 ? "0".concat(hours) : hours, ":").concat(minute < 10 ? "0".concat(minute) : minute, ":").concat(second < 10 ? "0".concat(second) : second);

            if (startTime - nowTime <= 0) {
              clearInterval(_this.setInter);
              _this.stateItem = 2;
            }
          }, 1000);
        }
      } else if (this.myCourseItem.longTerm) {
        // 长期直播间
        if (startTime <= nowTime) {
          // 直播中
          this.stateItem = 2;
        }
      } else if (this.myCourseItem.delayPlay && endDelayTime >= nowTime) {
        // 拖堂
        this.stateItem = 2;
      } else if (this.myCourseItem.vid) {
        // 回放
        this.stateItem = 3;
      } else {
        // 结束
        this.stateItem = 4;
      }
    }
  }
};
exports.default = _default2;