"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCount = getCount;
exports.getRequestList = getRequestList;
exports.setSequence = setSequence;
exports.delImageText = delImageText;
exports.textReviewPass = textReviewPass;
exports.imageTextReject = imageTextReject;
exports.imageTextRecovery = imageTextRecovery;
exports.imageTextHidden = imageTextHidden;
exports.imageTextCancelHidden = imageTextCancelHidden;
exports.stopSale = stopSale;
exports.cancelStopSale = cancelStopSale;
exports.cancelSequence = cancelSequence;
exports.batchRefuse = batchRefuse;
exports.batchSetAttribute = batchSetAttribute;
exports.textBatchReview = textBatchReview;
exports.batchRecovery = batchRecovery;
exports.batchIndependentSale = batchIndependentSale;
exports.textToDraft = textToDraft;
exports.getListByOpenId = getListByOpenId;
exports.textSaveReview = textSaveReview;
exports.textToDraftById = textToDraftById;
exports.textSavePublish = textSavePublish;
exports.getAlbumImgTxtList = getAlbumImgTxtList;
exports.getCommodityImgTxtList = getCommodityImgTxtList;
exports.getImgTxtTitleList = getImgTxtTitleList;
exports.textToSaveById = textToSaveById;
exports.updateImageTextEncode = updateImageTextEncode;

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _main = _interopRequireDefault(require("../utils/request/main"));

var _store = _interopRequireDefault(require("../store"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/rich-text-management/admin/text"));
var requestMp = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/rich-text-management/mp/text"));
/**
 * 获取各个状态数量
 * @param {Object} params
 * @returns {Promise<*>}
 */

function getCount(params) {
  return request.get('/request/count', {
    params: params
  });
}
/**
 * 查询图文
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getRequestList(params) {
  return request.get('/request/list', {
    params: params
  });
}
/**
 * 设置推荐
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function setSequence(openId, params) {
  return request.patch("/request/".concat(openId, "/sequence"), undefined, {
    params: params
  });
}
/**
 * 取消推荐
 * @param {String} openId
 * @returns  {Promise<*>}
 */


function cancelSequence(openId) {
  return request.patch("/request/".concat(openId, "/cancelSequence"));
}
/**
 * 删除
 * @param {String} openId
 * @returns {Promise<*>}
 */


function delImageText(openId) {
  return request.delete("/request/".concat(openId));
}
/**
 * 审核通过
 * @param {String} openId
 * @returns {Promise<*>}
 */


function textReviewPass(openId) {
  return request.post("/request/".concat(openId, "/review"));
}
/**
 * 驳回
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function imageTextReject(openId, params) {
  return request.post("/request/".concat(openId, "/reject"), undefined, {
    params: params
  });
}
/**
 * 恢复
 * @param {String} openId
 * @returns {Promise<*>}
 */


function imageTextRecovery(openId) {
  return request.post("/request/".concat(openId, "/recovery"));
}
/**
 * 隐藏
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function imageTextHidden(openId, params) {
  return request.post("/request/".concat(openId, "/hidden"), undefined, {
    params: params
  });
}
/**
 * 取消隐藏
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function imageTextCancelHidden(openId, params) {
  return request.post("/request/".concat(openId, "/cancelHidden"), undefined, {
    params: params
  });
}
/**
 * 停售
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function stopSale(openId, params) {
  return request.post("/request/".concat(openId, "/stopSale"), undefined, {
    params: params
  });
}
/**
 * 取消停售
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function cancelStopSale(openId, params) {
  return request.post("/request/".concat(openId, "/cancelStopSale"), undefined, {
    params: params
  });
}
/**
 * 批量驳回
 * @param {Object} params
 * @returns {Promise<*>}
 */


function batchRefuse(params) {
  return request.post('/request/rejects', undefined, {
    params: params
  });
}
/**
 * 批量修改属性
 * @param {Object} params
 * @returns {Promise<*>}
 */


function batchSetAttribute(params) {
  return request.patch('/request/attribute', undefined, {
    params: params
  });
}
/**
 * 批量恢复到草稿箱
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function batchRecovery(openIds) {
  return request.post('/request/recovers', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 批量提交审核
 * @param {String} openId
 * @returns  {Promise<*>}
 */


function textBatchReview(openIds) {
  return request.post('/request/reviews', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 批量修改是否独立售卖
 * @param {Object} params
 * @returns {Promise<*>}
 */


function batchIndependentSale(params) {
  return request.patch('/request/independentSale', undefined, {
    params: params
  });
}
/**
 * 保存至草稿箱(新建)
 * @param {Object} data
 * @returns {Promise<*>}
 */


function textToDraft(data) {
  return request.post('/request/drafts', data);
}
/**
 * 获取详情
 * @param {String} openId
 * @returns {Promise<*>}
 */


function getListByOpenId(openId) {
  return request.get("/request/".concat(openId));
}
/**
 * 保存并发布(新建)
 * @param {Object} data
 * @returns {Promise<*>}
 */


function textSavePublish(data) {
  return request.post('/request/review', data);
}
/**
 * 保存并发布（修改）
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function textSaveReview(openId, data) {
  return request.patch("/request/".concat(openId, "/review"), data);
}
/**
 * 保存至草稿箱通过Id(修改)
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function textToDraftById(openId, data) {
  return request.patch("/request/".concat(openId, "/drafts"), data);
}
/**
 * 保存通过Id(修改)
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function textToSaveById(openId, data) {
  return request.patch("/request/".concat(openId, "/unChange"), data);
}
/**
 * 获取直播列表（专辑用）
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAlbumImgTxtList(params) {
  return request.get('/list', {
    params: _objectSpread({
      isIndSale: 0
    }, params)
  });
}
/**
 * 获取直播列表（赠品用）
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getCommodityImgTxtList(params) {
  if (_store.default.state.microPage.isAdmin) {
    return request.get('/order/list', {
      params: _objectSpread({
        isIndSale: 0
      }, params)
    });
  }

  return requestMp.get('/order/list', {
    params: _objectSpread({
      isIndSale: 0
    }, params)
  });
}
/**
 * 获取标题列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getImgTxtTitleList(params) {
  return request.get('/request/repeat/title', {
    params: params
  });
}
/**
 * 修改编码信息
 * @param {String} resourceOpenId
 * @param {String} resourceEncode
 * @returns {Promise<*>}
 */


function updateImageTextEncode(resourceOpenId, resourceEncode) {
  return request.patch("/resourceEncode/".concat(resourceOpenId), undefined, {
    params: {
      resourceEncode: resourceEncode
    }
  });
}