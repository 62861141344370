"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

require("core-js/modules/es6.regexp.to-string");

var _RuleContent = _interopRequireDefault(require("./RuleContent"));

var _ShelfContent = _interopRequireDefault(require("./ShelfContent"));

var _microPage = require("../../utils/microPage");

var _ProductShelfContent = _interopRequireDefault(require("./CloudProduct/ProductShelfContent"));

var _ProductRuleContent = _interopRequireDefault(require("./CloudProduct/ProductRuleContent"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var _default2 = {
  name: 'SelectRuleAndResource',
  components: {
    ProductRuleContent: _ProductRuleContent.default,
    ProductShelfContent: _ProductShelfContent.default,
    ShelfContent: _ShelfContent.default,
    RuleContent: _RuleContent.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    storeName: {
      type: String,
      default: 'micro_resource'
    },
    dataRule: {
      type: String,
      default: 'custom'
    },
    valueResult: {
      type: [Object, Array],
      default: function _default() {
        return {} || [];
      }
    },
    modelKey: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      rule: 'custom',
      ruleForm: {
        sectionCode: undefined,
        gradeCode: undefined,
        volumeCode: undefined,
        subjectCode: undefined,
        editionCode: undefined,
        periodCode: undefined,
        regimentationIds: undefined,
        secondRegimentationIds: undefined,
        resourceType: undefined,
        independentSale: 0,
        // 独立售卖
        orderBy: 0,
        // 排序条件
        orderMode: 'desc',
        // 排序方式
        lowPrice: undefined,
        highestPrice: undefined,
        isGift: 0,
        isNeedAddress: 0,
        isTeacherFree: 0,
        price: 0,
        page: 1,
        size: 10,
        partnerOpenId: this.$store.state.microPage.partnerOpenId || ''
      },
      result: [],
      submitResultList: [],
      typeId: undefined,
      refresh: true,
      productForm: {
        groupIds: undefined,
        secondGroupIds: undefined,
        teacherBuy: '0',
        sortBy: undefined,
        // 排序条件
        sortOrder: 'desc',
        // 排序方式
        partnerOpenId: this.$store.state.microPage.partnerOpenId,
        sectionCode: undefined,
        gradeCode: undefined,
        volumeCode: undefined,
        subjectCode: undefined,
        editionCode: undefined,
        periodCode: undefined,
        electiveCode: undefined,
        seriesCode: undefined,
        keyword: undefined
      }
    };
  },
  computed: {
    title: function title() {
      return this.storeName === 'cloud_retail_goods' ? '选择商品' : '选择作品';
    }
  },
  watch: {
    dataRule: {
      deep: true,
      immediate: true,
      handler: function handler(val) {
        this.rule = val;
      }
    }
  },
  methods: {
    initData: function initData() {
      var _this = this;

      this.refresh = true;
      this.clearData();
      var val = (0, _microPage.deepCopy)(this.valueResult);
      this.result = [];
      this.typeId = this.modelKey;
      this.rule = val.rule ? val.rule : this.rule;

      if (this.rule === 'custom') {
        this.typeId = this.storeName === 'micro_types_navigate' ? val.id.toString() : this.modelKey;
        this.result = this.storeName === 'micro_types_navigate' ? val.content : val.parts;
      } else {
        if (this.storeName === 'cloud_retail_goods' && val.partsOptions && JSON.stringify(val.partsOptions) !== '{}') {
          this.productForm = _objectSpread({}, val.partsOptions);
          this.$nextTick(function () {
            if (_this.$refs.productForm) {
              _this.$refs.productForm.initData(val.partsOptions);
            }
          });
          return;
        }

        this.ruleForm = val.partsOptions && JSON.stringify(val.partsOptions) !== '{}' ? val.partsOptions : this.ruleForm;
      }
    },
    clearData: function clearData() {
      this.productForm = {
        groupIds: undefined,
        secondGroupIds: undefined,
        teacherBuy: '0',
        sortBy: undefined,
        // 排序条件
        sortOrder: 'desc',
        // 排序方式
        partnerOpenId: this.$store.state.microPage.partnerOpenId,
        sectionCode: undefined,
        gradeCode: undefined,
        volumeCode: undefined,
        subjectCode: undefined,
        editionCode: undefined,
        periodCode: undefined,
        electiveCode: undefined,
        seriesCode: undefined,
        keyword: undefined
      };
      this.ruleForm = {
        sectionCode: undefined,
        gradeCode: undefined,
        volumeCode: undefined,
        subjectCode: undefined,
        editionCode: undefined,
        periodCode: undefined,
        regimentationIds: undefined,
        secondRegimentationIds: undefined,
        resourceType: undefined,
        independentSale: 0,
        // 独立售卖
        orderBy: 0,
        // 排序条件
        orderMode: 'desc',
        // 排序方式
        lowPrice: undefined,
        highestPrice: undefined,
        isGift: 0,
        isNeedAddress: 0,
        isTeacherFree: 0,
        price: 0,
        page: 1,
        size: 10,
        partnerOpenId: this.$store.state.microPage.partnerOpenId
      };
    },
    handleResult: function handleResult(val) {
      this.submitResultList = val;
    },
    handleTab: function handleTab() {
      if (this.rule === 'custom') {
        this.refresh = true;
        return;
      }

      if (this.storeName === 'cloud_retail_goods') {
        this.$refs.productForm.initData(this.productForm);
      }
    },
    handleSubmit: function handleSubmit() {
      var _this2 = this;

      if (this.rule === 'custom') {
        if (this.submitResultList.length <= 0) {
          this.$message({
            type: 'warning',
            message: '请选择作品'
          });
          return;
        }

        this.$emit('selectResourceSubmit', this.submitResultList, this.rule); // 存放缓存

        if (this.storeName === 'micro_types_navigate') {
          (0, _microPage.setTypesNavigateStore)(this.submitResultList, this.storeName, this.valueResult.id);
        } else {
          (0, _microPage.setTypesNavigateStore)(this.submitResultList, this.storeName, this.modelKey);
        }
      } else {
        this.rule = 'default';

        if (this.storeName === 'cloud_retail_goods') {
          // 商品
          this.productForm.keyword = this.productForm.keyword ? this.productForm.keyword.trim() : this.productForm.keyword;
          this.$emit('selectResourceSubmit', this.productForm, this.rule);
          return;
        }

        if (this.$refs.ruleForm) {
          this.$refs.ruleForm.$refs.form.validate(function (valid) {
            if (!valid) {
              return;
            }

            _this2.$emit('selectResourceSubmit', _this2.ruleForm, _this2.rule);
          });
        }
      }
    },
    cancelDialogue: function cancelDialogue() {
      this.$emit('update:visible', false);
    }
  }
};
exports.default = _default2;