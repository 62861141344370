var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "select-paper",
      attrs: {
        title: "选择试卷",
        visible: _vm.visible,
        width: "1050px",
        "before-close": _vm.handleClose,
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        open: _vm.initData
      }
    },
    [
      _c("single-paper", {
        ref: "singPaper",
        attrs: { value: _vm.valueData, refresh: _vm.refreshPaper },
        on: {
          "update:refresh": function($event) {
            _vm.refreshPaper = $event
          },
          handlePaper: _vm.handlePaper,
          handleClose: _vm.handleClose,
          handleSubmit: _vm.handleSubmit
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }