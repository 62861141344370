"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

require("core-js/modules/es6.number.constructor");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.function.name");

require("core-js/modules/es7.array.includes");

var _main = _interopRequireDefault(require("@/organizationLibs/powerCache/v1.0/main"));

var _AppletLink = _interopRequireDefault(require("@/components/SelectLink/AppletLink"));

var _SpecialPage = _interopRequireDefault(require("./SpecialPage"));

var _ExternalLinks = _interopRequireDefault(require("./ExternalLinks"));

var _ApplicationPage = _interopRequireDefault(require("./ApplicationPage"));

var _CommonProblem = _interopRequireDefault(require("./CommonProblem"));

var _index = _interopRequireDefault(require("./SelectResource/index"));

var _Coupon = _interopRequireDefault(require("./Coupon"));

var _MicroPage = _interopRequireDefault(require("./MicroPage"));

var _AudioBook = _interopRequireDefault(require("./AudioBook/AudioBook"));

var _TestPaperLink = _interopRequireDefault(require("./TestPaperLink"));

var _SelectProduct = _interopRequireDefault(require("./SelectProduct"));

var _CloudPageClass = _interopRequireDefault(require("./CloudPageClass"));

var _BookCollectionActivity = _interopRequireDefault(require("./BookCollectionActivity"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var _default2 = {
  name: 'SelectLink',
  components: {
    BookCollectionActivity: _BookCollectionActivity.default,
    CloudPageClass: _CloudPageClass.default,
    SelectProduct: _SelectProduct.default,
    TestPaperLink: _TestPaperLink.default,
    AppletLink: _AppletLink.default,
    AudioBook: _AudioBook.default,
    MicroPage: _MicroPage.default,
    Coupon: _Coupon.default,
    SelectResource: _index.default,
    CommonProblem: _CommonProblem.default,
    ApplicationPage: _ApplicationPage.default,
    ExternalLinks: _ExternalLinks.default,
    SpecialPage: _SpecialPage.default
  },
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    visible: {
      type: Boolean,
      default: false
    },
    propsActiveName: {
      type: String,
      default: undefined
    } // 选项卡

  },
  data: function data() {
    return {
      resourceOpenId: undefined,
      linkTitle: undefined,
      linkRouter: undefined,
      linkCommonProblem: undefined,
      specialPageLink: undefined,
      linkHref: undefined,
      pageLink: undefined,
      externalLinks: {
        linkTitle: '',
        linkHref: ''
      },
      activeName: 'resource',
      tabsList: [],
      adminList: [{
        label: '作品链接',
        name: 'resource'
      }, {
        label: '试卷链接',
        name: 'paper'
      }, {
        label: '常见问题',
        name: 'problem'
      }, {
        label: '外部链接',
        name: 'link'
      }, {
        label: '应用页面',
        name: 'page'
      }, {
        label: '特殊链接',
        name: 'special'
      }, {
        label: '优惠券',
        name: 'coupon'
      }, {
        label: '点学书',
        name: 'audioBook'
      }, {
        label: '平台微页',
        name: 'microPage'
      }, {
        label: '商户微页',
        name: 'partnerMicroPage'
      }, {
        label: '小程序',
        name: 'applet'
      }],
      partnerTableList: [{
        label: '作品链接',
        name: 'resource'
      }, {
        label: '常见问题',
        name: 'problem'
      }, {
        label: '外部链接',
        name: 'link'
      }, {
        label: '点学书',
        name: 'audioBook'
      }, {
        label: '商户微页',
        name: 'partnerMicroPage'
      }, {
        label: '小程序',
        name: 'applet'
      }],
      appPageVal: {
        pageCode: undefined,
        pageName: undefined
      },
      special: {
        specialPageLink: undefined
      },
      linkData: {},
      commonProblem: {
        id: undefined // 数字类型

      },
      resource: {
        resourceType: undefined,
        resourceOpenId: undefined,
        linkCover: undefined
      },
      refreshResource: false,
      refreshProblem: false,
      refreshLink: false,
      refreshPage: false,
      refreshCoupon: false,
      refreshMicro: false,
      refreshAudioBook: false,
      refreshPartnerMicro: false,
      refreshPaper: false,
      refreshProduct: false,
      coupon: {
        title: undefined,
        openId: undefined
      },
      microPage: {
        partnerOpenId: this.$store.state.microPage.partnerOpenId,
        microPageOpenId: undefined,
        title: undefined
      },
      audioLink: {
        bookOpenId: undefined,
        sequence: undefined,
        id: undefined,
        bookTitle: undefined
      },
      rememberCurrent: true,
      showMerchant: this.$store.state.microPage.isAdmin,
      searchData: undefined,
      applet: {
        name: undefined,
        title: undefined,
        id: undefined,
        url: undefined,
        appSecret: undefined,
        query: undefined
      },
      appletLink: {
        // 回显时用
        list: []
      },
      paper: {
        examPaperOpenId: undefined
      },
      isCloudRetailPartner: false,
      product: {},
      cloudPageClass: {},
      bookCollectionActivity: {}
    };
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(val) {
        this.$emit('input', val);
      }
    }
  },
  methods: {
    // 切换选项卡
    handleChangeTab: function handleChangeTab() {
      if (this.activeName === 'audioBook') {
        this.refreshAudioBook = true;
      }

      if (this.activeName === 'microPage') {
        this.refreshMicro = true;
      }

      if (this.activeName === 'partnerMicroPage') {
        this.microPage.partnerOpenId = this.$store.state.microPage.partnerOpenId;
        this.refreshPartnerMicro = true;
      }

      if (this.activeName === 'applet') {
        if (this.$refs.applet) {
          this.$refs.applet.$refs.appletForm.clearValidate();
        }
      }

      var list = ['cloudPageClass', 'link', 'special', 'applet', 'bookCollectionActivity'];

      if (this.rememberCurrent && list.includes(this.activeName)) {
        this.putStore();
      }
    },
    // 处理新建 无回显数据
    handleNoData: function handleNoData(storeData) {
      try {
        var active = !this.isCloudRetailPartner ? 'resource' : 'product';
        this.clearSelectLinkData();

        if (this.propsActiveName) {
          this.activeName = this.propsActiveName ? this.propsActiveName : active;
          this.resource.resourceType = 1;

          if (this.rememberCurrent) {
            this.putStore();
          }
        }

        if (!this.propsActiveName) {
          if (storeData && storeData.remember) {
            // 处理云零售
            if (this.isCloudRetailPartner && storeData.activeName) {
              var list = this.tabsList.map(function (item) {
                return item.name;
              });
              this.activeName = list.includes(storeData.activeName) ? storeData.activeName : active;
            } else {
              this.activeName = storeData.activeName ? storeData.activeName : active;
            }

            if (!storeData[this.activeName] && !this.isCloudRetailPartner) {
              this.resource.resourceType = 1;
              this.putStore(undefined);
              return;
            }

            if (this.activeName === active && !this.isCloudRetailPartner) {
              this.resource.resourceType = storeData.resource.resourceType;
            }

            this.putStore(storeData[this.activeName]);
          } else {
            this.activeName = active;
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    initData: function initData() {
      this.microPage.partnerOpenId = this.$store.state.microPage.partnerOpenId;
      this.isCloudRetailPartner = this.$store.state.microPage.isCloudRetail;
      var partner = this.$store.state.microPage.partnerOpenId; // 处理小、中学号

      this.tabsList = this.adminList;
      var partnerList = process.env.VUE_APP_EXEMPT_REVIEW_PARTNER.split(',');

      if (partnerList.includes(partner) || this.$store.state.microPage.isAdmin) {
        this.tabsList = this.adminList;
      } else if (this.isCloudRetailPartner) {
        this.tabsList = [{
          label: '商品链接',
          name: 'product'
        }, {
          label: '外部链接',
          name: 'link'
        }, {
          label: '商城分类',
          name: 'cloudPageClass'
        }, {
          label: '商城页面',
          name: 'page'
        }, {
          label: '商户微页',
          name: 'partnerMicroPage'
        }, {
          label: '领书活动',
          name: 'bookCollectionActivity'
        }, {
          label: '小程序',
          name: 'applet'
        }];
      } else {
        this.tabsList = this.partnerTableList;
      }

      this.clearSelectLinkData(false);
      var val = this.value;
      this.refreshResource = true;
      this.refreshProblem = true;
      this.refreshLink = true;
      this.refreshPage = true;
      this.refreshCoupon = true;
      this.refreshMicro = true;
      this.refreshPartnerMicro = true;
      this.refreshPaper = true;

      var storeData = _main.default.get('micro_select_link_operation');

      if (storeData) {
        this.rememberCurrent = storeData.remember;
      }

      if (!val) {
        this.handleNoData(storeData);
        return;
      }

      if (!val.hasOwnProperty('type')) {
        this.handleNoData(storeData);
      } else {
        this.linkTitle = val.display.title; // 作品

        if (val.type === 'OpenId' && val.key.keyType === 'resource') {
          // 点学书到具体页面
          if (val.key.resourceType === 3 && (this.propsActiveName === 'audioBook' || val.key.sequence || val.key.pageId)) {
            this.audioLink.bookOpenId = val.key.openId;
            this.audioLink.sequence = val.key.sequence;
            this.audioLink.bookTitle = val.display.title;
            this.audioLink.id = val.key.pageId;
            this.audioLink.cover = val.display.cover;
            this.activeName = 'audioBook';

            if (this.rememberCurrent) {
              this.putStore(storeData.audioBook);
            }

            return;
          }

          this.refreshResource = true;
          this.activeName = 'resource';
          this.resource.resourceOpenId = val.key.openId;
          this.resource.resourceType = val.key.resourceType;
          this.resource.cover = val.display.cover;
        } // 常见问题


        if (val.type === 'Id' && val.key.keyType === 'problem') {
          this.activeName = 'problem';
          this.commonProblem.id = val.key.id;
        } // 外部链接


        if (val.type === 'Link') {
          this.activeName = 'link';
          this.externalLinks.linkHref = val.key.url;
          this.externalLinks.linkTitle = val.display.title;
        } // 应用页面


        if (val.type === 'Page') {
          this.activeName = 'page';
          this.appPageVal.pageName = val.display.title;
          this.appPageVal.pageCode = val.key.pageCode;
        } // 特殊链接


        if (val.type === 'Special') {
          this.activeName = 'special';
          this.special.specialPageLink = val.key.name;
        } // 优惠券


        if (val.type === 'OpenId' && val.key.keyType === 'Coupon') {
          this.activeName = 'coupon';
          this.coupon.title = val.display.title;
          this.coupon.openId = val.key.openId;
        } // 微页


        if (val.type === 'OpenId' && val.key.keyType === 'micropage') {
          this.activeName = val.key.partnerOpenId.toString() === '0' ? 'microPage' : 'partnerMicroPage';
          this.microPage.title = val.display.title;
          this.microPage.microPageOpenId = val.key.microPageOpenId;
          this.microPage.partnerOpenId = val.key.partnerOpenId;
        } // 小程序


        if (val.type === 'Applet') {
          this.activeName = 'applet';
          this.applet.id = val.key.id;
          this.applet.name = val.key.name;
          this.applet.url = val.key.url;
          this.applet.title = val.display.title;
          this.applet.appSecret = val.key.appSecret;
          this.applet.query = val.key.query;
        } // 试卷


        if (val.type === 'OpenId' && val.key.keyType === 'paper') {
          this.activeName = 'paper';
          this.paper.title = val.display.title;
          this.paper.examPaperOpenId = val.key.openId;
          this.paper.answerMould = val.key.answerMould;
        } // 商品


        if (val.type === 'OpenId' && val.key.keyType === 'product') {
          this.activeName = 'product';
          this.product.title = val.display.title;
          this.product.productOpenId = val.key.openId;
          this.product.cover = val.display.cover;
        } // 商品分类


        if (val.type === 'CloudPageClass') {
          this.activeName = 'cloudPageClass';
          this.cloudPageClass = {
            groupIds: [val.key.groupId, val.key.secondGroupId],
            gradeCode: val.key.gradeCode ? val.key.gradeCode.split(',') : [],
            volumeCode: val.key.volumeCode ? val.key.volumeCode.split(',') : [],
            subjectCode: val.key.subjectCode ? val.key.subjectCode.split(',') : [],
            electiveCode: val.key.electiveCode ? val.key.electiveCode.split(',') : [],
            areaCode: val.key.areaCode ? val.key.areaCode.split(',') : []
          };
        } // 领书活动


        if (val.type === 'OpenId' && val.key.keyType === 'bookCollectionActivity') {
          this.activeName = 'bookCollectionActivity';
          this.bookCollectionActivity.name = val.display.title;
          this.bookCollectionActivity.activityOpenId = val.key.openId;
        }

        if (this.rememberCurrent && storeData) {
          this.putStore(storeData[this.activeName]);
        }
      }
    },
    // 记住当前操作
    handleRemember: function handleRemember(val) {
      if (val) {
        this.putStore(this.searchData);
      } else {
        this.putStore();
      }
    },
    putStore: function putStore(searchData) {
      this.searchData = searchData;

      var storeData = _main.default.get('micro_select_link_operation');

      var query = {
        remember: this.rememberCurrent,
        activeName: !this.rememberCurrent ? '' : this.activeName
      };

      if (this.rememberCurrent) {
        query = _objectSpread(_objectSpread({}, storeData), query);
        query[this.activeName] = searchData;
      }

      _main.default.put('micro_select_link_operation', query);
    },
    // 全部置为空
    clearSelectLinkData: function clearSelectLinkData() {
      this.linkTitle = undefined;
      this.special.specialPageLink = undefined;
      this.commonProblem.id = undefined;
      this.externalLinks.linkHref = undefined;
      this.externalLinks.linkTitle = undefined;
      this.externalLinks.openInBrowser = false;
      this.special.specialPageLink = undefined;
      this.appPageVal.pageCode = undefined;
      this.appPageVal.pageName = undefined;
      this.resource.resourceType = undefined;
      this.resource.resourceOpenId = undefined;
      this.resource.resourceEncode = undefined;
      this.resource.cover = undefined;
      this.coupon.title = undefined;
      this.coupon.openId = undefined;
      this.multipleList = [];
      this.audioLink.bookOpenId = undefined;
      this.audioLink.sequence = undefined;
      this.audioLink.bookTitle = undefined;
      this.audioLink.id = undefined;
      this.audioLink.cover = undefined;
      this.audioLink.resourceEncode = undefined;
      this.microPage.microPageOpenId = undefined;
      this.microPage.partnerOpenId = undefined;
      this.applet.id = undefined;
      this.applet.name = undefined;
      this.applet.title = undefined;
      this.applet.url = undefined;
      this.applet.appSecret = undefined;
      this.applet.appId = undefined;
      this.applet.query = undefined;
      this.paper.title = undefined;
      this.paper.examPaperOpenId = undefined;
      this.paper.answerMould = undefined;
      this.paper.paperType = undefined;
      this.paper.resourceEncode = undefined;

      if (this.$refs.selectResource) {
        this.$refs.selectResource.clearSelectResource();
      }

      if (this.$refs.audioBook) {
        this.$refs.audioBook.clearAudioBook();
      }
    },
    // 资源
    handleResource: function handleResource(val, type) {
      if (!val || JSON.stringify(val) === '{}') return;
      this.linkTitle = val.title;
      this.resource.resourceType = val.resourceType ? val.resourceType : Number(type);
      this.resource.resourceOpenId = val.resourceOpenId;
      this.resource.cover = val.cover;
    },
    // 常见问题
    handleProblem: function handleProblem(val) {
      if (!val || JSON.stringify(val) === '{}') {
        return;
      }

      this.commonProblem.id = val.id;
      this.linkTitle = val.problemTitle;
    },
    // 优惠券
    handleCoupon: function handleCoupon(val) {
      if (!val || JSON.stringify(val) === '{}') {
        return;
      }

      this.coupon.title = val.title;
      this.coupon.openId = val.couponOpenId;
    },
    handleWarning: function handleWarning() {
      var isLink = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      // 外部链接
      if (isLink) {
        return this.$notify({
          title: '请填写完整的外部链接内容',
          message: '',
          type: 'warning'
        });
      } // 非外部链接


      this.$notify({
        title: '请选择链接',
        message: '',
        type: 'warning'
      });
    },
    // 应用页面
    handleAppPage: function handleAppPage(currentRow) {
      if (!currentRow || JSON.stringify(currentRow) === '{}') {
        return;
      }

      this.appPageVal.pageCode = currentRow.pageCode;
      this.appPageVal.pageName = currentRow.pageName;
      this.linkTitle = currentRow.pageName;
    },
    // 点学书
    handleAudioBook: function handleAudioBook(val) {
      this.audioLink = _objectSpread({}, val);
    },
    // 微页
    handleMicroPage: function handleMicroPage(val) {
      if (!val || JSON.stringify(val) === '{}') {
        return;
      }

      this.microPage.title = val.title;
      this.microPage.microPageOpenId = val.microPageOpenId;
      this.microPage.partnerOpenId = val.partnerOpenId;
    },
    // 试卷
    handlePaper: function handlePaper(val) {
      if (!val || JSON.stringify(val) === '{}') {
        return;
      }

      if (this.multipleCheck && val.length > 0) {
        this.multipleList = (0, _toConsumableArray2.default)(val);
        return;
      }

      this.paper.name = val.name;
      this.paper.examPaperOpenId = val.examPaperOpenId;
      this.paper.answerMould = val.answerMould;
    },
    // 商品
    handleProduct: function handleProduct(val) {
      if (!val || JSON.stringify(val) === '{}') {
        return;
      }

      this.product.title = val.title;
      this.product.productOpenId = val.productOpenId;
      this.product.cover = val.cover;
    },
    // 领书活动
    handleActivity: function handleActivity(val) {
      if (!val || JSON.stringify(val) === '{}') {
        return;
      }

      this.bookCollectionActivity = val;
    },
    // 确定
    handleSubmit: function handleSubmit() {
      var query = {}; // 处理小程序

      if (this.activeName === 'applet') {
        var validForm = false;
        this.$refs.applet.$refs.appletForm.validate(function (valid) {
          validForm = valid;
        });

        if (!validForm) {
          return;
        }
      } // 处理云零售分类


      if (this.activeName === 'cloudPageClass') {
        var _validForm = false;
        this.$refs.cloudPageClass.$refs.appletForm.validate(function (valid) {
          _validForm = valid;
        });

        if (!_validForm) {
          return;
        }
      }

      if (this.activeName === 'resource') {
        if (!this.resource.resourceOpenId) {
          this.handleWarning();
          return;
        }

        if (this.resource.resourceType === 3) {
          query = {
            type: 'OpenId',
            key: {
              keyType: 'resource',
              resourceType: this.resource.resourceType,
              openId: this.resource.resourceOpenId,
              sequence: '',
              pageId: ''
            },
            display: {
              title: this.linkTitle,
              cover: this.resource.cover
            }
          };
        } else {
          // 处理返回
          query = {
            type: 'OpenId',
            key: {
              keyType: 'resource',
              resourceType: this.resource.resourceType,
              openId: this.resource.resourceOpenId
            },
            display: {
              title: this.linkTitle,
              cover: this.resource.cover
            }
          };
        }
      }

      if (this.activeName === 'problem') {
        if (!this.commonProblem.id) {
          this.handleWarning();
          return;
        }

        this.linkCommonProblem = {
          id: this.commonProblem.id
        };
        query = {
          type: 'Id',
          key: {
            keyType: 'problem',
            id: this.commonProblem.id
          },
          display: {
            title: this.linkTitle
          }
        };
      }

      if (this.activeName === 'link') {
        if (!this.externalLinks.linkHref || !this.externalLinks.linkTitle) {
          this.handleWarning(true);
          return;
        }

        this.linkHref = this.externalLinks.linkHref;
        query = {
          type: 'Link',
          key: {
            url: this.externalLinks.linkHref
          },
          display: {
            title: this.externalLinks.linkTitle
          }
        };
      }

      if (this.activeName === 'page') {
        if (!this.appPageVal.pageCode) {
          this.handleWarning();
          return;
        }

        this.pageLink = this.appPageVal;
        query = {
          type: 'Page',
          key: {
            pageCode: this.appPageVal.pageCode
          },
          display: {
            title: this.appPageVal.pageName
          }
        };
      }

      if (this.activeName === 'special') {
        if (!this.special.specialPageLink) {
          this.handleWarning();
          return;
        }

        this.specialPageLink = this.special.specialPageLink;
        query = {
          type: 'Special',
          key: {
            name: this.special.specialPageLink
          },
          display: {
            title: this.special.specialPageLink
          }
        };
      }

      if (this.activeName === 'coupon') {
        if (!this.coupon.openId) {
          this.handleWarning();
          return;
        }

        query = {
          type: 'OpenId',
          key: {
            keyType: 'Coupon',
            openId: this.coupon.openId
          },
          display: {
            title: this.coupon.title
          }
        };
      }

      if (this.activeName === 'microPage' || this.activeName === 'partnerMicroPage') {
        query = {
          type: 'OpenId',
          key: {
            keyType: 'micropage',
            partnerOpenId: this.microPage.partnerOpenId,
            microPageOpenId: this.microPage.microPageOpenId
          },
          display: {
            title: this.microPage.title
          }
        };
      }

      if (this.activeName === 'audioBook') {
        if (!this.audioLink.bookOpenId) {
          this.handleWarning();
          return;
        }

        query = {
          type: 'OpenId',
          key: {
            keyType: 'resource',
            resourceType: 3,
            openId: this.audioLink.bookOpenId,
            sequence: this.audioLink.sequence,
            pageId: this.audioLink.id
          },
          display: {
            title: this.audioLink.bookTitle,
            cover: this.audioLink.cover
          }
        };
      }

      if (this.activeName === 'applet') {
        query = {
          type: 'Applet',
          // 小程序
          key: {
            id: this.applet.id,
            // 原始id
            appId: this.applet.appId,
            // 小程序id
            appSecret: this.applet.appSecret,
            // appSecret
            name: this.applet.name,
            // 页面名称
            url: this.applet.url,
            // 小程序路径
            query: this.applet.query
          },
          display: {
            title: this.applet.title // 小程序名称

          }
        };
      }

      if (this.activeName === 'paper') {
        if (!this.paper.examPaperOpenId) {
          this.handleWarning();
          return;
        }

        query = {
          type: 'OpenId',
          key: {
            keyType: 'paper',
            openId: this.paper.examPaperOpenId,
            answerMould: this.paper.answerMould,
            // 1 是考题必刷
            paperType: this.paper.paperType,
            // 试卷类型
            resourceEncode: this.paper.resourceEncode // 作品附件需要显示

          },
          display: {
            title: this.paper.name
          }
        };
      }

      if (this.activeName === 'product') {
        query = {
          type: 'OpenId',
          key: {
            keyType: 'product',
            openId: this.product.productOpenId
          },
          display: {
            title: this.product.title,
            cover: this.product.cover
          }
        };
      }

      if (this.activeName === 'cloudPageClass') {
        var title = this.$refs.cloudPageClass.getTitleData();
        var gradeCode = this.cloudPageClass.gradeCode ? this.cloudPageClass.gradeCode : [];
        var volumeCode = this.cloudPageClass.volumeCode ? this.cloudPageClass.volumeCode : [];
        var subjectCode = this.cloudPageClass.subjectCode ? this.cloudPageClass.subjectCode : [];
        var areaCode = this.cloudPageClass.areaCode ? this.cloudPageClass.areaCode : [];
        var electiveCode = this.cloudPageClass.electiveCode ? this.cloudPageClass.electiveCode : [];
        var groupIds = this.cloudPageClass.groupIds || [];
        query = {
          type: 'CloudPageClass',
          key: {
            groupId: groupIds.length > 0 ? this.cloudPageClass.groupIds[0] : '',
            secondGroupId: groupIds.length > 0 ? this.cloudPageClass.groupIds[1] : '',
            gradeCode: gradeCode.length > 0 ? this.cloudPageClass.gradeCode.join(',') : undefined,
            volumeCode: volumeCode.length > 0 ? this.cloudPageClass.volumeCode.join(',') : undefined,
            electiveCode: electiveCode.length > 0 ? this.cloudPageClass.electiveCode.join(',') : undefined,
            subjectCode: subjectCode.length > 0 ? this.cloudPageClass.subjectCode.join(',') : undefined,
            areaCode: areaCode.length > 0 ? this.cloudPageClass.areaCode.join(',') : undefined
          },
          display: {
            title: title
          }
        };
      }

      if (this.activeName === 'bookCollectionActivity') {
        console.log(this.bookCollectionActivity);
        query = {
          type: 'OpenId',
          key: {
            keyType: 'bookCollectionActivity',
            openId: this.bookCollectionActivity.activityOpenId
          },
          display: {
            title: this.bookCollectionActivity.name
          }
        };
      }

      this.valueProxy = query;
      this.$emit('update:visible', false);
    },
    handleClose: function handleClose() {
      this.$emit('update:visible', false);
    }
  }
};
exports.default = _default2;