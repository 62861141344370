"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  book_shelf: [{
    id: 1,
    isAudioBook: true,
    cover: undefined,
    title: '演示书籍名称',
    price: 0
  }, {
    id: 2,
    isAudioBook: true,
    cover: undefined,
    title: '演示书籍名称',
    price: 0
  }, {
    id: 3,
    isAudioBook: false,
    cover: undefined,
    title: '演示书籍名称',
    price: 0
  }],
  app_audio_book: [{
    id: 1,
    isAudioBook: true,
    cover: undefined,
    title: '点学书名称',
    price: 0
  }, {
    id: 2,
    isAudioBook: true,
    cover: undefined,
    title: '点学书名称',
    price: 500
  }, {
    id: 3,
    isAudioBook: false,
    cover: undefined,
    title: '点学书名称',
    price: 0
  }, {
    id: 4,
    isAudioBook: false,
    cover: undefined,
    title: '点学书名称',
    price: 0
  }],
  knowledge_goods: [{
    id: 1,
    title: '知识商品名称知识商品名称知识商品名称知识商品名称知识商品名称',
    resourceType: 4,
    price: 0
  }, {
    id: 2,
    title: '知识商品名称',
    resourceType: 2,
    price: 0
  }],
  ranking_list: [{
    id: 1,
    title: '排行榜商品名称排行榜名称排行榜商品名称知识商品名称知识商品名称',
    resourceType: 4,
    price: 0
  }, {
    id: 2,
    title: '排行榜商品名称',
    resourceType: 2,
    price: 0
  }],
  famous_teachers: [{
    id: 1,
    name: '导师名称',
    introduction: '导师简介，此处显示导师简介',
    resourceCount: 0
  }, {
    id: 2,
    name: '导师名称',
    introduction: '导师简介，此处显示导师简介',
    resourceCount: 0
  }, {
    id: 3,
    name: '导师名称',
    introduction: '导师简介，此处显示导师简介',
    resourceCount: 0
  }],
  types_navigate: [{
    id: 1,
    title: '作品标题最新商品显示名称，商品名称两行溢出隐藏',
    resourceType: 3,
    price: 0
  }, {
    id: 2,
    title: '作品标题',
    resourceType: 2,
    price: 0
  }],
  live: [{
    id: 1,
    cover: undefined,
    title: '最新商品显示名称，商品名称两行溢出隐藏',
    startTime: '2021-06-04 14:50:38',
    liveLength: 7200
  }, {
    id: 2,
    cover: undefined,
    title: '最新商品显示名称，商品名称两行溢出隐藏',
    startTime: '2021-06-29 14:50:38',
    liveLength: 7200
  }, {
    id: 3,
    cover: undefined,
    title: '最新商品显示名称，商品名称两行溢出隐藏',
    startTime: '2021-06-30 14:50:38',
    liveLength: 7200
  }],
  coupon: [{
    id: 1,
    title: '合并测试10',
    discountType: 1,
    discountTypeName: '满减券',
    usedAmount: 100,
    thresholdAmount: 100000,
    status: 2
  }, {
    id: 2,
    title: '合并测试10',
    discountType: 1,
    discountTypeName: '满减券',
    usedAmount: 200,
    thresholdAmount: 100000,
    status: 2
  }],
  test_paper: [{
    id: 1,
    name: '试卷名称',
    questionNum: 5,
    paperMark: 100,
    difficultDegree: 2
  }, {
    id: 2,
    name: '试卷名称',
    questionNum: 0,
    paperMark: 100,
    difficultDegree: 3
  }],
  test_questions: [{
    id: 1,
    stem: '试题名称'
  }, {
    id: 2,
    stem: '试题名称'
  }],
  micro_audio: [{
    title: '音频',
    duration: 0
  }, {
    title: '音频2',
    duration: 0
  }],
  micro_video: [{
    title: '视频'
  }, {
    title: '视频2'
  }],
  cloud_retail_goods: [{
    id: 1,
    title: '商品商品商品商品商品商品商品商品商品商品商品商品商品商品商品商品商品商品商品商品商品商品商品',
    cover: undefined,
    price: 0
  }, {
    id: 2,
    title: '商品2',
    cover: undefined,
    price: 0
  }, {
    id: 3,
    title: '商品3',
    cover: undefined,
    price: 0
  }]
};
exports.default = _default;