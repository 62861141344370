var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      class: ["micro-classification", _vm.isPlatform ? "" : "partner-class"]
    },
    [
      _c("div", { staticClass: "nav" }, [
        _c("span", { staticClass: "nav-title" }, [_vm._v("分类")]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: {
                  effect: "dark",
                  content: "刷新分类列表",
                  placement: "top"
                }
              },
              [
                _c("i", {
                  staticClass: "el-icon-refresh-right nav-icon",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.modifyRefresh($event)
                    }
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c("i", {
              staticClass: "el-icon-plus",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.modifyAdd(undefined, true)
                }
              }
            }),
            _vm._v(" "),
            _vm.list.length > 0
              ? _c("i", {
                  staticClass: "iconfont noWeight nav-icon",
                  class: _vm.expandAll
                    ? "icon-category-collapse"
                    : "icon-category-expand",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.isExpand($event)
                    }
                  }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("el-input", {
        staticClass: "search",
        attrs: { size: "mini", placeholder: "分类搜索...", clearable: "" },
        model: {
          value: _vm.filterText,
          callback: function($$v) {
            _vm.filterText = typeof $$v === "string" ? $$v.trim() : $$v
          },
          expression: "filterText"
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { style: { height: _vm.heightStyle, "overflow-y": " scroll" } },
        [
          _vm.list.length > 0
            ? _c("el-tree", {
                ref: "microTree",
                attrs: {
                  data: _vm.list,
                  "allow-drop": _vm.allowDrop,
                  "default-expand-all": _vm.expandAll,
                  "default-expanded-keys": _vm.expandedKeys,
                  draggable: "",
                  "node-key": "id",
                  "filter-node-method": _vm.filterNode,
                  "empty-text": "暂无此分类"
                },
                on: { "node-drop": _vm.handleDrop },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var node = ref.node
                        var data = ref.data
                        return _c(
                          "div",
                          {
                            staticClass: "custom-tree-node",
                            on: {
                              mouseenter: function($event) {
                                $event.stopPropagation()
                                return _vm.mouseoverFun(data)
                              },
                              mouseleave: function($event) {
                                $event.stopPropagation()
                                return _vm.mouseoutFun()
                              },
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.checkIdFun(data)
                              }
                            }
                          },
                          [
                            _c(
                              "span",
                              {
                                class:
                                  _vm.checkId === data.openId
                                    ? "color-check custom-tree-node-text"
                                    : "custom-tree-node-text",
                                attrs: { title: data.name + "\n" + data.openId }
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(data.name) +
                                    "\n        "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: data.id === _vm.mouseId,
                                    expression: "data.id === mouseId"
                                  }
                                ],
                                staticClass: "custom-tree-node-btn"
                              },
                              [
                                _c(
                                  "el-dropdown",
                                  {
                                    attrs: { "hide-timeout": 500 },
                                    on: { command: _vm.handleOperationClass }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont icon-More"
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "el-dropdown-menu",
                                      {
                                        attrs: { slot: "dropdown" },
                                        slot: "dropdown"
                                      },
                                      [
                                        _c(
                                          "el-dropdown-item",
                                          {
                                            attrs: {
                                              command: _vm.composeValue(
                                                "add",
                                                data
                                              )
                                            }
                                          },
                                          [_vm._v("添加子分类")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-dropdown-item",
                                          {
                                            attrs: {
                                              command: _vm.composeValue(
                                                "edit",
                                                data
                                              )
                                            }
                                          },
                                          [_vm._v("重命名")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-dropdown-item",
                                          {
                                            attrs: {
                                              command: _vm.composeValue(
                                                "remove",
                                                data
                                              )
                                            }
                                          },
                                          [_vm._v("移动到...")]
                                        ),
                                        _vm._v(" "),
                                        data.children === null || !data.children
                                          ? _c(
                                              "el-dropdown-item",
                                              {
                                                attrs: {
                                                  command: _vm.composeValue(
                                                    "del",
                                                    data
                                                  )
                                                }
                                              },
                                              [_vm._v("删除")]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        )
                      }
                    }
                  ],
                  null,
                  false,
                  2123557338
                )
              })
            : _c(
                "div",
                { staticClass: "no-data" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "no-data-btn",
                      on: {
                        click: function($event) {
                          return _vm.modifyAdd(undefined, true)
                        }
                      }
                    },
                    [_vm._v("\n        添加分类\n      ")]
                  )
                ],
                1
              )
        ],
        1
      ),
      _vm._v(" "),
      _c("add-classification-dialog", {
        attrs: { visible: _vm.addOpen, title: _vm.addTitle },
        on: {
          "update:visible": function($event) {
            _vm.addOpen = $event
          },
          refreshClassificationList: _vm.refreshClassificationList
        },
        model: {
          value: _vm.addData,
          callback: function($$v) {
            _vm.addData = $$v
          },
          expression: "addData"
        }
      }),
      _vm._v(" "),
      _c("move-classification-dialog", {
        attrs: {
          visible: _vm.removeDialog,
          "move-list": _vm.moveList,
          "old-open-id": _vm.oldOpenId
        },
        on: {
          "update:visible": function($event) {
            _vm.removeDialog = $event
          },
          refreshClassificationList: _vm.modifyRefresh
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }