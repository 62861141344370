"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _AudioBookPrice = _interopRequireDefault(require("../AudioBookShelf/AudioBookPrice"));

var _common = require("../../../utils/common");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'RankingInfo',
  filters: {
    formatNumberWithUnit: _common.formatNumberWithUnit
  },
  components: {
    AudioBookPrice: _AudioBookPrice.default
  },
  props: {
    value: {
      type: Object,
      default: function _default() {}
    },
    priceOptions: {
      type: Object,
      default: function _default() {}
    },
    content: {
      type: Object,
      default: function _default() {}
    }
  },
  computed: {
    showDownload: function showDownload() {
      if (this.content.rule === 'default') {
        // 排序是 下载量 类型 是文件 和 文件包
        return this.content.partsOptions.orderBy === 'download_nums' && [8, 10].includes(this.value.resourceType);
      }

      return false;
    },
    showViewNums: function showViewNums() {
      // 排序是 浏览量
      if (this.content.rule === 'default') {
        return this.content.partsOptions.orderBy === 'view_nums';
      }

      return false;
    }
  }
};
exports.default = _default2;