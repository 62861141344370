var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-column" },
    _vm._l(_vm.list, function(item, index) {
      return _c("div", { key: "knowledge_goods_" + index }, [
        index < _vm.size
          ? _c(
              "div",
              { staticClass: "flex-row resource-bottom" },
              [
                _c("resource-item", {
                  attrs: {
                    resource: item,
                    type: "line",
                    "cur-icon-index": _vm.recommendRule ? index + 2 : index + 1,
                    "is-rank-list": _vm.type === "ranking_list"
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "flex-column flex-space-between resource-info",
                    style: {
                      height: item.resourceType === 3 ? "110px" : "68px"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "live-title line-height-live hidden-line"
                      },
                      [_vm._v(_vm._s(item.title))]
                    ),
                    _vm._v(" "),
                    _vm.type === "knowledge_goods"
                      ? _c(
                          "div",
                          { staticClass: "flex-row flex-space-between" },
                          [
                            _c("resource-type", {
                              attrs: { "resource-type": item.resourceType }
                            }),
                            _vm._v(" "),
                            _c("audio-book-price", {
                              attrs: {
                                value: item,
                                options: Object.assign({}, _vm.options, {
                                  priceColor: "#F85862"
                                })
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.type === "ranking_list"
                      ? _c("ranking-info", {
                          attrs: {
                            content: _vm.content,
                            value: item,
                            "price-options": Object.assign({}, _vm.options, {
                              priceColor: "#F85862"
                            })
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }