var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "live-content" },
    [
      _c("search", { on: { search: _vm.search } }),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          ref: "multipleList",
          staticStyle: { width: "100%" },
          attrs: { data: _vm.list, height: "400px" },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _vm._v(" "),
          _c("el-table-column", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "resource-layout" },
                      [
                        _c("el-image", {
                          staticClass: "cover-layout",
                          attrs: {
                            src: _vm.getImgUrl(scope.row.cover),
                            fit: "cover"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "resource-title-layout flex-column" },
                          [
                            _c("span", { staticClass: "resource-title" }, [
                              _vm._v(_vm._s(_vm.displayTitle(scope.row)))
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "resource-info" }, [
                              _vm._v(_vm._s(_vm.getResourceInfo(scope.row)))
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "resource-type" }, [
                              _vm._v(
                                "发布时间：" +
                                  _vm._s(_vm.showVal(scope.row.publishedAt))
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("table-footer", {
        attrs: {
          "footer-check-all": _vm.checkAll,
          query: _vm.query,
          "total-elements": _vm.totalElements
        },
        on: {
          pagination: _vm.getLiveList,
          handleListCheckAll: _vm.handleListCheckAll
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }