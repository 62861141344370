"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ZhuGuanTi',
  props: {
    rightAnswerContent: {
      type: String,
      default: ''
    },
    answerRight: {
      type: Boolean,
      default: false
    },
    answerConfirm: {
      type: Boolean,
      default: false
    }
  }
};
exports.default = _default;