"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IMAGE_TEXT_ID = exports.FILE_ID = exports.LIVE_ID = exports.AUDIO_ID = exports.ALBUM_ID = exports.VEDIO_ID = exports.AUDIOBOOK_ID = exports.RESOURCE_DELETED_STATE = exports.RESOURCE_REFUSED_STATE = exports.RESOURCE_PULISHED_STATE = exports.RESOURCE_REVIEW_STATE = exports.RESOURCE_DRAFT_STATE = exports.PARTNER_REFUSED_STATUS = exports.PARTNER_PASSED_STATUS = exports.PARTNER_REVIEW_STATUS = void 0;

/* eslint-disable no-multi-spaces,no-unused-vars */
var PARTNER_REVIEW_STATUS = 1; // 商户状态 审核中

exports.PARTNER_REVIEW_STATUS = PARTNER_REVIEW_STATUS;
var PARTNER_PASSED_STATUS = 2; // 商户状态 已通过

exports.PARTNER_PASSED_STATUS = PARTNER_PASSED_STATUS;
var PARTNER_REFUSED_STATUS = 3; // 商户状态 已拒绝

exports.PARTNER_REFUSED_STATUS = PARTNER_REFUSED_STATUS;
var RESOURCE_DRAFT_STATE = 1; // 资源状态 草稿箱

exports.RESOURCE_DRAFT_STATE = RESOURCE_DRAFT_STATE;
var RESOURCE_REVIEW_STATE = 2; // 资源状态 审核中

exports.RESOURCE_REVIEW_STATE = RESOURCE_REVIEW_STATE;
var RESOURCE_PULISHED_STATE = 3; // 资源状态 已发布

exports.RESOURCE_PULISHED_STATE = RESOURCE_PULISHED_STATE;
var RESOURCE_REFUSED_STATE = 4; // 资源状态 未通过

exports.RESOURCE_REFUSED_STATE = RESOURCE_REFUSED_STATE;
var RESOURCE_DELETED_STATE = 5; // 资源状态 回收站

exports.RESOURCE_DELETED_STATE = RESOURCE_DELETED_STATE;
var AUDIOBOOK_ID = 3;
exports.AUDIOBOOK_ID = AUDIOBOOK_ID;
var VEDIO_ID = 1;
exports.VEDIO_ID = VEDIO_ID;
var ALBUM_ID = 4;
exports.ALBUM_ID = ALBUM_ID;
var AUDIO_ID = 2; // 音频资源类型

exports.AUDIO_ID = AUDIO_ID;
var LIVE_ID = 11; // 直播typeId

exports.LIVE_ID = LIVE_ID;
var FILE_ID = 8; // 文件typeId

exports.FILE_ID = FILE_ID;
var IMAGE_TEXT_ID = 9; // 图文typeId

exports.IMAGE_TEXT_ID = IMAGE_TEXT_ID;