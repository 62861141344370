"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.giftBookOrder = giftBookOrder;
exports.giftBookList = giftBookList;
exports.addGiftBookOrder = addGiftBookOrder;
exports.refreshExpress = refreshExpress;
exports.findGiftBookOrderByOne = findGiftBookOrderByOne;
exports.giftBookOrderlist = giftBookOrderlist;
exports.getwxPageImg = getwxPageImg;

var _main = _interopRequireDefault(require("../utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/third-sdk"));
var wxRequest = (0, _main.default)("https://api.weixin.qq.com/wxa/getwxacode");
/**
 * 查询赠书list
 * @param {String} params
 * @returns {Promise<*>}
 */

function giftBookOrderlist(params) {
  return request.get('/giftBookOrder/bookList', {
    params: params
  });
}
/**
 * 查询赠书list
 * @param {String} params
 * @returns {Promise<*>}
 */


function giftBookOrder(params) {
  return request.get('/giftBookOrder/findGiftBookOrderPage', {
    params: params
  });
}
/**
 * 查询书籍list
 * @param {String} params
 * @returns {Promise<*>}
 */


function giftBookList(params) {
  return request.get('/giftBookList/giftBookList', {
    params: params
  });
}
/**
 * 查询单个赠书记录
 * @param {String} params
 * @returns {Promise<*>}
 */


function findGiftBookOrderByOne(params) {
  return request.get('/giftBookOrder/findGiftBookOrderByOne', {
    params: params
  });
}
/**
 * 添加赠书
 * @param {String} data
 * @returns {Promise<*>}
 */


function addGiftBookOrder(data) {
  return request.post('/giftBookOrder/addGiftBookOrder', data);
}
/**
 * 刷新赠书订单
 * @param {String} data
 * @returns {Promise<*>}
 */


function refreshExpress(params) {
  return request.post('/giftBookOrder/refreshExpress', undefined, {
    params: params
  });
}
/**
 * 获取小程序具体页面二维码
 * @param {String} data
 * @returns {Promise<*>}
 */


function getwxPageImg(data, params) {
  return request.post('/weChat/client/wxa/getwxacode', data, {
    params: params
  });
}