"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isSafeNumber = isSafeNumber;
exports.fenToYuan = fenToYuan;
exports.yuanToFen = yuanToFen;
exports.displayToProportion = displayToProportion;
exports.proportionToDisplay = proportionToDisplay;
exports.proportionToLocalization = proportionToLocalization;

var _main = _interopRequireDefault(require("@/organizationLibs/typeIs/main"));

function isSafeNumber(value) {
  if (!_main.default.isNumber(value)) {
    return false;
  }

  if (_main.default.isNaN(value)) {
    return false;
  }

  if (_main.default.isInfinity(value)) {
    return false;
  }

  return true;
}

function fenToYuan(value) {
  if (!isSafeNumber(value)) {
    return 0;
  }

  return value / 100;
}

function yuanToFen(value) {
  if (!isSafeNumber(value)) {
    return 0;
  }

  return Math.floor(value * 100);
}

function displayToProportion(value) {
  if (!isSafeNumber(value)) {
    return 0;
  }

  return value / 100;
}

function proportionToDisplay(value) {
  if (!isSafeNumber(value)) {
    return 0;
  }

  return value * 100;
}

function proportionToLocalization(value) {
  if (!isSafeNumber(value)) {
    return 0;
  }

  return "".concat(value * 10, " \u6298");
}