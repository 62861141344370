import { render, staticRenderFns } from "./AudioBookVipIcon.vue?vue&type=template&id=219f1182&scoped=true&"
import script from "./AudioBookVipIcon.vue?vue&type=script&lang=js&"
export * from "./AudioBookVipIcon.vue?vue&type=script&lang=js&"
import style0 from "./AudioBookVipIcon.vue?vue&type=style&index=0&id=219f1182&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "219f1182",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/front-end_micro-page_oo0i/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('219f1182')) {
      api.createRecord('219f1182', component.options)
    } else {
      api.reload('219f1182', component.options)
    }
    module.hot.accept("./AudioBookVipIcon.vue?vue&type=template&id=219f1182&scoped=true&", function () {
      api.rerender('219f1182', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/MicroPage/AudioBookShelf/AudioBookVipIcon.vue"
export default component.exports