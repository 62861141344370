var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "good-item-info" }, [
    _c(
      "div",
      { class: [_vm.isAllTitle ? "" : "hidden-line", "common-title"] },
      [_vm._v(_vm._s(_vm.item.title))]
    ),
    _vm._v(" "),
    _vm.showReality || _vm.showOriginal
      ? _c("div", { staticClass: "flex-row price-top-margin" }, [
          _vm.showReality
            ? _c("span", { staticClass: "free-price" }, [
                _vm._v(_vm._s(_vm.getPriceFn(_vm.item)))
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.showOriginal && _vm.showReality
            ? _c("div", [
                _vm.getShowOriPrice(_vm.item)
                  ? _c("span", { staticClass: "original-price" }, [
                      _vm._v(_vm._s(_vm.getOrginalPrice))
                    ])
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.showOriginal && !_vm.showReality
            ? _c("span", { staticClass: "free-price" }, [
                _vm._v(_vm._s(_vm.getOrginalPrice))
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }