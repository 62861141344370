"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getEBookList = getEBookList;
exports.getBookCaseList = getBookCaseList;
exports.getEbooks = getEbooks;
exports.getEbookCases = getEbookCases;
exports.getEBookClass = getEBookClass;

var _main = _interopRequireDefault(require("../utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/e-book/mp"));
/**
 * 分页获取电子书列表
 * @param {Object} params
 */

function getEBookList(params) {
  return request.get('/ebook/list', {
    params: params
  });
}
/**
 * 查看电子书橱
 * @param {Object} params
 */


function getBookCaseList(params) {
  return request.get('/ebookCase/list', {
    params: params
  });
}
/**
 * 分类列表
 * @param {Object} params
 */


function getEBookClass(params) {
  return request.get('/ebookClassification/list', {
    params: params
  });
}
/**
 * 根据openIds 获取电子书橱
 * @param params
 * @returns {AxiosPromise<any>}
 */


function getEbookCases(params) {
  return request.get('/ebookCase/getEbookCaseList', {
    params: params
  });
}
/**
 * 根据openIds 获取电子书
 * @param params
 * @returns {AxiosPromise<any>}
 */


function getEbooks(params) {
  return request.get('/ebook/getEbooks', {
    params: params
  });
}