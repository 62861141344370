var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pan-duan" },
    _vm._l(_vm.judgeOption, function(item, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "flex-row align-center common-select common-top"
        },
        [
          _c("el-image", {
            staticClass: "judge-optionIcon",
            attrs: { src: item.icon, fit: "cover" }
          }),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(item.label))])
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }