"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.refreshTokenApi = refreshTokenApi;
exports.checkTokenApi = checkTokenApi;

var _axios = _interopRequireDefault(require("axios"));

var _qs = _interopRequireDefault(require("qs"));

var _config = require("./config");

var auth = {
  username: 'taozhi',
  password: 'MEVl0Iggvx31UNnmPIDi'
};
var headers = {
  'Content-type': 'application/x-www-form-urlencoded'
};
var request;

function getRequest() {
  if (!request) {
    request = _axios.default.create({
      baseURL: (0, _config.getConfig)().apiUrl,
      timeout: 200000
    });
    request.interceptors.response.use(function (response) {
      if (!response) {
        return undefined;
      }

      if (response.status === 204) {
        return undefined;
      } // No Content


      return response.data;
    });
    return request;
  }

  return request;
}
/**
 * 用户刷新 token
 *
 * @param {object} data
 * @returns {Promise<object>}
 */


function refreshTokenApi(data) {
  return getRequest().post('/oauth/token', _qs.default.stringify(data), {
    auth: auth,
    headers: headers
  });
}
/**
 * 用户检查 token
 *
 * @param {object} data
 * @returns {Promise<object>}
 */


function checkTokenApi(data) {
  return getRequest().post('/oauth/check_token', _qs.default.stringify(data), {
    auth: auth,
    headers: headers
  });
}