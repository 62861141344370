"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.number.constructor");

var _common = require("../../../utils/common");

var _microWord = require("../../../utils/microWord");

var _MoreBottom = _interopRequireDefault(require("./MoreBottom"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'WordCheckList',
  components: {
    MoreBottom: _MoreBottom.default
  },
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    item: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    options: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    curIndex: {
      type: Number,
      default: 0
    },
    isMore: {
      type: String,
      default: undefined
    },
    lastLength: {
      type: Number,
      default: 0
    }
  },
  computed: {
    chineseCharBishun: function chineseCharBishun() {
      if (!this.value.chineseCharBishun) {
        return [];
      }

      var list = this.value.chineseCharBishun.bishunJson ? JSON.parse(this.value.chineseCharBishun.bishunJson) : []; // 判断是否存在汉字 存在则不删除第一个

      var reg = /[\u4E00-\u9FA5\uF900-\uFA2D]{1,}/gi;

      if (list.length > 0) {
        if (!reg.test(list[0].bsText)) {
          list.splice(0, 1);
        }
      }

      return list;
    },
    hiddenNum: function hiddenNum() {
      return this.options.hiddenNum ? parseInt(this.options.hiddenNum, 0) : 0;
    }
  },
  methods: {
    isHidden: function isHidden() {
      if (!this.options.isCharacterHidden) {
        return true;
      }

      if (this.isMore === 'up') {
        return true;
      }

      if (this.options.hiddenNum) {
        return parseInt(this.options.hiddenNum, 0) >= this.curIndex + 1;
      }

      return true;
    },
    handleMore: function handleMore() {
      this.$emit('handleMore', this.isMore);
    },
    getRichText: function getRichText(val) {
      return (0, _microWord.getRich)(val);
    },
    // 处理图片
    getImgUrl: function getImgUrl(cover, style) {
      if (!cover) {
        return undefined;
      }

      if (cover.substring(0, 4) === 'http') {
        return cover;
      }

      return (0, _common.imageUrlFun)(cover, style);
    },
    imageSrc: function imageSrc(val) {
      var newBiShun = val.biShun.replace(/^<svg>/, '<svg width="30px" height="30px" version="1.1" xmlns="http://www.w3.org/2000/svg">');
      newBiShun = newBiShun.replace('scale(0.08203125, -0.08203125)', 'scale(0.026, -0.026)');
      newBiShun = newBiShun.replace('translate(8, 83.828125)', 'translate(2, 25)');
      return "data:image/svg+xml;base64,".concat(window.btoa(newBiShun));
    }
  }
};
exports.default = _default2;