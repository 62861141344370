var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "nav-btn" }, [
    _vm.navList.length === 1
      ? _c("div", { class: _vm.navigateBackground }, [
          _c("i", { class: _vm.navList[0].icon, style: _vm.iconFontSize })
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.navList.length === 2
      ? _c(
          "div",
          {
            staticClass: "nav-list",
            style: { color: _vm.navigateFontAndIconColor }
          },
          [
            _c("div", { class: _vm.navigateBackground }, [
              _c("i", { class: _vm.navList[0].icon, style: _vm.iconFontSize })
            ]),
            _vm._v(" "),
            _c("div", { class: _vm.navigateBackground }, [
              _c("i", { class: _vm.navList[1].icon, style: _vm.iconFontSize })
            ])
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.navList.length > 2
      ? _c(
          "div",
          {
            staticClass: "more-view",
            class: _vm.navigateBackground,
            attrs: { id: "moreList" }
          },
          [
            _c("i", {
              staticClass: "iconfont icon-gengduo",
              style: {
                color: _vm.navigateFontAndIconColor,
                "font-size": _vm.showNavigateBackground ? "20px" : "16px"
              },
              on: { click: _vm.handleMore }
            }),
            _vm._v(" "),
            _vm.showMore
              ? _c(
                  "div",
                  { staticClass: "more-list", style: _vm.navigateDrop },
                  _vm._l(_vm.navList, function(item, index) {
                    return _c("div", { key: index }, [
                      _c("i", { staticClass: "nav-icon", class: item.icon }),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(item.title))])
                    ])
                  }),
                  0
                )
              : _vm._e()
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", {
      staticClass: "more-mask",
      on: {
        click: function($event) {
          _vm.showMore = false
        }
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }