"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _ResourceItem = _interopRequireDefault(require("./ResourceItem"));

var _ResourceType = _interopRequireDefault(require("./ResourceType"));

var _price = require("../../../utils/price");

var _RankingInfo = _interopRequireDefault(require("../RankingList/RankingInfo"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'ContentSmallPicture',
  components: {
    RankingInfo: _RankingInfo.default,
    ResourceType: _ResourceType.default,
    ResourceItem: _ResourceItem.default
  },
  props: {
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    size: {
      type: [String, Number],
      default: undefined
    },
    card: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: undefined
    },
    options: {
      type: Object,
      default: function _default() {}
    },
    recommendRule: {
      type: Boolean,
      default: false
    },
    content: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      priceShowByCent: _price.priceShowByCent
    };
  }
};
exports.default = _default2;