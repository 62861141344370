var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "resource-dialogue" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.visible,
            width: "1050px",
            "before-close": _vm.cancelDialogue,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            },
            open: _vm.initData
          }
        },
        [
          _c("shelf-content", {
            attrs: {
              "is-not-rule": true,
              "resource-type": _vm.resourceType,
              "store-name": _vm.storeName,
              refresh: _vm.refresh,
              "type-id": _vm.modelKey,
              result: _vm.result
            },
            on: {
              "update:refresh": function($event) {
                _vm.refresh = $event
              },
              handleResult: _vm.handleResult
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.cancelDialogue } }, [
                _vm._v("取消")
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }