"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'MicroBookstore',
  data: function data() {
    return {
      partnerInfo: {
        partnerLogo: 'https://open.oss.taozhi.online/production/roleCover/03d9da76be1365607f4c83319f0c800d.png?x-oss-process=style%2Fwidth-limit-64',
        storeName: '某某书店名称',
        address: '地址'
      }
    };
  }
};
exports.default = _default;