"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ColorPicker',
  props: {
    value: {
      type: String,
      default: '#000000'
    },
    showAlpha: {
      type: Boolean,
      default: true
    },
    defaultColor: {
      type: String,
      default: '#000000'
    }
  },
  data: function data() {
    return {
      colorData: undefined
    };
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler: function handler(val) {
        this.colorData = val;
      }
    },
    colorData: function colorData(val) {
      this.$emit('input', val);
    }
  },
  methods: {
    changeDefault: function changeDefault() {
      this.colorData = this.defaultColor;
    }
  }
};
exports.default = _default;