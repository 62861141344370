var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.editLoading,
          expression: "editLoading"
        }
      ],
      staticClass: "edit-class",
      attrs: { "element-loading-text": _vm.loadingText }
    },
    [
      _c(
        "div",
        { staticClass: "navigation" },
        [
          _c("edit-navigation", {
            ref: "navigation",
            attrs: { "is-cloud-retail-partner": _vm.isCloudRetailPartner },
            on: {
              handelAllClear: _vm.handleAllClear,
              submitMicroPage: _vm.handleOpenDialogue
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("edit-left", {
        ref: "edit",
        attrs: {
          "competent-contents": _vm.competentContents,
          "basic-components": _vm.newBasicComponents,
          "class-components": _vm.newClassComponents,
          "marketing-components": _vm.newMarketingComponents,
          "other-components": _vm.newOtherComponents,
          "app-home-components": _vm.appHomeComponents,
          "page-title": _vm.htmlTitle,
          "class-data": _vm.classData,
          "is-app-home": _vm.isAppHome,
          "micro-page-basics": _vm.microPageBasics,
          "no-micro-page-id": _vm.noMicroPageId,
          "is-cloud-retail-partner": _vm.isCloudRetailPartner
        },
        on: { successSubmit: _vm.successSubmit }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }