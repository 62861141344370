"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ossUrl = ossUrl;
exports.getFileListossUrl = getFileListossUrl;

function ossUrl(path, defaultCover) {
  return path ? "https://open.oss.taozhi.online/".concat(process.env.VUE_APP_OSS_PATH, "/").concat(path) : defaultCover;
}

function getFileListossUrl(path, defaultCover) {
  return path ? "http://download.oss.taozhi.online/".concat(process.env.VUE_APP_OSS_PATH, "/").concat(path) : defaultCover;
} // eslint-disable-line import/prefer-default-export