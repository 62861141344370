var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cloud-retail-goods" }, [
    _vm.options.style === "smallPicture"
      ? _c(
          "div",
          { staticClass: "small-picture" },
          _vm._l(_vm.list, function(item, index) {
            return _c(
              "div",
              {
                key: item.id,
                class: [index < _vm.size ? "small-picture-item" : ""],
                style: { width: _vm.smallProductWidth }
              },
              [
                index < _vm.size
                  ? _c(
                      "div",
                      {
                        staticClass: "cover cover-position",
                        style: { height: _vm.smallProductWidth }
                      },
                      [
                        _c("el-image", {
                          staticClass: "cover",
                          style: { height: _vm.smallProductWidth },
                          attrs: {
                            fit: "cover",
                            src: _vm.getImgUrl(
                              item.cover
                                ? item.cover
                                : "assets/taozhi/shopMiniProgram/commodityList/commodity-cover.png",
                              "style/width-limit-256"
                            )
                          }
                        }),
                        _vm._v(" "),
                        _vm.getIsInventory(item)
                          ? _c(
                              "div",
                              { staticClass: "mask common-flex-center" },
                              [_vm._v("暂无库存")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                index < _vm.size
                  ? _c("good-item-info", {
                      attrs: {
                        item: item,
                        "show-reality": _vm.showReality,
                        "show-original": _vm.showOriginal
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.options.style === "line"
      ? _c(
          "div",
          { staticClass: "line" },
          _vm._l(_vm.list, function(item, index) {
            return _c(
              "div",
              { key: item.id, class: [index < _vm.size ? "line-item" : ""] },
              [
                index < _vm.size
                  ? _c(
                      "div",
                      { staticClass: "cover cover-position" },
                      [
                        _c("el-image", {
                          staticClass: "cover",
                          attrs: {
                            fit: "cover",
                            src: _vm.getImgUrl(
                              item.cover
                                ? item.cover
                                : "assets/taozhi/shopMiniProgram/commodityList/commodity-cover.png",
                              "style/width-limit-256"
                            )
                          }
                        }),
                        _vm._v(" "),
                        _vm.getIsInventory(item)
                          ? _c(
                              "div",
                              { staticClass: "mask common-flex-center" },
                              [_vm._v("暂无库存")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                index < _vm.size
                  ? _c("div", { staticClass: "info" }, [
                      _c("div", { staticClass: "hidden-line line-title" }, [
                        _vm._v(_vm._s(item.title))
                      ]),
                      _vm._v(" "),
                      _vm.showReality || _vm.showOriginal
                        ? _c(
                            "div",
                            { staticClass: "flex-row common-top-margin" },
                            [
                              _vm.showReality
                                ? _c("span", { staticClass: "free-price" }, [
                                    _vm._v(_vm._s(_vm.getPriceFn(item)))
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showOriginal && _vm.showReality
                                ? _c("div", [
                                    _vm.getShowOriPrice(item)
                                      ? _c(
                                          "span",
                                          { staticClass: "original-price" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.getOrginalPrice(item))
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showOriginal && !_vm.showReality
                                ? _c("span", { staticClass: "free-price" }, [
                                    _vm._v(_vm._s(_vm.getOrginalPrice(item)))
                                  ])
                                : _vm._e()
                            ]
                          )
                        : _vm._e()
                    ])
                  : _vm._e()
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.options.style === "card"
      ? _c(
          "div",
          { staticClass: "card" },
          _vm._l(_vm.list, function(item, index) {
            return _c(
              "div",
              {
                key: item.id,
                class: [index < _vm.size ? "card-item" : ""],
                style: {
                  width: _vm.smallProductWidth,
                  background:
                    index < _vm.size
                      ? _vm.options.color.background
                      : "transparent"
                }
              },
              [
                index < _vm.size
                  ? _c(
                      "div",
                      {
                        staticClass: "cover cover-position",
                        style: { height: _vm.cardProductWidth }
                      },
                      [
                        _c("el-image", {
                          staticClass: "cover",
                          style: { height: _vm.cardProductWidth },
                          attrs: {
                            fit: "cover",
                            src: _vm.getImgUrl(
                              item.cover
                                ? item.cover
                                : "assets/taozhi/shopMiniProgram/commodityList/commodity-cover.png",
                              "style/width-limit-256"
                            )
                          }
                        }),
                        _vm._v(" "),
                        _vm.getIsInventory(item)
                          ? _c(
                              "div",
                              { staticClass: "mask common-flex-center" },
                              [_vm._v("暂无库存")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                index < _vm.size
                  ? _c("good-item-info", {
                      attrs: {
                        item: item,
                        "show-reality": _vm.showReality,
                        "show-original": _vm.showOriginal
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.options.style === "slide"
      ? _c(
          "div",
          { staticClass: "product-slide" },
          [
            _c(
              "slider",
              {
                attrs: {
                  options: Object.assign({}, _vm.sliderinit, {
                    slidesToScroll: 3
                  })
                }
              },
              _vm._l(_vm.list, function(item, index) {
                return _c(
                  "slideritem",
                  {
                    key: index,
                    style: {
                      width: "120px",
                      "margin-bottom": "15px",
                      "margin-right": "15px",
                      "align-items": "flex-start"
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticStyle: { width: "100%" } },
                      [
                        index < _vm.size
                          ? _c(
                              "div",
                              { staticClass: "cover cover-position" },
                              [
                                _c("el-image", {
                                  staticClass: "cover",
                                  attrs: {
                                    fit: "cover",
                                    src: _vm.getImgUrl(
                                      item.cover
                                        ? item.cover
                                        : "assets/taozhi/shopMiniProgram/commodityList/commodity-cover.png",
                                      "style/width-limit-256"
                                    )
                                  }
                                }),
                                _vm._v(" "),
                                _vm.getIsInventory(item)
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "mask common-flex-center"
                                      },
                                      [_vm._v("暂无库存")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        index < _vm.size
                          ? _c("good-item-info", {
                              attrs: {
                                item: item,
                                "show-reality": _vm.showReality,
                                "show-original": _vm.showOriginal
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                )
              }),
              1
            )
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.options.style === "three"
      ? _c(
          "div",
          { staticClass: "product-three" },
          _vm._l(_vm.list, function(item, index) {
            return _c(
              "div",
              {
                key: item.id,
                class: [index < _vm.size ? "three-picture-item" : ""],
                style: { width: _vm.threeProductWidth }
              },
              [
                index < _vm.size
                  ? _c(
                      "div",
                      {
                        staticClass: "cover cover-position",
                        style: { height: _vm.threeProductWidth }
                      },
                      [
                        _c("el-image", {
                          staticClass: "cover",
                          style: { height: _vm.threeProductWidth },
                          attrs: {
                            fit: "cover",
                            src: _vm.getImgUrl(
                              item.cover
                                ? item.cover
                                : "assets/taozhi/shopMiniProgram/commodityList/commodity-cover.png",
                              "style/width-limit-256"
                            )
                          }
                        }),
                        _vm._v(" "),
                        _vm.getIsInventory(item)
                          ? _c(
                              "div",
                              { staticClass: "mask common-flex-center" },
                              [_vm._v("暂无库存")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                index < _vm.size
                  ? _c("good-item-info", {
                      attrs: {
                        item: item,
                        "show-reality": _vm.showReality,
                        "show-original": _vm.showOriginal
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.options.style === "waterfall"
      ? _c("div", { staticClass: "product-waterfall" }, [
          _c(
            "div",
            { staticClass: "waterfall-column" },
            _vm._l(_vm.leftList, function(item, index) {
              return _c(
                "div",
                {
                  key: item.id,
                  class: [index < _vm.size ? "small-picture-item " : ""],
                  style: { width: _vm.smallProductWidth }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "cover cover-position",
                      style: { height: _vm.smallProductWidth }
                    },
                    [
                      _c("el-image", {
                        staticClass: "cover",
                        style: { height: _vm.smallProductWidth },
                        attrs: {
                          fit: "cover",
                          src: _vm.getImgUrl(
                            item.cover
                              ? item.cover
                              : "assets/taozhi/shopMiniProgram/commodityList/commodity-cover.png",
                            "style/width-limit-256"
                          )
                        }
                      }),
                      _vm._v(" "),
                      _vm.getIsInventory(item)
                        ? _c(
                            "div",
                            { staticClass: "mask common-flex-center" },
                            [_vm._v("暂无库存")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("good-item-info", {
                    attrs: {
                      "is-all-title": true,
                      item: item,
                      "show-reality": _vm.showReality,
                      "show-original": _vm.showOriginal
                    }
                  })
                ],
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "waterfall-column" },
            _vm._l(_vm.rightList, function(item, index) {
              return _c(
                "div",
                {
                  key: item.id,
                  class: [index < _vm.size ? "small-picture-item" : ""],
                  style: { width: _vm.smallProductWidth }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "cover cover-position",
                      style: { height: _vm.smallProductWidth }
                    },
                    [
                      _c("el-image", {
                        staticClass: "cover",
                        style: { height: _vm.smallProductWidth },
                        attrs: {
                          fit: "cover",
                          src: _vm.getImgUrl(
                            item.cover
                              ? item.cover
                              : "assets/taozhi/shopMiniProgram/commodityList/commodity-cover.png",
                            "style/width-limit-256"
                          )
                        }
                      }),
                      _vm._v(" "),
                      _vm.getIsInventory(item)
                        ? _c(
                            "div",
                            { staticClass: "mask common-flex-center" },
                            [_vm._v("暂无库存")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("good-item-info", {
                    attrs: {
                      "is-all-title": true,
                      item: item,
                      "show-reality": _vm.showReality,
                      "show-original": _vm.showOriginal
                    }
                  })
                ],
                1
              )
            }),
            0
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.options.setPackageBuy && _vm.options.packageBuyBtn
      ? _c("div", { staticClass: "footer" }, [
          _c("div", { staticClass: "footer-btn" }, [
            _vm._v(_vm._s(_vm.options.packageBuyBtn))
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }