"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _typeof2 = _interopRequireDefault(require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime-corejs2/helpers/typeof"));

var _index = require("@/utils/cachedApi/common/index");

var _dictionary = require("@/utils/cachedApi/common/dictionary");

var _valueAddedServices = require("@/utils/cachedApi/valueAddedServices");

var _DefaultSyncInfo = _interopRequireDefault(require("../Selector/SyncInfo/DefaultSyncInfo"));

var _DefaultTagSelect = _interopRequireDefault(require("../Selector/TagSelect/DefaultTagSelect"));

var _DefaultSelector = _interopRequireDefault(require("../Selector/CommoditySecondaryCategories/DefaultSelector"));

var _TheUnderlyingColumn = _interopRequireDefault(require("./TheUnderlyingColumn"));

var _dictionary2 = require("../../../../../api/common/dictionary");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'CPMGeFilterOptionsConditionalFiltering',
  components: {
    TheUnderlyingColumn: _TheUnderlyingColumn.default,
    SyncInfo: _DefaultSyncInfo.default,
    TagSelect: _DefaultTagSelect.default,
    DefaultSelector: _DefaultSelector.default
  },
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      getSyncSubject: _index.getSyncSubject,
      getSyncSection: _index.getSyncSection,
      getSyncEdition: _index.getSyncEdition,
      getSyncGrade: _index.getSyncGrade,
      getSyncVolume: _index.getSyncVolume,
      getSyncElective: _dictionary.getSyncElective,
      getAllBookArea: _dictionary.getAllBookArea,
      getSyncPeriod: _dictionary.getSyncPeriod,
      getBrandList: _dictionary.getBrandList,
      getAllBookSeries: _valueAddedServices.getAllBookSeries,
      getSeriesList: _dictionary2.getSeriesList
    };
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    },
    getSeriesListSearchQuery: function getSeriesListSearchQuery() {
      return {
        brandOpenId: this.valueProxy.brandOpenId,
        orderBy: 'sequence',
        sortOrder: 'desc',
        size: 99999
      };
    },
    disableSeriesCode: function disableSeriesCode() {
      return typeof this.valueProxy.brandOpenId !== 'string' || this.valueProxy.brandOpenId.trim().length < 1;
    }
  },
  watch: {
    'valueProxy.brandOpenId': {
      handler: function handler(nVal, oVal) {
        var _this = this;

        if (nVal === oVal) {
          return;
        } // 此时值未变


        this.$nextTick(function () {
          if ((0, _typeof2.default)(_this.$refs.subjectList) !== 'object' || _this.$refs.subjectList === null) {
            return;
          } // 有时 subjectList 组件可能未加载


          _this.valueProxy.seriesCode = undefined;

          _this.$refs.subjectList.updateData();
        });
      },
      deep: true
    }
  }
};
exports.default = _default2;