var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "grading-list-layout"
    },
    [
      _c("product-header", {
        on: { "search-changed": _vm.updateData },
        scopedSlots: _vm._u(
          [
            {
              key: "left",
              fn: function() {
                return [_vm._t("header-left")]
              },
              proxy: true
            }
          ],
          null,
          true
        ),
        model: {
          value: _vm.query,
          callback: function($$v) {
            _vm.query = $$v
          },
          expression: "query"
        }
      }),
      _vm._v(" "),
      _c(
        "product-content-table",
        {
          ref: "singleTable",
          staticStyle: { width: "100%" },
          attrs: { data: _vm.list, height: "390px", "is-multiple": false },
          on: { handleCurrentChange: _vm.handleCurrentChange }
        },
        [
          _c("el-table-column", {
            attrs: { slot: "prefix", fixed: "", width: "30" },
            slot: "prefix",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-radio",
                      {
                        attrs: { label: scope.row.productOpenId },
                        model: {
                          value: _vm.value.productOpenId,
                          callback: function($$v) {
                            _vm.$set(_vm.value, "productOpenId", $$v)
                          },
                          expression: "value.productOpenId"
                        }
                      },
                      [
                        _c("div", { staticClass: "radio-content-hidden" }, [
                          _vm._v(_vm._s(scope.row.productOpenId))
                        ])
                      ]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c("pagination", {
            attrs: {
              total: _vm.totalElements,
              page: _vm.query.page,
              limit: _vm.query.size,
              "current-page": _vm.query.page
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.query, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.query, "size", $event)
              },
              pagination: _vm.updateData
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }