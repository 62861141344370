var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { style: { color: _vm.options.color.font } }, [
    _vm.options.style === "outsideBottom"
      ? _c(
          "div",
          { staticClass: "flex-column" },
          [
            _vm.value.image
              ? _c("el-image", {
                  class: [_vm.pictureStyle],
                  attrs: { fit: "cover", src: _vm.getImgUrl(_vm.value.image) }
                })
              : _c(
                  "div",
                  {
                    class: [_vm.pictureStyle, "common-flex-center"],
                    staticStyle: { "background-color": "#EFEFEF" }
                  },
                  [
                    _vm.isCloudRetailPartner
                      ? _c("img", {
                          staticStyle: { width: "43px", height: "51px" },
                          attrs: {
                            src: require("../../assets/cloudRetail/default-cover.png")
                          }
                        })
                      : _c("img", {
                          staticStyle: { width: "40px", height: "13px" },
                          attrs: {
                            src: require("../../assets/images/taozhiLogo.png")
                          }
                        })
                  ]
                ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "common-text outside-text hidden-line",
                style: { width: _vm.textWidth }
              },
              [_vm._v(_vm._s(_vm.value.title))]
            )
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.options.style === "innerBottom"
      ? _c(
          "div",
          { class: [_vm.pictureStyle, "common-position"] },
          [
            _vm.value.image
              ? _c("el-image", {
                  class: [_vm.pictureStyle],
                  attrs: { fit: "cover", src: _vm.getImgUrl(_vm.value.image) }
                })
              : _c(
                  "div",
                  {
                    class: [_vm.pictureStyle, "common-flex-center"],
                    staticStyle: { "background-color": "#EFEFEF" }
                  },
                  [
                    _vm.isCloudRetailPartner
                      ? _c("img", {
                          staticStyle: { width: "43px", height: "51px" },
                          attrs: {
                            src: require("../../assets/cloudRetail/default-cover.png")
                          }
                        })
                      : _c("img", {
                          staticStyle: { width: "40px", height: "13px" },
                          attrs: {
                            src: require("../../assets/images/taozhiLogo.png")
                          }
                        })
                  ]
                ),
            _vm._v(" "),
            _vm.value.title
              ? _c(
                  "div",
                  {
                    staticClass: "mask-position",
                    staticStyle: {
                      "border-bottom-left-radius": "4px",
                      "border-bottom-right-radius": "4px"
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "inner-bottom-text hidden-line" },
                      [
                        _vm._v(
                          "\n        " + _vm._s(_vm.value.title) + "\n      "
                        )
                      ]
                    )
                  ]
                )
              : _vm._e()
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.options.style === "innerCenter"
      ? _c(
          "div",
          { class: [_vm.pictureStyle, "common-position"] },
          [
            _vm.value.image
              ? _c("el-image", {
                  class: [_vm.pictureStyle],
                  attrs: { fit: "cover", src: _vm.getImgUrl(_vm.value.image) }
                })
              : _c(
                  "div",
                  {
                    class: [_vm.pictureStyle, "common-flex-center"],
                    staticStyle: { "background-color": "#EFEFEF" }
                  },
                  [
                    _vm.isCloudRetailPartner
                      ? _c("img", {
                          staticStyle: { width: "43px", height: "51px" },
                          attrs: {
                            src: require("../../assets/cloudRetail/default-cover.png")
                          }
                        })
                      : _c("img", {
                          staticStyle: { width: "40px", height: "13px" },
                          attrs: {
                            src: require("../../assets/images/taozhiLogo.png")
                          }
                        })
                  ]
                ),
            _vm._v(" "),
            _vm.value.title
              ? _c(
                  "div",
                  {
                    staticClass: "mask-position common-flex-center",
                    style: { height: _vm.textWidth, "border-radius": "4px" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "inner-center-text hidden-line" },
                      [
                        _vm._v(
                          "\n        " + _vm._s(_vm.value.title) + "\n      "
                        )
                      ]
                    )
                  ]
                )
              : _vm._e()
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }