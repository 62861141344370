"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.array.find");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.number.constructor");

var _RankingInfo = _interopRequireDefault(require("./RankingInfo"));

var _price = require("../../../utils/price");

var _common = require("../../../utils/common");

var _coverVideo = _interopRequireDefault(require("../../../assets/cover/cover-video.png"));

var _coverAudio = _interopRequireDefault(require("../../../assets/cover/cover-audio.png"));

var _microPageAudio = _interopRequireDefault(require("../../../assets/cover/micro-page-audio.png"));

var _coverAudiobook = _interopRequireDefault(require("../../../assets/cover/cover-audiobook.png"));

var _microPageAudiobook = _interopRequireDefault(require("../../../assets/cover/micro-page-audiobook.png"));

var _coverAlbum = _interopRequireDefault(require("../../../assets/cover/cover-album.png"));

var _coverFiles = _interopRequireDefault(require("../../../assets/cover/cover-files.png"));

var _microPageFiles = _interopRequireDefault(require("../../../assets/cover/micro-page-files.png"));

var _coverImgTxt = _interopRequireDefault(require("../../../assets/cover/cover-imgTxt.png"));

var _coverFile = _interopRequireDefault(require("../../../assets/cover/cover-file.png"));

var _microPageFile = _interopRequireDefault(require("../../../assets/cover/micro-page-file.png"));

var _coverLive = _interopRequireDefault(require("../../../assets/cover/cover-live.png"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'RecommendRankingList',
  components: {
    RankingInfo: _RankingInfo.default
  },
  props: {
    recommend: {
      type: [Object, String],
      default: undefined
    },
    element: {
      type: Object,
      default: function _default() {}
    },
    recommendRule: {
      type: Boolean,
      default: false
    },
    partsLength: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      priceShowByCent: _price.priceShowByCent
    };
  },
  computed: {
    isSpecial: function isSpecial() {
      if (!this.recommend) {
        return false;
      }

      return [3, 8, 10, 19, 2].includes(this.recommend.resourceType);
    },
    coverClass: function coverClass() {
      var val = this.recommend ? this.recommend.resourceType : undefined;

      if (val === 3) {
        return 'audio-book-cover';
      }

      if (val === 2 || val === 8 || val === 10 || val === 19) {
        return 'audio-cover';
      }

      return 'recommend-cover';
    }
  },
  methods: {
    // 知识商品推荐
    recommendType: function recommendType(val) {
      var resourceTypeList = [{
        value: 1,
        name: '视频'
      }, {
        value: 3,
        name: '点学书'
      }, {
        value: 2,
        name: '音频'
      }, {
        value: 4,
        name: '专辑'
      }, {
        value: 11,
        name: '直播'
      }, {
        value: 10,
        name: '文件'
      }, {
        value: 9,
        name: '图文'
      }, {
        value: 8,
        name: '文件包'
      }, {
        value: 16,
        name: '大专辑'
      }];
      var data = resourceTypeList.find(function (item) {
        return item.value === val;
      });
      return data ? data.name : undefined;
    },
    // 处理图片
    getImgUrl: function getImgUrl(cover) {
      var style = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'style/width-limit-256';

      if (cover) {
        return (0, _common.imageUrlFun)(cover, style);
      }

      var path;
      var type = 'line';

      switch (this.recommend.resourceType) {
        case 1:
          {
            path = _coverVideo.default;
            break;
          }

        case 2:
          {
            path = type === 'line' ? _coverAudio.default : _microPageAudio.default;
            break;
          }

        case 3:
          {
            path = type === 'line' ? _coverAudiobook.default : _microPageAudiobook.default;
            break;
          }

        case 4:
          {
            path = _coverAlbum.default;
            break;
          }

        case 8:
          {
            path = type === 'line' ? _coverFiles.default : _microPageFiles.default;
            break;
          }

        case 9:
          {
            path = _coverImgTxt.default;
            break;
          }

        case 10:
          {
            path = type === 'line' ? _coverFile.default : _microPageFile.default;
            break;
          }

        case 11:
          {
            path = _coverLive.default;
            break;
          }

        default:
          {
            path = _coverAlbum.default;
          }
      }

      return path;
    }
  }
};
exports.default = _default2;