var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "category-classification" },
    [
      _c("el-cascader", {
        attrs: {
          options: _vm.list,
          props: { value: "openId", label: "name" },
          clearable: ""
        },
        model: {
          value: _vm.valueProxy.classList,
          callback: function($$v) {
            _vm.$set(_vm.valueProxy, "classList", $$v)
          },
          expression: "valueProxy.classList"
        }
      }),
      _vm._v(" "),
      _vm.showUpdate
        ? _c(
            "el-link",
            {
              staticClass: "update-text",
              attrs: { underline: false, type: "primary" },
              on: { click: _vm.handleUpdate }
            },
            [_vm._v("编辑")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "micro-page-add-class",
          attrs: {
            title: "",
            width: "600px",
            "append-to-body": "",
            visible: _vm.visible,
            "before-close": _vm.cancelDialogue,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c("micro-classification", {
            staticClass: "classification-border",
            attrs: {
              "height-style": "500px",
              "is-platform": _vm.valueProxy.isPlatform,
              "partner-open-id": _vm.valueProxy.partnerOpenId,
              "is-dialog": true
            },
            on: { refreshClassList: _vm.refreshDetail }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }