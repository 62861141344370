"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _PartnerLogo = _interopRequireDefault(require("./PartnerLogo"));

var _PartnerDescribe = _interopRequireDefault(require("./PartnerDescribe"));

var _common = require("../../../utils/common");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'index',
  components: {
    PartnerDescribe: _PartnerDescribe.default,
    PartnerLogo: _PartnerLogo.default
  },
  props: {
    element: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  methods: {
    // 处理图片
    getImgUrl: function getImgUrl(cover) {
      var style = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'style/width-limit-768';
      var imgAllUrl = (0, _common.imageUrlFun)(cover, style);
      return imgAllUrl;
    }
  }
};
exports.default = _default2;