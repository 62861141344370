import { render, staticRenderFns } from "./ExternalLinks.vue?vue&type=template&id=5df27105&scoped=true&"
import script from "./ExternalLinks.vue?vue&type=script&lang=js&"
export * from "./ExternalLinks.vue?vue&type=script&lang=js&"
import style0 from "./ExternalLinks.vue?vue&type=style&index=0&id=5df27105&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5df27105",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/front-end_micro-page_oo0i/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5df27105')) {
      api.createRecord('5df27105', component.options)
    } else {
      api.reload('5df27105', component.options)
    }
    module.hot.accept("./ExternalLinks.vue?vue&type=template&id=5df27105&scoped=true&", function () {
      api.rerender('5df27105', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/SelectLink/ExternalLinks.vue"
export default component.exports