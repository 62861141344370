"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getBigAlbumList = getBigAlbumList;
exports.getBigAlbumResourceList = getBigAlbumResourceList;

var _main = _interopRequireDefault(require("../utils/request/main"));

var _store = _interopRequireDefault(require("../store"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/album-management/admin/"));
var requestMp = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/album-management/mp/"));
/**
 * 获取音频列表
 * @param {Object} params
 * @returns {Promise<*>}
 */

function getBigAlbumList(params) {
  return request.get('/big/request/list', {
    params: params
  });
}
/**
 * 获取音频列表(用于商品)
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getBigAlbumResourceList(params) {
  if (_store.default.state.microPage.isAdmin) {
    return request.get('/big/list', {
      params: params
    });
  }

  return requestMp.get('/big/list', {
    params: params
  });
}