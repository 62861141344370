var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      class: [
        "search-panel",
        _vm.typeName === "电子书" ? "search-panel-ebook" : ""
      ],
      attrs: { model: _vm.valueProxy, "label-width": "70px", size: "small" }
    },
    [
      _c(
        "div",
        { staticClass: "common-form" },
        [
          _c("div", { staticClass: "title" }, [_vm._v("条件筛选")]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "同步信息" } }, [
            _c(
              "div",
              { staticClass: "sync-info" },
              [
                _c("select-param-sync-info", {
                  attrs: {
                    size: "small",
                    "query-option": "FindXDList",
                    placeholder: "学段"
                  },
                  model: {
                    value: _vm.valueProxy.xd,
                    callback: function($$v) {
                      _vm.$set(_vm.valueProxy, "xd", $$v)
                    },
                    expression: "valueProxy.xd"
                  }
                }),
                _vm._v(" "),
                _c("select-param-sync-info", {
                  attrs: {
                    size: "small",
                    "query-option": "FindSubjectList",
                    placeholder: "学科"
                  },
                  model: {
                    value: _vm.valueProxy.subject,
                    callback: function($$v) {
                      _vm.$set(_vm.valueProxy, "subject", $$v)
                    },
                    expression: "valueProxy.subject"
                  }
                }),
                _vm._v(" "),
                _c("select-param-sync-info", {
                  attrs: {
                    size: "small",
                    "query-option": "FindBBList",
                    placeholder: "版本"
                  },
                  model: {
                    value: _vm.valueProxy.bb,
                    callback: function($$v) {
                      _vm.$set(_vm.valueProxy, "bb", $$v)
                    },
                    expression: "valueProxy.bb"
                  }
                }),
                _vm._v(" "),
                _c("select-param-sync-info", {
                  attrs: {
                    size: "small",
                    "query-option": "FindGradeList",
                    placeholder: "年级"
                  },
                  model: {
                    value: _vm.valueProxy.grade,
                    callback: function($$v) {
                      _vm.$set(_vm.valueProxy, "grade", $$v)
                    },
                    expression: "valueProxy.grade"
                  }
                }),
                _vm._v(" "),
                _c("select-param-sync-info", {
                  attrs: {
                    size: "small",
                    "query-option": "FindJDList",
                    placeholder: "阶段"
                  },
                  model: {
                    value: _vm.valueProxy.jd,
                    callback: function($$v) {
                      _vm.$set(_vm.valueProxy, "jd", $$v)
                    },
                    expression: "valueProxy.jd"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "地区信息" } },
            [
              _c("area-option", {
                staticClass: "common-select-width",
                attrs: { placeholder: "地区" },
                model: {
                  value: _vm.valueProxy.areaId,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "areaId", $$v)
                  },
                  expression: "valueProxy.areaId"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "版次信息" } },
            [
              _c("select-param-sync-info", {
                staticClass: "common-select-width",
                attrs: {
                  size: "small",
                  "query-option": "FindAll",
                  placeholder: "版次"
                },
                model: {
                  value: _vm.valueProxy.bc,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "bc", $$v)
                  },
                  expression: "valueProxy.bc"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "商品标签" } },
            [
              _c("attribute-tag", {
                staticClass: "common-select-width",
                attrs: { "type-name": _vm.typeName, "is-disabled": false },
                model: {
                  value: _vm.valueProxy.tags,
                  callback: function($$v) {
                    _vm.$set(_vm.valueProxy, "tags", $$v)
                  },
                  expression: "valueProxy.tags"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.typeName !== "电子书"
            ? _c(
                "el-form-item",
                { attrs: { label: "类目" } },
                [
                  _c("category-view", {
                    staticClass: "common-select-width",
                    on: { "category-change": _vm.categoryChange },
                    model: {
                      value: _vm.valueProxy.secondRegimentationIds,
                      callback: function($$v) {
                        _vm.$set(_vm.valueProxy, "secondRegimentationIds", $$v)
                      },
                      expression: "valueProxy.secondRegimentationIds"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.typeName !== "电子书"
            ? _c(
                "div",
                [
                  _c(
                    "div",
                    {
                      staticClass: "title",
                      staticStyle: { "margin-top": "10px" }
                    },
                    [_vm._v("状态筛选")]
                  ),
                  _vm._v(" "),
                  _vm.showJsonData
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "关联试题" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "common-select-width",
                              model: {
                                value: _vm.valueProxy.isHaveTest,
                                callback: function($$v) {
                                  _vm.$set(_vm.valueProxy, "isHaveTest", $$v)
                                },
                                expression: "valueProxy.isHaveTest"
                              }
                            },
                            [
                              _c("el-radio-button", { attrs: { label: 0 } }, [
                                _vm._v("全部")
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: 1 } }, [
                                _vm._v("关联")
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: 2 } }, [
                                _vm._v("未关联")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showJsonData
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "关联附件" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "common-select-width",
                              model: {
                                value: _vm.valueProxy.isClosure,
                                callback: function($$v) {
                                  _vm.$set(_vm.valueProxy, "isClosure", $$v)
                                },
                                expression: "valueProxy.isClosure"
                              }
                            },
                            [
                              _c("el-radio-button", { attrs: { label: 0 } }, [
                                _vm._v("全部")
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: 1 } }, [
                                _vm._v("关联")
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: 2 } }, [
                                _vm._v("未关联")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.typeName === "音频" && _vm.showVidSearch
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "音频文件" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "225px" },
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.valueProxy.isVid,
                                callback: function($$v) {
                                  _vm.$set(_vm.valueProxy, "isVid", $$v)
                                },
                                expression: "valueProxy.isVid"
                              }
                            },
                            [
                              _c("el-option", {
                                attrs: { value: 0, label: "全部" }
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { value: 1, label: "有" }
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { value: 2, label: "无" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "显示状态" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "common-select-width",
                          model: {
                            value: _vm.valueProxy.isShow,
                            callback: function($$v) {
                              _vm.$set(_vm.valueProxy, "isShow", $$v)
                            },
                            expression: "valueProxy.isShow"
                          }
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "0" } }, [
                            _vm._v("全部")
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "1" } }, [
                            _vm._v("显示")
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "2" } }, [
                            _vm._v("隐藏")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "推荐状态" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "common-select-width",
                          model: {
                            value: _vm.valueProxy.recommendSequence,
                            callback: function($$v) {
                              _vm.$set(_vm.valueProxy, "recommendSequence", $$v)
                            },
                            expression: "valueProxy.recommendSequence"
                          }
                        },
                        [
                          _c("el-radio-button", { attrs: { label: 0 } }, [
                            _vm._v("全部")
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: 1 } }, [
                            _vm._v("推荐")
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: 2 } }, [
                            _vm._v("非推荐")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "售卖状态" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "common-select-width",
                          model: {
                            value: _vm.valueProxy.isSale,
                            callback: function($$v) {
                              _vm.$set(_vm.valueProxy, "isSale", $$v)
                            },
                            expression: "valueProxy.isSale"
                          }
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "0" } }, [
                            _vm._v("全部")
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "1" } }, [
                            _vm._v("售卖")
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "2" } }, [
                            _vm._v("停售")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "独立售卖" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "sale-width",
                          model: {
                            value: _vm.valueProxy.isIndSale,
                            callback: function($$v) {
                              _vm.$set(_vm.valueProxy, "isIndSale", $$v)
                            },
                            expression: "valueProxy.isIndSale"
                          }
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "0" } }, [
                            _vm._v("全部")
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "1" } }, [
                            _vm._v("独立售卖")
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "2" } }, [
                            _vm._v("非独立售卖")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "common-form time-form common-form-padding" },
        [
          _c("div", { staticClass: "title" }, [_vm._v("时间与排序")]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "time-form-item", attrs: { label: "创建时间" } },
            [
              _c("el-date-picker", {
                staticClass: "time-form-width",
                attrs: {
                  "picker-options": _vm.pickerOptions,
                  "default-time": ["00:00:00", "23:59:59"],
                  type: "datetimerange",
                  align: "left",
                  "unlink-panels": "",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "value-format": "yyyy-MM-dd HH:mm:ss"
                },
                model: {
                  value: _vm.startDate,
                  callback: function($$v) {
                    _vm.startDate = $$v
                  },
                  expression: "startDate"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "time-form-item", attrs: { label: "发布时间" } },
            [
              _c("el-date-picker", {
                staticClass: "time-form-width",
                attrs: {
                  "picker-options": _vm.pickerOptions,
                  "default-time": ["00:00:00", "23:59:59"],
                  type: "datetimerange",
                  align: "left",
                  "unlink-panels": "",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "value-format": "yyyy-MM-dd HH:mm:ss"
                },
                model: {
                  value: _vm.endDate,
                  callback: function($$v) {
                    _vm.endDate = $$v
                  },
                  expression: "endDate"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.isLive
            ? _c(
                "el-form-item",
                { attrs: { label: "直播时间" } },
                [
                  _c("el-date-picker", {
                    staticClass: "time-form-width",
                    attrs: {
                      "picker-options": _vm.pickerOptions,
                      "default-time": ["00:00:00", "23:59:59"],
                      type: "datetimerange",
                      align: "left",
                      "unlink-panels": "",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "value-format": "yyyy-MM-dd HH:mm:ss"
                    },
                    model: {
                      value: _vm.liveDate,
                      callback: function($$v) {
                        _vm.liveDate = $$v
                      },
                      expression: "liveDate"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "排序条件" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "time-form-width",
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.valueProxy.orderBy,
                    callback: function($$v) {
                      _vm.$set(_vm.valueProxy, "orderBy", $$v)
                    },
                    expression: "valueProxy.orderBy"
                  }
                },
                _vm._l(
                  _vm.isLive ? _vm.liveOrderOptions : _vm.orderOptions,
                  function(item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value }
                    })
                  }
                ),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "排序方式" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.valueProxy.orderMode,
                    callback: function($$v) {
                      _vm.$set(_vm.valueProxy, "orderMode", $$v)
                    },
                    expression: "valueProxy.orderMode"
                  }
                },
                [
                  _c("el-radio-button", { attrs: { label: "asc" } }, [
                    _vm._v("正序")
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "desc" } }, [
                    _vm._v("倒序")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }