"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _resource = require("../../../utils/resource");

var _ResourceEncode = _interopRequireDefault(require("./ResourceEncode.vue"));

var _ResourceAuthor = _interopRequireDefault(require("./ResourceAuthor.vue"));

var _ResourcePrice = _interopRequireDefault(require("./ResourcePrice.vue"));

var _ResourceLiveState = _interopRequireDefault(require("./ResourceLiveState.vue"));

var _ResourceTitle = _interopRequireDefault(require("./ResourceTitle.vue"));

var _common = require("../../../utils/common");

var _const = require("../../../utils/const");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'ResourceSimple',
  components: {
    ResourceEncode: _ResourceEncode.default,
    ResourceAuthor: _ResourceAuthor.default,
    ResourcePrice: _ResourcePrice.default,
    resourceTitle: _ResourceTitle.default,
    ResourceLiveState: _ResourceLiveState.default
  },
  props: {
    resourceObj: {
      type: Object,
      default: null
    },
    isEdit: {
      type: Boolean,
      default: true
    },
    saveResourceEncode: {
      type: Function,
      default: function _default() {
        return {};
      }
    },
    handlerFinishClassFun: {
      type: Function,
      default: function _default() {
        return {};
      }
    },
    showPage: {
      type: Function,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      resourceEncodeFun: this.saveResourceEncode,
      getResourceInfo: _resource.getResourceInfo,
      imageUrlFun: _common.imageUrlFun,
      LIVE_ID: _const.LIVE_ID
    };
  },
  methods: {}
};
exports.default = _default2;