"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _price = require("../../../utils/price");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'AudioBookPrice',
  props: {
    options: {
      type: Object,
      default: function _default() {}
    },
    value: {
      type: Object,
      default: function _default() {}
    },
    fontSize: {
      type: String,
      default: '28rpx'
    },
    originalPriceSize: {
      type: String,
      default: '26rpx'
    },
    originalPriceColor: {
      type: String,
      default: '28rpx'
    }
  },
  data: function data() {
    return {
      priceShowByCent: _price.priceShowByCent
    };
  }
};
exports.default = _default2;