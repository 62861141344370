"use strict";

var _interopRequireWildcard = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.anyToNumber = anyToNumber;
exports.centToYuan = centToYuan;
exports.centListToYuan = centListToYuan;
exports.centToGoldStarCoin = centToGoldStarCoin;
exports.priceShowByCent = priceShowByCent;
exports.priceCoupon = priceCoupon;
exports.priceShowByCentNotSys = priceShowByCentNotSys;
exports.priceShowPurchased = priceShowPurchased;
exports.priceShow = priceShow;

var _typeof2 = _interopRequireDefault(require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime-corejs2/helpers/typeof"));

var math = _interopRequireWildcard(require("mathjs"));

var _main = _interopRequireDefault(require("../organizationLibs/typeIs/main"));

/**
 * 判断 value 是否是一个有效的数字
 * 主要用于排除 infinity 和 nan
 *
 * @param {number} value
 * @returns {boolean}
 */
function isAvailableNumber(value) {
  try {
    return _main.default.isInt(value) || _main.default.isFloat(value);
  } catch (e) {
    return false;
  }
}
/**
 * 将任何类型的值转换为 number
 *
 * @param {*} value
 * @returns {number}
 */


function anyToNumber(value) {
  try {
    // 如果是 number 直接返回
    if (isAvailableNumber(value)) {
      return value;
    } // 将 string 转换为 number


    if (_main.default.isString(value)) {
      var parsedValue = parseInt(value, 10);

      if (isAvailableNumber(parsedValue)) {
        return parsedValue;
      }
    }
  } catch (e) {// autoLog(e && e.message);
  }

  return 0;
}
/**
 *
 * 尝试将数值转换为 number
 *
 * @param {*} value
 * @returns {*}
 */


function anyTryToNumber() {
  var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : undefined;

  try {
    switch ((0, _typeof2.default)(value)) {
      case 'number':
        if (value === Infinity) {
          return 0;
        }

        if (value !== value) {
          return 0;
        }

        return value;

      case 'function':
        return anyTryToNumber(value());

      default:
        return anyTryToNumber(parseFloat(value));
    }
  } catch (e) {
    return 0;
  }
}
/**
 * 将给定的参数转换为 Chain
 *
 * @param {*} cent
 */


function createChain(cent) {
  try {
    if (_main.default.isObject(cent) && cent instanceof math.Chain) {
      return cent;
    }

    return math.chain(anyToNumber(cent));
  } catch (e) {// autoLog(e && e.message);
  }

  return math.chain(0);
}
/**
 * 从分转换为金星币
 *
 * @param {*} cent 要转换的分
 * @returns {number} 转换后的金星币
 */


function centToGoldStarCoin(cent) {
  try {
    return createChain(cent).divide(10).ceil().done();
  } catch (e) {
    return 0;
  }
}
/**
 * 从分转换为元
 * 由于需要末尾补零，所以结果必须为 string
 *
 * @param {*} cent 要转换的分
 * @returns {string} 转换后的元
 */


function centToYuan(cent) {
  try {
    return createChain(cent).divide(100).format({
      notation: 'fixed',
      precision: 2
    }).done();
  } catch (e) {
    return '0.00';
  }
}
/**
 * 将给定的一系列数字，计算出和并且从分转换为元
 *
 * @param {*} cents 要转换的分
 * @returns {string} 转换后的元
 */


function centListToYuan() {
  try {
    // 创建 Chain
    var chain;

    for (var _len = arguments.length, cents = new Array(_len), _key = 0; _key < _len; _key++) {
      cents[_key] = arguments[_key];
    }

    var cent = cents[0]; // 取出第一个参数

    if (_main.default.isObject(cent) && cent instanceof math.Chain) {
      chain = cent; // 如果第一个参数是 Chain 直接使用
    } else if (_main.default.isArray(cent)) {
      chain = math.chain(cent.map(anyToNumber)); // 如果第一个参数是 array，格式化后创建 Chain
    } else {
      chain = math.chain(cents.map(anyToNumber)); // 其他情况将所有的参数作为一个数组创建 Chain
    } //


    return centToYuan(chain.sum());
  } catch (e) {// autoLog(e && e.message);
  }

  return '0.00';
}
/**
 *
 * 把分显示为价格，会自动根据系统做判断
 *
 * @param centOrigin 原价（分）
 * @param iosName ios 系统上的单位
 * @param androidName android 系统上的单位
 * @returns {string} 显示价格
 */


function priceShowByCent(centOrigin) {
  var iosName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '💎';
  var androidName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '¥';

  try {
    // 强制转换为 number
    var cent = anyToNumber(centOrigin); // 免费情况直接返回免费

    if (cent <= 0) {
      return '免费';
    }

    return "".concat(androidName, " ").concat(centToYuan(cent)); // android 价格
  } catch (e) {// autoLog(e && e.message);
  }
}

function priceShow(centOrigin) {
  try {
    // 强制转换为 number
    var cent = anyToNumber(centOrigin); // 免费情况直接返回免费

    if (cent <= 0) {
      return '';
    }

    return "\xA5".concat(cent); // android 价格
  } catch (e) {// autoLog(e && e.message);
  }
}
/**
 * 我的已购使用
 * 根据购买类型来展示 💎或者￥
 * @param {Object} centOrigin
 * @param {Object} type
 */


function priceShowPurchased(centOrigin, type) {
  try {
    var iosName = '💎';
    var androidName = '¥'; // 强制转换为 number

    var cent = anyToNumber(centOrigin); // 免费情况直接返回免费

    if (cent <= 0) {
      if (type == 7) {
        return "0 ".concat(iosName);
      }

      return "".concat(androidName, " 0.00");
    }

    if (type == 7) {
      return "".concat(anyTryToNumber(centToYuan(cent)), " ").concat(iosName); // iOS 价格
    }

    return "".concat(androidName, " ").concat(centToYuan(cent)); // android 价格
  } catch (e) {
    autoLog(e && e.message);
  }
}
/**
 *
 * 把分显示为价格，不区分系统
 *
 * @param centOrigin 原价（分）
 * @param androidName android 系统上的单位
 * @returns {string} 显示价格
 */


function priceShowByCentNotSys(centOrigin) {
  var androidName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '¥';

  try {
    // 强制转换为 number
    var cent = anyToNumber(centOrigin); // 免费情况直接返回免费

    if (cent <= 0) {
      return '免费';
    }

    return "".concat(androidName, " ").concat(centToYuan(cent)); // android 价格
  } catch (e) {
    console.log(e);
  }
}
/**
 *
 * 把分显示为价格，会自动根据系统做判断
 *
 * @param centOrigin 原价（分）
 * @param iosName ios 系统上的单位
 * @param androidName android 系统上的单位
 * @returns {string} 显示价格
 */


function priceCoupon(centOrigin) {
  try {
    // 强制转换为 number
    var cent = anyToNumber(centOrigin); // 免费情况直接返回免费

    if (cent <= 0) {
      return;
    }

    return "".concat(anyTryToNumber(centToYuan(cent))); // // #ifdef APP-PLUS
    // if (uni.getSystemInfoSync().platform === 'ios') {
    //   return `${anyTryToNumber(centToYuan(cent))}`; // iOS 价格
    // } else {
    //   return `${anyTryToNumber(centToYuan(cent))}`; // android 价格
    // }
    // // #endif
    // // #ifndef APP-PLUS
    // return `${anyTryToNumber(centToYuan(cent))}`; // 其他端价格
    // // #endif
  } catch (e) {
    autoLog(e && e.message);
  }
}