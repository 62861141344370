"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCode = getCode;
exports.verifyCode = verifyCode;
exports.getAllCommentList = getAllCommentList;
exports.getAllReplayListApi = getAllReplayListApi;
exports.getRoofPlacementApi = getRoofPlacementApi;
exports.setShowComment = setShowComment;
exports.setHideComment = setHideComment;
exports.setBatchShowComment = setBatchShowComment;
exports.setBatchHideComment = setBatchHideComment;
exports.cancelRoofPlacement = cancelRoofPlacement;
exports.sendMsgTemp = sendMsgTemp;
exports.addBanner = addBanner;
exports.getBanner = getBanner;
exports.deleteBanner = deleteBanner;
exports.deletedCommon = deletedCommon;
exports.restoreCommentCommon = restoreCommentCommon;
exports.getAppVersionList = getAppVersionList;
exports.addAppVersion = addAppVersion;
exports.deleteAppVersion = deleteAppVersion;
exports.updateAppVersion = updateAppVersion;
exports.getURLTemporaryAuthorization = getURLTemporaryAuthorization;
exports.getAnnouncementListById = getAnnouncementListById;
exports.getAnnouncementList = getAnnouncementList;
exports.addAnnounce = addAnnounce;
exports.updateAnnounce = updateAnnounce;
exports.getNavigationList = getNavigationList;
exports.getNavigation = getNavigation;
exports.addNavigation = addNavigation;
exports.delNavigation = delNavigation;
exports.updateNavigation = updateNavigation;
exports.getFamousTeacherList = getFamousTeacherList;
exports.delFamousTeacher = delFamousTeacher;
exports.updateFamousTeacher = updateFamousTeacher;
exports.addFamousTeacher = addFamousTeacher;
exports.getFamousTeacherInfo = getFamousTeacherInfo;
exports.getTeacherResourceList = getTeacherResourceList;
exports.delTeacherResource = delTeacherResource;
exports.updateTeacherResource = updateTeacherResource;
exports.addTeacherResource = addTeacherResource;
exports.replyComment = replyComment;
exports.getResourceBrowsingHistory = getResourceBrowsingHistory;
exports.getUserBrowsingHistory = getUserBrowsingHistory;
exports.addBlacklistApi = addBlacklistApi;
exports.setBatchDeleteComment = setBatchDeleteComment;
exports.removeBlacklistApi = removeBlacklistApi;
exports.getRegimentationTree = getRegimentationTree;
exports.batchRecoveryCommentApi = batchRecoveryCommentApi;
exports.getUserBlacklists = getUserBlacklists;
exports.batchRemove = batchRemove;
exports.getResourceViewCount = getResourceViewCount;
exports.getResourceViewUserList = getResourceViewUserList;
exports.getCooperationChannelList = getCooperationChannelList;
exports.getCooperationChannelChildren = getCooperationChannelChildren;
exports.delChannel = delChannel;
exports.addCooperationChannel = addCooperationChannel;
exports.updateCooperationChannel = updateCooperationChannel;
exports.getFavoriteList = getFavoriteList;
exports.groupGuideStrategy = groupGuideStrategy;
exports.addGroupGuideStrategy = addGroupGuideStrategy;
exports.updateGroupGuideStrategy = updateGroupGuideStrategy;
exports.getGroupGuideDetailByGroupGuideId = getGroupGuideDetailByGroupGuideId;
exports.updateStatusById = updateStatusById;
exports.checkGroupGuideStrategy = checkGroupGuideStrategy;
exports.batchUpdateGradeCodesApi = batchUpdateGradeCodesApi;
exports.getTeacherByOpenIds = getTeacherByOpenIds;
exports.getWeChatThirdPartyAuthList = getWeChatThirdPartyAuthList;
exports.getWxACode = getWxACode;
Object.defineProperty(exports, "getVideoAddress", {
  enumerable: true,
  get: function get() {
    return _aliVod.getVideoAddress;
  }
});
Object.defineProperty(exports, "doGetUploadVideo", {
  enumerable: true,
  get: function get() {
    return _aliVod.doGetUploadVideo;
  }
});
Object.defineProperty(exports, "doGetRefreshVideo", {
  enumerable: true,
  get: function get() {
    return _aliVod.doGetRefreshVideo;
  }
});
Object.defineProperty(exports, "deleteVideo", {
  enumerable: true,
  get: function get() {
    return _aliVod.deleteVideo;
  }
});
Object.defineProperty(exports, "getVideoPlayAuth", {
  enumerable: true,
  get: function get() {
    return _aliVod.getVideoPlayAuth;
  }
});
Object.defineProperty(exports, "getPlayInfo", {
  enumerable: true,
  get: function get() {
    return _aliVod.getPlayInfo;
  }
});
Object.defineProperty(exports, "getSourceFile", {
  enumerable: true,
  get: function get() {
    return _aliVod.getSourceFile;
  }
});
Object.defineProperty(exports, "getDictionary", {
  enumerable: true,
  get: function get() {
    return _dictionary.getDictionary;
  }
});
Object.defineProperty(exports, "addItem", {
  enumerable: true,
  get: function get() {
    return _dictionary.addItem;
  }
});
Object.defineProperty(exports, "updateItem", {
  enumerable: true,
  get: function get() {
    return _dictionary.updateItem;
  }
});
Object.defineProperty(exports, "restoreItem", {
  enumerable: true,
  get: function get() {
    return _dictionary.restoreItem;
  }
});
Object.defineProperty(exports, "deleteItem", {
  enumerable: true,
  get: function get() {
    return _dictionary.deleteItem;
  }
});
Object.defineProperty(exports, "getResourceType", {
  enumerable: true,
  get: function get() {
    return _dictionary.getResourceType;
  }
});
Object.defineProperty(exports, "getResourceTypeAll", {
  enumerable: true,
  get: function get() {
    return _dictionary.getResourceTypeAll;
  }
});
Object.defineProperty(exports, "addResourceType", {
  enumerable: true,
  get: function get() {
    return _dictionary.addResourceType;
  }
});
Object.defineProperty(exports, "updateResourceType", {
  enumerable: true,
  get: function get() {
    return _dictionary.updateResourceType;
  }
});
Object.defineProperty(exports, "deleteResourceType", {
  enumerable: true,
  get: function get() {
    return _dictionary.deleteResourceType;
  }
});
Object.defineProperty(exports, "restoreResourceType", {
  enumerable: true,
  get: function get() {
    return _dictionary.restoreResourceType;
  }
});
Object.defineProperty(exports, "getApplicationAll", {
  enumerable: true,
  get: function get() {
    return _dictionary.getApplicationAll;
  }
});
Object.defineProperty(exports, "addApplication", {
  enumerable: true,
  get: function get() {
    return _dictionary.addApplication;
  }
});
Object.defineProperty(exports, "updateApplication", {
  enumerable: true,
  get: function get() {
    return _dictionary.updateApplication;
  }
});
Object.defineProperty(exports, "deleteApplication", {
  enumerable: true,
  get: function get() {
    return _dictionary.deleteApplication;
  }
});
Object.defineProperty(exports, "restoreApplication", {
  enumerable: true,
  get: function get() {
    return _dictionary.restoreApplication;
  }
});
Object.defineProperty(exports, "getApplicationList", {
  enumerable: true,
  get: function get() {
    return _dictionary.getApplicationList;
  }
});
Object.defineProperty(exports, "addSocialApplication", {
  enumerable: true,
  get: function get() {
    return _dictionary.addSocialApplication;
  }
});
Object.defineProperty(exports, "updateSocialApplication", {
  enumerable: true,
  get: function get() {
    return _dictionary.updateSocialApplication;
  }
});
Object.defineProperty(exports, "deleteSocialApplication", {
  enumerable: true,
  get: function get() {
    return _dictionary.deleteSocialApplication;
  }
});
Object.defineProperty(exports, "restoreSocialApplication", {
  enumerable: true,
  get: function get() {
    return _dictionary.restoreSocialApplication;
  }
});
Object.defineProperty(exports, "getSyncSubject", {
  enumerable: true,
  get: function get() {
    return _dictionary.getSyncSubject;
  }
});
Object.defineProperty(exports, "getSyncSection", {
  enumerable: true,
  get: function get() {
    return _dictionary.getSyncSection;
  }
});
Object.defineProperty(exports, "getSyncEdition", {
  enumerable: true,
  get: function get() {
    return _dictionary.getSyncEdition;
  }
});
Object.defineProperty(exports, "getSyncGrade", {
  enumerable: true,
  get: function get() {
    return _dictionary.getSyncGrade;
  }
});
Object.defineProperty(exports, "getSyncVolume", {
  enumerable: true,
  get: function get() {
    return _dictionary.getSyncVolume;
  }
});
Object.defineProperty(exports, "getSyncPeriod", {
  enumerable: true,
  get: function get() {
    return _dictionary.getSyncPeriod;
  }
});
Object.defineProperty(exports, "getCategoryView", {
  enumerable: true,
  get: function get() {
    return _dictionary.getCategoryView;
  }
});
Object.defineProperty(exports, "getResourceTypeList", {
  enumerable: true,
  get: function get() {
    return _dictionary.getResourceTypeList;
  }
});
Object.defineProperty(exports, "getDictionaryList", {
  enumerable: true,
  get: function get() {
    return _dictionary.getDictionaryList;
  }
});
Object.defineProperty(exports, "getApplicationPageList", {
  enumerable: true,
  get: function get() {
    return _dictionary.getApplicationPageList;
  }
});
Object.defineProperty(exports, "getAllPostCompanyList", {
  enumerable: true,
  get: function get() {
    return _dictionary.getAllPostCompanyList;
  }
});
Object.defineProperty(exports, "getAllSyncVolumeList", {
  enumerable: true,
  get: function get() {
    return _dictionary.getAllSyncVolumeList;
  }
});
Object.defineProperty(exports, "getAllSyncSubjectList", {
  enumerable: true,
  get: function get() {
    return _dictionary.getAllSyncSubjectList;
  }
});
Object.defineProperty(exports, "getAllSyncPeriodList", {
  enumerable: true,
  get: function get() {
    return _dictionary.getAllSyncPeriodList;
  }
});
Object.defineProperty(exports, "getAllSyncSectionList", {
  enumerable: true,
  get: function get() {
    return _dictionary.getAllSyncSectionList;
  }
});
Object.defineProperty(exports, "getAllSyncEditionList", {
  enumerable: true,
  get: function get() {
    return _dictionary.getAllSyncEditionList;
  }
});
Object.defineProperty(exports, "getAllSyncGradeList", {
  enumerable: true,
  get: function get() {
    return _dictionary.getAllSyncGradeList;
  }
});
Object.defineProperty(exports, "getDictionaryPageList", {
  enumerable: true,
  get: function get() {
    return _dictionary.getDictionaryPageList;
  }
});

var _main = _interopRequireDefault(require("../../utils/request/main"));

var _aliVod = require("./aliVod");

var _dictionary = require("./dictionary");

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/common/admin/"));
var commonRequestBase = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/common/"));
var third = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/third-sdk/"));
var thirdSdkRequest = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/third-sdk/common/"));
var thirdSdkClientRequest = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/third-sdk/client/"));
/**
 * 获取getFavoriteList
 * @returns {Promise<*>}
 */

function getFavoriteList(params) {
  return request.get('/favorite/getFavoriteList', {
    params: params
  });
}
/**
 * 获取合作渠道树状结构
 * @returns {Promise<*>}
 */


function getRegimentationTree() {
  return request.get('/cooperationChannel/getRegimentationTree');
}
/**
 * 分页获取资源浏览记录JSON数据接口
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getResourceBrowsingHistory(params) {
  return request.get('/records/getResourceBrowsingHistory', {
    params: params
  });
}
/**
 * 分页获取yonghu浏览记录JSON数据接口
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getUserBrowsingHistory(params) {
  return request.get('/records/getUserBrowsingHistory', {
    params: params
  });
}
/**
 * 获取 url下载临时凭证
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getURLTemporaryAuthorization(params) {
  return thirdSdkClientRequest.get('/oss/getURLTemporaryAuthorization', {
    params: params
  });
}
/**
 * 回复评论接口
 * @param {Object} params
 * @returns {Promise<*>}
 */


function replyComment(params) {
  return request.post('/comment/replyCommentNew', undefined, {
    params: params
  });
}
/**
 * 加入黑名单api
 * @param {Object} params
 * @returns {Promise<*>}
 */


function addBlacklistApi(data) {
  return request.post('/userBlacklist/add', data);
}
/**
 * 移除黑名单
 * @param {Object} params
 * @returns {Promise<*>}
 */


function removeBlacklistApi(userOpenId) {
  return request.delete("/userBlacklist/".concat(userOpenId));
}
/**
 * 查詢评论列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAllCommentList(params) {
  return request.get('/comment/getCommentListsNew', {
    params: params
  });
}
/**
 * 查詢黑名单列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getUserBlacklists(params) {
  return request.get('/userBlacklist/getUserBlacklists', {
    params: params
  });
}
/**
 * 根据评论id, 获取所有的恢复信息
 * @param {String} targetCommentId
 * @returns {Promise<*>}
 */


function getAllReplayListApi(targetCommentId) {
  return request.get('/comment/getCommentByTargetCommentIdNew', {
    params: {
      targetCommentId: targetCommentId
    }
  });
}
/**
 * 置顶评论
 * @param {String} id
 * @returns {Promise<*>}
 */


function getRoofPlacementApi(id) {
  return request.patch("/comment/topComment/".concat(id));
}
/**
 * 显示评论
 * @param {String} id
 * @returns {Promise<*>}
 */


function setShowComment(id) {
  return request.patch("/comment/showComment/".concat(id));
}
/**
 * 隐藏评论
 * @param {String} id
 * @returns {Promise<*>}
 */


function setHideComment(id) {
  return request.patch("/comment/hideComment/".concat(id));
}
/**
 * 批量显示评论
 * @param {String} ids
 * @returns {Promise<*>}
 */


function setBatchShowComment(ids) {
  return request.post('/comment/batchShowComment', undefined, {
    params: {
      ids: ids
    }
  });
}
/**
 * 批量隐藏评论
 * @param {String} ids
 * @returns {Promise<*>}
 */


function setBatchHideComment(ids) {
  return request.post('/comment/batchHideComment', undefined, {
    params: {
      ids: ids
    }
  });
}
/**
 * 批量删除评论
 * @param {String} ids
 * @returns {Promise<*>}
 */


function setBatchDeleteComment(ids) {
  return request.patch('/comment/batchRemoveComment', undefined, {
    params: {
      ids: ids
    }
  });
}
/**
 * 批量恢复评论
 * @param {String} ids
 * @returns {Promise<*>}
 */


function batchRecoveryCommentApi(ids) {
  return request.post('/comment/batchRecoverComment', undefined, {
    params: {
      ids: ids
    }
  });
}
/**
 * 批量恢复黑名单
 * @param {String} ids
 * @returns {Promise<*>}
 */


function batchRemove(userOpenIds) {
  return request.patch('/userBlacklist/batchRemove', undefined, {
    params: {
      userOpenIds: userOpenIds
    }
  });
}
/**
 * 取消置顶
 * @param {String} id
 * @returns {Promise<*>}
 */


function cancelRoofPlacement(id) {
  return request.patch("/comment/deleteTopComment/".concat(id));
}
/**
 * 删除评论
 * @param {String} id
 * @returns {Promise<*>}
 */


function deletedCommon(id) {
  return request.patch("/comment/removeComment/".concat(id));
}
/**
 * 恢复评论
 * @param {String} id
 * @returns {Promise<*>}
 */


function restoreCommentCommon(id) {
  return request.patch("/comment/restoreComment/".concat(id));
}
/**
 * 获取手机验证码
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getCode(params) {
  return thirdSdkRequest.get('/verificationCode/sendPhoneCodeMsg', {
    params: params
  });
}
/**
 * 验证手机验证码
 * @param {Object} params
 * @returns {Promise<*>}
 */


function verifyCode(params) {
  return thirdSdkRequest.get('/verificationCode/checkPhoneCodeMsg', {
    params: params
  });
}
/**
 * 发送短信通知
 * @param {Object} data
 * @returns {Promise<*>}
 */


function sendMsgTemp(data) {
  return thirdSdkRequest.post('/verificationCode/sendMsg', data);
}
/**
 * 添加轮播图
 * @param {Object} body
 * @returns {Promise<*>}
 */


function addBanner(body) {
  return request.post('/carousel/add', body);
}
/**
 * 分页获取轮播图信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getBanner(params) {
  return request.get('/carousel/getCarouselList', {
    params: params
  });
}
/**
 * 删除轮播图
 * @param {Number} id
 * @returns {Promise<*>}
 */


function deleteBanner(id) {
  return request.delete('/carousel/removeById', {
    params: {
      id: id
    }
  });
}
/**
 * 分页获取版本控制表信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAppVersionList(params) {
  return request.get('/appVersion', {
    params: params
  });
}
/**
 * 添版本控制信息
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addAppVersion(data) {
  return request.post('/appVersion/add', data);
}
/**
 * 删除版本(假删除)
 * @param id
 * @returns {AxiosPromise}
 */


function deleteAppVersion(id) {
  return request.delete("/appVersion/removeById/".concat(id));
}
/**
 * 修改版本控制信息
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateAppVersion(id, data) {
  return request.patch("/appVersion/update/".concat(id), data);
}
/**
 * 根据公告id, 获取公告详情
 * @param {Number} id
 * @returns {Promise<*>}
 */


function getAnnouncementListById(id) {
  return request.get('/announcement/getAnnouncementById', {
    params: {
      id: id
    }
  });
}
/**
 * 添加公告
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addAnnounce(data) {
  return request.post('/announcement/add', data);
}
/**
 * 分页获取公告详情
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAnnouncementList(params) {
  return request.get('/announcement/getAnnouncementList', {
    params: params
  });
}
/**
 * 修改公告
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateAnnounce(id, data) {
  return request.post("/announcement/update/".concat(id), data);
}
/**
 * 分页获取获取导航信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getNavigationList(params) {
  return request.get('/navigation/getNavigationList', {
    params: params
  });
}
/**
 * 获取导航详情
 * @param {Number} id
 * @returns {Promise<*>}
 */


function getNavigation(id) {
  return request.get('/navigation/getNavigationById', {
    params: {
      id: id
    }
  });
}
/**
 * 添加导航
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addNavigation(data) {
  return request.post('/navigation/save', data);
}
/**
 * 删除导航
 * @param id
 * @returns {Promise<*>}
 */


function delNavigation(id) {
  return request.delete('/navigation/removeById', {
    params: {
      id: id
    }
  });
}
/**
 * 修改导航
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateNavigation(id, data) {
  return request.patch("/navigation/update/".concat(id), data);
}
/**
 * 查询名师列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getFamousTeacherList(params) {
  return request.get('/famousTeacher', {
    params: params
  });
}
/**
 * 根据openIds查询名师列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getTeacherByOpenIds(params) {
  return request.get('/famousTeacher/listByOpenIds', {
    params: params
  });
}
/**
 * 查询名师列表
 * @param {String} id
 * @returns {Promise<*>}
 */


function getFamousTeacherInfo(id) {
  return request.get("/famousTeacher/".concat(id));
}
/**
 * 删除名师
 * @param id
 * @returns {Promise<*>}
 */


function delFamousTeacher(famousTeacherOpenId) {
  return request.delete("/famousTeacher/".concat(famousTeacherOpenId));
}
/**
 * 修改名师
 * @param id
 * @returns {Promise<*>}
 */


function updateFamousTeacher(id, data) {
  return request.put("/famousTeacher/".concat(id), data);
}
/**
 * 新增名师
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addFamousTeacher(data) {
  return request.post('/famousTeacher', data);
}
/**
 * 查询名师资源
 * @param {String} params
 * @returns {Promise<*>}
 */


function getTeacherResourceList(params) {
  return request.get('/famousTeacherResource', {
    params: params
  });
}
/**
 * 删除名师资源
 * @param String famousTeacherOpenId
 * @param String resourceOpenId
 * @returns {Promise<*>}
 */


function delTeacherResource(famousTeacherOpenId, resourceOpenId) {
  return request.delete("/famousTeacherResource/".concat(famousTeacherOpenId, "/").concat(resourceOpenId));
}
/**
 * 修改名师
 * @param String famousTeacherOpenId
 * @param String resourceOpenId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateTeacherResource(famousTeacherOpenId, resourceOpenId, data) {
  return request.put("/famousTeacherResource/".concat(famousTeacherOpenId, "/").concat(resourceOpenId), data);
}
/**
 * 新增名师
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addTeacherResource(famousTeacherOpenId, data) {
  return request.post("/famousTeacherResource/".concat(famousTeacherOpenId), data);
}
/**
 * 查询资源浏览量
 * @param {String} params
 * @returns {Promise<*>}
 */


function getResourceViewCount(params) {
  return commonRequestBase.get('/openFeign/records/resource/views', {
    params: params
  });
}
/**
 * 查询资源用户浏览列表
 * @param {String} params
 * @returns {Promise<*>}
 */


function getResourceViewUserList(params) {
  return request.get('/records/resource/viewList', {
    params: params
  });
}
/**
 * 分页查询合作渠道
 * @param {String} params
 * @returns {Promise<*>}
 */


function getCooperationChannelList(params) {
  return request.get('/cooperationChannel/getCooperationChannelList', {
    params: params
  });
}
/**
 * 根据父级openId 获取子级数据
 * @param {String} params
 * @returns {Promise<*>}
 */


function getCooperationChannelChildren(params) {
  return request.get('/cooperationChannel/getCooperationChannelByParentOpenId', {
    params: params
  });
}
/**
 * 移除黑名单
 * @param String id
 * @returns {Promise<*>}
 */


function delChannel(id) {
  return request.delete('/cooperationChannel/removeById', {
    params: {
      id: id
    }
  });
}
/**
 * 更新合作渠道
 * @param String id
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateCooperationChannel(id, data) {
  return request.post("/cooperationChannel/update/".concat(id), data);
}
/**
 * 新增合作渠道
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addCooperationChannel(data) {
  return request.post('/cooperationChannel/add', data);
}
/**
 * 分页获取引导加群
 * @param {Object} params
 * @returns {Promise<*>}
 */


function groupGuideStrategy(params) {
  return request.get('/groupGuideStrategy/list', {
    params: params
  });
}
/**
 * 获取引导加群详情
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getGroupGuideDetailByGroupGuideId(params) {
  return request.get('/groupGuideStrategy/getGroupGuideDetailByGroupGuideId', {
    params: params
  });
}
/**
 * 新增引导加群
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addGroupGuideStrategy(data) {
  return request.post('/groupGuideStrategy/addGroupGuideStrategy', data);
}
/**
 * 编辑引导加群
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateGroupGuideStrategy(data) {
  return request.post('/groupGuideStrategy/updateGroupGuideStrategy', data);
}
/**
 * 根据idxiu修改启用状态
 * @param {Object} data
 * @returns {Promise<*>}
 */


function updateStatusById(data) {
  return request.post('/groupGuideStrategy/updateStatusById', data);
}
/**
 * 校验引导加群规则
 * @param {Object} data
 * @returns {Promise<*>}
 */


function checkGroupGuideStrategy(data) {
  return request.post('/groupGuideStrategy/checkGroupGuideStrategy', data);
}
/**
 * 批量修改引导加群
 * @param {Object} data
 * @returns {Promise<*>}
 */


function batchUpdateGradeCodesApi(data) {
  return request.post('/groupGuideStrategy/batchUpdateGradeCodes', data);
}
/**
 * 公众号管理：获取微信第三方平台绑定信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getWeChatThirdPartyAuthList(params) {
  return third.get('/weChat/mp/bind/getWeChatThirdPartyAuthListByNickName', {
    params: params
  });
}
/**
 * 公众号管理：获取微信第三方平台绑定信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getWxACode(params) {
  return third.get('/mp/applet/getWxACode', {
    params: params
  });
}