"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.wordCheckData = wordCheckData;
exports.getRich = getRich;
exports.filterCheckList = filterCheckList;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.regexp.constructor");

require("core-js/modules/es6.array.find");

function termsCheckData(item) {
  var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var value = arguments.length > 2 ? arguments[2] : undefined;

  try {
    switch (item.label) {
      case 'synonymList':
        item.content = value.synonymList;
        item.name = '同义词';
        break;

      case 'nearList':
        item.content = value.nearList;
        item.name = '近义词';
        break;

      case 'antonymList':
        item.content = value.antonymList;
        item.name = '反义词';
        break;

      case 'interpretation':
        item.content = data.interpretation;
        item.name = '释义';
        break;

      case 'exampleSentence':
        item.content = data.exampleSentence;
        item.name = '例句';
        break;

      case 'pictures':
        item.content = value.pictures;
        item.name = '配图';
        break;

      case 'anecdote':
        item.content = value.anecdote;
        item.name = '典故';
        break;

      default:
    }
  } catch (e) {
    console.log(e);
  }
}
/**
 *
 * @param item checkList item
 * @param value 汉字详情
 * @param type
 */


function wordCheckData(item, value, type) {
  var data;

  if (value.pinyin && value.pinyinList.length > 0) {
    data = value.pinyinList.find(function (n) {
      return n.pinyin === value.pinyin;
    });
  } else {
    data = value.pinyinList.length > 0 ? value.pinyinList[0] : {};
  }

  if (type === 'micro_terms') {
    termsCheckData(item, data, value);
    return;
  }

  switch (item.label) {
    case 'wordMean':
      item.content = data.wordMean;
      item.name = '字义';
      break;

    case 'groupWords':
      item.content = data.groupWords;
      item.name = '组词';
      break;

    case 'sentenceMake':
      item.content = data.sentenceMake;
      item.name = '造句';
      break;

    case 'writeNotes':
      item.content = value.writeNotes;
      item.name = '书写';
      break;

    case 'etymology':
      item.content = value.etymology;
      item.name = '字源';
      break;

    case 'ingeniousNotes':
      item.content = value.ingeniousNotes;
      item.name = '巧记';
      break;

    case 'chineseCharBishun':
      item.content = value.chineseCharBishun;
      item.name = '笔顺';
      break;

    case 'charRiddle':
      item.content = value.charRiddle;
      item.name = '字谜';
      break;

    case 'charSong':
      item.content = value.charSong;
      item.name = '字歌';
      break;

    case 'errorPronePrompt':
      item.content = value.errorPronePrompt;
      item.name = '易错提示';
      break;

    case 'nearCharList':
      item.content = value.nearCharList;
      item.name = '形近字';
      break;

    case 'charFamily':
      item.content = value.charFamily;
      item.name = '字族';
      break;

    default:
  }
}

function getRich(richtext) {
  if (richtext != null) {
    var regex = new RegExp('<figure', 'gi');
    var regexStart = new RegExp('<section', 'gi');
    var regexEnd = new RegExp('</section', 'gi');
    var regexWraper = new RegExp('data-role="outer"', 'gi');
    richtext = richtext.replace(regex, '<div');
    richtext = richtext.replace(regexStart, '<div');
    richtext = richtext.replace(regexEnd, '</div');
    richtext = richtext.replace(regexWraper, 'style="box-sizing: border-box; padding: 15px 20px 0;"');
    richtext = richtext.replace(/\<img/gi, '<img style="max-width:100%;height:auto;"');
  }

  return richtext;
}
/**
 * 筛选选中属性
 * 没有内容的也显示  显示 “未添加内容”
 * @param value
 * @returns {*[]}
 */


function filterCheckList(value) {
  var list = JSON.parse(JSON.stringify(value.checkList));
  list = list.filter(function (item) {
    return item.check;
  });
  return list;
}