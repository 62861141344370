var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.type === "smallPicture"
      ? _c(
          "div",
          {
            style: { height: _vm.card ? "84px" : "93px", position: "relative" }
          },
          [
            _vm.resource.resourceType === 3
              ? _c("el-image", {
                  class: [_vm.card ? "card-album" : "album-cover"],
                  attrs: {
                    fit: "cover",
                    src: _vm.getImgUrl(_vm.resource.cover)
                  }
                })
              : _vm.isAudio
              ? _c("el-image", {
                  class: [_vm.card ? "card-album" : "album-cover"],
                  attrs: {
                    fit: "cover",
                    src: _vm.getImgUrl(_vm.resource.cover)
                  }
                })
              : _c("el-image", {
                  class: [_vm.card ? "card-album" : "album-cover"],
                  attrs: {
                    fit: "cover",
                    src: _vm.getImgUrl(_vm.resource.cover)
                  }
                }),
            _vm._v(" "),
            _vm.isRankList
              ? _c("div", {
                  class: ["rankingTag", "rankingTag" + _vm.curIconIndex]
                })
              : _vm._e(),
            _vm._v(" "),
            _c("audio-book-vip-icon", { attrs: { value: _vm.resource } })
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.type === "line"
      ? _c(
          "div",
          { staticClass: "line-picture" },
          [
            _vm.resource.resourceType === 3
              ? _c(
                  "div",
                  { staticClass: "line-book-cover" },
                  [
                    _c("el-image", {
                      staticClass: "line-book-cover",
                      attrs: {
                        fit: "cover",
                        src: _vm.getImgUrl(_vm.resource.cover)
                      }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "book-mask" })
                  ],
                  1
                )
              : _vm.isAudio
              ? _c("el-image", {
                  staticClass: "line-audio-cover",
                  attrs: {
                    fit: "cover",
                    src: _vm.getImgUrl(_vm.resource.cover)
                  }
                })
              : _c("el-image", {
                  staticClass: "line-album-cover",
                  attrs: {
                    fit: "cover",
                    src: _vm.getImgUrl(_vm.resource.cover)
                  }
                }),
            _vm._v(" "),
            _vm.isRankList
              ? _c("div", {
                  class: ["rankingTag", "rankingTag" + _vm.curIconIndex],
                  staticStyle: { bottom: "2px" }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("audio-book-vip-icon", { attrs: { value: _vm.resource } })
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.type === "roll"
      ? _c(
          "div",
          { staticClass: "roll-picture" },
          [
            _vm.resource.resourceType === 3
              ? _c("el-image", {
                  staticClass: "roll-album-cover",
                  attrs: {
                    fit: "cover",
                    src: _vm.getImgUrl(_vm.resource.cover)
                  }
                })
              : _vm.isAudio
              ? _c("el-image", {
                  staticClass: "roll-album-cover",
                  attrs: {
                    fit: "cover",
                    src: _vm.getImgUrl(_vm.resource.cover)
                  }
                })
              : _c("el-image", {
                  staticClass: "roll-album-cover",
                  attrs: {
                    fit: "cover",
                    src: _vm.getImgUrl(_vm.resource.cover)
                  }
                }),
            _vm._v(" "),
            _vm.isRankList
              ? _c("div", {
                  class: ["rankingTag", "rankingTag" + _vm.curIconIndex]
                })
              : _vm._e(),
            _vm._v(" "),
            _c("audio-book-vip-icon", { attrs: { value: _vm.resource } })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }