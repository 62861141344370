var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "object-info-bg" }, [
      _c(
        "div",
        {
          class: [
            "resource-img-box",
            _vm.resourceImgName(_vm.resourceObj.resourceType)
          ]
        },
        [
          _c("img", {
            staticClass: "object-cover-img",
            attrs: { src: _vm.getResourceCover(_vm.resourceObj), alt: "" }
          }),
          _vm._v(" "),
          _vm.resourceObj.resourceType === _vm.LIVE_ID
            ? _c(
                "span",
                {
                  class: [
                    "live-state-tag",
                    _vm.getLiveObj(_vm.resourceObj).className
                  ]
                },
                [_vm._v(_vm._s(_vm.getLiveObj(_vm.resourceObj).label))]
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "objInfo" }, [
        _c("div", { staticClass: "objInfo-txt-bg" }, [
          _c(
            "h3",
            {
              class: [
                "objName",
                _vm.resourceObj.resourceType === _vm.AUDIO_ID &&
                !_vm.resourceObj.vid
                  ? "audio-title"
                  : "objName",
                _vm.isEdit ? "editTitle" : ""
              ],
              attrs: { title: _vm.resourceObj.title },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.showPage(_vm.resourceObj.resourceOpenId)
                }
              }
            },
            [
              _vm.resourceObj.resourceType === _vm.AUDIO_ID &&
              !_vm.resourceObj.vid
                ? _c("span", [_vm._v("无音频：")])
                : _vm._e(),
              _vm._v(
                "\n          " +
                  _vm._s(_vm.displayTitle(_vm.resourceObj)) +
                  "\n          "
              ),
              _vm.resourceObj.resourceType === _vm.FILE_ID
                ? _c("span", { staticClass: "file-count-bg" }, [
                    _vm._v(
                      "[共" +
                        _vm._s(
                          _vm.resourceObj.fileContentNums
                            ? _vm.resourceObj.fileContentNums
                            : 0
                        ) +
                        "个]"
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.resourceObj.resourceType === _vm.ALBUM_ID
                ? _c("span", { staticClass: "file-count-bg" }, [
                    _vm._v(
                      "[共" +
                        _vm._s(
                          _vm.resourceObj.albumContentNums
                            ? _vm.resourceObj.albumContentNums
                            : 0
                        ) +
                        "讲]"
                    )
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "object-resource",
              attrs: { title: _vm.getResourceInfo(_vm.resourceObj) }
            },
            [_vm._v(_vm._s(_vm.getResourceInfo(_vm.resourceObj)))]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "object-tag-bg" },
          [
            _vm.resourceObj.vid
              ? _c("span", { staticClass: "txt-vid" }, [
                  _vm._v("VID: " + _vm._s(_vm.resourceObj.vid))
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.resourceObj.tagList, function(tagObj, index) {
              return _c(
                "el-tag",
                {
                  key: index,
                  staticClass: "tag-item-bg",
                  attrs: { type: "info", size: "mini" }
                },
                [_vm._v(_vm._s(tagObj))]
              )
            })
          ],
          2
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }