"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _imageViewer = _interopRequireDefault(require("element-ui/packages/image/src/image-viewer"));

var _clipboard = _interopRequireDefault(require("../../../utils/clipboard"));

var _common = require("../../../utils/common");

var _utils = require("../../../utils");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'ImageMaterialItem',
  components: {
    ElImageViewer: _imageViewer.default
  },
  filters: {
    parseTime: _utils.parseTime
  },
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    isShowCheck: {
      type: Boolean,
      default: false
    },
    isChecked: {
      type: Boolean,
      default: false
    },
    nameLoading: {
      type: Boolean,
      default: false
    },
    nameEdit: {
      type: Boolean,
      default: false
    },
    deleteLoading: {
      type: Boolean,
      default: false
    },
    deleteVisible: {
      type: Boolean,
      default: false
    },
    isSystem: {
      type: Boolean,
      default: false
    },
    //  弹窗时 是否系统素材
    closeDialog: {
      type: Boolean,
      default: false
    } // 关闭弹窗 把操作关闭

  },
  data: function data() {
    return {
      showOperation: false,
      visible: false,
      showPreview: false,
      popoverCopy: false,
      popoverEdit: false,
      showInfoIcon: false
    };
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(val) {
        this.$emit('input', val);
      }
    },
    imagePath: function imagePath() {
      return (0, _common.imageUrlFun)(this.value.path, 'style/width-limit-1000');
    }
  },
  watch: {
    deleteVisible: {
      deep: true,
      immediate: true,
      handler: function handler(val) {
        if (val) {
          this.visible = false;
        }
      }
    },
    nameEdit: {
      deep: true,
      immediate: true,
      handler: function handler(val) {
        if (val) {
          this.popoverEdit = false;
        }
      }
    },
    closeDialog: {
      deep: true,
      immediate: true,
      handler: function handler(val) {
        if (!val) {
          this.visible = false;
          this.popoverCopy = false;
          this.popoverEdit = false;
        }
      }
    }
  },
  methods: {
    handelPopoverCopy: function handelPopoverCopy() {
      this.popoverCopy = !this.popoverCopy;

      if (this.popoverCopy) {
        this.popoverEdit = false;
        this.visible = false;
      }
    },
    handelPopoverEdit: function handelPopoverEdit() {
      this.popoverEdit = !this.popoverEdit;

      if (this.popoverEdit) {
        this.popoverCopy = false;
        this.visible = false;
      }
    },
    handelPopoverDelete: function handelPopoverDelete() {
      this.visible = !this.visible;

      if (this.visible) {
        this.popoverCopy = false;
        this.popoverEdit = false;
      }
    },
    // 预览图片
    onPreview: function onPreview() {
      this.showPreview = true;
      this.$emit('clickImgResult', this.value);
    },
    // 关闭预览图
    closeViewer: function closeViewer() {
      this.showPreview = false;
      this.$emit('clickImgResult', this.value);
    },
    // 重命名提交
    submitChangeName: function submitChangeName() {
      this.$emit('submitChangeName', {
        picName: this.value.picName,
        materialOpenId: this.value.materialOpenId
      });
    },
    // 删除
    handleDelete: function handleDelete() {
      this.$emit('handelDelete', this.value.materialOpenId);
    },
    copy: function copy(url) {
      (0, _clipboard.default)(url, event);
      this.popoverCopy = false;
    },
    // 处理图片
    getImgUrl: function getImgUrl(cover) {
      var imgAllUrl = (0, _common.imageUrlFun)(cover, 'style/width-limit-80');
      return imgAllUrl;
    }
  }
};
exports.default = _default2;