var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "micro-terms" },
    [
      _c("div", { staticClass: "terms-name-flex" }, [
        _c(
          "div",
          {
            staticClass: "terms-nav-flex",
            style: {
              "flex-direction": _vm.valueName.length > 1 ? "column" : "row"
            }
          },
          _vm._l(_vm.valueName, function(item, index) {
            return _c(
              "div",
              { key: index, staticClass: "terms-nav" },
              _vm._l(item, function(n, nIndex) {
                return _c(
                  "div",
                  {
                    key: nIndex,
                    class: [
                      _vm.value.name.length > 5
                        ? "terms-name-bg"
                        : "big-terms-name"
                    ]
                  },
                  [
                    _c(
                      "span",
                      {
                        class: [
                          "name",
                          "common-flex-center",
                          nIndex === 0 && _vm.value.name.length < 7
                            ? "name-padding-left"
                            : "",
                          nIndex === _vm.value.name.length - 1 &&
                          _vm.value.name.length < 7
                            ? "name-padding-right"
                            : ""
                        ],
                        style: _vm.nameStyle
                      },
                      [_vm._v(_vm._s(n))]
                    )
                  ]
                )
              }),
              0
            )
          }),
          0
        )
      ]),
      _vm._v(" "),
      _vm._l(_vm.checkList, function(item, index) {
        return _c("div", { key: index, staticClass: "check-item" }, [
          _vm.isHidden(index)
            ? _c(
                "div",
                [
                  item.label !== "pictures"
                    ? _c("div", { staticClass: "check-name" }, [
                        _vm._v(_vm._s(item.name) + ":")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  (item.label === "synonymList" ||
                    item.label === "nearList" ||
                    item.label === "antonymList") &&
                  item.content
                    ? _c(
                        "div",
                        { staticClass: "near-list check-content" },
                        [
                          _vm._l(item.content, function(n, nIndex) {
                            return _c(
                              "div",
                              {
                                key: nIndex,
                                staticClass: "near-item checkcontent"
                              },
                              [_vm._v(_vm._s(n.name))]
                            )
                          }),
                          _vm._v(" "),
                          item.content.length <= 0
                            ? _c("div", [_vm._v("未添加内容")])
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  (item.label === "synonymList" ||
                    item.label === "nearList" ||
                    item.label === "antonymList") &&
                  !item.content
                    ? _c("div", { staticClass: "check-content" }, [
                        _vm._v("未添加内容")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showPictureBorder(index, item, "top")
                    ? _c("div", { staticClass: "pictures-border" })
                    : _vm._e(),
                  _vm._v(" "),
                  item.label === "pictures" && _vm.picturesList.length > 0
                    ? _c(
                        "div",
                        { staticClass: "flex-row pictures-top" },
                        _vm._l(_vm.picturesList, function(n, nIndex) {
                          return _c(
                            "div",
                            {
                              key: nIndex,
                              class: [
                                "pictures",
                                _vm.options.appearance !== "card"
                                  ? ""
                                  : "card-pictures-item"
                              ]
                            },
                            [
                              nIndex <= 2
                                ? _c("el-image", {
                                    staticClass: "pictures-cover",
                                    attrs: {
                                      fit: "cover",
                                      src: _vm.getImgUrl(n.picture)
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              nIndex === 2 && _vm.picturesList.length > 2
                                ? _c(
                                    "div",
                                    { staticClass: "pictures-cover mask" },
                                    [
                                      _vm._v(
                                        "+" +
                                          _vm._s(_vm.picturesList.length - 3)
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  item.label === "pictures" && _vm.picturesList.length <= 0
                    ? _c("div", { staticClass: "check-content" }, [
                        _vm._v("未添加图片内容")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showPictureBorder(index, item, "bottom")
                    ? _c("div", { staticClass: "pictures-border" })
                    : _vm._e(),
                  _vm._v(" "),
                  item.label !== "pictures" &&
                  item.label !== "synonymList" &&
                  item.label !== "nearList" &&
                  item.label !== "antonymList"
                    ? _c("div", {
                        staticClass: "check-content",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.getRichText(
                              item.content ? item.content : "未添加内容"
                            )
                          )
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.options.isCharacterHidden &&
                  _vm.hiddenNum < _vm.checkList.length
                    ? _c("more-bottom", {
                        attrs: {
                          "cur-index": index,
                          options: _vm.options,
                          "is-last": _vm.checkList.length - 1 === index,
                          "is-more": _vm.isMore
                        },
                        on: { handleMore: _vm.handleMore }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }