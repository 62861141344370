var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "partner-logo",
        _vm.element.options.style === "tile" ? "tile-logo" : "other-logo"
      ]
    },
    [
      _c("el-image", {
        class: [
          "partner-logo-img",
          _vm.element.options.style === "tile" ? "tile-logo" : "other-logo"
        ],
        style: { border: _vm.partnerBorder },
        attrs: {
          fit: "cover",
          src: _vm.getImgUrl(
            _vm.element.content.partnerLogo,
            "style/width-limit-80"
          )
        }
      }),
      _vm._v(" "),
      _vm.element.content.officialPartner
        ? _c("img", {
            staticClass: "partner-type",
            attrs: { src: require("../../../assets/official.png") }
          })
        : _vm.element.content.partnerType === "企业"
        ? _c("img", {
            staticClass: "partner-type",
            attrs: { src: require("../../../assets/official.png") }
          })
        : _vm.element.content.partnerType === "个人"
        ? _c("img", {
            staticClass: "partner-type",
            attrs: { src: require("../../../assets/personal.png") }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }