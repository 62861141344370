var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-row align-center product-shelf-content" },
    [
      _c(
        "div",
        {
          staticClass: "flex-row flex-space-between align-center product-border"
        },
        [
          _c("product-content", {
            attrs: {
              "show-remember": true,
              "height-style": "340px",
              "model-key": _vm.modelKey,
              "result-list": _vm.tableCheckList,
              "product-type": _vm.productType
            },
            on: { handleChecked: _vm.handleChecked }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "select-result" },
        [
          _c("select-result", {
            attrs: {
              type: "cloud_retail_goods",
              "result-list": _vm.resultList
            },
            on: {
              handleResult: _vm.handleResult,
              handleDeleteResult: _vm.handleDeleteResult
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("i", { staticClass: "el-icon-d-arrow-right" })])
  }
]
render._withStripped = true

export { render, staticRenderFns }