var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: ["slide-price"],
      style: { color: _vm.options.priceColor, "font-size": _vm.fontSize }
    },
    [
      _vm.value.price && _vm.value.price !== 0 && !_vm.value.vipOnly
        ? _c("div", { staticClass: "price-div" }, [
            _vm.value.discount > 0 && _vm.value.discount < 1
              ? _c("span", [
                  _c("span", { style: { "font-size": _vm.fontSize } }, [
                    _vm._v(
                      _vm._s(
                        _vm.priceShowByCent(
                          _vm.value.price * _vm.value.discount
                        )
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      class: ["discount-original-price"],
                      style: {
                        color: _vm.originalPriceColor,
                        "font-size": _vm.originalPriceSize
                      }
                    },
                    [_vm._v(_vm._s(_vm.priceShowByCent(_vm.value.price)))]
                  )
                ])
              : _c("span", [
                  _vm._v(_vm._s(_vm.priceShowByCent(_vm.value.price)))
                ])
          ])
        : _vm._e(),
      _vm._v(" "),
      (!_vm.value.price || _vm.value.price === 0) && !_vm.value.vipOnly
        ? _c("span", [_vm._v("免费")])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }