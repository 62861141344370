"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _Catalogue = _interopRequireDefault(require("../Material/Catalogue/Catalogue"));

var _Content = _interopRequireDefault(require("../Material/MaterialContent/Content"));

var _main = _interopRequireDefault(require("@/organizationLibs/powerCache/v1.0/main"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SelectMaterialDialogue',
  components: {
    MaterialContent: _Content.default,
    Catalogue: _Catalogue.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isPersonal: {
      type: Boolean,
      default: false
    },
    materialClassificationOpenId: {
      type: String,
      default: ''
    },
    materialOpenId: {
      type: Number,
      default: undefined
    } // 用于图片回显

  },
  data: function data() {
    return {
      personal: false,
      openId: undefined,
      result: {},
      refresh: false,
      open: false
    };
  },
  methods: {
    initData: function initData() {
      this.open = true;
    },
    refreshCatalogue: function refreshCatalogue(val) {
      this.refresh = val;
    },
    cancelDialogue: function cancelDialogue() {
      this.open = false;
      this.$emit('update:visible', false);
    },
    selectImgResult: function selectImgResult(item) {
      this.result = item;
    },
    submitMaterial: function submitMaterial() {
      if (!this.result || JSON.stringify(this.result) === '{}') {
        this.$message.warning({
          message: '请选择图片'
        });
        return;
      }

      this.$emit('submitMaterial', this.result, this.openId, this.personal);
      this.cancelDialogue();
    },
    handleClick: function handleClick(val, personal) {
      this.openId = val;
      this.personal = personal; // 存入缓存

      _main.default.put('select_material_dialog', {
        classOpenId: val,
        isPersonal: personal
      });
    }
  }
};
exports.default = _default;