"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

var _common = require("../../utils/common");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'PublicWidgetItemNavTitle',
  props: {
    titleData: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    titleColor: {
      type: String,
      default: '#101010'
    },
    iconTitle: {
      type: String,
      default: undefined
    },
    showDictation: {
      type: Boolean,
      default: false
    },
    moreColor: {
      type: String,
      default: '#999999'
    },
    type: {
      type: String,
      default: undefined
    }
  },
  computed: {
    iconTitleStyle: function iconTitleStyle() {
      switch (this.iconTitle) {
        case '认':
          {
            return '#FF8400';
          }

        case '写':
          {
            return '#00A2FF';
          }

        case '音':
          {
            return '#00CCFF';
          }

        default:
          {
            return '#C535EE';
          }
      }
    },
    sizeStyle: function sizeStyle() {
      if (this.type === 'cloud_retail_goods') {
        return '16px';
      }

      if (!this.iconTitle) {
        return '';
      }

      var list = ['认', '写', '音', '词'];
      return list.includes(this.iconTitle) ? '16px' : '';
    }
  },
  methods: {
    // 处理图片
    getImgUrl: function getImgUrl(cover) {
      var style = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'style/width-limit-32';

      if (cover) {
        if (cover.substring(0, 4) === 'http') {
          return cover;
        }
      }

      return (0, _common.imageUrlFun)(cover, style);
    }
  }
};
exports.default = _default2;