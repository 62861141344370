var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select-add-content" },
    [
      _vm.type !== "types_navigate"
        ? _c(
            "div",
            [
              _vm.type !== "micro_video" && _vm.type !== "micro_audio"
                ? _c("span", { staticClass: "common-color-title" }, [
                    _vm._v("设置显示个数(最多30个)")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.proxyNumber,
                  callback: function($$v) {
                    _vm.proxyNumber = $$v
                  },
                  expression: "proxyNumber"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            _vm.type !== "types_navigate"
              ? "select-coupon"
              : "types-nav-select-coupon"
          ]
        },
        [
          _vm.type === "types_navigate" ||
          _vm.type === "knowledge_goods" ||
          _vm.type === "ranking_list" ||
          _vm.type === "cloud_retail_goods"
            ? _c(
                "div",
                [
                  _vm.isShowRuleAdd
                    ? _c(
                        "el-button",
                        {
                          staticClass: "add-btn",
                          attrs: { icon: "el-icon-plus" },
                          on: { click: _vm.addContent }
                        },
                        [_vm._v("添加内容")]
                      )
                    : _c(
                        "div",
                        { staticClass: "flex-row flex-space-between" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "select-result flex-row align-center flex-space-between"
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.ruleData.rule === "custom"
                                      ? "自主选择"
                                      : "规则选择"
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass: "el-icon-edit edit-icon",
                                on: { click: _vm.addContent }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c("el-button", {
                            staticClass:
                              "common-select-content-button common-flex-center",
                            attrs: {
                              type: "primary",
                              icon: "iconfont icon-zhongzhi"
                            },
                            on: { click: _vm.restoreRuleDefault }
                          })
                        ],
                        1
                      )
                ],
                1
              )
            : _c(
                "div",
                [
                  _vm.partsData.length <= 0
                    ? _c(
                        "el-button",
                        {
                          staticClass: "add-btn",
                          attrs: { icon: "el-icon-plus" },
                          on: { click: _vm.addContent }
                        },
                        [_vm._v("添加内容")]
                      )
                    : _c(
                        "div",
                        { staticClass: "flex-row flex-space-between" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "select-result flex-row align-center flex-space-between"
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "共选择" +
                                    _vm._s(_vm.partsData.length) +
                                    "个" +
                                    _vm._s(_vm.titleName)
                                )
                              ]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass: "el-icon-edit edit-icon",
                                on: { click: _vm.addContent }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c("el-button", {
                            staticClass:
                              "common-select-content-button common-flex-center",
                            attrs: {
                              type: "primary",
                              icon: "iconfont icon-zhongzhi"
                            },
                            on: { click: _vm.restoreDefault }
                          })
                        ],
                        1
                      )
                ],
                1
              )
        ]
      ),
      _vm._v(" "),
      _c("select-rule-and-resource", {
        attrs: {
          visible: _vm.openResource,
          options: _vm.options,
          loading: _vm.loading,
          "model-key": _vm.modelKey,
          "store-name": _vm.storeName,
          "value-result": _vm.ruleData
        },
        on: {
          "update:visible": function($event) {
            _vm.openResource = $event
          },
          selectResourceSubmit: _vm.selectResourceSubmit
        }
      }),
      _vm._v(" "),
      _c("select-resource-not-rule", {
        attrs: {
          visible: _vm.openNotRuleResource,
          "model-key": _vm.modelKey,
          type: _vm.type,
          "value-result": _vm.partsData
        },
        on: {
          "update:visible": function($event) {
            _vm.openNotRuleResource = $event
          },
          selectResourceSubmit: _vm.submitDialogue
        }
      }),
      _vm._v(" "),
      _vm.visible
        ? _c("select-dialogue", {
            attrs: {
              loading: _vm.loading,
              visible: _vm.visible,
              "model-key": _vm.modelKey,
              type: _vm.type,
              "value-result": _vm.partsData
            },
            on: {
              "update:visible": function($event) {
                _vm.visible = $event
              },
              submitDialogue: _vm.submitDialogue
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }