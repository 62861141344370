"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAudioList = getAudioList;
exports.getAudioResourceList = getAudioResourceList;
exports.getAudioDetail = getAudioDetail;

var _main = _interopRequireDefault(require("../utils/request/main"));

var _store = _interopRequireDefault(require("../store"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/audio/admin/"));
var requestMp = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/audio/mp/"));
/**
 * 获取音频列表
 * @param {Object} params
 * @returns {Promise<*>}
 */

function getAudioList(params) {
  return request.get('/audio/request/list', {
    params: params
  });
}
/**
 * 获取音频列表(用于商品)
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAudioResourceList(params) {
  if (_store.default.state.microPage.isAdmin) {
    return request.get('/audio/order/list', {
      params: params
    });
  }

  return requestMp.get('/audio/order/list', {
    params: params
  });
}
/**
 * 获取音频详情
 * @param {String} openId
 * @returns {Promise<*>}
 */


function getAudioDetail(openId) {
  return request.get("/audio/request/".concat(openId));
}