var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.options.style === "line"
      ? _c("div", { staticClass: "book-shelf-item" }, [
          _c(
            "div",
            { staticClass: "item-info", style: { height: _vm.lineHeight } },
            [
              _c(
                "div",
                {
                  staticClass: "item-info-position",
                  style: { bottom: _vm.options.showBgImg ? "-5px" : 0 }
                },
                [
                  _c(
                    "div",
                    {
                      class: [
                        "line-cover",
                        _vm.isRowEbook ? "small-cover-ebook" : ""
                      ]
                    },
                    [
                      _c("el-image", {
                        staticClass: "common-shelf-cover",
                        attrs: { fit: "cover", src: _vm.getImgUrl(_vm.value) }
                      }),
                      _vm._v(" "),
                      _vm.value.isAudioBook === 3 &&
                      _vm.value.coverReferenceType === 2
                        ? _c(
                            "div",
                            { staticClass: "ebookcase-position-title" },
                            [_vm._v(_vm._s(_vm.value.title))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.value.isAudioBook === 0
                        ? _c("audio-book-vip-icon", {
                            attrs: {
                              value: _vm.value,
                              "icon-style": { width: "50px", height: "18px" }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: [
                        "flex-column",
                        "line-info",
                        _vm.isRowEbook ? "small-cover-ebook" : ""
                      ],
                      style: {
                        "justify-content":
                          _vm.options.showPrice &&
                          _vm.options.showResourceTitle !== false
                            ? "space-between"
                            : "center"
                      }
                    },
                    [
                      _vm.options.showResourceTitle !== false
                        ? _c(
                            "div",
                            {
                              class: [
                                "common-title-line",
                                !_vm.options.showAllTitle ? "hidden-line" : ""
                              ],
                              style: { color: _vm.options.resourceColor }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.value.isAudioBook !== 1
                                    ? _vm.value.title
                                    : _vm.value.name
                                )
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.options.showPrice && _vm.value.isAudioBook === 0
                        ? _c("audio-book-price", {
                            attrs: { value: _vm.value, options: _vm.options }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _vm.options.showBgImg
            ? _c("img", {
                class: [
                  _vm.options.appearance !== "card"
                    ? "shelf-bg-bottom"
                    : "shelf-bg-bottom-card"
                ],
                attrs: {
                  src: require("../../assets/microPage/shelf-bg-bottom.png")
                }
              })
            : _vm._e()
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.bookCover === 1 && _vm.options.namePosition === "0"
      ? _c(
          "div",
          {
            class: [
              _vm.options.appearance !== "card" ? "big-cover" : "middle-cover"
            ],
            style: { height: _vm.ebookHeight }
          },
          [
            _c("el-image", {
              staticClass: "common-shelf-cover item-absolute",
              style: { height: _vm.pictureHeight },
              attrs: { fit: "cover", src: _vm.getImgUrl(_vm.value) }
            }),
            _vm._v(" "),
            _vm.value.isAudioBook === 3 && _vm.value.coverReferenceType === 2
              ? _c("div", { staticClass: "ebookcase-position-title" }, [
                  _vm._v(_vm._s(_vm.value.title))
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.value.isAudioBook === 0
              ? _c("audio-book-vip-icon", { attrs: { value: _vm.value } })
              : _vm._e()
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.bookCover === 2 && _vm.options.namePosition === "0"
      ? _c(
          "div",
          [
            _vm.options.showResourceTitle !== false
              ? _c(
                  "div",
                  {
                    class: [
                      "common-title-line common-title-top",
                      !_vm.options.showAllTitle ? "hidden-line" : ""
                    ],
                    style: {
                      width:
                        _vm.options.appearance === "card" ? "95px" : "105px",
                      color: _vm.options.resourceColor
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.value.isAudioBook !== 1
                          ? _vm.value.title
                          : _vm.value.name
                      )
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.options.showPrice && _vm.value.isAudioBook === 0
              ? _c("audio-book-price", {
                  attrs: { value: _vm.value, options: _vm.options }
                })
              : _vm._e()
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.options.namePosition === "1" && _vm.options.style !== "line"
      ? _c(
          "div",
          {
            class: [
              _vm.options.appearance !== "card" ? "big-cover" : "middle-cover"
            ],
            style: { height: _vm.ebookHeight }
          },
          [
            _c("el-image", {
              staticClass: "common-shelf-cover item-absolute",
              style: { height: _vm.pictureHeight },
              attrs: { fit: "cover", src: _vm.getImgUrl(_vm.value) }
            }),
            _vm._v(" "),
            _vm.value.isAudioBook === 3 && _vm.value.coverReferenceType === 2
              ? _c("div", { staticClass: "ebookcase-position-title" }, [
                  _vm._v(
                    _vm._s(_vm.value.title) + "/" + _vm._s(_vm.value.price)
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.showTitle
              ? _c(
                  "div",
                  {
                    staticClass: "picture-info flex-column",
                    style: {
                      width:
                        _vm.options.appearance === "card" ? "95px" : "105px"
                    }
                  },
                  [
                    _vm.options.showResourceTitle !== false
                      ? _c(
                          "div",
                          {
                            class: [
                              "book-title",
                              "common-title-line",
                              !_vm.options.showAllTitle ? "hidden-line" : ""
                            ],
                            style: { color: _vm.options.resourceColor }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.value.isAudioBook !== 1
                                  ? _vm.value.title
                                  : _vm.value.name
                              )
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.options.showPrice && _vm.value.isAudioBook === 0
                      ? _c("audio-book-price", {
                          attrs: {
                            value: _vm.value,
                            options: _vm.options,
                            "font-size": "12px",
                            "original-price-size": "12px",
                            "original-price-color": "#FFFFFF"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.value.isAudioBook === 0
              ? _c("audio-book-vip-icon", { attrs: { value: _vm.value } })
              : _vm._e()
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.options.style === "slide"
      ? _c(
          "div",
          { staticClass: "slide-view" },
          [
            _c(
              "div",
              {
                class: [
                  _vm.options.appearance !== "card"
                    ? "big-cover"
                    : "middle-cover"
                ],
                style: { height: _vm.ebookHeight },
                attrs: { fit: "cover", src: _vm.getImgUrl(_vm.value) }
              },
              [
                _c("el-image", {
                  staticClass: "common-shelf-cover item-absolute",
                  style: { height: _vm.pictureHeight },
                  attrs: { fit: "cover", src: _vm.getImgUrl(_vm.value) }
                }),
                _vm._v(" "),
                _vm.value.isAudioBook === 0
                  ? _c("audio-book-vip-icon", { attrs: { value: _vm.value } })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _vm.options.showResourceTitle !== false
              ? _c(
                  "div",
                  {
                    class: [
                      "common-title-line common-title-top",
                      !_vm.options.showAllTitle ? "hidden-line" : ""
                    ],
                    style: {
                      color: _vm.options.resourceColor,
                      "font-size": "14px"
                    }
                  },
                  [_vm._v(_vm._s(_vm.value.title))]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.options.showPrice && _vm.value.isAudioBook === 0
              ? _c("audio-book-price", {
                  attrs: { value: _vm.value, options: _vm.options }
                })
              : _vm._e()
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }