"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLanguage = getLanguage;
exports.default = void 0;

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _vue = _interopRequireDefault(require("vue"));

var _vueI18n = _interopRequireDefault(require("vue-i18n"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var _en = _interopRequireDefault(require("element-ui/lib/locale/lang/en"));

var _zhCN = _interopRequireDefault(require("element-ui/lib/locale/lang/zh-CN"));

var _es = _interopRequireDefault(require("element-ui/lib/locale/lang/es"));

var _en2 = _interopRequireDefault(require("./en"));

var _zh = _interopRequireDefault(require("./zh"));

var _es2 = _interopRequireDefault(require("./es"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

_vue.default.use(_vueI18n.default);

var messages = {
  en: _objectSpread(_objectSpread({}, _en2.default), _en.default),
  zh: _objectSpread(_objectSpread({}, _zh.default), _zhCN.default),
  es: _objectSpread(_objectSpread({}, _es2.default), _es.default)
};

function getLanguage() {
  var chooseLanguage = _jsCookie.default.get('language');

  if (chooseLanguage) return chooseLanguage; // if has not choose language

  var language = (navigator.language || navigator.browserLanguage).toLowerCase();
  var locales = Object.keys(messages);

  for (var _i = 0, _locales = locales; _i < _locales.length; _i++) {
    var locale = _locales[_i];

    if (language.indexOf(locale) > -1) {
      return locale;
    }
  }

  return 'en';
}

var i18n = new _vueI18n.default({
  // set locale
  // options: en | zh | es
  locale: getLanguage(),
  // set locale messages
  messages: messages
});
var _default = i18n;
exports.default = _default;