"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'MoreBottom',
  props: {
    options: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    curIndex: {
      type: Number,
      default: 0
    },
    isMore: {
      type: String,
      default: undefined
    },
    isLast: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hiddenNum: function hiddenNum() {
      if (this.options.hiddenNum) {
        return parseInt(this.options.hiddenNum, 0);
      }

      return 0;
    }
  },
  methods: {
    handleMore: function handleMore() {
      this.$emit('handleMore', this.isMore);
    }
  }
};
exports.default = _default2;