"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _common = require("../../../../../utils/common");

//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'CPMGeContentCommodityCellIndex',
  props: {
    commodity: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  methods: {
    getImgUrlWl32: function getImgUrlWl32(img) {
      return (0, _common.imageUrlFun)(img, 'style/width-limit-32');
    }
  }
};
exports.default = _default2;