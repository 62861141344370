var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "paper-recommend",
      style: { "background-color": _vm.options.color.background }
    },
    [
      _vm.showBakImg
        ? _c("el-image", {
            staticClass: "recommend-bak-img",
            attrs: { src: _vm.getImgUrl(_vm.recommendBakImg) }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { class: ["paper-recommend-info", _vm.showBakImg ? "mask" : ""] },
        [
          _c(
            "span",
            {
              staticClass: "info-name hidden-line",
              style: { color: _vm.showBakImg ? "#FFFFFF" : "#2A536B" }
            },
            [_vm._v(_vm._s(_vm.itemData.name ? _vm.itemData.name : "试卷名称"))]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "info-number-mark",
              style: { color: _vm.showBakImg ? "#FFFFFF" : "#2A536B" }
            },
            [
              _vm._v(
                "共 " +
                  _vm._s(
                    _vm.itemData.questionNum ? _vm.itemData.questionNum : 0
                  ) +
                  " 道题, " +
                  _vm._s(
                    _vm.itemData.paperMark ? _vm.itemData.paperMark : 100
                  ) +
                  "分"
              )
            ]
          ),
          _vm._v(" "),
          _c("el-rate", {
            attrs: {
              value: _vm.difficultDegree,
              disabled: "",
              "text-color": "#ff9900"
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "answer" },
            [
              _c(
                "el-button",
                {
                  staticClass: "answer-btn common-flex-center",
                  attrs: { type: "primary", plain: !_vm.showBakImg }
                },
                [_vm._v("答题")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }