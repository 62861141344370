"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/web.dom.iterable");

var _vue = _interopRequireDefault(require("vue"));

var _vuex = _interopRequireDefault(require("vuex"));

var _vuexPersistedstate = _interopRequireDefault(require("vuex-persistedstate"));

var _getters = _interopRequireDefault(require("./getters"));

var _labevue = _interopRequireDefault(require("./modules/labevue"));

var _syncData = _interopRequireDefault(require("./modules/syncData"));

var _videoBulkUpload = _interopRequireDefault(require("./modules/videoBulkUpload"));

var _dictionary = _interopRequireDefault(require("./modules/dictionary"));

// import partnerInfo from './modules/partnerInfo';
_vue.default.use(_vuex.default); // https://webpack.js.org/guides/dependency-management/#requirecontext


var modulesFiles = require.context('./modules', false, /\.js$/); // you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
// eslint-disable-next-line no-shadow


var modules = modulesFiles.keys().reduce(function (modules, modulePath) {
  // set './app.js' => 'app'
  var moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  var value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});
var store = new _vuex.default.Store({
  modules: modules,
  getters: _getters.default,
  videoBulkUpload: _videoBulkUpload.default,
  labevue: _labevue.default,
  syncData: _syncData.default,
  dictionary: _dictionary.default,
  plugins: [(0, _vuexPersistedstate.default)({
    storage: window.sessionStorage,
    reducer: function reducer(val) {
      return {
        labevue: val.labevue
      };
    }
  })]
});
var _default = store;
exports.default = _default;