"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _common = require("../../../utils/common");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'CloudRetailPartner',
  props: {
    element: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  computed: {
    partnerInfo: function partnerInfo() {
      if (this.$store.state.microPage.partnerInfo) {
        return this.$store.state.microPage.partnerInfo;
      }

      return {};
    },
    infoWidth: function infoWidth() {
      var num = 0;

      if (this.element.options.showAddressIcon) {
        num += 42;
      }

      if (this.element.options.showPhoneIcon) {
        num += 42;
      }

      return "calc( 100% - 72px - ".concat(num, "px )");
    },
    storeAddress: function storeAddress() {
      if (this.element.content.storeAddress) {
        return this.element.content.storeAddress;
      }

      if (!this.partnerInfo.extended) {
        return '';
      }

      return "".concat(this.partnerInfo.extended.areaName).concat(this.partnerInfo.extended.address);
    },
    storePhone: function storePhone() {
      if (this.element.content.storePhone) {
        return this.element.content.storePhone;
      }

      return this.partnerInfo.operatorPhone;
    },
    storeName: function storeName() {
      if (this.element.content.storeName) {
        return this.element.content.storeName;
      }

      return this.partnerInfo.storeName;
    }
  },
  methods: {
    // 处理图片
    getImgUrl: function getImgUrl(cover) {
      var style = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'style/width-limit-32';
      return (0, _common.imageUrlFun)(cover, style);
    }
  }
};
exports.default = _default2;