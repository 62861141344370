var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select-link" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "select-link-dialog",
          attrs: {
            title: "选择链接",
            "append-to-body": "",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "lock-scroll": false,
            visible: _vm.visible,
            width: "1000px",
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            },
            open: _vm.initData
          }
        },
        [
          _c(
            "div",
            { staticClass: "dialog-border" },
            [
              _c(
                "el-tabs",
                {
                  attrs: { "tab-position": "left" },
                  on: { "tab-click": _vm.handleChangeTab },
                  model: {
                    value: _vm.activeName,
                    callback: function($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName"
                  }
                },
                _vm._l(_vm.tabsList, function(item, index) {
                  return _c(
                    "el-tab-pane",
                    { key: index, attrs: { name: item.name } },
                    [
                      _c("div", { attrs: { slot: "label" }, slot: "label" }, [
                        _vm._v(_vm._s(item.label))
                      ])
                    ]
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "select-link-content" },
                [
                  _vm.activeName === "resource"
                    ? _c("select-resource", {
                        ref: "selectResource",
                        attrs: {
                          "show-merchant": _vm.showMerchant,
                          refresh: _vm.refreshResource,
                          "resource-type": _vm.resource.resourceType,
                          "resource-data": _vm.resource
                        },
                        on: {
                          "update:refresh": function($event) {
                            _vm.refreshResource = $event
                          },
                          putSearchStore: _vm.putStore,
                          handleResource: _vm.handleResource
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activeName === "problem"
                    ? _c("common-problem", {
                        attrs: {
                          refresh: _vm.refreshProblem,
                          value: _vm.commonProblem
                        },
                        on: {
                          "update:refresh": function($event) {
                            _vm.refreshProblem = $event
                          },
                          putSearchStore: _vm.putStore,
                          handleProblem: _vm.handleProblem
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activeName === "link"
                    ? _c("external-links", {
                        attrs: {
                          value: _vm.externalLinks,
                          refresh: _vm.refreshLink
                        },
                        on: {
                          "update:refresh": function($event) {
                            _vm.refreshLink = $event
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activeName === "page"
                    ? _c("application-page", {
                        attrs: {
                          "is-cloud-retail-partner": _vm.isCloudRetailPartner,
                          refresh: _vm.refreshPage,
                          value: _vm.appPageVal
                        },
                        on: {
                          "update:refresh": function($event) {
                            _vm.refreshPage = $event
                          },
                          putSearchStore: _vm.putStore,
                          handleAppPage: _vm.handleAppPage
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activeName === "special"
                    ? _c("special-page", { attrs: { value: _vm.special } })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activeName === "coupon"
                    ? _c("coupon", {
                        attrs: {
                          refresh: _vm.refreshCoupon,
                          value: _vm.coupon
                        },
                        on: {
                          "update:refresh": function($event) {
                            _vm.refreshCoupon = $event
                          },
                          putSearchStore: _vm.putStore,
                          handleCoupon: _vm.handleCoupon
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activeName === "audioBook"
                    ? _c("audio-book", {
                        ref: "audioBook",
                        attrs: {
                          refresh: _vm.refreshAudioBook,
                          "book-open-id-val": _vm.audioLink.bookOpenId,
                          value: _vm.audioLink
                        },
                        on: {
                          "update:refresh": function($event) {
                            _vm.refreshAudioBook = $event
                          },
                          putSearchStore: _vm.putStore,
                          handleAudioBook: _vm.handleAudioBook
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activeName === "microPage"
                    ? _c("micro-page", {
                        attrs: {
                          refresh: _vm.refreshMicro,
                          value: _vm.microPage,
                          "is-platform": 0,
                          "partner-open-id": "0"
                        },
                        on: {
                          "update:refresh": function($event) {
                            _vm.refreshMicro = $event
                          },
                          putSearchStore: _vm.putStore,
                          handleMicroPage: _vm.handleMicroPage
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activeName === "partnerMicroPage"
                    ? _c("micro-page", {
                        attrs: {
                          refresh: _vm.refreshPartnerMicro,
                          value: _vm.microPage,
                          "is-platform": 1,
                          "partner-open-id": _vm.microPage.partnerOpenId,
                          "is-cloud-retail-partner": _vm.isCloudRetailPartner
                        },
                        on: {
                          "update:refresh": function($event) {
                            _vm.refreshPartnerMicro = $event
                          },
                          putSearchStore: _vm.putStore,
                          handleMicroPage: _vm.handleMicroPage
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activeName === "applet"
                    ? _c("applet-link", {
                        ref: "applet",
                        attrs: { value: _vm.applet }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activeName === "paper"
                    ? _c("test-paper-link", {
                        attrs: { refresh: _vm.refreshPaper, value: _vm.paper },
                        on: {
                          "update:refresh": function($event) {
                            _vm.refreshPaper = $event
                          },
                          putSearchStore: _vm.putStore,
                          handlePaper: _vm.handlePaper
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activeName === "product"
                    ? _c("select-product", {
                        attrs: {
                          refresh: _vm.refreshPaper,
                          value: _vm.product
                        },
                        on: {
                          "update:refresh": function($event) {
                            _vm.refreshPaper = $event
                          },
                          putSearchStore: _vm.putStore,
                          handleProduct: _vm.handleProduct
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activeName === "cloudPageClass"
                    ? _c("cloud-page-class", {
                        ref: "cloudPageClass",
                        attrs: { refresh: _vm.refreshPaper },
                        on: {
                          "update:refresh": function($event) {
                            _vm.refreshPaper = $event
                          }
                        },
                        model: {
                          value: _vm.cloudPageClass,
                          callback: function($$v) {
                            _vm.cloudPageClass = $$v
                          },
                          expression: "cloudPageClass"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activeName === "bookCollectionActivity"
                    ? _c("book-collection-activity", {
                        attrs: {
                          value: _vm.bookCollectionActivity,
                          refresh: _vm.refreshPaper
                        },
                        on: {
                          "update:refresh": function($event) {
                            _vm.refreshPaper = $event
                          },
                          putSearchStore: _vm.putStore,
                          handleActivity: _vm.handleActivity
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "select-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-checkbox",
                {
                  on: { change: _vm.handleRemember },
                  model: {
                    value: _vm.rememberCurrent,
                    callback: function($$v) {
                      _vm.rememberCurrent = $$v
                    },
                    expression: "rememberCurrent"
                  }
                },
                [_vm._v("记住当前操作")]
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("el-button", { on: { click: _vm.handleClose } }, [
                    _vm._v("取消")
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleSubmit }
                    },
                    [_vm._v("确定")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }