"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getResourceInfo = getResourceInfo;
exports.translationResourceType = translationResourceType;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

function getResourceInfo(_ref) {
  var syncInfoText = _ref.syncInfoText,
      categoryInfoText = _ref.categoryInfoText,
      periodName = _ref.periodName;
  return [syncInfoText, categoryInfoText, periodName].filter(function (i) {
    return !!i;
  }).join(' · ');
}

function translationResourceType(resourceTypeId) {
  var map = {
    1: '视频',
    2: '音频',
    3: '点学书',
    4: '专辑',
    5: '金星币',
    6: 'VIP',
    11: '直播',
    8: '文件'
  };
  return Object.keys(map).includes("".concat(resourceTypeId)) ? map[resourceTypeId] : resourceTypeId;
}