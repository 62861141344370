"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var getters = {
  selectedBook: function selectedBook(state) {
    return state.book.book;
  },
  bookOpenId: function bookOpenId(state) {
    return state.book.bookOpenId;
  },
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  device: function device(state) {
    return state.app.device;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  token: function token(state) {
    return state.user.token;
  },
  userInfo: function userInfo(state) {
    return state.user.info;
  },
  sumstate: function sumstate(state) {
    return state.labevue.sumstate;
  },
  famousTeacherNum: function famousTeacherNum(state) {
    return state.labevue.famousTeacherNum;
  },
  couponNum: function couponNum(state) {
    return state.labevue.couponNum;
  },
  syncObj: function syncObj(state) {
    return state.syncData.syncObj;
  },
  periodArr: function periodArr(state) {
    return state.syncData.periodArr;
  },
  category: function category(state) {
    return state.syncData.category;
  },
  edition: function edition(state) {
    return state.videoBulkUpload.edition;
  },
  stopUpload: function stopUpload(state) {
    return state.videoBulkUpload.stopUpload;
  },
  uploadIndex: function uploadIndex(state) {
    return state.videoBulkUpload.uploadIndex;
  },
  isPauseUpload: function isPauseUpload(state) {
    return state.videoBulkUpload.isPauseUpload;
  },
  openUpload: function openUpload(state) {
    return state.videoBulkUpload.openUpload;
  },
  uploadStep: function uploadStep(state) {
    return state.videoBulkUpload.uploadStep;
  },
  tableData: function tableData(state) {
    return state.videoBulkUpload.tableData;
  },
  selectedArr: function selectedArr(state) {
    return state.videoBulkUpload.selectedArr;
  },
  commentdataList: function commentdataList(state) {
    return state.comment.allCommentList;
  },
  resource: function resource(state) {
    return state.labevue.resource;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  permission_routers: function permission_routers(state) {
    return state.permission.routers;
  },
  addRouters: function addRouters(state) {
    return state.permission.addRouters;
  },
  albumDisabled: function albumDisabled(state) {
    return state.user.albumDisabled;
  }
};
var _default = getters;
exports.default = _default;