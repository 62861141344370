var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "editorLayout" },
    [
      _c("display", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.readonly,
            expression: "readonly"
          }
        ],
        staticClass: "editorDisplay",
        attrs: { content: _vm.valueProxy }
      }),
      _vm._v(" "),
      _c("u-editor", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.readonly,
            expression: "!readonly"
          }
        ],
        ref: "showEditor",
        attrs: {
          config: _vm.editorConfig,
          "is-init-content": _vm.isInitContent
        },
        model: {
          value: _vm.valueProxy,
          callback: function($$v) {
            _vm.valueProxy = $$v
          },
          expression: "valueProxy"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }