"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

//
//
//
//
//
//
//
var _default = {
  name: 'SelectorType',
  props: {
    value: {
      type: Number,
      default: undefined
    },
    expectType: {
      type: String,
      default: 'resourceLink'
    }
  },
  data: function data() {
    return {
      wordContent: [{
        value: 1,
        name: '视频'
      }],
      resourceOption: [{
        value: 1,
        name: '视频'
      }, {
        value: 3,
        name: '点学书'
      }, {
        value: 2,
        name: '音频'
      }, {
        value: 4,
        name: '专辑'
      }, {
        value: 11,
        name: '直播'
      }, {
        value: 8,
        name: '文件'
      }, {
        value: 9,
        name: '图文'
      }],
      albumOption: [{
        value: 1,
        name: '视频'
      }, {
        value: 3,
        name: '点学书'
      }, {
        value: 2,
        name: '音频'
      }, {
        value: 11,
        name: '直播'
      }, {
        value: 8,
        name: '文件'
      }, {
        value: 9,
        name: '图文'
      }],
      famousTeacherOption: [{
        value: 1,
        name: '视频'
      }, {
        value: 3,
        name: '点学书'
      }, {
        value: 4,
        name: '专辑'
      }, {
        value: 2,
        name: '音频'
      }, {
        value: 11,
        name: '直播'
      }, {
        value: 8,
        name: '文件'
      }, {
        value: 9,
        name: '图文'
      }],
      commodityOption: [{
        value: 1,
        name: '视频'
      }, {
        value: 3,
        name: '点学书'
      }, {
        value: 4,
        name: '专辑'
      }, {
        value: 6,
        name: 'VIP'
      }, {
        value: 5,
        name: '金星币'
      }, {
        value: 2,
        name: '音频'
      }, {
        value: 11,
        name: '直播'
      }, {
        value: 8,
        name: '文件'
      }, {
        value: 9,
        name: '图文'
      }],
      resourceLink: [{
        value: 9,
        name: '图文'
      }, {
        value: 2,
        name: '音频'
      }, {
        value: 1,
        name: '视频'
      }, {
        value: 11,
        name: '直播'
      }, {
        value: 10,
        name: '文件'
      }, {
        value: 8,
        name: '文件包'
      }, {
        value: 3,
        name: '点学书'
      }, {
        value: 4,
        name: '专辑'
      }, {
        value: 16,
        name: '大专辑'
      }, {
        value: 19,
        name: '英语会话'
      }]
    };
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    },
    typeOptions: function typeOptions() {
      var option = null;

      if (this.expectType === 'resource') {
        option = this.resourceOption;
      } else if (this.expectType === 'albumContent') {
        option = this.albumOption;
      } else if (this.expectType === 'famousTeacherContent') {
        option = this.famousTeacherOption;
      } else if (this.expectType === 'wordContent') {
        option = this.wordContent;
      } else if (this.expectType === 'resourceLink') {
        option = this.resourceLink;
      } else {
        option = this.commodityOption;
      }

      return option;
    }
  }
};
exports.default = _default;