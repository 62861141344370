"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPaperClass = getPaperClass;
exports.getPaperList = getPaperList;
exports.getQuestionClass = getQuestionClass;
exports.getQuestionList = getQuestionList;
exports.getQuestionPoints = getQuestionPoints;
exports.getQuestionTypeList = getQuestionTypeList;
exports.getQuestionByOpenIds = getQuestionByOpenIds;
exports.getPaperByOpenIds = getPaperByOpenIds;

var _axios = _interopRequireDefault(require("axios"));

var _main = _interopRequireDefault(require("../utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/question/mp"));
var requestClient = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/question/client"));
/**
 * 查询试卷分类
 * @param {Object} params
 * @returns {Promise<*>}
 */

function getPaperClass(params) {
  return request.get('/paperCategory/getPaperCategory', {
    params: params
  });
}
/**
 * 查询试卷列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getPaperList(params) {
  return request.get('/examPaper/findExamPaperPage', {
    params: params
  });
}
/**
 * 查询试题分类
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getQuestionClass(params) {
  return request.get('/questionCategory/getQuestionCategory', {
    params: params
  });
}
/**
 * 获取试题列表
 */


function getQuestionList(params) {
  return request.get('/examQuestion/findQuestionPage', {
    params: params
  });
}
/**
 * 查询试题知识点
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getQuestionPoints(params) {
  return request.get('/knowledge/findKnowledgeList', {
    params: params
  });
}
/**
 * 获取题型列表
 */


function getQuestionTypeList(data) {
  return request.get('/questionType/findQuestionTypeList', {
    params: data
  });
}
/**
 * 根据多个openId查询试题
 *
 * @param {string} data
 * @returns {Promise<*>}
 */


function getQuestionByOpenIds(data) {
  return request.post('/examQuestion/getClientQuestion', data, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
}
/**
 * 多个openIds获取试卷
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getPaperByOpenIds(params) {
  return request.get('/examPaper/getClientExamPaper', {
    params: params
  });
}