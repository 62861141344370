var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "resource-dialogue" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.visible,
            width: "1050px",
            "before-close": _vm.cancelDialogue,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            },
            open: _vm.initData
          }
        },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleTab },
              model: {
                value: _vm.rule,
                callback: function($$v) {
                  _vm.rule = $$v
                },
                expression: "rule"
              }
            },
            [
              _vm.storeName !== "cloud_retail_goods"
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "规则选择", name: "default" } },
                    [
                      _c("rule-content", {
                        ref: "ruleForm",
                        attrs: { "store-name": _vm.storeName },
                        model: {
                          value: _vm.ruleForm,
                          callback: function($$v) {
                            _vm.ruleForm = $$v
                          },
                          expression: "ruleForm"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "自主选择", name: "custom" } },
                [
                  _vm.rule === "custom" &&
                  _vm.storeName !== "cloud_retail_goods"
                    ? _c("shelf-content", {
                        attrs: {
                          "store-name": _vm.storeName,
                          refresh: _vm.refresh,
                          "type-id": _vm.typeId,
                          result: _vm.result
                        },
                        on: {
                          "update:refresh": function($event) {
                            _vm.refresh = $event
                          },
                          handleResult: _vm.handleResult
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.rule === "custom" &&
                  _vm.storeName === "cloud_retail_goods"
                    ? _c("product-shelf-content", {
                        attrs: {
                          "product-type": _vm.options.setPackageBuy
                            ? "single"
                            : "",
                          loading: _vm.loading,
                          "model-key": _vm.modelKey,
                          refresh: _vm.refresh,
                          result: _vm.result
                        },
                        on: {
                          "update:refresh": function($event) {
                            _vm.refresh = $event
                          },
                          handleResult: _vm.handleResult
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _vm.storeName === "cloud_retail_goods" &&
              !_vm.options.setPackageBuy
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "规则选择", name: "default" } },
                    [
                      _c("product-rule-content", {
                        ref: "productForm",
                        model: {
                          value: _vm.productForm,
                          callback: function($$v) {
                            _vm.productForm = $$v
                          },
                          expression: "productForm"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.cancelDialogue } }, [
                _vm._v("取消")
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.handleSubmit }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }