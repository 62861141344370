var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isHidden()
    ? _c(
        "div",
        { staticClass: "word-check-list" },
        [
          _c("div", { staticClass: "check-name" }, [
            _vm._v(_vm._s(_vm.item.name) + ":")
          ]),
          _vm._v(" "),
          _vm.item.label === "etymology" && _vm.item.content
            ? _c("el-image", {
                staticClass: "etymology",
                attrs: { src: _vm.getImgUrl(_vm.item.content) }
              })
            : _vm.item.label === "chineseCharBishun"
            ? _c(
                "div",
                { staticClass: "bishun" },
                [
                  _vm._l(_vm.chineseCharBishun, function(bs, bsIndex) {
                    return _c("div", { key: bsIndex }, [
                      _c("img", {
                        staticClass: "stroke-order-image",
                        attrs: { src: _vm.imageSrc(bs) }
                      })
                    ])
                  }),
                  _vm._v(" "),
                  _vm.chineseCharBishun.length <= 0
                    ? _c("div", [_vm._v("未添加内容")])
                    : _vm._e()
                ],
                2
              )
            : _vm.item.label === "nearCharList" && _vm.item.content
            ? _c("div", [
                _vm.item.content.length > 0
                  ? _c(
                      "div",
                      { staticClass: "near-character" },
                      _vm._l(_vm.item.content, function(item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "near-item check-content"
                          },
                          [_vm._v(_vm._s(item.name))]
                        )
                      }),
                      0
                    )
                  : _c("div", [_vm._v("未添加内容")])
              ])
            : _c("div", {
                staticClass: "check-content",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.getRichText(
                      _vm.item.content ? _vm.item.content : "未添加内容"
                    )
                  )
                }
              }),
          _vm._v(" "),
          _vm.options.isCharacterHidden && _vm.hiddenNum < _vm.lastLength
            ? _c("more-bottom", {
                attrs: {
                  options: _vm.options,
                  "cur-index": _vm.curIndex,
                  "is-more": _vm.isMore,
                  "is-last": _vm.lastLength - 1 === _vm.curIndex
                },
                on: { handleMore: _vm.handleMore }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }