"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'TestPaper',
  props: {
    itemData: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    isOnlyOne: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    difficultDegree: function difficultDegree() {
      if (!this.itemData.difficultDegree) {
        return 0;
      }

      return parseInt(this.itemData.difficultDegree, 0);
    }
  }
};
exports.default = _default2;