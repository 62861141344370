var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "word-content-dialog",
          attrs: {
            title: "选择内容",
            visible: _vm.visible,
            "append-to-body": "",
            "close-on-click-modal": false,
            width: "800px",
            "before-close": _vm.cancel
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "nav flex-row align-center flex-space-between" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.addCustom }
                },
                [_vm._v("添加自定义内容")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "tip flex-row align-center" }, [
                _c("i", { staticClass: "iconfont icon-tuozhuai draggle-icon" }),
                _vm._v("\n        拖动下方内容可进行排序\n      ")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "table" },
            [
              _c(
                "el-table",
                {
                  ref: "multipleTable",
                  staticClass: "word-drag-table",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData,
                    "tooltip-effect": "dark",
                    height: "100%",
                    border: "",
                    "row-key": "label",
                    size: "small"
                  },
                  on: { "selection-change": _vm.handleSelectionChange }
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55" }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "内容" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", {}, [_vm._v(_vm._s(scope.row.name))])
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "操作", width: "170" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            !scope.row.disable
                              ? _c("span", [_vm._v("--")])
                              : _c(
                                  "div",
                                  {
                                    staticClass: "flex-row flex-justify-center"
                                  },
                                  [
                                    _c(
                                      "el-link",
                                      {
                                        staticClass: "edit",
                                        attrs: {
                                          type: "primary",
                                          underline: false
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.editData(scope.row)
                                          }
                                        }
                                      },
                                      [_vm._v("编辑")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-link",
                                      {
                                        attrs: {
                                          type: "danger",
                                          underline: false
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteData(scope.row)
                                          }
                                        }
                                      },
                                      [_vm._v("删除")]
                                    )
                                  ],
                                  1
                                )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.cancel } },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.submit }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.customDialog
        ? _c("word-custom-dialog", {
            attrs: { visible: _vm.customDialog, "edit-data": _vm.customData },
            on: {
              "update:visible": function($event) {
                _vm.customDialog = $event
              },
              submit: _vm.submitCustom
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }