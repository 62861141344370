"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAlbumList = getAlbumList;
exports.getAudioBookRequestList = getAudioBookRequestList;
exports.getVideoRequestList = getVideoRequestList;
exports.getAlbumContent = getAlbumContent;
exports.audioRequestList = audioRequestList;
exports.getCommodityAlbumList = getCommodityAlbumList;
exports.getResourceInfoByOpenId = getResourceInfoByOpenId;
exports.getResourceNum = getResourceNum;
exports.getSearch = getSearch;

var _main = _interopRequireDefault(require("../utils/request/main"));

var _store = _interopRequireDefault(require("../store"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/album-management/admin/"));
var requestMp = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/album-management/mp/"));
var resourceRequest = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/album-management/client/"));
/**
 * 获取专辑列表
 * @param {Object} params
 * @returns {Promise<*>}
 */

function getAlbumList(params) {
  return request.get('/album/request/list', {
    params: params
  });
}
/**
 * 查询资源点学书列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAudioBookRequestList(params) {
  return request.get('/content/request/resource/book/list', {
    params: params
  });
}
/**
 * 查询资源视频列表
 * @param {String} params
 * @returns {Promise<*>}
 */


function getVideoRequestList(params) {
  return request.get('/content/request/resource/video/list', {
    params: params
  });
}
/**
 * 查询资源视频列表
 * @param {String} params
 * @returns {Promise<*>}
 */


function audioRequestList(params) {
  return request.get('/content/request/resource/audio/list', {
    params: params
  });
}
/**
 * 根据专辑openId和menuId查询内容
 * @param {String} id
 * @param {Number} menuId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAlbumContent(id, menuId, params) {
  return request.get("/content/request/list/".concat(id, "/").concat(menuId), {
    params: params
  });
}
/**
 * 分页获取专辑商品信息
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getCommodityAlbumList(params) {
  if (_store.default.state.microPage.isAdmin) {
    return request.get('/album/admin', {
      params: params
    });
  }

  return requestMp.get('/album/mp', {
    params: params
  });
}
/**
 * 根据openId 查询作品
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getResourceInfoByOpenId(params) {
  if (!params) {
    return;
  }

  return request.get('album/by/openIds', {
    params: params
  });
}
/**
 * 商户主页各资源数量
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getResourceNum(params) {
  return resourceRequest.get('album/partner/resourceNum', {
    params: params
  });
}
/**
 * 搜索
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getSearch(params) {
  return request.get('es/search', {
    params: params
  });
}