"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _typeof2 = _interopRequireDefault(require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime-corejs2/helpers/typeof"));

var _collect = _interopRequireDefault(require("collect.js"));

var _main = _interopRequireDefault(require("@/organizationLibs/typeIs/main"));

var _price = require("@/utils/product/price");

var _index = _interopRequireDefault(require("../CommodityCell/index"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'CPMGrContentIndex',
  components: {
    CommodityCell: _index.default
  },
  props: {
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    height: {
      type: String,
      default: undefined
    },
    select: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    selectType: {
      type: String,
      default: undefined
    },
    isMultiple: {
      type: Boolean,
      default: true
    },
    productType: {
      type: String,
      default: undefined
    }
  },
  computed: {
    selectProxy: {
      get: function get() {
        return this.select;
      },
      set: function set(value) {
        this.$emit('update:select', value);
      }
    },
    selectTypeProxy: {
      get: function get() {
        return this.selectType;
      },
      set: function set(value) {
        this.$emit('update:selectType', value);
      }
    }
  },
  watch: {
    data: {
      deep: true,
      handler: function handler() {
        var _this = this;

        this.$nextTick(function () {
          _this.doLayout();
        });
      }
    }
  },
  methods: {
    selectable: function selectable(row) {
      if (this.productType === 'single') {
        if (row.resourceType !== 20) {
          return false;
        }

        return row.specificType <= 0;
      }

      return true;
    },
    resourceTypeFormatter: function resourceTypeFormatter(row, column, cellValue) {
      if (typeof cellValue === 'undefined') {
        return '--';
      }

      if ((0, _typeof2.default)(cellValue) === 'object' && cellValue === null) {
        return '--';
      }

      return {
        20: '实物商品',
        21: '数字商品',
        22: '组合商品'
      }[cellValue];
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.$emit('handleCurrentChange', val);
    },
    clearSelection: function clearSelection() {
      this.$refs.table.clearSelection();
    },
    typeCellClassName: function typeCellClassName(_ref) {
      var row = _ref.row,
          column = _ref.column;

      if (column.property === 'type') {
        return row.type === 1 ? 'type-distribution' : 'type-self-operated';
      }

      return undefined;
    },
    doLayout: function doLayout() {
      this.$refs.table.doLayout();
    },
    toggleRowSelection: function toggleRowSelection(value, isCheck) {
      try {
        if (this.$refs.table) {
          this.$refs.table.toggleRowSelection(value, isCheck);
        }
      } catch (e) {
        console.log(e);
      }
    },
    setCurrentRow: function setCurrentRow(val) {
      if (this.$refs.table) {
        this.$refs.table.setCurrentRow(val);
      }
    },
    toggleAllSelection: function toggleAllSelection(value) {
      this.$refs.table.toggleAllSelection(value);
    },
    hasFormatter: function hasFormatter(row, column, cellValue) {
      return cellValue ? '有' : '无';
    },
    booleanFormatter: function booleanFormatter(row, column, cellValue) {
      return cellValue ? '是' : '否';
    },
    quotaFormatter: function quotaFormatter(row, column, cellValue) {
      return cellValue === 0 ? '不限' : cellValue;
    },
    priceFormatter: function priceFormatter(row, column, cellValue) {
      return (0, _price.fenToYuan)(cellValue);
    },
    discountFormatter: function discountFormatter(row, column, cellValue) {
      var localization = (0, _price.proportionToLocalization)(cellValue);
      var price = (0, _price.fenToYuan)(Math.floor(row.orginalPrice * cellValue));
      return "".concat(localization, " / ").concat(price);
    },
    selectCall: function selectCall(row) {
      this.$refs.table.toggleRowSelection(row);
    },
    handleSelectionChange: function handleSelectionChange(value) {
      this.selectProxy = (0, _collect.default)(value).map(function (i) {
        return i.productOpenId;
      }).all();
      this.updateSelectType(value);
      this.$emit('handleSelectionChange', value);
    },
    updateSelectType: function updateSelectType(value) {
      if (!_main.default.isObject(value) || !_main.default.isArray(value)) {
        this.selectTypeProxy = 'NO';
      }

      if (value.length < 1) {
        this.selectTypeProxy = 'NO';
      } else if (value.length === this.data.length) {
        this.selectTypeProxy = 'ALL';
      } else {
        this.selectTypeProxy = 'HALF';
      }
    }
  }
};
exports.default = _default2;