import { render, staticRenderFns } from "./index.vue?vue&type=template&id=47afe810&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=47afe810&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47afe810",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/front-end_micro-page_oo0i/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('47afe810')) {
      api.createRecord('47afe810', component.options)
    } else {
      api.reload('47afe810', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=47afe810&scoped=true&", function () {
      api.rerender('47afe810', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/SelectRuleAndResource/index.vue"
export default component.exports