"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = handleClipboard;

var _vue = _interopRequireDefault(require("vue"));

var _clipboard = _interopRequireDefault(require("clipboard"));

function clipboardSuccess() {
  _vue.default.prototype.$notify({
    message: '复制成功',
    type: 'success',
    duration: 1500
  });
}

function clipboardError() {
  _vue.default.prototype.$notify({
    message: '复制失败',
    type: 'error'
  });
}

function handleClipboard(_text, event) {
  var clipboard = new _clipboard.default(event.target, {
    text: function text() {
      return _text;
    }
  });
  clipboard.on('success', function () {
    clipboardSuccess();
    clipboard.off('error');
    clipboard.off('success');
    clipboard.destroy();
  });
  clipboard.on('error', function () {
    clipboardError();
    clipboard.off('error');
    clipboard.off('success');
    clipboard.destroy();
  });
  clipboard.onClick(event);
}