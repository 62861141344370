"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var pageList = {
  namespaced: true,
  state: {
    PageList: [],
    selectedPageIndex: 0,
    selectedPageKey: undefined,
    maxPageSequence: 0
  },
  mutations: {
    SET_PAGE_LIST: function SET_PAGE_LIST(state, data) {
      state.PageList = data;
    },
    SET_PAGE_INDEX: function SET_PAGE_INDEX(state, index) {
      state.selectedPageIndex = index;
    },
    SET_PAGE_KEY: function SET_PAGE_KEY(state, key) {
      state.selectedPageKey = key;
    },
    SET_PAGE_SEQUENCE: function SET_PAGE_SEQUENCE(state, sequence) {
      state.maxPageSequence = sequence;
    }
  },
  actions: {
    set_page_list: function set_page_list(_ref, data) {
      var commit = _ref.commit;
      commit('SET_PAGE_LIST', data);
    },
    set_page_index: function set_page_index(_ref2, index) {
      var commit = _ref2.commit;
      commit('SET_PAGE_INDEX', index);
    },
    set_page_key: function set_page_key(_ref3, key) {
      var commit = _ref3.commit;
      commit('SET_PAGE_KEY', key);
    },
    set_page_sequence: function set_page_sequence(_ref4, sequence) {
      var commit = _ref4.commit;
      commit('SET_PAGE_SEQUENCE', sequence);
    }
  }
};
var _default = pageList;
exports.default = _default;