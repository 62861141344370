"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/es6.object.keys");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

var _vuedraggable = _interopRequireDefault(require("vuedraggable"));

var _vueConciseSlider = require("vue-concise-slider");

var _MicroPage = _interopRequireDefault(require("../MicroPage"));

var _microPage = require("../../utils/microPage");

var _DraggableOptions = _interopRequireDefault(require("./DraggableOptions"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var _default2 = {
  name: 'WidgetFormItem',
  components: {
    DraggableOptions: _DraggableOptions.default,
    MicroPageItem: _MicroPage.default,
    slider: _vueConciseSlider.slider,
    slideritem: _vueConciseSlider.slideritem,
    Draggable: _vuedraggable.default
  },
  props: {
    element: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    elementKey: {
      type: String,
      default: undefined
    },
    elementIndex: {
      type: Number,
      default: undefined
    },
    isCloudRetailPartner: {
      type: Boolean,
      default: false
    },
    htmlData: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      sliderinit: {
        pagination: false,
        currentPage: 0
      },
      activeName: 0,
      selectWidget: this.select,
      deleteVisible: false,
      newData: 10
    };
  },
  computed: {
    activeTypeIndex: function activeTypeIndex() {
      var data = this.$store.state.microPage.activeType;

      if (this.$store.state.microPage.activeType) {
        return Number(data.index);
      }

      return undefined;
    },
    activeTypeModel: function activeTypeModel() {
      var data = this.$store.state.microPage.activeType;

      if (this.$store.state.microPage.activeType) {
        return data.model;
      }

      return '';
    }
  },
  watch: {
    element: {
      deep: true,
      immediate: true,
      handler: function handler(val) {
        if (val.type === 'types_navigate') {
          var bigLength = 0;
          val.content.forEach(function (item) {
            bigLength = item.title.length > bigLength ? item.title.length : bigLength;
          });
          this.$set(this.element.options, 'bigLength', bigLength);
        }
      }
    }
  },
  methods: {
    handleMoveEnd: function handleMoveEnd(_ref) {
      var newIndex = _ref.newIndex;
      this.handleActiveIndex({
        model: this.element.content[this.activeTypeIndex].parts[newIndex].model,
        index: newIndex
      });
      this.handleSelectType(this.element.content[this.activeTypeIndex].parts[newIndex]);
    },
    handleTypeChoose: function handleTypeChoose(model, oldIndex) {
      this.handleActiveIndex({
        model: model,
        index: oldIndex
      });
      this.$emit('changeTabs', 'component');
      this.handleSelectType(this.element.content[this.activeName].parts[oldIndex]);
    },
    handleTypeAdd: function handleTypeAdd(evt) {
      this.$emit('changeTabs', 'component');
      var newIndex = evt.newIndex; // 为拖拽到容器的元素添加唯一 key

      var key = "".concat(Date.parse(new Date()), "_").concat(Math.ceil(Math.random() * 99999));
      var data = (0, _microPage.deepCopy)(this.element.content[this.activeName].parts[newIndex]);

      if (data.type === 'types_navigate') {
        this.element.content[this.activeName].parts.splice(newIndex, 1);
        this.$message({
          message: '提示：分类导航不支持套用',
          type: 'warning'
        });
        return;
      }

      this.handleActiveIndex({
        model: "".concat(data.type, "_").concat(key),
        index: newIndex
      });

      if (data.type === 'carousel' || data.type === 'picture_navigate' || data.type === 'image_advert' || data.type === 'image_navigate') {
        var newContent = (0, _microPage.deepCopy)(data.content);
        newContent.parts.forEach(function (item) {
          item.id = "".concat(new Date().getTime()).concat(Math.ceil(Math.random() * 99999));
        });
        data = _objectSpread(_objectSpread({}, data), {}, {
          content: newContent
        });
      }

      var newOptions = (0, _microPage.setAddDraggableData)(data);
      this.$set(this.element.content[this.activeName].parts, newIndex, _objectSpread(_objectSpread({}, newOptions), {}, {
        key: key,
        // 绑定键值
        model: "".concat(data.type, "_").concat(key)
      }));
      this.handleSelectType(this.element.content[this.activeName].parts[newIndex]);
      var stateData = this.$store.state.microPage.currentFilterList;
      stateData.push(data.type);
      this.$store.dispatch('setCurrentFilterList', stateData);
    },
    handleActiveIndex: function handleActiveIndex(val) {
      this.$store.dispatch('setActiveType', val);
    },
    // 分类组件里面的 选择组件
    handleSelectType: function handleSelectType(val) {
      this.$emit('handleSelectWidget', val, true, this.elementIndex);
    },
    // 非分类组件里面的 选择组件
    handleNotTypeSelectWidget: function handleNotTypeSelectWidget(data) {
      this.handleActiveIndex({
        model: undefined,
        index: undefined
      });
      this.$emit('handleSelectWidget', data, false, this.elementIndex);
    },
    getCard: function getCard(val) {
      return val.options.appearance === 'card' ? 'card-all-padding' : '';
    },
    // 处理分类导航 width
    getTypeClass: function getTypeClass(data) {
      var val = data.content.length;
      var titleLength = data.options.hasOwnProperty('bigLength') ? data.options.bigLength : 0;

      if (val === 1 && titleLength <= 19) {
        return '100%';
      }

      if (val === 2 && titleLength <= 9) {
        return '50%';
      }

      if (val === 3 && titleLength <= 5) {
        return '33%';
      }

      if (val === 4 && titleLength <= 3) {
        return '25%';
      }

      return 'auto';
    },
    changeTypeNav: function changeTypeNav(val) {
      var beforeActive = this.activeName;
      this.activeName = val;
      var exitType = false;

      if (!this.element.content[beforeActive].parts) {
        return;
      }

      this.element.content[beforeActive].parts.map(function (item) {
        if (item.type === 'test_questions') {
          exitType = true;
        }
      }); // 有没有试题组件

      if (exitType) {
        var data = this.$store.state.microPage.currentQuestionPlay;

        if (!data) {
          return;
        }

        Bus.$emit('microCurrentQuestionVideo');
      }
    }
  }
};
exports.default = _default2;