var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.titleData.style !== "none"
    ? _c("div", { staticClass: "flex-row flex-space-between public-nav" }, [
        _c(
          "div",
          { staticClass: "flex-row align-center" },
          [
            _vm.iconTitle && _vm.titleData.title
              ? _c(
                  "div",
                  {
                    staticClass: "title-icon",
                    style: { background: _vm.iconTitleStyle }
                  },
                  [_vm._v(_vm._s(_vm.iconTitle))]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.titleData.cover
              ? _c("el-image", {
                  staticClass: "title-cover",
                  attrs: {
                    src: _vm.getImgUrl(
                      _vm.titleData.cover,
                      "style/width-limit-64"
                    )
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "title-font",
                style: { color: _vm.titleColor, "font-size": _vm.sizeStyle }
              },
              [_vm._v(_vm._s(_vm.titleData.title))]
            )
          ],
          1
        ),
        _vm._v(" "),
        _vm.showDictation
          ? _c("div", { staticClass: "dictation" }, [
              _c("img", {
                staticClass: "dictation-img",
                attrs: {
                  src: require("@/assets/wordTrems/terms-dictation.png")
                }
              }),
              _vm._v(" "),
              _c("span", [_vm._v("听写")])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.titleData.isShowMore
          ? _c("div", { staticClass: "flex-row align-center" }, [
              _c(
                "span",
                { staticClass: "more-font", style: { color: _vm.moreColor } },
                [_vm._v(_vm._s(_vm.titleData.more))]
              ),
              _vm._v(" "),
              _c("img", {
                staticClass: "see-more-img",
                attrs: { src: require("@/assets/seeMore.png") }
              })
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }