"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/web.dom.iterable");

var _oss = require("../../../utils/oss");

var _common = require("../../../utils/common");

var _resource = require("../../../utils/resource");

var _ResourceSimple = _interopRequireDefault(require("../../Resource/ResourceItem/ResourceSimple"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'DialogTableContent',
  components: {
    ResourceSimple: _ResourceSimple.default
  },
  props: {
    resourceList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    excludeData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    refresh: {
      type: Boolean,
      default: false
    } // 刷新数据

  },
  data: function data() {
    return {
      ossUrl: _oss.ossUrl,
      showVal: _common.showVal,
      getResourceInfo: _resource.getResourceInfo,
      translationResourceType: _resource.translationResourceType,
      defaultCover: 'https://open.oss.taozhi.online/production/assets/mp/resource/select-resource/book_cover.png?x-oss-process=style%2Fthumbnail'
    };
  },
  watch: {
    refresh: {
      deep: true,
      immediate: true,
      handler: function handler(val) {
        if (val) {
          this.handleInitData();
        }
      }
    }
  },
  methods: {
    handleInitData: function handleInitData() {
      var _this = this;

      if (this.$refs.singleTable) {
        this.$refs.singleTable.setCurrentRow();
      }

      this.$emit('refreshResource', false);

      if (this.value.resourceOpenId && this.resourceList.length > 0) {
        this.resourceList.forEach(function (item) {
          if (_this.value.resourceOpenId === item.resourceOpenId) {
            _this.$refs.singleTable.setCurrentRow(item);
          }
        });
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.$emit('handleResourceContent', val);
    },
    getResourceCoverClass: function getResourceCoverClass(type) {
      return parseInt(type, 10) === 3 ? 'book-cover' : 'resource-cover';
    },
    getResourceCover: function getResourceCover() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          cover = _ref.cover,
          resourceType = _ref.resourceType;

      // 获取封面图片
      if ([5, 6].includes(resourceType)) {
        var name = resourceType === 5 ? 'jinxinbiCover' : 'vipCover';
        return "https://open.oss.taozhi.online/develop/mp/orderCover/".concat(name, ".png?x-oss-process=style%2Fthumbnail");
      }

      return this.ossUrl(cover, this.defaultCover);
    },

    /**
     * 显示标题
     */
    displayTitle: function displayTitle(data) {
      if (data.resourceTitle && data.resourceTitle.trim().length > 0) {
        return data.resourceTitle;
      }

      return data.title;
    },
    selectable: function selectable(_ref2) {
      var itemId = _ref2.resourceOpenId;
      var except = this.excludeData.find(function (_ref3) {
        var resourceOpenId = _ref3.resourceOpenId;
        return resourceOpenId === itemId;
      });
      return !except;
    },
    tableRowClassName: function tableRowClassName(_ref4) {
      var row = _ref4.row;
      return this.selectable(row) ? '' : 'disabled-row';
    }
  }
};
exports.default = _default2;