"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isRefreshTokenExpired = isRefreshTokenExpired;
exports.isAccessTokenExpired = isAccessTokenExpired;
exports.getApiErrorMessage = getApiErrorMessage;
exports.isTaoZhiCloudApi = isTaoZhiCloudApi;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _url = _interopRequireDefault(require("url"));

/**
 * 从 axios 的错误中获取 data
 *
 * @param {Error} error
 * @returns {object}
 */
function getAxiosErrorData(error) {
  try {
    var _error$response = error.response;
    _error$response = _error$response === void 0 ? {} : _error$response;
    var _error$response$data = _error$response.data,
        data = _error$response$data === void 0 ? {} : _error$response$data;
    return data;
  } catch (e) {
    return {};
  }
}
/**
 * 判断错误是否是 refresh token 过期
 *
 * @param {Error} error
 * @returns {boolean}
 */


function isRefreshTokenExpired() {
  var error = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  try {
    var _getAxiosErrorData = getAxiosErrorData(error),
        _getAxiosErrorData$er = _getAxiosErrorData.error,
        errorText = _getAxiosErrorData$er === void 0 ? '' : _getAxiosErrorData$er,
        _getAxiosErrorData$er2 = _getAxiosErrorData.error_description,
        errorDescription = _getAxiosErrorData$er2 === void 0 ? '' : _getAxiosErrorData$er2; // refresh token 过期


    return errorText === 'invalid_token' && errorDescription.indexOf('Invalid refresh token (expired)') === 0 || errorText === 'unauthorized' && errorDescription.indexOf('设备已被移除， 请重新登录！') === 0;
    ;
  } catch (e) {
    console.error(e);
    return false;
  }
}
/**
 * 判断错误是否是 access token 过期
 *
 * @param {Error} error
 * @returns {boolean}
 */


function isAccessTokenExpired() {
  var error = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  try {
    var _getAxiosErrorData2 = getAxiosErrorData(error),
        _getAxiosErrorData2$e = _getAxiosErrorData2.error,
        errorText = _getAxiosErrorData2$e === void 0 ? '' : _getAxiosErrorData2$e,
        _getAxiosErrorData2$e2 = _getAxiosErrorData2.error_description,
        errorDescription = _getAxiosErrorData2$e2 === void 0 ? '' : _getAxiosErrorData2$e2; // refresh token 过期


    return errorText === 'invalid_token' && errorDescription === 'Token has expired';
  } catch (e) {
    console.error(e);
    return false;
  }
}
/**
 * 从错误中提取错误信息
 *
 * @param {Error} error
 * @returns {string}
 */


function getApiErrorMessage() {
  var error = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  try {
    var _getAxiosErrorData3 = getAxiosErrorData(error),
        message = _getAxiosErrorData3.message,
        _getAxiosErrorData3$e = _getAxiosErrorData3.error_description,
        errorDescription = _getAxiosErrorData3$e === void 0 ? '' : _getAxiosErrorData3$e;

    if (errorDescription) {
      return errorDescription;
    }

    if (message) {
      return message;
    }

    return error.message;
  } catch (e) {
    return error.message;
  }
}
/**
 * 判断 url 是不是淘知学堂 cloud 的 api
 *
 * @param {string} url
 * @returns {boolean}
 */


function isTaoZhiCloudApi() {
  var url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

  try {
    var _nodeUrl$parse = _url.default.parse(url),
        hostname = _nodeUrl$parse.hostname;

    return ['api.cloud.taozhi.online', 'api.cloud.dev.taozhi.online', 'api.cloud.staging.taozhi.online'].includes(hostname);
  } catch (e) {
    console.error(e);
    return false;
  }
}