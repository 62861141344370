"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFileList = getFileList;
exports.getFileDetail = getFileDetail;
exports.fileToDraft = fileToDraft;
exports.fileToDraftById = fileToDraftById;
exports.fileToReview = fileToReview;
exports.fileToReviewById = fileToReviewById;
exports.fileHidden = fileHidden;
exports.fileCancelHidden = fileCancelHidden;
exports.fileStopSale = fileStopSale;
exports.fileCancelStopSale = fileCancelStopSale;
exports.fileReview = fileReview;
exports.fileBatchReview = fileBatchReview;
exports.getFileStatusCount = getFileStatusCount;
exports.setFileSequence = setFileSequence;
exports.fileCancelSequence = fileCancelSequence;
exports.fileRecovery = fileRecovery;
exports.fileBatchRecovery = fileBatchRecovery;
exports.delFile = delFile;
exports.fileBatchSetAttribute = fileBatchSetAttribute;
exports.fileBatchIndependentSale = fileBatchIndependentSale;
exports.fileReviewPass = fileReviewPass;
exports.fileReject = fileReject;
exports.fileBatchRefuse = fileBatchRefuse;
exports.fileSavePublish = fileSavePublish;
exports.fileSaveReview = fileSaveReview;
exports.getFileResourceList = getFileResourceList;
exports.getAlbumFileList = getAlbumFileList;
exports.addFileList = addFileList;
exports.getFileListDetail = getFileListDetail;
exports.fileDelete = fileDelete;
exports.getCommodityFileList = getCommodityFileList;
exports.getFileTitleList = getFileTitleList;
exports.getUserdownLogList = getUserdownLogList;
exports.updateFileEncode = updateFileEncode;

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _main = _interopRequireDefault(require("../utils/request/main"));

var _store = _interopRequireDefault(require("../store"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/file-management/admin/filePackage/"));
var requestMp = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/file-management/mp/filePackage/"));
var requestFile = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/file-management/admin/fileContent/"));
var commonRequest = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/file-management/admin/"));
/**
 * 用户下载记录
 * @param {Object} params
 * @returns {Promise<*>}
 */

function getUserdownLogList(params) {
  return commonRequest.get('/downLog', {
    params: params
  });
}
/**
 * 查询文件列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getFileListDetail(params) {
  return requestFile.get('/request/list', {
    params: params
  });
}
/**
 * 批量修改文件(新建 更新)
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function addFileList(openId, data) {
  return requestFile.patch("/request/list/".concat(openId), data);
}
/**
 * 获取文件列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getFileList(params) {
  return request.get('/request/list', {
    params: params
  });
}
/**
 * 获取直播列表(用于商品)
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getFileResourceList(params) {
  return request.get('/order/list', {
    params: params
  });
}
/**
 * 获取直播详情
 * @param {String} openId
 * @returns {Promise<*>}
 */


function getFileDetail(openId) {
  return request.get("/request/".concat(openId));
}
/**
 * 保存至草稿箱(新建)
 * @param {Object} data
 * @returns {Promise<*>}
 */


function fileToDraft(data) {
  return request.post('/request/drafts', data);
}
/**
 * 保存至草稿箱通过Id(修改)
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function fileToDraftById(openId, data) {
  return request.patch("/request/".concat(openId, "/unChange"), data);
}
/**
 * 保存至提交审核
 * @param {Object} data
 * @returns  {Promise<*>}
 */


function fileToReview(data) {
  return request.post('/request/commit', data);
}
/**
 * 保存至提交审核
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function fileToReviewById(openId, data) {
  return request.patch("/request/".concat(openId, "/commit"), data);
}
/**
 * 直播隐藏
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function fileHidden(openId, params) {
  return request.post("/request/".concat(openId, "/hidden"), undefined, {
    params: params
  });
}
/**
 * 直播取消隐藏
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function fileCancelHidden(openId, params) {
  return request.post("/request/".concat(openId, "/cancelHidden"), undefined, {
    params: params
  });
}
/**
 * 直播停售
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function fileStopSale(openId, params) {
  return request.post("/request/".concat(openId, "/stopSale"), undefined, {
    params: params
  });
}
/**
 * 直播取消停售
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function fileCancelStopSale(openId, params) {
  return request.post("/request/".concat(openId, "/cancelStopSale"), undefined, {
    params: params
  });
}
/**
 * 直播-提交审核
 * @param {String} openId
 * @returns {Promise<*>}
 */


function fileReview(openId) {
  return request.post("/request/".concat(openId, "/commit"));
}
/**
 * 直播-批量提交审核
 * @param {String} openId
 * @returns  {Promise<*>}
 */


function fileBatchReview(openIds) {
  return request.post('/request/reviews', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 获取不同状态下的直播数量
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getFileStatusCount(params) {
  return request.get('/request/count', {
    params: params
  });
}
/**
 * 设置推荐
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function setFileSequence(openId, params) {
  return request.patch("/request/".concat(openId, "/sequence"), undefined, {
    params: params
  });
}
/**
 * 取消推荐
 * @param {String} openId
 * @returns  {Promise<*>}
 */


function fileCancelSequence(openId) {
  return request.patch("/request/".concat(openId, "/cancelSequence"));
}
/**
 * 直播-恢复
 * @param {String} openId
 * @returns {Promise<*>}
 */


function fileRecovery(openId) {
  return request.post("/request/".concat(openId, "/recovery"));
}
/**
 * 批量恢复到草稿箱
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function fileBatchRecovery(openIds) {
  return request.post('/request/recovers', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 删除
 * @param {String} openId
 * @returns {Promise<*>}
 */


function delFile(openId) {
  return request.delete("/request/".concat(openId));
}
/**
 * 批量设置属性
 * @param {Object} params
 * @returns {Promise<*>}
 */


function fileBatchSetAttribute(params) {
  return request.patch('/request/attribute', undefined, {
    params: params
  });
}
/**
 * 批量修改是否独立售卖
 * @param {Object} params
 * @returns {Promise<*>}
 */


function fileBatchIndependentSale(params) {
  return request.patch('/request/independentSale', undefined, {
    params: params
  });
}
/**
 * 直播-审核通过
 * @param {String} openId
 * @returns {Promise<*>}
 */


function fileReviewPass(openId) {
  return request.post("/request/".concat(openId, "/review"));
}
/**
 * 直播-保存并发布(新建)
 * @param {Object} data
 * @returns {Promise<*>}
 */


function fileSavePublish(data) {
  return request.post('/request/review', data);
}
/**
 * 直播-保存并发布（修改）
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function fileSaveReview(openId, data) {
  return request.patch("/request/".concat(openId, "/review"), data);
}
/**
 * 直播-驳回
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function fileReject(openId, params) {
  return request.post("/request/".concat(openId, "/reject"), undefined, {
    params: params
  });
}
/**
 * 直播-批量驳回
 * @param {Object} params
 * @returns {Promise<*>}
 */


function fileBatchRefuse(params) {
  return request.post('/request/rejects', undefined, {
    params: params
  });
}
/**
 * 获取文件列表(专辑专用)
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAlbumFileList(params) {
  return request.get('/list', {
    params: _objectSpread({
      isIndSale: 0
    }, params)
  });
}
/**
 * 获取文件列表(赠品专用)
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getCommodityFileList(params) {
  if (_store.default.state.microPage.isAdmin) {
    return request.get('/order/list', {
      params: _objectSpread({
        isIndSale: 0
      }, params)
    });
  }

  return requestMp.get('/order/list', {
    params: _objectSpread({
      isIndSale: 0
    }, params)
  });
}
/**
 * 删除文件
 * @param {Object} params
 * @returns {Promise<*>}
 */


function fileDelete(fileContentOpenId) {
  return requestFile.delete("/request/".concat(fileContentOpenId));
}
/**
 * 获取标题列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getFileTitleList(params) {
  return request.get('/request/repeat/title', {
    params: params
  });
}
/**
 * 修改编码信息
 * @param {String} resourceOpenId
 * @param {String} resourceEncode
 * @returns {Promise<*>}
 */


function updateFileEncode(resourceOpenId, resourceEncode) {
  return request.patch("/resourceEncode/".concat(resourceOpenId), undefined, {
    params: {
      resourceEncode: resourceEncode
    }
  });
}