var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "micro-bookstore" },
    [
      _c("el-image", {
        staticClass: "bg",
        attrs: {
          fit: "cover",
          src:
            "https://open.oss.taozhi.online/production/assets/taozhi/app/static/microPage/micro-bookstore-bg.png"
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "bookstore-position" }, [
        _c(
          "div",
          { staticClass: "flex-row" },
          [
            _vm.partnerInfo.partnerLogo
              ? _c("el-image", {
                  staticClass: "logo",
                  attrs: { src: _vm.partnerInfo.partnerLogo }
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "address-info",
                style: {
                  width: _vm.partnerInfo.partnerLogo
                    ? "calc(100% - 300rpx)"
                    : "calc(100% - 180rpx)"
                }
              },
              [
                _c("div", { staticClass: "shop-name" }, [
                  _vm._v(_vm._s(_vm.partnerInfo.storeName))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "address" }, [
                  _vm._v(_vm._s(_vm.partnerInfo.address))
                ])
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "common-flex to-shop" },
          [
            _vm._v("\n      进店\n      "),
            _c("el-image", {
              staticClass: "right-icon",
              attrs: {
                src:
                  "https://open.oss.taozhi.online/production/assets/taozhi/app/static/microPage/right-icon.png"
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }