"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _SelectResult = _interopRequireDefault(require("../../SelectDialogue/SelectResult"));

var _ProductContent = _interopRequireDefault(require("../../SelectDialogue/TableContent/ProductContent"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'ProductShelfContent',
  components: {
    ProductContent: _ProductContent.default,
    SelectResult: _SelectResult.default
  },
  props: {
    modelKey: {
      type: String,
      default: ''
    },
    // 用于多个书架缓存时区分
    refresh: {
      type: Boolean,
      default: false
    },
    result: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    productType: {
      type: String,
      default: undefined
    }
  },
  data: function data() {
    return {
      resultList: [],
      tableCheckList: []
    };
  },
  watch: {
    refresh: {
      deep: true,
      immediate: true,
      handler: function handler(val) {
        if (val) {
          this.initData();
        }
      }
    }
  },
  methods: {
    initData: function initData() {
      this.tableCheckList = this.result;
      this.resultList = this.result;
      this.$emit('update:refresh', false);
    },
    // 获取列表选中数据
    handleChecked: function handleChecked(val) {
      this.resultList = val;
    },
    handleResult: function handleResult(val) {
      this.resultList = val;
      this.$emit('handleResult', val);
    },
    handleDeleteResult: function handleDeleteResult(val) {
      this.tableCheckList = val;
    }
  }
};
exports.default = _default2;