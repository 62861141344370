"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.function.name");

var _common = require("../../../utils/common");

var _WordCheckList = _interopRequireDefault(require("./WordCheckList"));

var _microWord = require("../../../utils/microWord");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'ReadItem',
  components: {
    WordCheckList: _WordCheckList.default
  },
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    options: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      isMore: undefined
    };
  },
  computed: {
    hiddenNum: function hiddenNum() {
      if (this.options.hiddenNum) {
        return parseInt(this.options.hiddenNum, 0);
      }

      return 0;
    },
    checkList: function checkList() {
      return (0, _microWord.filterCheckList)(this.value);
    },
    newImgSrc: function newImgSrc() {
      if (!this.value.name) {
        return undefined;
      }

      var data = escape(this.value.name).replace(/\%u/g, '');

      if (!data) {
        return undefined;
      }

      return "".concat(process.env.VUE_APP_IMG_URL_PREFIX, "production/assets/characterKaiTi/").concat(data, ".svg");
    }
  },
  methods: {
    handleMore: function handleMore(val) {
      this.isMore = val === 'up' ? 'down' : 'up';
    },
    getPinYin: function getPinYin(val) {
      if (val.pinyin) {
        return val.pinyin;
      }

      return val.pinyinList[0].pinyin;
    },
    // 处理图片
    getImgUrl: function getImgUrl(cover, style) {
      if (!cover) {
        return undefined;
      }

      if (cover.substring(0, 4) === 'http') {
        return cover;
      }

      return (0, _common.imageUrlFun)(cover, style);
    }
  }
};
exports.default = _default2;