"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.zpMarketComponents = exports.zpContentComponents = exports.zpNavComponents = exports.zpBasicComponents = void 0;
var zpBasicComponents = [{
  type: 'input',
  id: 1,
  title: '搜索',
  activeIcon: 'material/imageList/ff78a25d2e7284e89d069b865c83f056.png',
  defaultIcon: 'material/cover/521548a311cc9959823e510c2371a072.png',
  isActive: false,
  options: {
    appearance: '白色',
    required: false,
    dataType: 'string',
    pattern: '',
    placeholder: '搜索',
    disabled: false,
    color: {
      background: '#ffffff',
      searchBackground: '#ffffff',
      font: '#7A7C7B',
      icon: '#F2272B'
    },
    style: 'cloudRetail'
  }
}, {
  type: 'micro_title',
  id: 2,
  title: '标题',
  activeIcon: 'material/imageList/d72b7bfb70b94a5355cd2be3b0469139.png',
  defaultIcon: 'material/imageList/01df719d17be6a2ffa19cee9b03be3ac.png',
  isActive: false,
  options: {
    appearance: 'open',
    // open：展开; card：卡片
    spacingTop: false,
    //  up：上间距 down：下间距
    spacingBottom: false,
    //  up：上间距 down：下间距
    color: {
      background: '#ffffff',
      mainFont: '#000000',
      subFont: '#999999'
    },
    backgroundImage: 'assets/taozhi/app/static/micro_title_bg.png',
    showBgImg: false
  },
  content: {
    align: 'center',
    mainTitle: '主标题标题名称',
    subTitle: '副标题名称',
    mainSize: 14,
    subSize: 12,
    mainWeight: 400,
    subWeight: 400
  }
}, {
  type: 'rich_text',
  title: '富文本',
  id: 3,
  activeIcon: 'material/imageList/40769ec196cf23483f8a05b7ec45cea0.png',
  defaultIcon: 'material/cover/8aff04243eccc1077f0dd91b8361e1e4.png',
  isActive: false,
  options: {
    appearance: 'open',
    // open：展开; card：卡片
    innerMargin: 'margin',
    // fill margin
    spacingTop: false,
    //  up：上间距 down：下间距
    spacingBottom: false,
    //  up：上间距 down：下间距
    color: {
      background: '#ffffff',
      font: '#000000'
    }
  },
  content: {
    title: '标题名称',
    // 标题
    text: '',
    isShowTitle: true
  }
}, {
  type: 'image_advert',
  title: '图片',
  id: 4,
  activeIcon: 'material/imageList/f8676aa6a33d1169d1d24cc1937b86a6.png',
  defaultIcon: 'material/cover/f39c6e131629beebf64c2c1d2a47a22b.png',
  isActive: false,
  options: {
    appearance: 'open',
    // open：展开; card：卡片
    imageHeight: 200,
    // 高度
    innerMargin: 'margin',
    // fill margin
    spacingTop: false,
    //  up：上间距 down：下间距
    spacingBottom: false,
    //  up：上间距 down：下间距
    color: {
      background: '#ffffff',
      font: '#000000'
    }
  },
  content: {
    size: 1,
    // -1 轮播  1-3正常
    parts: [{
      image: '',
      id: 1
    }]
  }
}, {
  type: 'carousel',
  title: '轮播图',
  id: 5,
  activeIcon: 'material/imageList/e7c45c0156eca98e0c0e87da9dda0af8.png',
  defaultIcon: 'material/cover/5af30589261e514e25c5c3e20a164fdf.png',
  isActive: false,
  options: {
    style: 'carousel',
    // carousel：轮播; slide：左右滑动
    imageHeight: 150,
    // 图片高度
    spacingTop: false,
    //  up：上间距 down：下间距
    spacingBottom: false,
    //  up：上间距 down：下间距
    immerse: false,
    // 是否为沉浸式
    color: {
      background: '#ffffff',
      font: '#000000'
    }
  },
  content: {
    size: 2,
    parts: [{
      image: '',
      id: 1
    }, {
      image: '',
      id: 2
    }]
  }
}, {
  type: 'countdown',
  title: '倒计时',
  id: 13,
  activeIcon: 'material/countdow-active.png',
  defaultIcon: 'material/countdown.png',
  isActive: false,
  options: {
    style: 'one',
    // one 样式1 two样式2
    spacingTop: false,
    //  up：上间距 down：下间距
    spacingBottom: false,
    //  up：上间距 down：下间距
    appearance: 'open',
    color: {
      background: '#ffffff',
      font: '#000000',
      // 标题
      timeTxtColor: '#000000',
      // 时间文字颜色
      timeBgColor: '#F0F2F5',
      // 时间背景颜色
      timeSymbolColor: '#000000',
      // 时间符号颜色
      tipColor: '#ffffff'
    },
    backgroundImage: 'material/cloud-partner-coudown-bg.png',
    showBgImg: true,
    bgMode: 'custom',
    // custom自定义 default模版
    modeType: ''
  },
  content: {
    title: '标题名称',
    startTime: '',
    endTime: '',
    notStartTitle: '活动还未开始，敬请期待',
    endTitle: '此次活动已结束',
    showNotStartTitle: true
  }
}, {
  type: 'tool',
  title: '工具栏',
  id: 14,
  activeIcon: 'material/active-tool.png',
  defaultIcon: 'material/tool.png',
  isActive: false,
  options: {
    style: 'row',
    // row 横向 column
    spacingTop: false,
    //  up：上间距 down：下间距
    spacingBottom: false,
    //  up：上间距 down：下间距
    appearance: 'open',
    color: {
      background: '#ffffff',
      font: '#000000' // 标题

    }
  },
  titleData: {
    style: 'custom',
    // default：默认 custom：自定义 none：无
    title: '工具',
    more: '',
    isShowMore: false,
    cover: ''
  },
  setTitle: true,
  content: {
    parts: [{
      nav: '团购接龙',
      title: '团购接龙',
      describe: '社区分享 快速收款',
      show: true
    }, {
      nav: '商品报价',
      title: '商品报价',
      describe: '方案制作 精准报价',
      show: true
    }]
  }
}, {
  type: 'micro_video',
  title: '视频',
  id: 15,
  activeIcon: 'material/cover/5c83cea7e3eb7ade4bae946e295e1774.png',
  defaultIcon: 'material/cover/d3283e26cd12641890ba92b981a4fdf5.png',
  options: {
    appearance: 'card',
    // open：展开; card：卡片
    style: 'line',
    // line:音频列表 column：纵向列表
    spacingTop: false,
    //  up：上间距 down：下间距
    spacingBottom: false,
    //  up：上间距 down：下间距
    innerMargin: 'fill',
    // fill margin
    color: {
      background: '#ffffff',
      font: '#000000'
    },
    autoPlay: false,
    isScreenFull: true,
    // 允许全屏
    screenFull: undefined,
    // 全屏 默认 undefined 横屏 -90 竖屏 0
    screenWidth: 16,
    screenHeight: 9,
    showTitle: false // 显示作品标题

  },
  titleData: {
    style: 'none',
    // default：默认 custom：自定义 none：无
    title: '视频',
    more: undefined,
    isShowMore: false
  },
  content: {
    size: '1',
    parts: []
  }
}];
exports.zpBasicComponents = zpBasicComponents;
var zpNavComponents = [{
  type: 'image_navigate',
  title: '图标导航',
  id: 6,
  activeIcon: 'material/imageList/50f246bb4ad2c77ec7ce752a84104b6a.png',
  defaultIcon: 'material/cover/bc10b826e7e5bfae915fe349e3bb323d.png',
  isActive: false,
  options: {
    appearance: 'open',
    shape: 'round',
    // round：圆; square：方
    spacingTop: false,
    //  up：上间距 down：下间距
    spacingBottom: false,
    //  up：上间距 down：下间距
    color: {
      background: '#ffffff',
      font: '#000000'
    }
  },
  content: {
    // 内容设置
    size: 3,
    parts: [{
      title: '导航1',
      image: '',
      id: 1
    }, {
      title: '导航2',
      image: '',
      id: 2
    }, {
      title: '导航3',
      image: '',
      id: 3
    }]
  }
}, {
  type: 'picture_navigate',
  title: '图片导航',
  id: 7,
  activeIcon: 'material/cover/903c5aaa92928b7b80ef54c0a134bba2.png',
  defaultIcon: 'material/cover/5c9cccfc5c056a6a66f003f6863a2c48.png',
  isActive: false,
  options: {
    appearance: 'open',
    style: 'outsideBottom',
    // outsideBottom 图片下方 innerBottom 图片内下方 innerCenter 图片内居中
    spacingTop: false,
    //  up：上间距 down：下间距
    spacingBottom: false,
    //  up：上间距 down：下间距
    color: {
      background: '#ffffff',
      font: '#000000'
    }
  },
  content: {
    // 内容设置
    size: 3,
    // 单行个数
    parts: [{
      title: '导航1',
      image: '',
      id: 1
    }, {
      title: '导航2',
      image: '',
      id: 2
    }, {
      title: '导航3',
      image: '',
      id: 3
    }]
  }
}, {
  type: 'types_navigate',
  title: '分类导航',
  id: 12,
  activeIcon: 'material/imageList/7f5ca0a3c253b4721edcb846fbabe8ec.png',
  defaultIcon: 'material/cover/0117bd32eb0e255c89f0aa30ce907f31.png',
  isActive: false,
  options: {
    // 基础设置
    appearance: 'open',
    // open：展开; card：卡片
    style: 'line',
    // smallPicture：小图显示 line:图文列表
    spacingTop: false,
    //  up：上间距 down：下间距
    spacingBottom: false,
    //  up：上间距 down：下间距
    color: {
      activityFont: '#F2272B',
      underline: '#F2272B',
      background: '#ffffff',
      font: '#000000'
    }
  },
  content: [{
    title: '分类1',
    id: 1,
    parts: []
  }, {
    title: '分类2',
    id: 2,
    parts: []
  }]
}];
exports.zpNavComponents = zpNavComponents;
var zpContentComponents = [{
  type: 'cloud_retail_goods',
  title: '商品',
  id: 7,
  activeIcon: 'material/imageList/88323d66b9210dbac3d7b2333f47490f.png',
  defaultIcon: 'material/cover/ec08b1283c0c0fdf0667ac0b5ce4c9f4.png',
  isActive: false,
  options: {
    appearance: 'open',
    // open：展开; card：卡片
    style: 'line',
    // smallPicture：小图显示 line:图文列表 card: 卡片列表 slide 滚动 three 一行三列  waterfall 瀑布流
    spacingTop: false,
    //  up：上间距 down：下间距
    spacingBottom: false,
    //  up：上间距 down：下间距
    color: {
      background: '#ffffff',
      font: '#000000'
    },
    setPackageBuy: false,
    packageBuyBtn: '立即下单'
  },
  titleData: {
    style: 'custom',
    // default：默认 custom：自定义 none：无
    title: '商品',
    more: '更多',
    isShowMore: true,
    cover: 'assets/taozhi/shopMiniProgram/home/commdity-icon.png'
  },
  setTitle: true,
  price: {
    showReality: true,
    showOriginal: true,
    showVipIcon: false
  },
  setPrice: true,
  content: {
    rule: 'custom',
    // default：默认 custom：自定义
    size: '2',
    // 数量
    parts: [],
    // 作品选择
    partsOptions: {} // 条件选择

  }
}];
exports.zpContentComponents = zpContentComponents;
var zpMarketComponents = [{
  type: 'cloud_retail_partner',
  title: '商店信息',
  id: 7,
  activeIcon: 'material/imageList/f671d3a4d054dfdebb307d4ca1f99518.png',
  defaultIcon: 'material/cover/9287f7b0ddc8765cf0a7ae842d0a8949.png',
  isActive: false,
  options: {
    // 基础设置
    appearance: 'open',
    // open：展开; card：卡片
    shape: 'round',
    // round：圆; square：方
    spacingTop: false,
    //  up：上间距 down：下间距
    spacingBottom: false,
    //  up：上间距 down：下间距
    showAddressIcon: true,
    showPhoneIcon: false,
    showPhoneTxt: true,
    showAddressTxt: true,
    color: {
      partnerName: '#181818',
      phone: '#7A7C7B',
      background: '#FFFFFF',
      address: '#7A7C7B'
    }
  },
  content: {
    storePhone: '',
    storeAddress: '',
    storeName: ''
  }
}];
exports.zpMarketComponents = zpMarketComponents;