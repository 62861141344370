"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'PanDuan',
  data: function data() {
    return {
      selectRight: 'http://taozhi-open.oss-cn-beijing.aliyuncs.com/develop/assets/taozhi/app/static/question/selectRight.png',
      selectError: 'http://taozhi-open.oss-cn-beijing.aliyuncs.com/develop/assets/taozhi/app/static/question/selectError.png',
      judgeOption: [{
        value: 'yes',
        label: '正确',
        icon: 'http://taozhi-open.oss-cn-beijing.aliyuncs.com/develop/assets/taozhi/app/static/question/NSelectRight.png'
      }, {
        value: 'no',
        label: '错误',
        icon: 'http://taozhi-open.oss-cn-beijing.aliyuncs.com/develop/assets/taozhi/app/static/question/NSelectError.png'
      }]
    };
  }
};
exports.default = _default;