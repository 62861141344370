var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "countdown",
      style: {
        "background-color": _vm.options.color.background,
        "background-image": _vm.bgImg
      }
    },
    [
      _vm.options.style === "one"
        ? _c("div", { staticClass: "one-style" }, [
            _c(
              "div",
              {
                staticClass: "title",
                style: { color: _vm.options.color.font }
              },
              [_vm._v(_vm._s(_vm.value.title))]
            ),
            _vm._v(" "),
            _vm.isNotStart === "start"
              ? _c(
                  "div",
                  {
                    staticClass: "one-time",
                    style: { background: _vm.options.color.timeBgColor }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "txt",
                        style: { color: _vm.options.color.timeTxtColor }
                      },
                      [_vm._v("倒计时")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "common-flex" }, [
                      _c(
                        "span",
                        { style: { color: _vm.options.color.timeTxtColor } },
                        [_vm._v(_vm._s(_vm.hour))]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "one-common-margin",
                          style: { color: _vm.options.color.timeSymbolColor }
                        },
                        [_vm._v("：")]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { style: { color: _vm.options.color.timeTxtColor } },
                        [_vm._v(_vm._s(_vm.minute))]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "one-common-margin",
                          style: { color: _vm.options.color.timeSymbolColor }
                        },
                        [_vm._v("：")]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { style: { color: _vm.options.color.timeTxtColor } },
                        [_vm._v(_vm._s(_vm.second))]
                      )
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isNotStart === "noStart"
              ? _c("div", { staticClass: "one-style-no-start" }, [
                  _vm.value.notStartTitle && _vm.value.showNotStartTitle
                    ? _c(
                        "div",
                        {
                          staticClass: "one-not-start-title",
                          style: { color: _vm.options.color.tipColor }
                        },
                        [_vm._v(_vm._s(_vm.value.notStartTitle))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "one-style-time",
                      style: { background: _vm.options.color.timeBgColor }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "txt",
                          style: { color: _vm.options.color.timeTxtColor }
                        },
                        [_vm._v("活动开始时间：")]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "txt",
                          style: { color: _vm.options.color.timeTxtColor }
                        },
                        [_vm._v(_vm._s(_vm.value.startTime))]
                      )
                    ]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.isNotStart === "end" && _vm.value.endTitle
              ? _c("div", { staticClass: "one-style-no-start" }, [
                  _c(
                    "div",
                    {
                      staticClass: "one-style-time end-one-time",
                      style: { background: _vm.options.color.timeBgColor }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "txt",
                          style: { color: _vm.options.color.timeTxtColor }
                        },
                        [_vm._v(_vm._s(_vm.value.endTitle))]
                      )
                    ]
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.options.style === "two" && _vm.isNotStart !== "noStart"
        ? _c("div", { staticClass: "two-style" }, [
            _c(
              "div",
              {
                staticClass: "title",
                style: { color: _vm.options.color.font }
              },
              [_vm._v(_vm._s(_vm.value.title))]
            ),
            _vm._v(" "),
            _vm.isNotStart === "start"
              ? _c("div", { staticClass: "two-style-flex" }, [
                  _c(
                    "div",
                    {
                      staticClass: "two-style-time",
                      style: {
                        color: _vm.options.color.timeTxtColor,
                        background: _vm.options.color.timeBgColor
                      }
                    },
                    [_vm._v(_vm._s(_vm.hour))]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "two-common-margin",
                      style: { color: _vm.options.color.timeSymbolColor }
                    },
                    [_vm._v("：")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "two-style-time",
                      style: {
                        color: _vm.options.color.timeTxtColor,
                        background: _vm.options.color.timeBgColor
                      }
                    },
                    [_vm._v(_vm._s(_vm.minute))]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "two-common-margin",
                      style: { color: _vm.options.color.timeSymbolColor }
                    },
                    [_vm._v("：")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "two-style-time",
                      style: {
                        color: _vm.options.color.timeTxtColor,
                        background: _vm.options.color.timeBgColor
                      }
                    },
                    [_vm._v(_vm._s(_vm.second))]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.isNotStart === "end" && _vm.value.endTitle
              ? _c(
                  "div",
                  {
                    staticClass: "end-two-style-time",
                    style: {
                      color: _vm.options.color.timeTxtColor,
                      background: _vm.options.color.timeBgColor
                    }
                  },
                  [_vm._v("\n      " + _vm._s(_vm.value.endTitle) + "\n    ")]
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.options.style === "two" && _vm.isNotStart === "noStart"
        ? _c("div", { staticClass: "two-style-no-start" }, [
            _c("div", { staticClass: "two-style-time" }, [
              _c(
                "span",
                {
                  staticClass: "title",
                  style: { color: _vm.options.color.font }
                },
                [_vm._v(_vm._s(_vm.value.title))]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "two-time",
                  style: {
                    color: _vm.options.color.timeTxtColor,
                    background: _vm.options.color.timeBgColor
                  }
                },
                [
                  _vm._v(_vm._s(_vm.value.startTime)),
                  _c("span", { staticClass: "txt" }, [_vm._v("开始")])
                ]
              )
            ]),
            _vm._v(" "),
            _vm.value.notStartTitle && _vm.value.showNotStartTitle
              ? _c(
                  "div",
                  {
                    staticClass: "two-not-start-title",
                    style: { color: _vm.options.color.tipColor }
                  },
                  [_vm._v(_vm._s(_vm.value.notStartTitle))]
                )
              : _vm._e()
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }