var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "coupon-item flex-row align-center",
      style: { width: _vm.couponWidth, height: "78px" }
    },
    [
      !_vm.isSideslip
        ? _c("img", {
            staticClass: "coupon-image",
            attrs: { src: require("@/assets/microPage/coupon-big.png") }
          })
        : _c("img", {
            staticClass: "coupon-image",
            attrs: { src: require("@/assets/microPage/coupon-slide.png") }
          }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "amount-box",
          style: { left: _vm.isSideslip ? "20px" : "45px" }
        },
        [
          _vm.itemInfo.discountType === 1
            ? _c("div", { staticClass: "amount-box-title" }, [
                _vm._v(_vm._s(_vm.itemInfo.usedAmount / 100)),
                _c("text", [_vm._v("元")])
              ])
            : _c("div", { staticClass: "amount-box-title" }, [
                _vm._v(_vm._s(_vm.itemInfo.discount / 10)),
                _c("text", [_vm._v("折")])
              ]),
          _vm._v(" "),
          _vm.itemInfo.thresholdAmount > 0
            ? _c("div", { staticClass: "coupon-describe" }, [
                _vm._v(
                  "满" + _vm._s(_vm.itemInfo.thresholdAmount / 100) + "可减"
                )
              ])
            : _c("div", { staticClass: "coupon-describe" }, [
                _vm._v("使用立享")
              ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "coupon-receive" }, [
        !_vm.isSideslip
          ? _c("div", [_vm._v("立即领取")])
          : _c("div", [_vm._v("领"), _c("br"), _vm._v("取")])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }