"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isInt = isInt;
exports.isFloat = isFloat;
exports.isNaN = isNaN;
exports.isInfinity = isInfinity;
exports.isArray = isArray;
exports.isTrue = isTrue;
exports.isFalse = isFalse;
exports.isNone = isNone;
exports.isTruthy = isTruthy;
exports.isFalsy = isFalsy;
exports.isBlank = isBlank;
exports.isEmpty = isEmpty;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.number.is-integer");

require("core-js/modules/es6.number.is-finite");

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.number.is-nan");

var _type = _interopRequireDefault(require("./base/type"));

var _names = _interopRequireDefault(require("./base/names"));

function isNaN(target) {
  if ((0, _type.default)(target) === _names.default.number) {
    return Number.isNaN(target);
  }

  return false;
}

function isInfinity(target) {
  if ((0, _type.default)(target) === _names.default.number) {
    return !Number.isFinite(target);
  }

  return false;
}

function isInt(target) {
  if ((0, _type.default)(target) === _names.default.number) {
    if (!isNaN(target) && !isInfinity(target)) {
      return Number.isInteger(target);
    }
  }

  return false;
}

function isFloat(target) {
  if ((0, _type.default)(target) === _names.default.number) {
    if (!isNaN(target) && !isInfinity(target)) {
      return !Number.isInteger(target);
    }
  }

  return false;
}

function isArray(target) {
  if ((0, _type.default)(target) === _names.default.object) {
    return target instanceof Array;
  }

  return false;
}

function isTrue(target) {
  if ((0, _type.default)(target) === _names.default.boolean) {
    return target;
  }

  return false;
}

function isFalse(target) {
  if ((0, _type.default)(target) === _names.default.boolean) {
    return !target;
  }

  return false;
}

function isTruthy(target) {
  return !!target;
}

function isFalsy(target) {
  return !target;
}

function isNone(target) {
  return [_names.default.null, _names.default.undefined].includes((0, _type.default)(target));
}

function isBlank(target) {
  if ((0, _type.default)(target) === _names.default.string) {
    // 只对 string 有效
    return target.length < 1;
  }

  return true;
}

function isEmpty(target) {
  if ((0, _type.default)(target) === _names.default.string) {
    // 只对 string 有效
    return target.trim().length < 1;
  }

  return true;
}