var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "partner" }, [
    _vm.element.options.style === "tile"
      ? _c(
          "div",
          { staticClass: "tile" },
          [
            _c(
              "div",
              { staticClass: "tile-partner" },
              [
                _c("partner-logo", {
                  staticClass: "tile-logo",
                  attrs: { element: _vm.element }
                }),
                _vm._v(" "),
                _c("partner-describe", { attrs: { element: _vm.element } })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "follow-btn tile-btn",
                style: {
                  "background-color": _vm.element.followBtn.background,
                  "border-color": _vm.element.followBtn.background,
                  color: _vm.element.followBtn.font
                },
                attrs: { size: "mini", icon: "el-icon-plus", round: "" }
              },
              [_vm._v("关注")]
            )
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.element.options.style === "default"
      ? _c(
          "div",
          { staticClass: "default" },
          [
            _c(
              "el-image",
              {
                staticClass: "background-img",
                style: {
                  height: _vm.element.options.immerse ? "120px" : "108px"
                },
                attrs: {
                  fit: "cover",
                  src: _vm.getImgUrl(_vm.element.options.backgroundImage)
                }
              },
              [
                _c("div", {
                  staticClass: "error-default",
                  attrs: { slot: "error" },
                  slot: "error"
                })
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "default-partner common-flex-center" }, [
              _c(
                "div",
                { staticClass: "partner-info common-flex-center" },
                [
                  _c("partner-logo", { attrs: { element: _vm.element } }),
                  _vm._v(" "),
                  _c("partner-describe", { attrs: { element: _vm.element } }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "follow-btn others-btn",
                      style: {
                        "background-color": _vm.element.followBtn.background,
                        "border-color": _vm.element.followBtn.background,
                        color: _vm.element.followBtn.font
                      },
                      attrs: { size: "mini", round: "" }
                    },
                    [_vm._v("关注")]
                  )
                ],
                1
              )
            ])
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.element.options.style === "center"
      ? _c(
          "div",
          { staticClass: "partner-center" },
          [
            _c(
              "el-image",
              {
                staticClass: "center-img",
                attrs: {
                  fit: "cover",
                  src: _vm.getImgUrl(_vm.element.options.backgroundImage)
                }
              },
              [
                _c("div", {
                  staticClass: "error-default",
                  attrs: { slot: "error" },
                  slot: "error"
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "center-partner-info common-flex-center" },
              [
                _c("partner-logo", { attrs: { element: _vm.element } }),
                _vm._v(" "),
                _c("partner-describe", { attrs: { element: _vm.element } }),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "follow-btn others-btn",
                    style: {
                      "background-color": _vm.element.followBtn.background,
                      "border-color": _vm.element.followBtn.background,
                      color: _vm.element.followBtn.font
                    },
                    attrs: { size: "mini", round: "" }
                  },
                  [_vm._v("关注")]
                )
              ],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }