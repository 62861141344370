"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _main = require("../../organizationLibs/vueSsoSdk/main");

function isToErrorPage(name) {
  return name.trim().indexOf('Error') === 0;
}

function verificationHost(to, from, next) {
  try {
    // 开发环境下不必检查
    if (process.env.VUE_APP_ENVIRONMENT !== 'production') {
      next();
      return;
    } // 如果路由不受保护不必检查


    if (!(0, _main.routerIsProtect)(to)) {
      next();
      return;
    } // 如果是前往其他错误页面也不必检查


    var _to$name = to.name,
        routeName = _to$name === void 0 ? '' : _to$name;

    if (isToErrorPage(routeName) && routeName.trim() !== 'ErrorHost') {
      next();
      return;
    } // 如果当前域名无效


    if (!(0, _main.isEffectiveDomain)()) {
      if (routeName.trim() === 'ErrorHost') {
        next(); // 如果当前页面就是 ErrorHost 则继续
      } else {
        next('/error/hostError'); // 如果当前页面不是 ErrorHost 则跳转到错误页面
      }

      return;
    } // 如果当前域名有效


    if (routeName.trim() === 'ErrorHost') {
      next('/'); // 如果当前页面是前往 ErrorHost 则返回主页

      return;
    } // 当前域名有效，继续


    next();
  } catch (e) {
    console.error(e);
    next();
  }
}

var _default = verificationHost;
exports.default = _default;