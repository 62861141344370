"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSingleFileList = getSingleFileList;
exports.getCommoditySingleFileList = getCommoditySingleFileList;
exports.getAlbumSingleFileList = getAlbumSingleFileList;

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _main = _interopRequireDefault(require("../utils/request/main"));

var _store = _interopRequireDefault(require("../store"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/file-management/admin/file/"));
var requestMp = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/file-management/mp/file/"));
/**
 * 查询文件列表
 * @param {Object} params
 * @returns {Promise<*>}
 */

function getSingleFileList(params) {
  return request.get('/request/list', {
    params: params
  });
}
/**
 * 获取文件列表(赠品专用)
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getCommoditySingleFileList(params) {
  return request.get('/order/list', {
    params: _objectSpread({
      isIndSale: 0
    }, params)
  });
}
/**
 * 获取文件列表(专辑专用)
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAlbumSingleFileList(params) {
  if (_store.default.state.microPage.isAdmin) {
    return request.get('/list', {
      params: _objectSpread({
        isIndSale: 0
      }, params)
    });
  }

  return requestMp.get('/list', {
    params: _objectSpread({
      isIndSale: 0
    }, params)
  });
}