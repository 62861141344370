var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "grading-layout" }, [
    _c("div", { staticClass: "grading-layout-left" }, [_vm._t("left")], 2),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "grading-layout-right" },
      [
        _c("search-group", {
          attrs: {
            placeholder: _vm.searchPlaceholder,
            "show-remember": _vm.showRemember
          },
          on: { "search-changed": _vm.searchChanged },
          model: {
            value: _vm.valueProxy,
            callback: function($$v) {
              _vm.valueProxy = $$v
            },
            expression: "valueProxy"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }