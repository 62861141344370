var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "paper-item" }, [
    _vm.isOnlyOne
      ? _c(
          "div",
          { staticClass: "one-paper" },
          [
            _c("span", { staticClass: "one-paper-name hidden-line" }, [
              _vm._v(_vm._s(_vm.itemData.name))
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "number-mark one-mark" }, [
              _vm._v(
                "共 " +
                  _vm._s(_vm.itemData.questionNum) +
                  " 道题, " +
                  _vm._s(_vm.itemData.paperMark) +
                  "分"
              )
            ]),
            _vm._v(" "),
            _c("el-rate", {
              attrs: {
                value: _vm.difficultDegree,
                disabled: "",
                "text-color": "#ff9900"
              }
            }),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "one-answer-btn common-flex-center",
                attrs: { type: "primary", plain: "" }
              },
              [_vm._v("开始答题")]
            )
          ],
          1
        )
      : _c("div", { staticClass: "flex-column" }, [
          _c("span", { staticClass: "paper-name hidden-line" }, [
            _vm._v(_vm._s(_vm.itemData.name))
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-row flex-space-between" },
            [
              _c("span", { staticClass: "number-mark" }, [
                _vm._v(
                  "共 " +
                    _vm._s(_vm.itemData.questionNum) +
                    " 道题, " +
                    _vm._s(_vm.itemData.paperMark) +
                    "分"
                )
              ]),
              _vm._v(" "),
              _c("el-rate", {
                attrs: {
                  value: _vm.difficultDegree,
                  disabled: "",
                  "text-color": "#ff9900"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "answer" },
            [
              _c(
                "el-button",
                {
                  staticClass: "answer-btn common-flex-center",
                  attrs: { type: "primary", plain: "" }
                },
                [_vm._v("答题")]
              )
            ],
            1
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }