"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

require("core-js/modules/es6.regexp.search");

var _microPage = require("../utils/microPage");

var _main = _interopRequireDefault(require("../organizationLibs/powerCache/v1.0/main"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var _default = {
  name: 'EditIndex',
  created: function created() {
    (0, _microPage.clearAllSelectStore)();
    var query = (0, _microPage.getSearchString)(window.location.search.substr(1)); // 存入缓存

    var now = new Date().getTime();

    var data = _main.default.get('microPageQuery');

    if (!data) {
      data = [];
    }

    if (!query) {
      return;
    }

    query = _objectSpread(_objectSpread({}, query), {}, {
      nowDate: now
    }); // 清除一天之前的缓存

    var list = [];
    data.forEach(function (item) {
      if (item.nowDate > now - 86400000) {
        list.push(item);
      }
    });
    list.push(query);

    _main.default.put('microPageQuery', list);

    if (query.microPageOpenId) {
      this.$router.push({
        path: '/MicroPage',
        query: {
          nowDate: now
        }
      });
    }
  }
};
exports.default = _default;