"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.array.find");

require("core-js/modules/es6.number.constructor");

//
//
//
//
//
//
var _default = {
  name: 'ResourceType',
  props: {
    resourceType: {
      type: Number,
      default: 4
    }
  },
  data: function data() {
    return {
      resourceTypeList: [{
        value: 1,
        name: '视频'
      }, {
        value: 3,
        name: '点学书'
      }, {
        value: 2,
        name: '音频'
      }, {
        value: 4,
        name: '专辑'
      }, {
        value: 11,
        name: '直播'
      }, {
        value: 10,
        name: '文件'
      }, {
        value: 9,
        name: '图文'
      }, {
        value: 8,
        name: '文件包'
      }, {
        value: 16,
        name: '大专辑'
      }],
      type: ''
    };
  },
  computed: {
    name: function name() {
      var _this = this;

      var title = this.resourceTypeList.find(function (item) {
        return item.value === _this.resourceType;
      });
      return title.name;
    }
  },
  watch: {
    resourceType: {
      deep: true,
      immediate: true,
      handler: function handler(val) {
        if (val === 1) {
          this.type = 'danger';
        } else {
          this.type = '';
        }
      }
    }
  }
};
exports.default = _default;