"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _SelectMaterialDialogue = _interopRequireDefault(require("../SelectMaterialDialogue"));

var _SelectOfficial = _interopRequireDefault(require("./SelectOfficial"));

var _common = require("../../utils/common");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var _default2 = {
  name: 'OfficialAccount',
  components: {
    SelectOfficial: _SelectOfficial.default,
    SelectMaterialBtn: _SelectMaterialDialogue.default
  },
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    type: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      visible: false,
      officialBackgroundImg: {},
      appBackgroundImg: {}
    };
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(val) {
        this.$emit('input', val);
      }
    }
  },
  methods: {
    // 处理图片
    getImgUrl: function getImgUrl(cover) {
      var style = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'style/width-limit-80';
      var imgAllUrl = (0, _common.imageUrlFun)(cover, style);
      return imgAllUrl;
    },
    submitHeadMaterial: function submitHeadMaterial(val) {
      this.valueProxy = _objectSpread(_objectSpread({}, this.valueProxy), {}, {
        headImg: val
      });
    },
    submitMaterial: function submitMaterial(val) {
      this.valueProxy = _objectSpread(_objectSpread({}, this.valueProxy), {}, {
        qrcodeUrl: val
      });
    },
    // 保存公众号
    submit: function submit(val) {
      this.valueProxy.headImg = val.headImg;
      this.valueProxy.nickName = val.nickName;
      this.valueProxy.qrcodeUrl = val.ossQrcodeUrl;
      this.valueProxy.wechatBindOpenId = val.wechatBindOpenId;
    }
  }
};
exports.default = _default2;