"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLiveList = getLiveList;
exports.getLiveDetail = getLiveDetail;
exports.liveToDraft = liveToDraft;
exports.liveToDraftById = liveToDraftById;
exports.liveToReview = liveToReview;
exports.liveToReviewById = liveToReviewById;
exports.liveHidden = liveHidden;
exports.liveCancelHidden = liveCancelHidden;
exports.liveStopSale = liveStopSale;
exports.liveCancelStopSale = liveCancelStopSale;
exports.liveReview = liveReview;
exports.liveBatchReview = liveBatchReview;
exports.getLiveStatusCount = getLiveStatusCount;
exports.setLiveSequence = setLiveSequence;
exports.liveCancelSequence = liveCancelSequence;
exports.liveRecovery = liveRecovery;
exports.liveBatchRecovery = liveBatchRecovery;
exports.delLive = delLive;
exports.liveBatchSetAttribute = liveBatchSetAttribute;
exports.liveBatchIndependentSale = liveBatchIndependentSale;
exports.liveReviewPass = liveReviewPass;
exports.liveReject = liveReject;
exports.liveBatchRefuse = liveBatchRefuse;
exports.liveSavePublish = liveSavePublish;
exports.liveSaveReview = liveSaveReview;
exports.getLiveResourceList = getLiveResourceList;
exports.getAlbumLiveList = getAlbumLiveList;
exports.getCommodityLiveList = getCommodityLiveList;
exports.getLiveTitleList = getLiveTitleList;
exports.liveToSaveById = liveToSaveById;
exports.setSyncToVod = setSyncToVod;
exports.getUploadUrl = getUploadUrl;
exports.getUploadResult = getUploadResult;
exports.getUploadInfo = getUploadInfo;
exports.setVideoCategory = setVideoCategory;
exports.getVideoUrl = getVideoUrl;
exports.getVideoStatus = getVideoStatus;
exports.delBjyVideo = delBjyVideo;
exports.getPushLiveUrl = getPushLiveUrl;
exports.getResumeUploadUrl = getResumeUploadUrl;
exports.liveJoiner = liveJoiner;
exports.getOutViewersExcel = getOutViewersExcel;
exports.updateLiveEncode = updateLiveEncode;
exports.getExportAwardStat = getExportAwardStat;
exports.exportAwardStatExcel = exportAwardStatExcel;
exports.liveOverClass = liveOverClass;
exports.getBjyPlayerToken = getBjyPlayerToken;

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _qs = _interopRequireDefault(require("qs"));

var _main = _interopRequireDefault(require("../utils/request/main"));

var _store = _interopRequireDefault(require("../store"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/live/admin/live/"));
var requestMp = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/live/mp/live/"));
var requestBjyApi = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/live/openFeign/live/"));
var bjYRequest = (0, _main.default)(process.env.VUE_APP_BJY_URL, {
  headers: {
    'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
  }
}, {
  filled: false
});
var bjYUploadRequest = (0, _main.default)(process.env.VUE_APP_BJY_URL, {
  headers: {
    'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
  },
  timeout: 1000 * 60 * 30
}, {
  filled: false
});
/**
 * 获取直播列表
 * @param {Object} params
 * @returns {Promise<*>}
 */

function getLiveList(params) {
  return request.get('/request/list', {
    params: params
  });
}
/**
 * 获取直播列表(用于商品)
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getLiveResourceList(params) {
  return request.get('/order/list', {
    params: params
  });
}
/**
 * 获取直播详情
 * @param {String} openId
 * @returns {Promise<*>}
 */


function getLiveDetail(openId) {
  return request.get("/request/".concat(openId));
}
/**
 * 保存至草稿箱(新建)
 * @param {Object} data
 * @returns {Promise<*>}
 */


function liveToDraft(data) {
  return request.post('/request/drafts', data);
}
/**
 * 保存至草稿箱通过Id(修改)
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function liveToDraftById(openId, data) {
  return request.patch("/request/".concat(openId, "/drafts"), data);
}
/**
 * 保存至草稿箱通过Id(修改)
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function liveToSaveById(openId, data) {
  return request.patch("/request/".concat(openId, "/unChange"), data);
}
/**
 * 保存至提交审核
 * @param {Object} data
 * @returns  {Promise<*>}
 */


function liveToReview(data) {
  return request.post('/request/commit', data);
}
/**
 * 保存至提交审核
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function liveToReviewById(openId, data) {
  return request.patch("/request/".concat(openId, "/commit"), data);
}
/**
 * 直播隐藏
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function liveHidden(openId, params) {
  return request.post("/request/".concat(openId, "/hidden"), undefined, {
    params: params
  });
}
/**
 * 直播取消隐藏
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function liveCancelHidden(openId, params) {
  return request.post("/request/".concat(openId, "/cancelHidden"), undefined, {
    params: params
  });
}
/**
 * 直播停售
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function liveStopSale(openId, params) {
  return request.post("/request/".concat(openId, "/stopSale"), undefined, {
    params: params
  });
}
/**
 * 直播取消停售
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function liveCancelStopSale(openId, params) {
  return request.post("/request/".concat(openId, "/cancelStopSale"), undefined, {
    params: params
  });
}
/**
 * 直播-提交审核
 * @param {String} openId
 * @returns {Promise<*>}
 */


function liveReview(openId) {
  return request.post("/request/".concat(openId, "/commit"));
}
/**
 * 直播-批量提交审核
 * @param {String} openId
 * @returns  {Promise<*>}
 */


function liveBatchReview(openIds) {
  return request.post('/request/reviews', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 获取不同状态下的直播数量
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getLiveStatusCount(params) {
  return request.get('/request/count', {
    params: params
  });
}
/**
 * 设置推荐
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function setLiveSequence(openId, params) {
  return request.patch("/request/".concat(openId, "/sequence"), undefined, {
    params: params
  });
}
/**
 * 取消推荐
 * @param {String} openId
 * @returns  {Promise<*>}
 */


function liveCancelSequence(openId) {
  return request.patch("/request/".concat(openId, "/cancelSequence"));
}
/**
 * 直播-恢复
 * @param {String} openId
 * @returns {Promise<*>}
 */


function liveRecovery(openId) {
  return request.post("/request/".concat(openId, "/recovery"));
}
/**
 * 批量恢复到草稿箱
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function liveBatchRecovery(openIds) {
  return request.post('/request/recovers', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 删除
 * @param {String} openId
 * @returns {Promise<*>}
 */


function delLive(openId) {
  return request.delete("/request/".concat(openId));
}
/**
 * 批量设置属性
 * @param {Object} params
 * @returns {Promise<*>}
 */


function liveBatchSetAttribute(params) {
  return request.patch('/request/attribute', undefined, {
    params: params
  });
}
/**
 * 批量修改是否独立售卖
 * @param {Object} params
 * @returns {Promise<*>}
 */


function liveBatchIndependentSale(params) {
  return request.patch('/request/independentSale', undefined, {
    params: params
  });
}
/**
 * 直播-审核通过
 * @param {String} openId
 * @returns {Promise<*>}
 */


function liveReviewPass(openId) {
  return request.post("/request/".concat(openId, "/review"));
}
/**
 * 直播-保存并发布(新建)
 * @param {Object} data
 * @returns {Promise<*>}
 */


function liveSavePublish(data) {
  return request.post('/request/review', data);
}
/**
 * 直播-保存并发布（修改）
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function liveSaveReview(openId, data) {
  return request.patch("/request/".concat(openId, "/review"), data);
}
/**
 * 直播-驳回
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function liveReject(openId, params) {
  return request.post("/request/".concat(openId, "/reject"), undefined, {
    params: params
  });
}
/**
 * 直播-批量驳回
 * @param {Object} params
 * @returns {Promise<*>}
 */


function liveBatchRefuse(params) {
  return request.post('/request/rejects', undefined, {
    params: params
  });
}
/**
 * 获取直播列表(专辑专用)
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getAlbumLiveList(params) {
  return request.get('/list', {
    params: _objectSpread({
      isIndSale: 0
    }, params)
  });
}
/**
 * 获取直播列表(赠品专用)
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getCommodityLiveList(params) {
  if (_store.default.state.microPage.isAdmin) {
    return request.get('/list', {
      params: _objectSpread({
        isIndSale: 0
      }, params)
    });
  }

  return requestMp.get('/list', {
    params: _objectSpread({
      isIndSale: 0
    }, params)
  });
}
/**
 * 获取标题列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getLiveTitleList(params) {
  return request.get('/request/repeat/title', {
    params: params
  });
}
/**
 * 转移视频到vod
 * @param {Object} params
 * @returns {Promise<*>}
 */


function setSyncToVod(params) {
  return request.get('/syncToVod', {
    params: params
  });
}
/**
 * 获取视频/音频上传地址
 * @param {Object} data
 * @returns {Promise<*>}
 */


function getUploadUrl(data) {
  return bjYRequest.post('/openapi/video/getUploadUrl', _qs.default.stringify(data));
}
/**
 * 上传视频/音频
 * @param {Object} data
 * @returns {Promise<*>}
 */


function getUploadResult(requestUrl, data) {
  return bjYUploadRequest.post(requestUrl, data);
}
/**
 * 获取指定ID视频信息
 * @param {Object} data
 * @returns {Promise<*>}
 */


function getUploadInfo(data) {
  return bjYRequest.post('/openapi/video/getInfo', _qs.default.stringify(data));
}
/**
 * 设置视频分类
 * @param {Object} data
 * @returns {Promise<*>}
 */


function setVideoCategory(data) {
  return bjYRequest.post('/openapi/video/setVideoCategory', _qs.default.stringify(data));
}
/**
 * 获取转码后视频/音频地址
 * @param {Object} data
 * @returns {Promise<*>}
 */


function getVideoUrl(data) {
  return bjYRequest.post('/openapi/video/getUrl', _qs.default.stringify(data));
}
/**
 * 查询视频转码状态
 * @param {Object} data
 * @returns {Promise<*>}
 */


function getVideoStatus(data) {
  return bjYRequest.post('/openapi/video/getStatus', _qs.default.stringify(data));
}
/**
 * 获取转码后视频/音频地址
 * @param {Object} data
 * @returns {Promise<*>}
 */


function delBjyVideo(data) {
  return bjYRequest.post('/openapi/video/delete', _qs.default.stringify(data));
}
/**
 * 获取转码后视频/音频地址
 * @param {Object} data
 * @returns {Promise<*>}
 */


function getPushLiveUrl(data) {
  return bjYRequest.post('/openapi/room/getPushLiveUrl', _qs.default.stringify(data));
}
/**
 * 获取断点续传地址
 * @param {Object} data
 * @returns {Promise<*>}
 */


function getResumeUploadUrl(data) {
  return bjYRequest.post('/openapi/video/getResumeUploadUrl', _qs.default.stringify(data));
}
/**
 * 直播间订阅详细
 * @param {Object} params
 * @returns {Promise<*>}
 */


function liveJoiner(params) {
  return request.get('/joiner', {
    params: params
  });
}
/**
 * 直播间订阅详细
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getOutViewersExcel(params) {
  return request.get('/outViewersExcel', {
    params: params
  });
}
/**
 * 修改编码信息
 * @param {String} resourceOpenId
 * @param {String} resourceEncode
 * @returns {Promise<*>}
 */


function updateLiveEncode(resourceOpenId, resourceEncode) {
  return request.patch("/resourceEncode/".concat(resourceOpenId), undefined, {
    params: {
      resourceEncode: resourceEncode
    }
  });
}
/**
 * 获取中奖名单
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getExportAwardStat(params) {
  return request.get('/exportAwardStat', {
    params: params
  });
}
/**
 * 导出获取中奖名单
 * @param {Object} params
 * @returns {Promise<*>}
 */


function exportAwardStatExcel(params) {
  return request.get('/exportAwardStatExcel', {
    params: params
  });
}
/**
 * 批量下课
 * @param {String} resourceOpenIds
 * @returns {Promise<*>}
 */


function liveOverClass(resourceOpenIds) {
  return request.patch('/overClass', null, {
    params: {
      resourceOpenIds: resourceOpenIds
    }
  });
}
/**
 * 获取百家云回放token
 * @param {String} roomId
 * @returns {Promise<*>}
 */


function getBjyPlayerToken(roomId) {
  return requestBjyApi.get('/getPlayerToken', {
    params: {
      roomId: roomId
    }
  });
}