"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

var _main = _interopRequireDefault(require("@/organizationLibs/typeIs/main"));

var _OriginalTagSelect = _interopRequireDefault(require("./OriginalTagSelect"));

//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CGFromSelectorTagSelectDefaultTagSelect',
  components: {
    OriginalTagSelect: _OriginalTagSelect.default
  },
  props: {
    value: {
      type: String,
      default: undefined
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: undefined
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    valueProxy: {
      get: function get() {
        if (this.multiple) {
          if (!_main.default.isString(this.value)) {
            return [];
          }

          if (_main.default.isEmpty(this.value)) {
            return [];
          }

          return this.value.split(',');
        }

        return this.value;
      },
      set: function set(value) {
        if (this.multiple) {
          this.$emit('input', value.join(','));
        } else {
          this.$emit('input', value);
        }
      }
    }
  }
};
exports.default = _default;