"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _microWord = require("../../../utils/microWord");

var _common = require("../../../utils/common");

var _MoreBottom = _interopRequireDefault(require("../Word/MoreBottom"));

var _microQuestion = require("../../../utils/microQuestion");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'Terms',
  components: {
    MoreBottom: _MoreBottom.default
  },
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    options: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      isMore: undefined
    };
  },
  computed: {
    hiddenNum: function hiddenNum() {
      return this.options.hiddenNum ? parseInt(this.options.hiddenNum, 0) : 0;
    },
    checkList: function checkList() {
      var list = JSON.parse(JSON.stringify(this.value.checkList));
      list = list.filter(function (item) {
        return item.check;
      });
      return list;
    },
    picturesList: function picturesList() {
      return this.value.pictures ? JSON.parse(this.value.pictures) : [];
    },
    valueName: function valueName() {
      if (this.value.name.length > 7) {
        return (0, _microQuestion.getChunkArray)(this.value.name, 7);
      }

      return [this.value.name];
    },
    nameStyle: function nameStyle() {
      if (this.options.appearance === 'card') {
        return this.value.name.length > 5 ? {
          'font-size': '22px',
          width: '40px'
        } : {
          'font-size': '28px',
          width: '50px'
        };
      }

      return this.value.name.length > 5 ? {
        'font-size': '26px',
        width: '45px'
      } : {
        'font-size': '32px',
        width: '55px'
      };
    }
  },
  methods: {
    isHidden: function isHidden(index) {
      if (!this.options.isCharacterHidden) {
        return true;
      }

      if (this.isMore === 'up') {
        return true;
      }

      if (this.options.hiddenNum) {
        return parseInt(this.options.hiddenNum, 0) >= index + 1;
      }

      return true;
    },
    showPictureBorder: function showPictureBorder(index, item, type) {
      if (item.label !== 'pictures') {
        return false;
      } // if (this.picturesList.length <= 0) { return false; }


      if (type === 'top' && index > 0) {
        return !!this.checkList[index - 1];
      }

      if (type === 'bottom' && index < this.checkList.length - 1) {
        return !!this.checkList[index + 1];
      }

      return false;
    },
    handleMore: function handleMore() {
      this.isMore = this.isMore === 'up' ? 'down' : 'up';
    },
    getRichText: function getRichText(val) {
      return (0, _microWord.getRich)(val);
    },
    // 处理图片
    getImgUrl: function getImgUrl(cover, style) {
      if (!cover) {
        return undefined;
      }

      if (cover.substring(0, 4) === 'http') {
        return cover;
      }

      return (0, _common.imageUrlFun)(cover, style);
    }
  }
};
exports.default = _default2;