"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCookie = getCookie;
exports.setCookie = setCookie;
exports.removeCookie = removeCookie;
exports.dotTime = dotTime;
exports.msgHandle = msgHandle;
exports.notifyHandle = notifyHandle;
exports.changeUnit = changeUnit;
exports.getImageUrl = getImageUrl;
exports.imageUrlFun = imageUrlFun;
exports.showVal = showVal;
exports.toPercent = toPercent;
exports._debounce = _debounce;
exports.errorUserPhoto = errorUserPhoto;
exports.toStringOrTrim = toStringOrTrim;
exports.ossUploadUrl = ossUploadUrl;
exports.numFormat = numFormat;
exports.successNotice = successNotice;
exports.operatorIDCardFront = operatorIDCardFront;
exports.operatorIDCardBackend = operatorIDCardBackend;
exports.resourcetypetoStr = resourcetypetoStr;
exports.formatRouteName = formatRouteName;
exports.buyTypetoStr = buyTypetoStr;
exports.showPayType = showPayType;
exports.showChannel = showChannel;
exports.productionImgFun = productionImgFun;
exports.audioPath = audioPath;
exports.decryptData = decryptData;
exports.formatNumberWithUnit = formatNumberWithUnit;
exports.filterTime = filterTime;
exports.imageUrlProductionFn = imageUrlProductionFn;

require("core-js/modules/es6.array.find");

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.regexp.to-string");

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var _qs = _interopRequireDefault(require("qs"));

var _elementUi = require("element-ui");

var _url = _interopRequireDefault(require("url"));

var _path = _interopRequireDefault(require("path"));

var _payType = _interopRequireDefault(require("@/assets/json/payType"));

var _cryptoJs = _interopRequireDefault(require("crypto-js"));

var _main = _interopRequireDefault(require("../organizationLibs/typeIs/main"));

/* eslint-disable no-unreachable */
function getCookie(attr) {
  return _jsCookie.default.get(attr);
}

function setCookie(attr, value) {
  return _jsCookie.default.set(attr, value);
}

function removeCookie(attr) {
  return _jsCookie.default.remove(attr);
}

function dotTime(time) {
  var str = '';

  if (time > 60) {
    // eslint-disable-next-line no-unused-expressions,radix
    parseInt(time / 60).toString().length >= 2 ? str = "".concat(parseInt(time / 60).toString(), ":") : str = "0".concat(parseInt(time / 60).toString(), ":"); // eslint-disable-next-line no-unused-expressions

    time % 60 >= 10 ? str += (time % 60).toString() : str += "0".concat((time % 60).toString());
  } else {
    // eslint-disable-next-line no-unused-expressions
    time >= 10 ? str = "00:".concat(time) : str = "00:0".concat(time);
  }

  return str;
}

function msgHandle(msg, level) {
  if (!msg) {
    msg = '获取数据失败';
  }

  (0, _elementUi.Message)({
    message: msg,
    type: level
  });
}

function notifyHandle(title, msg, level) {
  if (!msg) {
    msg = '获取数据失败';
  }

  (0, _elementUi.Notification)({
    title: title,
    message: msg,
    type: level
  });
}

function changeUnit(limit) {
  var size = '';

  if (limit < 0.1 * 1024) {
    // 小于0.1KB，则转化成B
    size = "".concat(limit.toFixed(2), "B");
  } else if (limit < 0.1 * 1024 * 1024) {
    // 小于0.1MB，则转化成KB
    size = "".concat((limit / 1024).toFixed(2), "KB");
  } else if (limit < 0.1 * 1024 * 1024 * 1024) {
    // 小于0.1GB，则转化成MB
    size = "".concat((limit / (1024 * 1024)).toFixed(2), "MB");
  } else {
    // 其他转化成GB
    size = "".concat((limit / (1024 * 1024 * 1024)).toFixed(2), "GB");
  }

  var sizeStr = "".concat(size); // 转成字符串

  var index = sizeStr.indexOf('.'); // 获取小数点处的索引

  var dou = sizeStr.substr(index + 1, 2); // 获取小数点后两位的值

  if (dou === '00') {
    // 判断后两位是否为00，如果是则删除00
    return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2);
  }

  return size;
}

function getImageUrl(url, style) {
  var imgUrl = "".concat(process.env.VUE_APP_IMG_URL_PREFIX + process.env.VUE_APP_OSS_PATH, "/").concat(url);

  if (style && style.length > 0) {
    imgUrl += "?".concat(style);
  }

  return imgUrl;
} // 图片路径拼接新方法


function imageUrlFun(url, style) {
  if (!url) {
    return undefined;
  }

  var query = _qs.default.stringify({
    'x-oss-process': style
  });

  if (url.substring(0, 4) === 'http') {
    return url.includes(process.env.VUE_APP_IMG_URL_PREFIX) ? "".concat(url, "?").concat(query) : url;
  }

  var image = "".concat(process.env.VUE_APP_IMG_URL_PREFIX).concat(process.env.VUE_APP_OSS_PATH, "/").concat(url);
  return query ? "".concat(image, "?").concat(query) : image;
}

function imageUrlProductionFn(url, style) {
  if (!url) {
    return undefined;
  }

  var query = style ? _qs.default.stringify({
    'x-oss-process': style
  }) : '';
  var image = "".concat(process.env.VUE_APP_IMG_URL_PREFIX, "production/").concat(url);
  return query ? "".concat(image, "?").concat(query) : image;
}

function audioPath(url) {
  return "".concat(process.env.VUE_APP_IMG_URL_PREFIX).concat(process.env.VUE_APP_OSS_PATH, "/").concat(url);
}

function productionImgFun(url, style) {
  var image = "".concat(process.env.VUE_APP_IMG_URL_PREFIX).concat(url);

  var query = _qs.default.stringify({
    'x-oss-process': style
  });

  return query ? "".concat(image, "?").concat(query) : image;
}
/* 字段为空 默认值显示为 --*/


function showVal(val) {
  return val === undefined || val === null || val === '' ? '--' : val;
}

function toPercent(point) {
  var str = "".concat(Number(point * 100).toFixed(0), "%");
  return str;
} // 防抖
// eslint-disable-next-line no-underscore-dangle


function _debounce(fn, delay) {
  var delays = delay || 200;
  var timer;
  return function () {
    var th = this; // eslint-disable-next-line prefer-rest-params

    var args = arguments;

    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(function () {
      timer = null;
      fn.apply(th, args);
    }, delays);
  };
}

function errorUserPhoto() {
  // eslint-disable-next-line global-require
  return "this.src=\"".concat(require("../assets/book_cover.png"), "\"");
}
/* 正面证件照 */


function operatorIDCardFront() {
  // eslint-disable-next-line global-require
  return "".concat(require("../assets/operatorIDCardFront.png"));
}
/* 背面证件照 */


function operatorIDCardBackend() {
  // eslint-disable-next-line global-require
  return "".concat(require("../assets/operatorIDCardBackend.png"));
}
/**
 * 将任意对象转换为 string
 *
 * @param {*} target
 * @param {string|undefined} defaultValue
 * @returns {string|undefined}
 */


function anyTypeToString(target, defaultValue) {
  try {
    return _main.default.isString(target) ? target : target.toString();
  } catch (e) {
    return defaultValue;
  }
}
/**
 * 将一个对象转换为 string 并且 trim
 * 主意，如果目标是空字符串，则会变为 undefined
 *
 * @param {*} target
 * @param {string|undefined} defaultValue
 * @returns {string|undefined}
 */


function toStringOrTrim(target) {
  var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;
  // 进行 trim
  var trimed;

  if (_main.default.isString(target)) {
    trimed = target.trim();
  } else {
    trimed = anyTypeToString(target, defaultValue);
  } // 如果 trim 结果是空字符串，则重置为 undefined


  return trimed || undefined;
} // 格式化金额


function numFormat(x) {
  // 强制保留两位小数
  // eslint-disable-next-line no-var
  var f = parseFloat(x); // eslint-disable-next-line no-restricted-globals

  if (isNaN(f)) return 0; // eslint-disable-next-line vars-on-top,no-redeclare,no-var

  var f = Math.round(x * 100) / 100;
  var s = f.toString();
  var rs = s.indexOf('.');

  if (rs < 0) {
    rs = s.length;
    s += '.';
  }

  while (s.length < rs + 1 + 2) {
    s += '0';
  } // 每三位用一个逗号隔开


  var leftNum = s.split('.')[0];
  var rightNum = ".".concat(s.split('.')[1]);
  var result; // 定义数组记录截取后的价格
  // eslint-disable-next-line no-array-constructor

  var resultArray = new Array();

  if (leftNum.length > 3) {
    // eslint-disable-next-line no-var,no-plusplus,no-redeclare,vars-on-top,block-scoped-var
    var i = true; // eslint-disable-next-line block-scoped-var

    while (i) {
      resultArray.push(leftNum.slice(-3));
      leftNum = leftNum.slice(0, leftNum.length - 3);

      if (leftNum.length < 4) {
        // eslint-disable-next-line block-scoped-var
        i = false;
      }
    } // 由于从后向前截取，所以从最后一个开始遍历并存到一个新的数组，顺序调换
    // eslint-disable-next-line no-array-constructor


    var sortArray = new Array(); // eslint-disable-next-line no-var,no-plusplus,no-redeclare,vars-on-top

    for (var i = resultArray.length - 1; i >= 0; i--) {
      sortArray.push(resultArray[i]);
    }

    result = "".concat(leftNum, ",").concat(sortArray.join(',')).concat(rightNum);
  } else {
    result = s;
  }

  return result;
}
/**
 * 获取 oss 中 upload 方式的文件地址，如果是图片，则可以使用 style
 *
 * @param {string} url
 * @param {string} style
 * @returns {string}
 */


function ossUploadUrl(url, style) {
  if (!url) {
    return undefined;
  }

  try {
    // 拼接 url
    var imagePath = _path.default.resolve(process.env.VUE_APP_OSS_PATH, url);

    var image = _url.default.resolve(process.env.VUE_APP_OSS_URL, imagePath); // 格式化 query 参数


    var query = _qs.default.stringify({
      'x-oss-process': style
    }); // 如果有 query 参数，通过 ? 拼接


    return query ? "".concat(image, "?").concat(query) : image;
  } catch (e) {
    console.error(e); // 如果发生错误，回退使用简单模式

    return "".concat(process.env.VUE_APP_OSS_URL).concat(process.env.VUE_APP_ENVIRONMENT, "/").concat(url);
  }
}

function successNotice(_this, title, message) {
  _this.$notify({
    title: _this.$t("alert.".concat(title)),
    message: _this.$t("alert.".concat(message)),
    type: 'success'
  });
}
/* 资源类型转化成文本 */


function resourcetypetoStr(resourceType) {
  switch (Number(resourceType)) {
    case 1:
      return '视频';
      break;

    case 2:
      return '音频';
      break;

    case 3:
      return '点学书';
      break;

    case 4:
      return '专辑';
      break;

    case 5:
      return '金星币';
      break;

    case 6:
      return 'VIP';
      break;

    case 7:
      return '跟读书';
      break;

    case 8:
      return '文件';
      break;

    case 9:
      return '图文';
      break;

    case 11:
      return '直播';
      break;

    default:
      return resourceType || '--';
  }
}
/* 购买类型转化成文本 */


function buyTypetoStr(buyType) {
  switch (Number(buyType)) {
    case 1:
      return '现金';
      break;

    case 2:
      return '金星币';
      break;

    case 3:
      return '激活卡购买';
      break;

    case 4:
      return '赠品';
      break;

    case 5:
      return '后台添加';
      break;

    case 6:
      return '原系统导入';
      break;

    case 7:
      return 'IOS余额';
      break;

    case 8:
      return 'android余额购买';
      break;

    case 9:
      return '活动赠送';
      break;

    default:
      return '--';
  }
}
/* 格式化浏览记录跳转路由名字 */


function formatRouteName(resourceType) {
  if (resourceType === '1') {
    return 'video/index?';
  }

  if (resourceType === '2') {
    return 'audio/index?';
  }

  if (resourceType === '3') {
    return 'audiobook/index?';
  }

  if (resourceType === '4') {
    return 'album/index?';
  }

  if (resourceType === '8') {
    return 'file/file?';
  }

  if (resourceType === '9') {
    return 'imageAndText/imageAndText?';
  }
}
/* 显示支付渠道 */


function showPayType(payType) {
  var channelData = _payType.default.payTypeData.find(function (_ref) {
    var ping_plus_plus_channel = _ref.ping_plus_plus_channel;
    return ping_plus_plus_channel === payType;
  });

  return showVal(channelData ? channelData.title : undefined);
}
/* 显示购买渠道 */


function showChannel(channel) {
  var channelData = this.$store.state.dictionary.service.find(function (_ref2) {
    var openId = _ref2.openId;
    return openId === channel;
  });
  return showVal(channelData ? channelData.title : undefined);
} // aes解密


function decryptData(word) {
  var key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _cryptoJs.default.enc.Utf8.parse(process.env.VUE_APP_NEW_DATA_MICRO);
  var iv = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _cryptoJs.default.enc.Utf8.parse(process.env.VUE_APP_NEW_DATA_TIME_MICRO);

  var encryptedHexStr = _cryptoJs.default.enc.Hex.parse(word);

  var srcs = _cryptoJs.default.enc.Base64.stringify(encryptedHexStr);

  var decrypt = _cryptoJs.default.AES.decrypt(srcs, key, {
    iv: iv,
    mode: _cryptoJs.default.mode.CBC,
    padding: _cryptoJs.default.pad.Pkcs7
  });

  var decryptedStr = decrypt.toString(_cryptoJs.default.enc.Utf8);
  return decryptedStr.toString();
}

function formatNumberWithUnit(allMinute) {
  if (allMinute >= 10000) {
    return "".concat((allMinute / 10000).toFixed(1), "w");
  }

  if (allMinute >= 1000) {
    return "".concat((allMinute / 1000).toFixed(1), "k");
  }

  return allMinute;
}

function filterTime(result, type) {
  if (result <= 0) {
    return '00:00:00';
  }

  var h = Math.floor(result / 3600) < 10 ? "0".concat(Math.floor(result / 3600)) : Math.floor(result / 3600);
  var m = Math.floor(result / 60 % 60) < 10 ? "0".concat(Math.floor(result / 60 % 60)) : Math.floor(result / 60 % 60);
  var s = Math.floor(result % 60) < 10 ? "0".concat(Math.floor(result % 60)) : Math.floor(result % 60);
  return type === 'object' ? {
    h: h,
    m: m,
    s: s
  } : "".concat(h, ":").concat(m, ":").concat(s);
}