var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.hiddenNum === _vm.curIndex + 1 && _vm.isMore !== "up"
      ? _c(
          "div",
          {
            staticClass: "more common-flex-center",
            on: { click: _vm.handleMore }
          },
          [
            _vm._v("\n    查看更多"),
            _c("img", {
              staticClass: "more-img",
              attrs: { src: require("../../../assets/wordTrems/more.png") }
            })
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.isMore === "up" && _vm.isLast
      ? _c(
          "div",
          {
            staticClass: "more common-flex-center",
            on: { click: _vm.handleMore }
          },
          [
            _vm._v("\n    收起"),
            _c("img", {
              staticClass: "more-img",
              attrs: { src: require("../../../assets/wordTrems/more-up.png") }
            })
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }