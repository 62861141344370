"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _common = require("../../../utils/common");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'CloudRetailTool',
  props: {
    options: {
      type: Object,
      default: function _default() {}
    },
    value: {
      type: Object,
      default: function _default() {}
    }
  },
  computed: {
    parts: function parts() {
      return this.value.parts.filter(function (item) {
        return item.show;
      });
    },
    widthStyle: function widthStyle() {
      if (this.options.appearance === 'card') {
        return this.options.style === 'row' ? '150px' : '100%';
      }

      return this.options.style === 'row' ? '165px' : '100%';
    },
    heightStyle: function heightStyle() {
      return this.options.appearance === 'card' ? '87px' : '100px';
    }
  },
  methods: {
    getPath: function getPath(val) {
      if (this.options.style === 'row') {
        var _path = val.nav === '商品报价' ? 'assets/taozhi/shopMiniProgram/home/row-quotation.png' : 'assets/taozhi/shopMiniProgram/home/row-splicing.png';

        return this.getImg(_path, 'style/width-limit-256');
      }

      var path = val.nav === '商品报价' ? 'assets/taozhi/shopMiniProgram/home/column-quotation.png' : 'assets/taozhi/shopMiniProgram/home/column-splicing.png';
      return this.getImg(path, 'style/width-limit-512');
    },
    getImg: function getImg(path, style) {
      return (0, _common.imageUrlProductionFn)(path, style);
    }
  }
};
exports.default = _default2;