"use strict";

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find");

var _Type = _interopRequireDefault(require("@/components/Resource/Selector/Type.vue"));

var _DialogFilterPanel = _interopRequireDefault(require("@/components/Resource/SelectResourceDialog/Header/DialogFilterPanel.vue"));

var _searchbtn = _interopRequireDefault(require("@/components/publictop/searchbtn.vue"));

var _search = _interopRequireDefault(require("@/components/SearchUser/search.vue"));

var _Category = _interopRequireDefault(require("@/components/Resource/Selector/Category.vue"));

var _main = _interopRequireDefault(require("@/organizationLibs/powerCache/v1.0/main"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable */
var _default2 = {
  name: 'DialogFilterTopBar',
  components: {
    SelectParamType: _Type.default,
    SearchButton: _searchbtn.default,
    DialogFilterPanel: _DialogFilterPanel.default,
    SearchUser: _search.default,
    Category: _Category.default
  },
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    expectType: {
      type: String,
      default: 'resource'
    },
    showMerchant: {
      type: Boolean,
      default: false
    },
    showResourceType: {
      type: Boolean,
      default: true
    },
    storeName: {
      type: String,
      default: ''
    },
    refresh: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      resourceList: [{
        lable: '点学书',
        value: 3
      }, {
        lable: '视频',
        value: 1
      }, {
        lable: '音频',
        value: 2
      }, {
        lable: '专辑',
        value: 4
      }, {
        lable: '直播',
        value: 11
      }, {
        lable: '文件包',
        value: 8
      }, {
        lable: '图文',
        value: 9
      }, {
        lable: '文件',
        value: 10
      }, {
        lable: '大专辑',
        value: 16
      }, {
        lable: '英语会话',
        value: 19
      }],
      remember: true
    };
  },
  computed: {
    valueProxy: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    },
    typeName: function typeName() {
      var _this = this;

      var resourceObj = this.resourceList.find(function (resource) {
        return resource.value === _this.valueProxy.resourceType;
      });
      return resourceObj ? resourceObj.lable : '';
    }
  },
  watch: {
    'valueProxy.resourceType': function valueProxyResourceType() {
      this.valueProxy.type = '';
      this.$emit('search');
    },
    'valueProxy.type': function valueProxyType() {
      this.$emit('search');
    },
    'valueProxy.partnerOpenId': function valueProxyPartnerOpenId() {
      this.$emit('search');
    },
    refresh: {
      immediate: true,
      handler: function handler(val) {
        if (!val) {
          return false;
        }

        var store = _main.default.get(this.storeName);

        if (store) {
          this.remember = store.checked !== undefined && store.checked !== null ? store.checked : true;
        }
      }
    }
  },
  methods: {
    initData: function initData() {
      var store = _main.default.get(this.storeName);

      if (store !== 'undefined' && store !== 'null') {
        this.remember = _main.default.get(this.storeName);
      }
    },

    /* 获取选择商户中partnerOpenId */
    receivePartnerId: function receivePartnerId(id) {
      this.valueProxy.partnerOpenId = id;
    },
    searchResource: function searchResource() {
      this.valueProxy.page = 1;
      this.$emit('search');
    },
    handleRemember: function handleRemember(val) {
      var store = _main.default.get(this.storeName);

      if (store) {
        store.checked = val; // 防止先设置搜索条件 在勾选记住

        store.query = store.query ? store.query : this.valueProxy;

        _main.default.put(this.storeName, val ? store : {
          checked: false
        });
      } else {
        _main.default.put(this.storeName, {
          checked: false
        });
      }
    }
  }
};
exports.default = _default2;